<template>
    <div class="site-title">

        <div v-if="title" :class="'subtitle'+(isDark ? ' dark' : '')" v-html="title"/>
        <div :class="'title'+(isDark ? ' dark' : '')+(hasDistance ? ' distance' : '')">
            <span v-html="name"/>
            <slot/>
        </div>

    </div>
</template>

<script>

export default {
    name: 'SiteTitle',
    props: {
        name: String,
        title: String,
        isDark: Boolean,
        hasDistance: {
            type: Boolean,
            default: true,
        }
    },
}

</script>


<style lang="scss" scoped>

.title {
    color: $site-color-hover;
    font-weight: 600;
    font-size: 32px;

    &.dark {
        color: $theme-color-hover;
    }

    &.distance {
        margin-bottom: 40px;
    }
}

.subtitle {
    color: $site-color;
    font-weight: 600;
    font-size: 18px;

    &.dark {
        color: $theme-color;
    }
}

</style>