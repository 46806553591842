<template>
    <GenericCreate
        :action="create" :action-error="error"

        :action-label="$t('create')" :action-loading="loading"

        :description="$t('createNewUserMessage')" :icon-tooltip="$t('createNewUser')"

        :modal-id="modalId" :success-description="$t('users.create.success', {name: itemName, email: itemEmail})"
        :success-title="$t('createdUser')" :title="$t('createNewUser')"

        :trigger="trigger"
        icon="plus"
    >

        <span v-for="(inputField, key) in textFields" :key="inputField.name">
            <TextField v-model="inputField.value" :autocomplete="inputField.autocomplete"
                       :autofocus="inputField.autofocus" :field-key="key"
                       :hasPasswordMeter="inputField.hasPasswordMeter"
                       :icon="inputField.icon"
                       :label="inputField.label"
                       :name="inputField.name"
                       :onEnter="create"
                       :rules="inputField.rules"
                       :type="inputField.type"
                       :validate="trigger"
                       @setValid="setValid"
            />
            <div v-if="key !== textFields.length -1" class="uk-margin-bottom"/>
        </span>

        <div class="uk-margin-top"/>
        <CardSelect v-model="userRole" :options="getRoleOptions()" :rules="textFields[0].rules" :validate="trigger"
                    label="role"
                    label-name="role" label-plural="roles" @setValid="setUserRoleValid"/>

    </GenericCreate>
</template>

<script>

import {postUser} from "@/client/vue-api-client";
import TextField from "../generic/TextField";
import CardSelect from "../CardSelect";
import GenericCreate from "@/components/creates/GenericCreate";
import UIkit from "uikit";

export default {
    name: 'CreateUser',
    components: {
        GenericCreate,
        CardSelect,
        TextField,
    },
    props: {
        value: Boolean,
    },
    data() {
        return {
            modalId: "modal-create-user",
            trigger: 0,

            textFields: [
                {
                    value: "",
                    valid: false,

                    autofocus: true,
                    name: "name",
                    label: "foreAndSurname",
                    rules: "required|alpha_spaces",
                    icon: "user",
                },
                {
                    value: "",
                    valid: false,

                    autofocus: false,
                    name: "email",
                    label: "emailAddress",
                    rules: "required|email",
                    icon: "mail",
                },
                {
                    value: "",
                    valid: false,

                    autofocus: false,
                    name: "new-password",
                    label: "password",
                    rules: "required",
                    icon: "lock",

                    type: "password",
                    autocomplete: "new-password",
                    hasPasswordMeter: true,
                },
            ],

            userRole: "User",
            userRoleValid: true,

            loading: false,
            error: null,
            itemName: "",
            itemEmail: "",
        }
    },
    methods: {
        setValid(valid, fieldKey) {
            this.textFields[fieldKey].valid = valid

        },
        setUserRoleValid(valid) {
            this.userRoleValid = valid;

        },
        create() {
            this.trigger++;

            if (!this.userRoleValid) {
                return;
            }

            for (let i = 0, j = this.textFields.length; i < j; i++) {
                if (!this.textFields[i].valid) {
                    return;
                }
            }

            let self = this;
            self.loading = true;

            postUser({
                user: {
                    name: self.textFields[0].value,
                    mailAddress: self.textFields[1].value,
                    password: self.textFields[2].value,
                    roleId: self.userRole,
                },

            }).then(function () {
                self.itemName = self.textFields[0].value;
                self.itemEmail = self.textFields[1].value;

                self.loading = false;

                UIkit.modal("#" + self.modalId + "-success").show();
                self.$emit('afterSuccess');

                for (let i = 0, j = self.textFields.length; i < j; i++) {
                    self.textFields[i].value = "";
                    self.textFields[i].valid = false;
                }
                self.userRole = "User";

            }).catch(function (e) {
                self.checkGenericError(e);

                self.error = e;
                self.loading = false;

            })
        },
        getRoleOptions() {
            let allowedRoles = this.getAllowedRoles;
            let preparedRoles = []

            for (let i = 0, j = allowedRoles.length; i < j; i++) {
                preparedRoles.push(
                    {
                        nameIcon: 'tag',
                        name: this.$t(allowedRoles[i]),
                        value: allowedRoles[i],
                    }
                );
            }

            return preparedRoles;
        },


    }
}

</script>

<style lang="scss" scoped>

</style>
