<template>
    <div class="login">

        <Seo :title="$t('login')"/>
        <div v-if="!totpEnabled">

            <SiteTitle :name="$t('loginWelcomeMessage')" class="uk-text-center"/>
            <TextField :ref="email" v-model="email" :onEnter="login" :validate="validationTrigger"
                       autofocus
                       icon="mail" label="emailAddress" name="email" rules="required|email" @setValid="setEmailValid"/>

            <div class="uk-margin-top"/>
            <TextField :ref="password" v-model="password" :onEnter="login" :validate="validationTrigger"
                       icon="lock"
                       label="password" name="password" rules="required" type="password" @setValid="setPasswordValid"/>

            <div class="uk-margin-top"/>
            <LinkItem to="resetpassword">
                {{ $t('forgotQuestion') }}
            </LinkItem>

            <div v-if="loginError" class="uk-animation-fade">
                <div class="uk-margin-medium-top"/>
                <InfoBox mode="danger">
                    <ErrorMessage :custom-errors="[{code: 401, message: $t('loginFailedMessage')}]"
                                  :error="loginError"/>
                </InfoBox>
            </div>

            <div class="uk-margin-medium-top"/>
            <Button :action="login" :is-loading="loading" :text="$t('login')" has-action is-large is-max mode="theme"/>

            <div class="uk-margin-top"/>
            <Checkbox v-if="getCookieConsetAccepted" v-model="remember" label="rememberLogin" name="remember"/>
            <div v-else :uk-tooltip="'title:'+$t('needCookiesAcceptMessage')">
                <Checkbox disabled label="rememberLogin" name="remember"/>
            </div>

        </div>
        <div v-else class="uk-animation-fade">
            <SiteTitle :name="$t('twoFactorAuthentication')" class="uk-text-center"/>

            <TextField :ref="authenticationCode" v-model="authenticationCode" :onEnter="login"
                       :validate="validationTrigger" autofocus
                       icon="lock" label="authenticationCode" name="authenticationCode"
                       rules="required" @setValid="setAuthenticationCodeValid"/>

            <div v-if="loginError" class="uk-animation-fade">
                <div class="uk-margin-medium-top"/>
                <InfoBox mode="danger">
                    <ErrorMessage :custom-errors="[{code: 401, message: $t('authenticationCodeError')}]"
                                  :error="loginError"/>
                </InfoBox>
            </div>

            <div class="uk-margin-medium-top"/>
            <Button :action="login" :is-loading="loading" :text="$t('authenticate')" has-action is-large is-max
                    mode="theme"/>
        </div>

        <hr class="uk-margin-medium-top uk-margin-medium-bottom"/>

        <div class="uk-grid-small uk-grid-match uk-flex uk-flex-middle" uk-grid>
            <div class="uk-width-expand@s uk-width-1-1 hint">
                {{ $t('noAccountQuestion') }}
            </div>
            <div class="uk-width-auto@s uk-width-1-1 uk-text-center">
                <LinkItem to="register">
                    <Button :text="$t('registerNow')" mode="default"/>
                </LinkItem>
            </div>
        </div>


    </div>
</template>

<script>

import TextField from "@/components/generic/TextField";
import Button from "@/components/generic/Button";
import SiteTitle from "@/components/SiteTitle";
import LinkItem from "@/components/generic/LinkItem";
import InfoBox from "@/components/generic/InfoBox";
import ErrorMessage from "@/components/generic/ErrorMessage";
import Seo from "@/components/generic/Seo";
import axios from "axios";
import {postLogin} from "@/client/vue-api-client";
import Checkbox from "@/components/generic/Checkbox";


export default {
    name: 'Login',
    components: {Checkbox, Seo, ErrorMessage, InfoBox, LinkItem, SiteTitle, Button, TextField},
    data() {
        return {
            email: "",
            password: "",
            authenticationCode: "",
            remember: true,

            emailValid: false,
            passwordValid: false,
            authenticationCodeValid: false,

            validationTrigger: 0,
            loading: false,
            loginError: null,

            totpEnabled: false,
        }
    },
    created() {
        if (this.getInvitedEmail) {
            this.email = this.getInvitedEmail;
        }
    },
    methods: {
        setEmailValid(valid) {
            this.emailValid = valid;
        },
        setPasswordValid(valid) {
            this.passwordValid = valid;
        },
        setAuthenticationCodeValid(valid) {
            this.authenticationCodeValid = valid;
        },
        login() {
            this.validationTrigger++;

            if (!this.emailValid || !this.passwordValid) {
                return;
            }

            if (this.totpEnabled && !this.authenticationCodeValid) {
                return;
            }

            let self = this;
            self.loading = true;

            let totp = undefined;
            if (this.authenticationCodeValid) {
                totp = this.authenticationCode.trim();
            }

            postLogin({
                credentials: {
                    username: this.email,
                    password: this.password,
                    totp
                },

            }).then(function (response) {
                self.loginError = null;
                self.loading = false;

                if (response.status === 202) {
                    self.totpEnabled = true;
                    return;
                }

                let expiresAt = "";
                if (self.getCookieConsetAccepted && self.remember) {
                    expiresAt = new Date(parseInt(response.data.expires / 1_000_000.0));

                    self.$cookie.set("token", response.data.token, {expires: expiresAt});
                    self.$cookie.set("userId", response.data.userId, {expires: expiresAt});
                    self.$cookie.set("userEmail", self.email, {expires: expiresAt});
                    self.$cookie.set("roleId", response.data.roleId, {expires: expiresAt});
                }
                self.$store.state.token = response.data.token;
                self.$store.state.userId = response.data.userId;
                self.$store.state.userEmail = self.email;
                self.$store.state.roleId = response.data.roleId;

                axios.defaults.headers.common["Authorization"] = 'Bearer ' + response.data.token;
                self.hasConfirmedDistributor(self.getCookieConsetAccepted, expiresAt);

                if (self.getInvitedTo) {
                    self.$router.push(self.getInvitedTo);
                    self.$store.state.invitedPath = null;
                    self.$store.state.invitedEmail = "";
                    self.$store.state.requestedPath = null;
                    return;
                }

                if (self.getPathTo && !self.getPathTo.includes('login')) {
                    self.$router.push(self.getPathTo);
                    self.$store.state.requestedPath = null;
                    return;
                }

                self.routeTo('dashboard');

            }).catch(function (e) {
                self.checkGenericError(e);

                self.loginError = e;
                self.loading = false;

            })

        },
    }
}
</script>


<style lang="scss" scoped>

.hint {
    font-size: $site-font-size-small;
}

@media (max-width: $breakpoint-xsmall-max) {
    .hint {
        justify-content: center;
    }
}

</style>
