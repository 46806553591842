<template>
    <GenericItem
        :actions="getActions()"
        :additionals="[{icon: 'mail', text: user.mailAddress, copy: true}]"
        :icon="user.unverifiedSince ? 'incognito' : 'user'"

        :icon-tooltip="$t(user.unverifiedSince ? 'unverifiedUser' : 'user')"
        :labels="getLabels()"

        :name="user.name">

        <template v-slot:action>
            <div v-if="user.unverifiedSince">

                <ActionButton icon="clock" no-action>
                    <div>{{ $t('willBeAutoDeletedIn') }}</div>

                    <countdown :end-time="new Date(user.unverifiedSince).getTime() + 3600000" @finish="softDeleteUser">
                        <span slot="process" slot-scope="{ timeObj }">
                            {{ timeObj.ceil.s }}
                        </span>
                    </countdown>

                    {{ $t('seconds') }}
                </ActionButton>

            </div>
        </template>

    </GenericItem>
</template>


<script>

import GenericItem from "@/components/items/GenericItem";
import {deleteUserById} from "@/client/vue-api-client";
import ActionButton from "@/components/generic/ActionButton";

export default {
    name: 'UserItem',
    components: {ActionButton, GenericItem},
    props: {
        user: {
            createdAt: String,
            id: String,
            mailAddress: String,
            name: String,
            permittedAt: String,
            roleId: String,
            role: String,
            status: String,
            unverifiedSince: String,
        },
    },
    methods: {
        getActions() {
            let actions = [];

            if (this.user.status === 'unverified') {
                return actions;
            }

            actions.push({
                action: this.deleteUser,
                icon: 'trash',
                text: this.$t('delete'),
                mode: 'danger',

                button: this.$t('deleteUser'),
                confirmTitle: this.$t('reallyDelete'),
                confirmMessage: this.$t('reallyDeleteUser', {name: this.user.name, email: this.user.mailAddress})
            });

            return actions;
        },
        getLabels() {
            let labels = [];

            let userType = "user";
            if (this.user.role === 'admin') {
                userType = "theme";
            }

            labels.push({
                icon: 'user',
                text: this.$t(this.user.role),
                type: userType,
            });

            if (this.user.status === 'unverified') {
                labels.push({
                    type: "danger",
                    icon: "info",
                    text: this.$t("unverified"),
                })

            } else {
                labels.push({
                    type: "success",
                    icon: "check",
                    text: this.$t("permitted"),
                    tooltip: this.$t('At') + " " + this.getFormattedDateByDateString(this.user.permittedAt),
                })

            }

            return labels;
        },
        softDeleteUser() {
            this.$emit('setDeleted', this.user.id);

        },
        deleteUser() {
            let self = this;

            deleteUserById({
                id: self.user.id,

            }).then(function () {
                self.$emit('setDeleted', self.user.id);

            }).catch(function (e) {
                self.checkGenericError(e);

            })
        },
    }
}

</script>


<style lang="scss" scoped>

</style>
