<template>
    <div>
        <GenericItem
            :actions="getActions()"
            :additionals="[{icon: 'calendar', text: getFormattedDateByDateString(backup.uploadedAt)}]"

            :icon-tooltip="$t('backup')"

            :labels="getLabels()"
            :name="backup.additionalInformation.replace('.cfg', '')"

            icon="history">

        </GenericItem>
    </div>
</template>


<script>

import GenericItem from "@/components/items/GenericItem";
import {deleteUserById, getDeviceByIdConfigbackupsByBackupId,} from "@/client/vue-api-client";
import dayjs from "dayjs";
import UIkit from "uikit";

export default {
    name: 'BackupItem',
    components: {GenericItem},
    props: {
        deviceId: String,
        backup: {
            uploadedAt: String,
            id: String,
            additionalInformation: {
                type: String,
                default: "backup"
            },
        },

        isLatest: Boolean,
    },
    data() {
        return {
            loading: false,
        }
    },
    methods: {
        getActions() {
            let actions = [];

            actions.push({
                action: this.download,
                icon: 'cloud-download',
                text: this.$t('download'),
                mode: "primary",
                loading: this.loading,
            });

            return actions;
        },
        getLabels() {
            let labels = [];

            let then = dayjs(this.backup.uploadedAt)
            let daysAgo = this.getDaysAgoByDateString(this.backup.uploadedAt);
            let fromNow = then.fromNow(true);

            if (daysAgo === 0) {
                labels.push({
                    type: "success",
                    icon: 'clock',
                    text: this.$t('ago') + " " + fromNow,
                    tooltip: this.$t('from') + " " + this.getFormattedDateByDateString(this.backup.uploadedAt),
                })

            } else if (daysAgo <= 14) {
                labels.push({
                    type: "primary",
                    icon: 'history',
                    text: this.$t('ago') + " " + fromNow,
                    tooltip: this.$t('from') + " " + this.getFormattedDateByDateString(this.backup.uploadedAt),
                })

            } else if (daysAgo <= 90) {
                labels.push({
                    type: "info",
                    icon: 'history',
                    text: this.$t('ago') + " " + fromNow,
                    tooltip: this.$t('from') + " " + this.getFormattedDateByDateString(this.backup.uploadedAt),
                })

            } else if (daysAgo <= 180) {
                labels.push({
                    type: "warning",
                    icon: 'history',
                    text: this.$t('ago') + " " + fromNow,
                    tooltip: this.$t('from') + " " + this.getFormattedDateByDateString(this.backup.uploadedAt),
                })

            } else {
                labels.push({
                    type: "danger",
                    icon: 'history',
                    text: this.$t('ago') + " " + fromNow,
                    tooltip: this.$t('from') + " " + this.getFormattedDateByDateString(this.backup.uploadedAt),
                })

            }

            return labels;
        },
        delete() {
            let self = this;

            deleteUserById({
                id: self.user.id,

            }).then(function () {
                self.$emit('setDeleted', self.user.id);

            }).catch(function (e) {
                self.checkGenericError(e);

            })
        },
        download() {
            event.preventDefault();
            let self = this;

            self.loading = true;
            getDeviceByIdConfigbackupsByBackupId({
                id: self.deviceId,
                backupId: self.backup.id

            }).then(function (response) {

                let request = new XMLHttpRequest();
                request.open("GET", response.data.url, true);
                request.responseType = "blob";
                request.onload = function () {
                    let blob = request.response;
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);

                    let fileName = "backup.cfg";
                    if (self.backup.additionalInformation) {
                        fileName = self.backup.additionalInformation;

                        if (!fileName.includes('.cfg')) {
                            fileName += '.cfg';
                        }
                    }
                    link.download = fileName;

                    link.click();
                    self.error = null;
                    self.loading = false;
                };

                // request.setRequestHeader("Authorization", 'Bearer ' + this.getToken);
                request.send();

            }).catch(function (e) {
                let loggedOut = self.checkGenericError(e);
                if (!loggedOut) {
                    UIkit.notification({
                        message: self.$t('unexpectedErrorMessage'),
                        status: 'info',
                        pos: 'top-center',
                        timeout: self.notificationTimeout,
                    });
                }

                self.error = e;
                self.loading = false;

            })

        }
    }
}

</script>


<style lang="scss" scoped>

</style>
