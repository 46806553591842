<template>
    <GenericCreate
        :action="update" :action-error="error" :action-label="$t('save.changes')"

        :action-loading="loading" :modal-id="modalId"

        :success-description="$t('subscription.updated.success', {module: itemModule, distributor: itemDistributor})"
        :success-title="$t('subscription.updated')" :title="$t('subscription.change')" :trigger="trigger"

        icon="plus"
        is-button-hidden

        modal-is-medium
    >
        <CardSelect v-model="textFields[0].value" :disabled="textFields[0].disabled" :field-key="0"
                    :label="textFields[0].label" :label-name="textFields[0].name"
                    :no-items-message="$t('modules.notcreated')"
                    :optional="textFields[0].optional" :options="getPlanOptions()" :rules="textFields[0].rules"
                    :validate="trigger"
                    label-plural="modules"
                    @setValid="setModuleValid"/>
        <div class="uk-margin-bottom"/>

        <span v-for="(inputField, key) in textFields" :key="inputField.name">
            <div v-if="key > 0 && key < textFields.length-3">
                <TextField v-model="inputField.value" :autocomplete="inputField.autocomplete"
                           :autofocus="inputField.autofocus" :disabled="inputField.disabled" :field-key="key"
                           :hasPasswordMeter="inputField.hasPasswordMeter"
                           :icon="inputField.icon"
                           :is-optional="inputField.optional"
                           :label="inputField.label"
                           :name="inputField.name"
                           :onEnter="update"
                           :rules="inputField.rules"
                           :type="inputField.type"
                           :validate="trigger"
                           @setValid="setValid"
                />
            </div>
            <div v-if="key > 0 && key < textFields.length-1" class="uk-margin-bottom"/>
        </span>

        <div class="uk-margin-bottom"/>
        <LabelWithError :label="$t('validity')"/>
        <v-date-picker v-model="range" :columns="$screens({ default: 1, sm: 2, lg: 3 })" is-expanded is-range/>

        <div class="uk-margin-bottom"/>
        <CardSelect v-model="textFields[4].value" :disabled="textFields[4].disabled" :field-key="3"
                    :label="textFields[4].label" :label-name="textFields[4].name"
                    :no-items-message="$t('distributors.notcreated')"
                    :optional="textFields[4].optional" :options="getDistributorList()" :rules="textFields[4].rules"
                    :validate="trigger"
                    is-searchable
                    label-plural="distributors"
                    @setValid="setDistributorValid"/>

    </GenericCreate>
</template>

<script>

import {putSubscriptionByIdValid} from "@/client/vue-api-client";
import TextField from "../generic/TextField";
import GenericCreate from "@/components/creates/GenericCreate";
import UIkit from "uikit";
import CardSelect from "@/components/CardSelect";
import LabelWithError from "@/components/generic/LabelWithError";

export default {
    name: 'UpdateSubscription',
    components: {
        LabelWithError,
        CardSelect,
        GenericCreate,
        TextField,
    },
    props: {
        distributors: Array,
        plans: Array,
        modalId: String,

        item: {
            id: String,
            quota: Number,
            satelliteIds: Array,
            distributorId: String,
            planId: String,
            valid: {from: String, until: String}
        },
    },
    data() {
        return {
            trigger: 0,

            textFields: [
                {
                    value: this.item.planId,
                    valid: true,

                    autofocus: false,
                    name: "plan",
                    label: "plan",
                    icon: "social",
                    rules: "required",
                    disabled: true,
                },
                {
                    value: this.item.quota,
                    valid: true,

                    autofocus: false,
                    name: "quantity",
                    label: "quantity",
                    icon: "cart",
                    rules: "required|min_value:1|decimal:0",
                    type: "number",
                    disabled: true,
                },
                {
                    value: this.item.valid.from,
                    valid: true,

                    autofocus: false,
                    name: "validFrom",
                    label: "validFrom",
                    icon: "clock",
                    rules: "required|date_format:yyyy-MM-dd",
                    type: "date"
                },
                {
                    value: this.item.valid.until,
                    valid: true,

                    autofocus: false,
                    name: "validUntil",
                    label: "validUntil",
                    icon: "future",
                    rules: "required|date_format:yyyy-MM-dd",
                    type: "date"
                },
                {
                    value: this.item.distributorId,
                    valid: true,

                    autofocus: false,
                    name: "distributor",
                    label: "distributor",
                    icon: "social",
                    rules: "required",
                    disabled: true,
                },
            ],

            range: {
                start: new Date(),
                end: new Date(),
            },

            loading: false,
            error: null,

            itemModule: "",
            itemDistributor: "",

            parsedDistributors: [],
        }
    },
    created() {
        let parsedItems = [];
        for (let i = 0, j = this.distributors.length; i < j; i++) {
            if (this.distributors[i].confirmedAt) {
                parsedItems.push(this.distributors[i]);
            }
        }
        this.parsedDistributors = parsedItems;

        this.range.start = new Date(this.item.valid.from);
        this.range.end = new Date(this.item.valid.until);
    },
    methods: {
        getDistributorName(id) {
            for (let i = 0, j = this.distributors.length; i < j; i++) {
                if (this.distributors[i].id === id) {
                    return this.distributors[i].name;
                }
            }

            return null;
        },
        setModuleValid(valid) {
            this.textFields[0].valid = valid;
        },
        setDistributorValid(valid) {
            this.textFields[4].valid = valid;
        },
        setValid(valid, fieldKey) {
            this.textFields[fieldKey].valid = valid
        },
        getPlanOptions() {
            let allowedRoles = this.plans;
            let preparedRoles = []

            for (let i = 0, j = allowedRoles.length; i < j; i++) {
                preparedRoles.push(
                    {
                        nameIcon: 'cart',
                        name: this.$t(allowedRoles[i].id),
                        value: allowedRoles[i].id,
                    }
                );
            }

            return preparedRoles;
        },
        getDistributorList() {
            let items = [];

            for (let i = 0, j = this.parsedDistributors.length; i < j; i++) {
                items.push({
                    nameIcon: "social",
                    name: this.parsedDistributors[i].name,
                    value: this.parsedDistributors[i].id,
                });
            }

            return items;
        },
        update() {
            this.trigger++;

            for (let i = 0, j = this.textFields.length; i < j; i++) {
                if (!this.textFields[i].valid) {
                    return;
                }
            }

            let self = this;
            self.loading = true;

            putSubscriptionByIdValid({
                id: self.item.id,
                updateSubscriptionValidPayload: {
                    planId: self.textFields[0].value,
                    quota: self.textFields[1].value,
                    from: self.range.start.toISOString(),
                    until: self.range.end.toISOString(),
                },

            }).then(function () {
                self.itemModule = self.textFields[0].value;
                self.itemDistributor = self.getDistributorName(self.textFields[4].value);

                self.loading = false;

                UIkit.modal("#" + self.modalId + "-success").show();
                self.$emit('afterSuccess')

            }).catch(function (e) {
                self.checkGenericError(e);

                self.error = e;
                self.loading = false;

            })
        },
    }
}

</script>

<style lang="scss" scoped>

</style>
