<template>
    <div class="logout">

    </div>
</template>

<script>


export default {
    name: 'Logout',
    created() {
        this.logout();
    }
}

</script>

<style lang="scss" scoped>
</style>
