<template>
    <GenericCreate
        :action="create" :action-error="error"

        :action-label="$t('request')"
        :action-loading="loading"
        :description="$t('subscriptionrequest.description')"

        :modal-id="modalId" :success-description="$t('subscriptionrequest.created.success', {name: itemName})"
        :success-title="$t('subscriptionrequest.created')" :title="$t('subscriptionrequest.create')"

        :trigger="trigger"
        icon="cart"
    >

        <CardSelect v-model="textFields[0].value" :options="getRoleOptions()" :rules="textFields[0].rules"
                    :validate="trigger"
                    label="module" label-name="module" label-plural="modules" @setValid="setValid"/>

    </GenericCreate>
</template>


<script>

import {getPlan, postDistributorByIdSubscriptionsRequest} from "@/client/vue-api-client";
import GenericCreate from "@/components/creates/GenericCreate";
import UIkit from "uikit";
import CardSelect from "@/components/CardSelect";

export default {
    name: 'CreateSubscriptionRequest',
    components: {CardSelect, GenericCreate},
    props: {
        distributorId: String,
    },
    data() {
        return {
            modalId: "modal-create-subscription-request-" + this.distributorId,
            trigger: 0,

            textFields: [
                {
                    value: "",
                    valid: false,

                    autofocus: true,
                    name: "module-name",
                    label: "moduleName",
                    rules: "required",
                    icon: "hashtag",
                },
            ],

            loading: false,
            error: null,

            itemName: "",

            plans: [],
        }
    },
    created() {
        this.getPlans();
    },
    methods: {
        setValid(valid) {
            this.textFields[0].valid = valid;
        },
        create() {
            this.trigger++;

            for (let i = 0, j = this.textFields.length; i < j; i++) {
                if (!this.textFields[i].valid) {
                    return;
                }
            }

            let self = this;
            self.loading = true;

            postDistributorByIdSubscriptionsRequest({
                id: self.distributorId,
                plan: {
                    kindId: self.textFields[0].value,
                },

            }).then(function () {
                self.$emit('input', true)

                self.itemName = self.textFields[0].value;

                for (let i = 0, j = self.textFields.length; i < j; i++) {
                    self.textFields[i].value = "";
                    self.textFields[i].valid = false;
                }

                self.loading = false;

                UIkit.modal("#" + self.modalId + "-success").show();
                self.$emit('afterSuccess')

            }).catch(function (e) {
                self.checkGenericError(e);

                self.error = e;
                self.loading = false;

            })
        },
        getRoleOptions() {
            let allowedRoles = this.plans;
            let preparedRoles = []

            for (let i = 0, j = allowedRoles.length; i < j; i++) {
                preparedRoles.push(
                    {
                        nameIcon: 'cart',
                        name: this.$t(allowedRoles[i].id),
                        value: allowedRoles[i].id,
                    }
                );
            }

            return preparedRoles;
        },
        getPlans() {
            let self = this;

            getPlan().then(function (response) {
                self.plans = response.data;

            }).catch(function (e) {
                self.checkGenericError(e);

            })
        },
    }
}

</script>

<style lang="scss" scoped>

</style>
