<template>
    <span class="text-field">

        <LabelWithError v-if="label" :class="(isLabelHidden ? ' uk-hidden' : '')"
                        :error="errors.first(name)"
                        :is-label-large="isLabelLarge"
                        :label="$t(label, labelParams) + (isOptional ? ' ('+$t('optional')+')' : '')"/>

        <div :class="'uk-inline uk-width-1-1' + (isHidden ? ' uk-hidden' : '')">
            <span v-if="icon" :uk-icon="'icon: '+icon" class="uk-form-icon"/>
            <input ref="ref"
                   v-validate="rules"
                   :autocomplete="autocomplete"
                   :autofocus="autofocus"
                   :class="classGenerator(name)"
                   :data-vv-as="$t(label)"
                   :disabled="disabled"
                   :name="name"
                   :placeholder="placeholder ? placeholder : (icon === 'search' ? $t('search') : '')"
                   :title="title"
                   :type="(toggle ? 'text' : (type ? type : 'text'))"
                   :uk-tooltip="tooltip ? 'title:'+tooltip : ''"
                   :value="value"
                   v-on:change="onChange"
                   v-on:input="updateValue($event.target.value)"
                   v-on:keyup.enter="onEnter"
            />

            <div v-if="type==='password'" :uk-icon="'icon:'+ (toggle ? 'eye-closed' : 'eye')"
                 class="reveal uk-form-icon uk-form-icon-flip cursor-pointer"
                 v-on:click="toggle= !toggle"/>

            <div v-if="icon==='search' && value && value.length>0"
                 class="reveal uk-form-icon uk-form-icon-flip cursor-pointer"
                 uk-icon="close"
                 v-on:click="updateValue($event.target.value)"/>

            <div v-if="actionIcon" :uk-icon="'icon:'+ actionIcon" :uk-tooltip="'title:'+actionTooltip"
                 class="reveal uk-form-icon uk-form-icon-flip cursor-pointer"
                 v-on:click="actionFunction"/>
        </div>

        <Password v-if="hasPasswordMeter" v-model="value" class="password-meter" strength-meter-only/>

    </span>
</template>

<script>
import Password from 'vue-password-strength-meter'
import LabelWithError from "@/components/generic/LabelWithError";

const isIpPrivate = require('private-ip')

export default {
    name: 'TextField',
    components: {LabelWithError, Password},
    props: {
        fieldKey: Number,
        placeholder: String,
        name: String,
        rules: String,
        value: [String, Number],
        label: String,
        type: String,
        icon: String,
        disabled: Boolean,
        simple: Boolean,
        validate: Number,
        labelParams: Object,
        onEnter: {
            type: Function,
            default: function () {
            },
        },
        onChange: {
            type: Function,
            default: function () {
            },
        },
        onInput: {
            type: Function,
            default: function () {
            },
        },
        title: String,
        onUpdate: {
            type: Function,
            default: function () {
            },
        },
        autocomplete: {
            type: String,
            default: "off"
        },
        autofocus: {
            type: Boolean,
            default: false
        },
        hasPasswordMeter: Boolean,
        tooltip: String,

        actionIcon: String,
        actionFunction: Function,
        actionTooltip: String,
        isDark: Boolean,
        isHidden: Boolean,
        isLabelHidden: Boolean,
        isOptional: Boolean,
        isLabelLarge: Boolean,
    },
    created() {
        this.$validator.extend(
            'private_ip', {
                getMessage: field => field + ' ' + this.$t('privateIpError'),
                validate: (value) => {
                    return isIpPrivate(value);
                }
            });

        this.$validator.extend(
            'unique', {
                getMessage: field => field + ' ' + this.$t('exists.already'),
                validate: (value, field) => {
                    return !field.includes(value);
                }
            });
    },
    data() {
        return {
            toggle: false,
        }
    },
    watch: {
        validate() {
            this.validation();
        },
    },
    methods: {
        validation() {
            this.$validator.validate().then(valid => {
                if (!valid && this.autofocus) {
                    this.$refs.ref.focus();
                }

                this.$emit('setValid', valid, this.fieldKey)
            });
        },
        updateValue(value) {

            if (this.type === 'number') {
                value = parseInt(value);
            }

            this.$emit('input', value, this.fieldKey)
            this.validation();

            let self = this;
            setTimeout(function () {
                self.onInput();
            }, 50);
        },
        classGenerator(name) {
            let classes = "uk-input";

            if (this.errors.first(name)) {
                classes += " danger";
            }

            if (this.simple) {
                classes += " simple";
            }

            if (this.isDark) {
                classes += " dark";
            }

            if (this.actionIcon) {
                classes += " with-icon";
            }

            return classes;
        },
    },
}
</script>

<style lang="scss" scoped>

.reveal {
    pointer-events: initial !important;
}

.uk-input {
    border-radius: $border-radius;
    border-color: $border-color !important;
    height: 50px;
    color: $site-color-hover !important;
    width: 100%;
    font-weight: 500;
    font-size: 16px;

    &:hover, &:focus {
        border-color: $theme-background !important;
    }

    &.dark {
        &:hover, &:focus {
            border-color: $theme-color-hover !important;
        }
    }

    &.danger {
        background: rgba(240, 80, 80, .15);
        border-color: $theme-background !important;
    }

    &.simple {
        border-radius: 0;
        border-top: 0;
        border-left: 0;
        border-right: 0;
    }
}

input {
    &:disabled {
        cursor: not-allowed;
        border-top-radius: $border-radius;
    }
}

.password-meter {
    margin-top: 15px;
}

.with-icon {
    cursor: auto !important;
}

</style>
