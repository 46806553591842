<template>

    <GenericItem
        :actions="getActions()"
        :additionals="getAdditionals()"
        :icon="'bolt'"

        :icon-tooltip="$t('monitoring')"
        :labels="getLabels()"
        :name="item.device.description">

        <template v-slot:description>
            <div class="uk-text-center">
                <div class="image-struct uk-flex uk-flex-middle uk-flex-center">
                    <ImageEnhanced :src="require('@/assets/devices/'+item.device.kindId+'.png')"
                                   :uk-tooltip="item.device.kindId"
                                   class="image"
                                   uk-img/>
                </div>
            </div>
        </template>


        <Modal :id="modalId" :title="$t('monitoringDetails')">
            <LabelWithError :label="$t('status')"/>
            <Card :mode="getModeByStatus(item.status)">
                <IconText :icon="getIconByStatus()"
                          :ratio="2.0"
                          :uk-tooltip="'title:'+ (item.status === 'determine' ? $t('determine.description') : $t('since') + ' ' + getFormattedDateByDateString(this.getStatusSince))"
                          class="status-title">
                    {{ getDescriptionByStatus() }}
                </IconText>
            </Card>

            <div v-if="item.status !== 'determine'">
                <div class="uk-margin-medium-top"/>
                <LabelWithError :label="$t('statistics')"/>
                <div class="uk-grid-small uk-child-width-1-1" uk-grid>
                    <div v-for="statItem in statItems" :key="statItem.hours">
                        <Label
                            :text="getUptimePercentageByLastHours(item.events, statItem.hours).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) + '%'"
                            :type="getUptimePercentageMode(item.events, statItem.hours)"
                            class="uk-margin-small-right"/>
                        {{ statItem.description }} online
                    </div>
                </div>


                <div class="uk-margin-medium-top"/>
                <LabelWithError :label="$t('events')"/>
                <v-calendar
                    :attributes='attributes'
                    :columns="$screens({ default: 1, sm: 2, lg: 2 })"
                    :max-date="new Date(item.parsedEvents[item.parsedEvents.length-1].until)"
                    :min-date="new Date(item.parsedEvents[0].from)"
                    is-expanded
                />

                <div class="uk-margin-top"/>
                <div class="uk-panel uk-panel-scrollable">
                    <table class="uk-table uk-table-hover uk-table-divider">
                        <tbody>
                        <tr v-for="(event, key) in getReversedEvents" :key="event.id">
                            <td>
                                <Label v-if="event.alive"
                                       :text="$t('Online')" class="uk-margin-small-right" icon="bolt" type="success"/>

                                <Label v-else
                                       :text="$t('Offline')" class="uk-margin-small-right" icon="warning"
                                       type="danger"/>

                                <span class="diff">{{ getDiffByDateStrings(event.from, event.until) }} </span>

                                ({{ getFormattedDateByDateString(event.from, true) }} {{ $t('to') }} <span
                                v-if="key !== 0">{{ getFormattedDateByDateString(event.until, true) }}</span><span
                                v-else>{{ $t('now') }}</span>)
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>


            </div>

        </Modal>
    </GenericItem>

</template>


<script>

import GenericItem from "@/components/items/GenericItem";
import UIkit from "uikit";
import Modal from "@/components/generic/Modal";
import Card from "@/components/generic/Card";
import IconText from "@/components/generic/IconText";
import LabelWithError from "@/components/generic/LabelWithError";
import Label from "@/components/Label";
import ImageEnhanced from "@/components/ImageEnhanced";
import dayjs from "dayjs";

export default {
    name: 'MonitoringItem',
    components: {ImageEnhanced, Label, LabelWithError, IconText, Card, Modal, GenericItem},
    props: {
        item: {
            id: String,
            parsedEvents: Array,
            status: String,
            device: {
                description: String,
                id: String,
                ipAddress: String,
                kindId: String
            },
            events: [
                {
                    alive: Boolean,
                    timeStamp: String,
                }
            ],
        }
    },
    data() {
        return {
            modalId: "modal-details-monitoring-" + (+new Date()),
            inited: false,

            statItems: [
                {
                    hours: 24,
                    description: this.$t('last') + " 24 " + this.$t('hours'),
                },
                {
                    hours: 24 * 7,
                    description: this.$t('last') + " 7 " + this.$t('days'),
                },
                {
                    hours: 24 * 30,
                    description: this.$t('last') + " 30 " + this.$t('days'),
                },
            ],
        }
    },
    computed: {
        getReversedEvents() {
            let parsedEvents = JSON.parse(JSON.stringify(this.item.parsedEvents));
            return parsedEvents.reverse();
        },
        getStatusSince() {
            let parsedEventsReversed = this.getReversedEvents;
            if (parsedEventsReversed.length > 0) {
                return parsedEventsReversed[0].from;
            }

            return null;
        },
        attributes() {
            let attributes = [];

            let parsedEvents = this.item.parsedEvents;
            for (let i = 0, j = parsedEvents.length; i < j; i++) {
                let from = dayjs(parsedEvents[i].from);

                attributes.push({
                    highlight: {
                        start: {
                            color: this.hasDayMixedEvents(parsedEvents[i].from) ? 'yellow' : (parsedEvents[i].alive ? 'green' : 'red'),
                            fillMode: 'solid',
                        },
                        base: {
                            color: parsedEvents[i].alive ? 'green' : 'red',
                            fillMode: 'light'
                        },
                        end: {
                            fillMode: 'solid',
                            color: this.hasDayMixedEvents(parsedEvents[i].until) ? 'yellow' : (parsedEvents[i].alive ? 'green' : 'red'),
                        },
                    },
                    dates: {start: new Date(parsedEvents[i].from), end: new Date(parsedEvents[i].until)},
                });

                attributes.push({
                    dates: new Date(parsedEvents[i].from),
                    dot: {
                        color: parsedEvents[i].alive ? 'green' : 'red',
                    },
                    popover: {
                        label: (parsedEvents[i].alive ? this.$t('Online') : this.$t('Offline')) + " " + from.format("HH:mm:ss"),
                    },
                });
            }

            return attributes;
        },
    },
    methods: {
        hasDayMixedEvents(dateString) {
            let targetDate = dayjs(dateString);
            let online = 0;
            let offline = 0;

            let events = this.item.parsedEvents;
            for (let i = 0, j = events.length; i < j; i++) {
                if (targetDate.isBetween(dayjs(events[i].from), dayjs(events[i].until), 'day', '[]')) {
                    if (events[i].alive) {
                        online++;
                    } else {
                        offline++;
                    }
                }
            }


            if (online > 0 && offline > 0) {
                return true;
            }

            return false;
        },
        getModeByStatus(status) {
            if (status === 'online') {
                return "success";
            }

            if (status === 'offline') {
                return "danger";
            }

            if (status === 'determine') {
                return "default";
            }

            return status;
        },
        getUptimePercentageMode(item, hours) {
            let percentage = this.getUptimePercentageByLastHours(item, hours);
            if (percentage >= 90) {
                return "success";
            }

            if (percentage >= 60) {
                return "warning";
            }

            return "danger";
        },
        getUptimePercentageByLastHours(items, hours) {
            let online = 0;
            let offline = 0;

            let now = new Date();
            let then = new Date(new Date().setDate(now.getDate() - (hours / 24)));

            for (let i = 0, j = items.length; i < j; i++) {
                let itemDate = new Date(items[i].until);
                if (itemDate.getTime() < then.getTime()) {
                    continue;
                }

                let diff = dayjs(items[i].until).diff(dayjs(items[i].from))

                if (items[i].alive) {
                    online += diff;

                } else {
                    offline += diff;
                }
            }

            if (online === 0) {
                return 0;
            }

            return online / (online + offline) * 100;
        },
        getTimeAgo() {
            let then = dayjs(this.getStatusSince);
            return then.fromNow(true);
        },
        openDetails() {
            this.inited = true;
            UIkit.modal("#" + this.modalId).show();
            event.preventDefault();
        },
        getActions() {
            let actions = [];

            actions.push({
                action: this.openDetails,
                icon: 'chart-line',
                text: this.$t('details'),
                mode: "primary",
            });

            return actions;
        },
        getAdditionals() {
            let additionals = [];

            if (!this.getStatusSince) {
                additionals.push({
                    icon: 'info',
                    text: this.$t('monitoring.nodata'),
                    tooltip: this.$t('noMonitoringDataMessage'),
                });

                return additionals;
            }

            additionals.push({
                icon: 'clock',
                text: this.$t('since') + " " + this.getTimeAgo(),
                tooltip: this.$t('since') + " " + this.getFormattedDateByDateString(this.getStatusSince)
            });

            return additionals;
        },
        getDescriptionByStatus() {
            if (this.getStatusSince) {
                return this.$t('since') + " " + this.getTimeAgo() + " " + this.$t(this.item.status);
            }

            return this.$t('determine.description');
        },
        getIconByStatus() {
            let status = this.item.status;

            if (status === "online") {
                return "bolt";
            }

            if (status === "offline") {
                return "warning";
            }

            return "info"
        },
        getLabels() {
            let labels = [];

            if (this.item.status === 'online') {
                labels.push({
                    type: "success",
                    icon: "bolt",
                    text: this.$t("Online"),
                    tooltip: this.$t('online.description.' + this.item.type)
                });

            } else if (this.item.status === 'offline') {
                labels.push({
                    type: "danger",
                    icon: "warning",
                    text: this.$t("Offline"),
                    tooltip: this.$t('offline.description.' + this.item.type)
                });

            } else if (this.item.status === 'determine') {
                labels.push({
                    type: "default",
                    icon: "info",
                    text: this.$t("determine"),
                    tooltip: this.$t('determine.description')
                });

            } else if (this.item.status === 'warning') {
                labels.push({
                    type: "warning",
                    icon: "warning",
                    text: this.$t("warning"),
                    tooltip: this.$t('warning.description.' + this.item.type, {minutes: this.removeFromNowSuffix(this.getTimeAgo())})
                });

            }

            return labels;
        },
    }
}

</script>


<style lang="scss" scoped>

.latest {
    margin-top: 15px;
    font-size: 14px;
}

.status {
    font-size: 20px;
}

.uk-switcher {
    margin-top: 25px;
}

.uk-tab > * > a {
    text-transform: initial !important;
    font-size: 16px;
    font-weight: 400;
    border-color: transparent;

    &:hover {
        border-bottom: 1px solid #afafaf;
    }
}

.uk-tab > .uk-active > a {
    border-bottom: 1px solid #afafaf;
}

.image {
    max-height: 100%;
    max-width: 250px;
}

.image-struct {
    margin-top: 8px;
    height: 140px;
    margin-bottom: 10px;
}

.uk-table-hover > tr:hover,
.uk-table-hover tbody tr:hover {
    background: rgba(9, 38, 51, 0.05);
}

.uk-panel {
    max-height: 500px;
    min-height: 300px;
}

.status-title {
    font-size: 18px;
}

.uk-panel {
    border-radius: $border-radius;
}

</style>
