<template>

    <span :class="'uk-label uk-label-'+type + generateClasses()" :uk-tooltip="tooltip? 'title:'+tooltip : ''">
        <IconText v-if="icon" :has-text="text !== undefined" :icon="icon">{{ text }}<slot/></IconText>
        <span v-else>{{ text }}<slot/></span>
    </span>


</template>


<script>

import IconText from "@/components/generic/IconText";

export default {
    name: 'Label',
    components: {IconText},
    props: {
        type: String,
        text: String,
        icon: String,
        isLarge: Boolean,
        tooltip: String,
        isSmall: Boolean,
        isBold: Boolean,
    },
    methods: {
        generateClasses() {
            let classes = '';

            if (this.isLarge) {
                classes += ' large';
            }

            if (this.isSmall) {
                classes += ' small';
            }

            if (this.isBold) {
                classes += ' bold';
            }

            return classes;
        }
    }
}

</script>


<style lang="scss" scoped>

.uk-label-default {
    background-color: transparent;
    color: #333;
    border: 1px solid #e5e5e5;
}

.large {
    font-size: 24px !important;
    padding: 6px 12px !important;
}

.medium {
    font-size: 18px !important;
    padding: 5px 10px !important;
}

.small {
    font-size: 14px !important;
    padding: 4px 8px !important;
}

.bold {
    font-weight: 500 !important;
}

</style>
