<template>
    <div class="seo">

        <seo-title :content="getMergedString()+' - ' + this.siteName"/>
        <seo-canonical v-if="canonical" :content="this.baseUrl + canonical"/>

    </div>
</template>

<script>

export default {
    name: 'Seo',
    props: {
        properties: Array,
        canonical: String,
    },
    methods: {
        getMergedString() {
            let parts = this.getRouteParts(this.properties);

            let merged = "";
            for (let i = 0, j = parts.length; i < j; i++) {
                merged += " " + parts[i].name + " /";
            }

            merged = merged.slice(0, -1);
            return merged.trim();
        }
    }
}

</script>

<style lang="scss" scoped>

</style>
