<template>
    <div class="reset-password-confirm">


        <SiteTitle :name="$t('resetPassword')" class="uk-text-center"/>

        <div v-if="tokenInvalid" class="uk-animation-fade">
            <InfoBox mode="danger">
                {{ $t('tokenInvalidMessage') }}
            </InfoBox>

            <div class="uk-margin-medium-top"/>
            <LinkItem to="resetpassword">
                <Button :text="$t('resetPasswordAgain')" is-large is-max mode="theme"/>
            </LinkItem>
        </div>
        <div v-else-if="reset" class="uk-animation-fade">
            <InfoBox mode="success">
                {{ $t('passwordResetSuccess') }}
            </InfoBox>

            <div v-if="resetError" class="uk-animation-fade">
                <div class="uk-margin-medium-top"/>
                <InfoBox mode="danger">
                    <ErrorMessage :error="resetError"/>
                </InfoBox>
            </div>

            <div class="uk-margin-medium-top"/>
            <LinkItem to="login">
                <Button :text="$t('gotoLogin')" is-large is-max mode="theme"/>
            </LinkItem>
        </div>

        <div v-else>

            <div class="uk-margin-medium-top"/>

            <TextField v-model="password" :onEnter="confirm" :validate="trigger" autocomplete="new-password"
                       autofocus hasPasswordMeter icon="lock" label="newPassword" name="new-password"
                       rules="required" type="password" @setValid="setPasswordValid"/>

            <div v-if="resetError" class="uk-animation-fade">
                <div class="uk-margin-medium-top"/>
                <InfoBox mode="danger">
                    <ErrorMessage :error="resetError"/>
                </InfoBox>
            </div>

            <div class="uk-margin-medium-top"/>
            <Button :action="confirm" :text="$t('setNewPassword')" has-action is-large is-max mode="theme"/>

        </div>


    </div>
</template>

<script>

import TextField from "@/components/generic/TextField";
import Button from "@/components/generic/Button";
import SiteTitle from "@/components/SiteTitle";
import InfoBox from "@/components/generic/InfoBox";
import LinkItem from "@/components/generic/LinkItem";
import ErrorMessage from "@/components/generic/ErrorMessage";
import {postRecoveryById} from "@/client/vue-api-client";


export default {
    name: 'ResetPasswordConfirm',
    components: {ErrorMessage, LinkItem, InfoBox, SiteTitle, Button, TextField},
    data() {
        return {
            password: "",
            reset: false,

            passwordValid: false,
            tokenInvalid: false,

            trigger: 0,
            loading: false,
            resetError: null,
        }
    },
    methods: {
        setPasswordValid(valid) {
            this.passwordValid = valid;
        },
        confirm() {
            this.trigger++;

            if (!this.passwordValid) {
                return;
            }

            let self = this;
            self.loading = true;

            postRecoveryById({
                id: self.$route.params.token,
                recovery: {
                    password: self.password,
                },

            }).then(function () {
                self.resetError = null;
                self.reset = true;
                self.loading = false;

            }).catch(function (e) {
                self.checkGenericError(e);

                if (e && e.response && e.response.status === 400) {
                    self.tokenInvalid = true;
                }

                self.resetError = e;
                self.loading = false;
            })
        },
    }
}

</script>


<style lang="scss" scoped>

</style>
