<template>
    <div>

        <LoadingStruct :is-loading="loading">
            <GenericSiteTitle :title-poperties="[{key: 0, disabled: true}]">
                <template v-slot:action>
                    <CreateUser @afterSuccess="getItems"/>
                </template>
            </GenericSiteTitle>

            <GenericItemView
                :computed-items="computedItems"
                :eror="error"
                :filters="getFilters()"
                :is-loading="loading"
                :is-search="search.length>0"
                :items="items"
                :no-items-message="$t('noUsersMessage')"
                is-searchable
                label-plural="users"
                @search="setSearch"
            >
                <template v-slot:items>
                    <li v-for="item in computedItems" :key="item.id"
                        :data-role="item.role" :data-status="item.status">
                        <UserItem :user="item" @setDeleted="setDeleted" @setPermitted="setPermitted"/>
                    </li>
                </template>

            </GenericItemView>
        </LoadingStruct>

    </div>
</template>

<script>

import Vue from 'vue';
import {getUser} from "@/client/vue-api-client";
import UserItem from "@/components/items/UserItem";
import CreateUser from "@/components/creates/CreateUser";
import GenericItemView from "@/views/GenericItemView";
import GenericSiteTitle from "@/views/GenericSiteTitle";
import LoadingStruct from "@/views/LoadingStruct";

export default {
    name: 'UsersAdministration',
    components: {
        LoadingStruct,
        GenericSiteTitle,
        GenericItemView, CreateUser, UserItem,
    },
    data() {
        return {
            loading: true,
            error: null,
            items: [],
            search: "",
        }
    },
    created() {
        this.getItems();
    },
    computed: {
        computedItems() {
            let items = this.items;
            if (this.search && this.search.length) {
                items = this.powerSearcher(items, this.search);
            }

            if (items.length > this.defaultShowLimit) {
                return items.slice(0, this.defaultShowLimit);
            }

            return items;
        },
    },
    methods: {
        setSearch(text) {
            this.search = text;
        },
        getFilters() {
            let filters = [
                {
                    type: 'status',
                    values: [
                        {name: 'unconfirmed', total: 0, active: true},
                        {name: 'confirmed', total: 0},
                        {name: 'unverified', total: 0},
                    ]
                },
                {
                    type: 'role',
                    values: [
                        {name: 'admin', total: 0},
                        {name: 'user', total: 0},
                    ]
                }
            ];

            return filters;
        },
        getRole(user) {
            if (this.adminRoles.includes(user.roleId)) {
                return 'admin';
            }

            return 'user';
        },
        getStatus(user) {
            if (user.unverifiedSince !== undefined) {
                return 'unverified';
            }

            return 'confirmed';
        },
        setPermitted(id) {
            for (let i = 0, j = this.items.length; i < j; i++) {
                if (this.items[i].id === id) {
                    Vue.set(this.items[i], 'permittedAt', new Date());
                    break;
                }
            }
        },
        setDeleted(id) {
            this.items = this.items.filter(x => {
                return x.id !== id;
            });
        },
        getItems() {
            let self = this;

            getUser().then(function (response) {
                let items = response.data;

                for (let i = 0, j = items.length; i < j; i++) {
                    items[i]['status'] = self.getStatus(items[i]);
                    items[i]['role'] = self.getRole(items[i]);
                }

                items.reverse();

                self.items = items;
                self.loading = false;

            }).catch(function (e) {
                self.checkGenericError(e);
                self.error = e;
                self.loading = false;

            })
        },
    }
}

</script>

<style lang="scss" scoped>

</style>
