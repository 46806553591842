<template>
    <GenericCreate
        :action="update" :action-error="error" :action-label="$t('detach')"
        :action-loading="loading"

        :description="$t('subscriptions.linked.satellites')"

        :modal-id="modalId"
        :success-description="$t('subscription.detached.message', {module: itemModule, satellite: itemSatellite})"
        :success-title="$t('subscription.detached')" :title="$t('subscription.detach')"

        :trigger="trigger"
        is-button-hidden

        modal-is-medium
    >
        <CardSelect v-model="textFields[0].value" :field-key="0" :label="textFields[0].label"
                    :label-name="textFields[0].name" :no-items-message="$t('satellite.notexist.withoutmonitoring')"
                    :optional="textFields[0].optional"
                    :options="getCardList()" :rules="textFields[0].rules" :validate="trigger" is-searchable
                    label-plural="distributors"
                    @setValid="setCardValid"/>

    </GenericCreate>
</template>

<script>

import {deleteSubscriptionByIdSatelliteIdsBySatelliteId} from "@/client/vue-api-client";
import GenericCreate from "@/components/creates/GenericCreate";
import UIkit from "uikit";
import CardSelect from "@/components/CardSelect";

export default {
    name: 'DeleteSubscriptionSatellite',
    components: {
        CardSelect,
        GenericCreate,
    },
    props: {
        item: {
            id: String,
            quota: Number,
            satelliteIds: Array,
            distributorId: String,
            planId: String,
            valid: {from: String, until: String}
        },

        modalId: String,
        satellites: Array,
    },
    data() {
        return {
            trigger: 0,

            textFields: [
                {
                    value: "",
                    valid: false,

                    autofocus: false,
                    name: "satellite",
                    label: "satellite",
                    icon: "rss",
                    rules: "required",
                },
            ],

            loading: false,
            error: null,

            itemModule: "",
            itemSatellite: "",
        }
    },
    methods: {
        getSatelliteById(id) {
            for (let i = 0, j = this.satellites.length; i < j; i++) {
                if (this.satellites[i].id === id) {
                    return this.satellites[i];
                }
            }

            return null;
        },
        getCardList() {
            let items = [];

            for (let i = 0, j = this.item.satelliteIds.length; i < j; i++) {
                let item = this.getSatelliteById(this.item.satelliteIds[i]);

                if (item) {
                    let description = "";
                    if (item.description) {
                        description = " (" + item.description + ")";
                    }

                    items.push({
                        name: item.ean + description,
                        value: item.id,
                        nameIcon: "rss",
                    });

                } else {
                    items.push({
                        name: this.item.satelliteIds[i],
                        value: this.item.satelliteIds[i],
                        nameIcon: "rss",
                    });

                }
            }

            return items;
        },
        setCardValid(valid) {
            this.textFields[0].valid = valid;
        },
        getSatelliteName(id) {
            for (let i = 0, j = this.satellites.length; i < j; i++) {
                if (this.satellites[i].id === id) {
                    let description = "";
                    if (this.satellites[i].description) {
                        description = " (" + this.satellites[i].description + ")";
                    }

                    return this.satellites[i].ean + description;
                }
            }

            return null;
        },
        update() {
            this.trigger++;

            for (let i = 0, j = this.textFields.length; i < j; i++) {
                if (!this.textFields[i].valid) {
                    return;
                }
            }

            let self = this;
            self.loading = true;

            deleteSubscriptionByIdSatelliteIdsBySatelliteId({
                id: self.item.id,
                satelliteId: self.textFields[0].value,

            }).then(function (response) {
                self.itemModule = self.item.planId;
                self.itemSatellite = self.getSatelliteName(self.textFields[0].value);

                self.loading = false;

                UIkit.modal("#" + self.modalId + "-success").show();
                self.$emit('afterSuccess', response.data);

            }).catch(function (e) {
                self.checkGenericError(e);

                self.error = e;
                self.loading = false;

            })
        },
    }
}

</script>

<style lang="scss" scoped>

</style>
