<template>
    <GenericCreate
        :action="update" :action-error="error" :action-label="$t('assign')"

        :action-loading="loading"

        :modal-id="modalId"
        :success-description="$t('subscription.assigned.message', {module: itemModule, satellite: itemSatellite})"
        :success-title="$t('subscription.assigned')" :title="$t('subscription.assign')"

        :trigger="trigger"
        is-button-hidden

        modal-is-medium
    >
        <CardSelect v-model="textFields[0].value" :field-key="0" :label="textFields[0].label"
                    :label-name="textFields[0].name" :label-params="textFields[0].labelParams"
                    :no-items-message="$t('satellite.notexist.withoutmonitoring')"
                    :optional="textFields[0].optional" :options="getCardList()" :rules="textFields[0].rules"
                    :validate="trigger"
                    is-searchable
                    label-plural="distributors"
                    @setValid="setCardValid"/>

    </GenericCreate>
</template>

<script>

import {postSubscriptionByIdSatelliteIds} from "@/client/vue-api-client";
import GenericCreate from "@/components/creates/GenericCreate";
import UIkit from "uikit";
import CardSelect from "@/components/CardSelect";

export default {
    name: 'UpdateSubscriptionSatellite',
    components: {
        CardSelect,
        GenericCreate,
    },
    props: {
        item: {
            id: String,
            quota: Number,
            satelliteIds: Array,
            distributorId: String,
            planId: String,
            valid: {from: String, until: String}
        },

        modalId: String,
        satellites: Array,
    },
    data() {
        return {
            trigger: 0,

            textFields: [
                {
                    value: "",
                    valid: false,

                    autofocus: false,
                    name: "satellite",
                    label: "satelliteWithoutModule",
                    labelParams: {modul: this.item.planId},
                    icon: "rss",
                    rules: "required",
                },
            ],

            loading: false,
            error: null,

            itemModule: "",
            itemSatellite: "",
        }
    },
    methods: {
        getCardList() {
            let items = [];

            for (let i = 0, j = this.satellites.length; i < j; i++) {

                if (this.satellites[i].details && this.satellites[i].details.Subscriptions[(this.item.planId).toLowerCase()]) {
                    continue;
                }

                if (this.item.satelliteIds.includes(this.satellites[i].id)) {
                    continue;
                }

                let description = "";
                if (this.satellites[i].description) {
                    description = " (" + this.satellites[i].description + ")";
                }

                items.push({
                    name: this.satellites[i].ean + description,
                    value: this.satellites[i].id,
                    nameIcon: "rss",
                });
            }

            return items;
        },
        setCardValid(valid) {
            this.textFields[0].valid = valid;
        },
        getSatelliteName(id) {
            for (let i = 0, j = this.satellites.length; i < j; i++) {
                if (this.satellites[i].id === id) {
                    let description = "";
                    if (this.satellites[i].description) {
                        description = " (" + this.satellites[i].description + ")";
                    }

                    return this.satellites[i].ean + description;
                }
            }

            return null;
        },
        update() {
            this.trigger++;

            for (let i = 0, j = this.textFields.length; i < j; i++) {
                if (!this.textFields[i].valid) {
                    return;
                }
            }

            let self = this;
            self.loading = true;

            postSubscriptionByIdSatelliteIds({
                id: self.item.id,
                addSatelliteId: {
                    id: self.textFields[0].value,
                },

            }).then(function (response) {
                self.itemModule = self.item.planId;
                self.itemSatellite = self.getSatelliteName(self.textFields[0].value);

                self.loading = false;

                UIkit.modal("#" + self.modalId + "-success").show();
                self.$emit('afterSuccess', response.data);

            }).catch(function (e) {
                self.checkGenericError(e);

                self.error = e;
                self.loading = false;

            })
        },
    }
}

</script>

<style lang="scss" scoped>

</style>
