<template>
    <div>

        <LoadingStruct :is-loading="false">
            <GenericSiteTitle/>
            <Tabs :tabs="tabs">

                <li class="active">
                    <Card mode="default">
                        <MonitoringText/>
                    </Card>
                </li>
                <li>
                    <Card mode="default">
                        <VAssist2Text/>
                    </Card>
                </li>
                <li>
                    <Card mode="default">
                        <BackupsText/>
                    </Card>
                </li>
                <li>
                    <Card mode="default">
                        <PortalText/>
                    </Card>
                </li>
                <li>
                    <Card mode="default">
                        <SubscriptionsText/>
                    </Card>
                </li>
                <li>
                    <Card mode="default">
                        <SattelitesText/>
                    </Card>
                </li>
                <li>
                    <Card mode="default">
                        <UsersText/>
                    </Card>
                </li>
                <li>
                    <Card mode="default">
                        <SupportText/>
                    </Card>
                </li>
                <li>
                    <Card mode="default">
                        <ProvisioningText/>
                    </Card>
                </li>

            </Tabs>
        </LoadingStruct>

    </div>
</template>

<script>

import Tabs from "@/components/Tabs";
import GenericSiteTitle from "@/views/GenericSiteTitle";
import LoadingStruct from "@/views/LoadingStruct";
import Card from "@/components/generic/Card";
import BackupsText from "@/assets/texts/de/Backups";
import MonitoringText from "@/assets/texts/de/Monitoring";
import VAssist2Text from "@/assets/texts/de/VAssist2";
import PortalText from "@/assets/texts/de/Portal";
import SubscriptionsText from "@/assets/texts/de/Subscriptions";
import SattelitesText from "@/assets/texts/de/Satellites";
import UsersText from "@/assets/texts/de/Users";
import SupportText from "@/assets/texts/de/Support";
import ProvisioningText from "@/assets/texts/de/Provisioning";

export default {
    name: 'Distributor',
    components: {
        ProvisioningText,
        SupportText,
        UsersText,
        SattelitesText,
        SubscriptionsText,
        PortalText,
        VAssist2Text,
        MonitoringText,
        BackupsText,
        Card,
        LoadingStruct,
        GenericSiteTitle,
        Tabs,
    },
    data() {
        return {
            tabs: ['monitoring', 'vassist2', 'backups', 'portal', 'subscriptions', 'satellites', 'users', 'support', 'provisioning'],
        }
    },
}

</script>

<style lang="scss" scoped>

</style>
