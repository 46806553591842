<template>
    <div class="card-select">

        <div uk-grid>
            <div class="uk-width-expand">
                <TextField v-model="value" :is-label-large="isTitleLarge" :is-optional="optional" :label="label"
                           :label-params="labelParams"
                           :name="labelName" :rules="rules"
                           :validate="trigger" is-hidden @setValid="setValid"/>
            </div>
            <div v-if="isSearchable" class="uk-width-auto@s uk-width-1-1">
                <form class="uk-search uk-search-default"
                      @submit.prevent>
                    <span uk-search-icon/>
                    <input v-model="search"
                           :placeholder="$t('search')"
                           class="uk-search-input filter-input"
                           type="search">
                    <span v-if="search.length>0" class="uk-form-icon uk-form-icon-flip cursor-pointer"
                          uk-icon="icon: close" v-on:click="search=''"/>
                </form>
            </div>
        </div>

        <div
            :class="'uk-grid-small uk-grid-match uk-child-width-1-3@m uk-child-width-1-2@s uk-child-width-1-1' +(isSearchable ? '' : ' uk-margin-remove-top')"
            uk-grid>

            <div v-for="option in computedOptions" :key="option.value">
                <div v-on:click="updateValue(option.value)">

                    <Card
                        :class="'uk-height-1-1 uk-width-1-1 uk-card-hover uk-text-center'+(value === option.value ? ' selected' : '')+(disabled  ? ' cursor-notallowed' : ' cursor-pointer')"
                        mode="default">

                        <div v-if="option.media">
                            <div class="image-struct uk-flex uk-flex-middle uk-flex-center">
                                <ImageEnhanced :src="require('@/assets/devices/'+option.name+'.png')" class="image"
                                               uk-img/>
                            </div>
                        </div>

                        <div v-if="option.icon" class="image-struct">
                            <Icon :icon="option.icon" :ratio="6"/>
                        </div>

                        <div v-if="option.nameIcon">
                            <IconText :icon="option.nameIcon">{{ option.name }}</IconText>
                        </div>
                        <div v-else>{{ option.name }}</div>
                    </Card>
                </div>
            </div>

            <div v-if="options.length === 0"
                 class="uk-width-1-1">
                <InfoCard icon="info">{{ noItemsMessage }}</InfoCard>
            </div>
            <div v-else-if="!computedOptions.length"
                 class="uk-width-1-1">
                <InfoCard icon="info">{{ $t('noSearchResult') }}</InfoCard>
            </div>
            <div v-else-if="options.length> optionShowLimit && computedOptions.length === optionShowLimit"
                 class="uk-width-1-1">
                <InfoCard icon="info">{{
                        $t('searchForMore', {count: options.length - optionShowLimit, name: $t(labelPlural)})
                    }}
                </InfoCard>
            </div>


        </div>


    </div>
</template>

<script>

import Card from "./generic/Card";
import Icon from "./generic/Icon";
import TextField from "./generic/TextField";
import ImageEnhanced from "@/components/ImageEnhanced";
import InfoCard from "@/components/generic/InfoCard";
import IconText from "@/components/generic/IconText";

export default {
    name: 'CardSelect',
    components: {IconText, InfoCard, ImageEnhanced, TextField, Icon, Card},
    props: {
        labelPlural: String,
        label: String,
        labelParams: Object,
        labelName: String,
        options: Array,
        value: String,
        validate: Number,
        optional: Boolean,
        rules: String,
        isSearchable: Boolean,
        disabled: Boolean,
        noItemsMessage: String,
        optionShowLimit: {
            type: Number,
            default: 6,
        },
        isTitleLarge: Boolean,
    },
    data() {
        return {
            trigger: 0,
            isValid: null,
            search: "",
        }
    },
    computed: {
        computedOptions() {
            let items = this.options;
            if (this.search && this.search.length) {
                items = this.powerSearcher(items, this.search, 'name');
            }

            if (items.length > this.optionShowLimit) {
                return items.slice(0, this.optionShowLimit);
            }

            return items;
        },
    },
    watch: {
        validate() {
            this.trigger++;
        }
    },
    methods: {
        updateValue(value) {
            if (this.disabled) {
                return;
            }

            this.$emit('input', value)

            let self = this;
            setTimeout(function () {
                self.trigger++;
            }, 50);

            this.$validator.validate().then(valid => {
                this.$emit('setValid', valid)
            });
        },
        setValid(valid) {
            this.isValid = valid;
            this.$emit('setValid', valid)
        },
    },
}
</script>

<style lang="scss" scoped>

.label {
    margin-bottom: 8px;
    font-size: $site-font-size-small;
    color: $border-color-hover;
}

.uk-card-hover {
    border-left: 4px solid transparent;
}

.selected {
    border-left: 4px solid $theme-background;
    border-radius: 0 !important;
    box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16);
}

.device-name {
    margin-top: 8px;
    font-size: 20px;
    font-weight: 500;
    text-overflow: ellipsis;
}

.image {
    max-height: 100%;
    max-width: 210px;
}

.image-struct {
    margin-top: 8px;
    height: 140px;
    margin-bottom: 10px;
}

.filter-input {
    height: 31px !important;
    border-radius: $border-radius;
    background: #fff !important;
}

.uk-form-icon:not(a):not(button):not(input) {
    pointer-events: initial !important;
}

</style>
