<template>
    <GenericCreate
        :action="create" :action-error="error"

        :action-label="$t('request')" :action-loading="loading"
        :description="$t(initial ? 'createNewDistributorMessage' : 'createNewOrganisationMessage')"

        :is-button-hidden="isButtonHidden" :modal-id="modalId"
        :success-description="$t('requestDistributorAccessSuccessMessage', {email: itemName})"
        :success-title="$t(initial ? 'requestedNewDistributor' : 'requestedNewOrganisation')"

        :title="$t(initial ? 'requestDistributorAccess' : 'createNewDistributor')"
        :trigger="trigger"

        icon="plus"
    >

        <span v-for="(inputField, key) in textFields" :key="inputField.name">
            <TextField v-model="inputField.value" :autocomplete="inputField.autocomplete"
                       :autofocus="inputField.autofocus" :field-key="key"
                       :hasPasswordMeter="inputField.hasPasswordMeter"
                       :icon="inputField.icon"
                       :label="inputField.label"
                       :name="inputField.name"
                       :onEnter="create"
                       :rules="inputField.rules"
                       :type="inputField.type"
                       :validate="trigger"
                       @setValid="setValid"
            />
            <div v-if="key !== textFields.length -1" class="uk-margin-bottom"/>
        </span>

    </GenericCreate>
</template>


<script>

import {postDistributor} from "@/client/vue-api-client";
import TextField from "../generic/TextField";
import GenericCreate from "@/components/creates/GenericCreate";
import UIkit from "uikit";

export default {
    name: 'CreateDistributor',
    components: {GenericCreate, TextField},
    props: {
        value: Boolean,

        title: String,
        description: String,

        isButtonHidden: Boolean,
        initial: Boolean,
    },
    data() {
        return {
            modalId: "modal-create-distributor",
            trigger: 0,

            textFields: [
                {
                    value: "",
                    valid: false,

                    autofocus: true,
                    name: "distributor-name",
                    label: "distributorName",
                    rules: "required",
                    icon: "hashtag",
                },
            ],

            loading: false,
            error: null,
            itemName: "",
        }
    },
    methods: {
        setValid(valid, fieldKey) {
            this.textFields[fieldKey].valid = valid;

        },
        create() {
            this.trigger++;

            for (let i = 0, j = this.textFields.length; i < j; i++) {
                if (!this.textFields[i].valid) {
                    return;
                }
            }

            let self = this;
            self.loading = true;

            postDistributor({
                distributor: {
                    name: self.textFields[0].value,
                },

            }).then(function () {
                self.$emit('input', true)

                self.itemName = self.textFields[0].value;

                for (let i = 0, j = self.textFields.length; i < j; i++) {
                    self.textFields[i].value = "";
                    self.textFields[i].valid = false;
                }

                self.loading = false;

                UIkit.modal("#" + self.modalId + "-success").show();
                self.$emit('afterSuccess')

            }).catch(function (e) {
                self.checkGenericError(e);

                self.error = e;
                self.loading = false;

            })
        },
    }
}

</script>

<style lang="scss" scoped>

</style>
