<template>
    <GenericCreate
        :action="update" :action-error="error" :action-label="$t('saveChanges')"

        :action-loading="loading"

        :description="$t('backup.pro.message')" :modal-id="modalId"
        :success-description="$t( 'backup.settings.saved.message')" :success-title="$t('backup.settings.saved')"

        :trigger="trigger"
        icon="settings"
    >
        <template v-slot:button-description>
            <span class="uk-visible@s">
                {{ $t('backup') }} <Label :text="$t('PRO')" is-bold is-small type="success"/>
            </span>
        </template>

        <template v-slot:modal-title>
            <ModalTitle class="uk-text-center">
                {{ $t('backup') }} <Label :text="$t('PRO')" is-bold is-large type="success"/>
            </ModalTitle>
        </template>

        <SwitchButton v-model="textFields[0].value" :name="textFields[0].name" :label="$t(textFields[0].label)"/>

        <div class="uk-margin-top"/>
        <LabelWithError :label="$t('interval')"/>

        <div class="uk-grid-small uk-flex uk-flex-middle" uk-grid>
            <div>
                <select v-model="intervalType" class="uk-select">
                    <option v-for="type in intervalTypes" :key="type" :value="type">
                        {{ $t(type) }}
                    </option>
                </select>
            </div>


            <div v-if="['weekly', 'monthly'].includes(intervalType)">
                {{ $t('at') }}
            </div>
            <div v-if="intervalType === 'weekly'">
                <select v-model="intervalWeekday" class="uk-select">
                    <option v-for="intervalWeekday in intervalWeekdays" :key="intervalWeekday" :value="intervalWeekday">
                        {{ $t(intervalWeekday) }}
                    </option>
                </select>
            </div>
            <div v-if="intervalType === 'monthly'">
                <select v-model="intervalDay" class="uk-select">
                    <option v-for="day in 30" :key="day" :value="day">
                        {{ day }}
                    </option>
                    <option key="last" value="last">
                        {{ $t('last') }}
                    </option>
                </select>
            </div>
            <div v-if="intervalType === 'monthly'">
                {{ $t('day') }}
            </div>

            <div>
                {{ $t('by') }}
            </div>

            <div>
                <select v-model="intervalHour" class="uk-select">
                    <option key="0" value="0">
                        00
                    </option>
                    <option v-for="hour in 23" :key="hour" :value="hour">
                        {{ ('' + hour).padStart(2, '0') }}
                    </option>
                </select>
            </div>
            <div>
                :
            </div>
            <div>
                <select v-model="intervalMinute" class="uk-select">
                    <option v-for="minute in [0, 15, 30, 45]" :key="minute" :value="minute">
                        {{ ('' + minute).padStart(2, '0') }}
                    </option>
                </select>
            </div>
            <div>
                {{ $t('clock') }}
            </div>
        </div>

        <span v-for="(inputField, key) in textFields" :key="inputField.name">
            <div v-if="key>0 && key < textFields.length-1">
                <TextField v-model="inputField.value" :autocomplete="inputField.autocomplete"
                           :autofocus="inputField.autofocus" :disabled="inputField.disabled" :field-key="key"
                           :hasPasswordMeter="inputField.hasPasswordMeter"
                           :icon="inputField.icon"
                           :is-optional="inputField.optional"
                           :label="inputField.label"
                           :name="inputField.name"
                           :onEnter="update"
                           :rules="inputField.rules"
                           :type="inputField.type"
                           :validate="trigger"
                           @setValid="setValid"
                />
            </div>
            <div v-if="key !== textFields.length -1" class="uk-margin-bottom"/>
        </span>

    </GenericCreate>
</template>

<script>

import TextField from "../generic/TextField";
import GenericCreate from "@/components/creates/GenericCreate";
import Label from "@/components/Label";
import ModalTitle from "@/components/generic/ModalTitle";
import SwitchButton from "@/components/generic/SwitchButton";
import UIkit from "uikit";
import LabelWithError from "@/components/generic/LabelWithError";

export default {
    name: 'UpdateBackupSettings',
    components: {
        LabelWithError,
        SwitchButton,
        ModalTitle,
        Label,
        GenericCreate,
        TextField,
    },
    props: {
        deviceId: String,
    },
    data() {
        return {
            trigger: 0,
            modalId: "modal-update-backup-settings-" + this.deviceId,

            intervalTypes: ['daily', 'weekly', 'monthly'],
            intervalWeekdays: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],


            intervalType: 'daily',
            intervalWeekday: 'sunday',
            intervalDay: 1,
            intervalHour: 0,
            intervalMinute: 0,

            textFields: [
                {
                    value: false,
                    valid: true,

                    label: 'backups.automatic.activate',
                },
            ],

            loading: false,
            error: null,
        }
    },
    methods: {
        setValid(valid, fieldKey) {
            this.textFields[fieldKey].valid = valid
        },
        update() {
            this.trigger++;

            for (let i = 0, j = this.textFields.length; i < j; i++) {
                if (!this.textFields[i].valid) {
                    return;
                }
            }

            let self = this;
            self.loading = true;


            self.loading = false;
            UIkit.modal("#" + self.modalId + "-success").show();
            self.$emit('afterSuccess');
        },
    }
}

</script>

<style lang="scss" scoped>

.uk-switcher {
    margin-top: 25px;
}

.uk-tab > * > a {
    text-transform: initial !important;
    font-size: 16px;
    font-weight: 400;
    color: $site-color;
    border-background: transparent;

    &:hover {
        color: $site-color-hover;
        border-bottom: 1px solid $site-color-hover;
    }

    &:focus {
        color: $site-color;
    }
}

.uk-tab > .uk-active > a {
    color: $site-color-hover;
    border-bottom: 1px solid $site-color-hover;
}

.display-time {

}

</style>
