/* eslint-disable */
import axios from 'axios'
import qs from 'qs'
let domain = ''
export const getDomain = () => {
  return domain
}
export const setDomain = ($domain) => {
  domain = $domain
}
export const request = (method, url, body, queryParameters, form, config) => {
  method = method.toLowerCase()
  let keys = Object.keys(queryParameters)
  let queryUrl = url
  if (keys.length > 0) {
    queryUrl = url + '?' + qs.stringify(queryParameters)
  }
  // let queryUrl = url+(keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
  if (body) {
    return axios[method](queryUrl, body, config)
  } else if (method === 'get') {
    return axios[method](queryUrl, {
      params: form
    }, config)
  } else {
    return axios[method](queryUrl, qs.stringify(form), config)
  }
}
/*==========================================================
 *                    This is the api for portal.a365.systems
 ==========================================================*/
/**
 * Get's you a list of every created device
 * request: getDevice
 * url: getDeviceURL
 * method: getDevice_TYPE
 * raw_url: getDevice_RAW_URL
 */
export const getDevice = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/device'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getDevice_RAW_URL = function() {
  return '/device'
}
export const getDevice_TYPE = function() {
  return 'get'
}
export const getDeviceURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/device'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Get's you a list of all available categories
 * request: getDeviceCategories
 * url: getDeviceCategoriesURL
 * method: getDeviceCategories_TYPE
 * raw_url: getDeviceCategories_RAW_URL
 */
export const getDeviceCategories = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/device/categories'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getDeviceCategories_RAW_URL = function() {
  return '/device/categories'
}
export const getDeviceCategories_TYPE = function() {
  return 'get'
}
export const getDeviceCategoriesURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/device/categories'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Get's you the model of a specific device
 * request: getDeviceById
 * url: getDeviceByIdURL
 * method: getDeviceById_TYPE
 * raw_url: getDeviceById_RAW_URL
 * @param id - device id to receive
 */
export const getDeviceById = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/device/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getDeviceById_RAW_URL = function() {
  return '/device/{id}'
}
export const getDeviceById_TYPE = function() {
  return 'get'
}
export const getDeviceByIdURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/device/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Will update the device's properties with body params
 * request: putDeviceById
 * url: putDeviceByIdURL
 * method: putDeviceById_TYPE
 * raw_url: putDeviceById_RAW_URL
 * @param id - device id
 * @param deviceInformation - device information
 */
export const putDeviceById = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/device/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['deviceInformation'] !== undefined) {
    body = parameters['deviceInformation']
  }
  if (parameters['deviceInformation'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: deviceInformation'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const putDeviceById_RAW_URL = function() {
  return '/device/{id}'
}
export const putDeviceById_TYPE = function() {
  return 'put'
}
export const putDeviceByIdURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/device/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Will upload the given voicemail and save it for later use
 * request: postDeviceByIdConfigbackups
 * url: postDeviceByIdConfigbackupsURL
 * method: postDeviceByIdConfigbackups_TYPE
 * raw_url: postDeviceByIdConfigbackups_RAW_URL
 * @param id - device id
 * @param voicemail - .mp3-file which holds the desired voicemail
 * @param remotePhoneNumber - phone number which left this voicemail on your device
 * @param localPhoneNumber - phone number of the device which received this voicemail
 * @param message - the content of the voicemail in written format
 */
export const postDeviceByIdConfigbackups = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/device/{id}/configbackups'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['voicemail'] !== undefined) {
    form['voicemail'] = parameters['voicemail']
  }
  if (parameters['voicemail'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: voicemail'))
  }
  if (parameters['remotePhoneNumber'] !== undefined) {
    form['remotePhoneNumber'] = parameters['remotePhoneNumber']
  }
  if (parameters['remotePhoneNumber'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: remotePhoneNumber'))
  }
  if (parameters['localPhoneNumber'] !== undefined) {
    form['localPhoneNumber'] = parameters['localPhoneNumber']
  }
  if (parameters['localPhoneNumber'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: localPhoneNumber'))
  }
  if (parameters['message'] !== undefined) {
    form['message'] = parameters['message']
  }
  if (parameters['message'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: message'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const postDeviceByIdConfigbackups_RAW_URL = function() {
  return '/device/{id}/configbackups'
}
export const postDeviceByIdConfigbackups_TYPE = function() {
  return 'post'
}
export const postDeviceByIdConfigbackupsURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/device/{id}/configbackups'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Will ideally result in a download-url for the corresponding config-backup
 * request: getDeviceByIdConfigbackupsByBackupId
 * url: getDeviceByIdConfigbackupsByBackupIdURL
 * method: getDeviceByIdConfigbackupsByBackupId_TYPE
 * raw_url: getDeviceByIdConfigbackupsByBackupId_RAW_URL
 * @param id - device id
 * @param backupId - id of the backup you want to download
 */
export const getDeviceByIdConfigbackupsByBackupId = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/device/{id}/configbackups/{backupId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  path = path.replace('{backupId}', `${parameters['backupId']}`)
  if (parameters['backupId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: backupId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getDeviceByIdConfigbackupsByBackupId_RAW_URL = function() {
  return '/device/{id}/configbackups/{backupId}'
}
export const getDeviceByIdConfigbackupsByBackupId_TYPE = function() {
  return 'get'
}
export const getDeviceByIdConfigbackupsByBackupIdURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/device/{id}/configbackups/{backupId}'
  path = path.replace('{id}', `${parameters['id']}`)
  path = path.replace('{backupId}', `${parameters['backupId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Will sent an invitation to the given mail-address
 * request: postDeviceByIdInvites
 * url: postDeviceByIdInvitesURL
 * method: postDeviceByIdInvites_TYPE
 * raw_url: postDeviceByIdInvites_RAW_URL
 * @param id - device id
 * @param mailAddress - address object
 */
export const postDeviceByIdInvites = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/device/{id}/invites'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['mailAddress'] !== undefined) {
    body = parameters['mailAddress']
  }
  if (parameters['mailAddress'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: mailAddress'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const postDeviceByIdInvites_RAW_URL = function() {
  return '/device/{id}/invites'
}
export const postDeviceByIdInvites_TYPE = function() {
  return 'post'
}
export const postDeviceByIdInvitesURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/device/{id}/invites'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Will accept the invite if there is one for the mail-address of current user
 * request: postDeviceByIdInvitesAccept
 * url: postDeviceByIdInvitesAcceptURL
 * method: postDeviceByIdInvitesAccept_TYPE
 * raw_url: postDeviceByIdInvitesAccept_RAW_URL
 * @param id - device id
 */
export const postDeviceByIdInvitesAccept = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/device/{id}/invites/accept'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const postDeviceByIdInvitesAccept_RAW_URL = function() {
  return '/device/{id}/invites/accept'
}
export const postDeviceByIdInvitesAccept_TYPE = function() {
  return 'post'
}
export const postDeviceByIdInvitesAcceptURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/device/{id}/invites/accept'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Will reject the invite if there is one for the mail-address of current user
 * request: postDeviceByIdInvitesReject
 * url: postDeviceByIdInvitesRejectURL
 * method: postDeviceByIdInvitesReject_TYPE
 * raw_url: postDeviceByIdInvitesReject_RAW_URL
 * @param id - device id
 */
export const postDeviceByIdInvitesReject = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/device/{id}/invites/reject'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const postDeviceByIdInvitesReject_RAW_URL = function() {
  return '/device/{id}/invites/reject'
}
export const postDeviceByIdInvitesReject_TYPE = function() {
  return 'post'
}
export const postDeviceByIdInvitesRejectURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/device/{id}/invites/reject'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Will delete an invite which is not accepted yet
 * request: deleteDeviceByIdInvitesByMailAddress
 * url: deleteDeviceByIdInvitesByMailAddressURL
 * method: deleteDeviceByIdInvitesByMailAddress_TYPE
 * raw_url: deleteDeviceByIdInvitesByMailAddress_RAW_URL
 * @param id - device id
 * @param mailAddress - mailaddress you want to delete the invite for
 */
export const deleteDeviceByIdInvitesByMailAddress = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/device/{id}/invites/{mailAddress}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  path = path.replace('{mailAddress}', `${parameters['mailAddress']}`)
  if (parameters['mailAddress'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: mailAddress'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteDeviceByIdInvitesByMailAddress_RAW_URL = function() {
  return '/device/{id}/invites/{mailAddress}'
}
export const deleteDeviceByIdInvitesByMailAddress_TYPE = function() {
  return 'delete'
}
export const deleteDeviceByIdInvitesByMailAddressURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/device/{id}/invites/{mailAddress}'
  path = path.replace('{id}', `${parameters['id']}`)
  path = path.replace('{mailAddress}', `${parameters['mailAddress']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Will update the device's satellite property with body params
 * request: putDeviceByIdSatellite
 * url: putDeviceByIdSatelliteURL
 * method: putDeviceByIdSatellite_TYPE
 * raw_url: putDeviceByIdSatellite_RAW_URL
 * @param id - device id
 * @param satelliteInformation - satellite information
 */
export const putDeviceByIdSatellite = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/device/{id}/satellite'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['satelliteInformation'] !== undefined) {
    body = parameters['satelliteInformation']
  }
  if (parameters['satelliteInformation'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: satelliteInformation'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const putDeviceByIdSatellite_RAW_URL = function() {
  return '/device/{id}/satellite'
}
export const putDeviceByIdSatellite_TYPE = function() {
  return 'put'
}
export const putDeviceByIdSatelliteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/device/{id}/satellite'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Get all monitoring events for a device
 * request: getDeviceByIdSatelliteModulesMonitoring
 * url: getDeviceByIdSatelliteModulesMonitoringURL
 * method: getDeviceByIdSatelliteModulesMonitoring_TYPE
 * raw_url: getDeviceByIdSatelliteModulesMonitoring_RAW_URL
 * @param id - deviceId
 * @param deviceEvents - deviceEvents
 * @param peripheralEvents - peripheralEvents
 */
export const getDeviceByIdSatelliteModulesMonitoring = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/device/{id}/satellite/modules/monitoring'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['deviceEvents'] !== undefined) {
    queryParameters['deviceEvents'] = parameters['deviceEvents']
  }
  if (parameters['peripheralEvents'] !== undefined) {
    queryParameters['peripheralEvents'] = parameters['peripheralEvents']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getDeviceByIdSatelliteModulesMonitoring_RAW_URL = function() {
  return '/device/{id}/satellite/modules/monitoring'
}
export const getDeviceByIdSatelliteModulesMonitoring_TYPE = function() {
  return 'get'
}
export const getDeviceByIdSatelliteModulesMonitoringURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/device/{id}/satellite/modules/monitoring'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['deviceEvents'] !== undefined) {
    queryParameters['deviceEvents'] = parameters['deviceEvents']
  }
  if (parameters['peripheralEvents'] !== undefined) {
    queryParameters['peripheralEvents'] = parameters['peripheralEvents']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Will add n peripherals to the peripheral-list of this device
 * request: postDeviceByIdSatellitePeripherals
 * url: postDeviceByIdSatellitePeripheralsURL
 * method: postDeviceByIdSatellitePeripherals_TYPE
 * raw_url: postDeviceByIdSatellitePeripherals_RAW_URL
 * @param id - device id
 * @param peripheralInformation - peripheral information
 */
export const postDeviceByIdSatellitePeripherals = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/device/{id}/satellite/peripherals'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['peripheralInformation'] !== undefined) {
    body = parameters['peripheralInformation']
  }
  if (parameters['peripheralInformation'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: peripheralInformation'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const postDeviceByIdSatellitePeripherals_RAW_URL = function() {
  return '/device/{id}/satellite/peripherals'
}
export const postDeviceByIdSatellitePeripherals_TYPE = function() {
  return 'post'
}
export const postDeviceByIdSatellitePeripheralsURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/device/{id}/satellite/peripherals'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Will remove one peripheral from the peripheral-list of this device
 * request: deleteDeviceByIdSatellitePeripheralsByPeripheralId
 * url: deleteDeviceByIdSatellitePeripheralsByPeripheralIdURL
 * method: deleteDeviceByIdSatellitePeripheralsByPeripheralId_TYPE
 * raw_url: deleteDeviceByIdSatellitePeripheralsByPeripheralId_RAW_URL
 * @param id - device id
 * @param peripheralId - peripheral id
 */
export const deleteDeviceByIdSatellitePeripheralsByPeripheralId = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/device/{id}/satellite/peripherals/{peripheralId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  path = path.replace('{peripheralId}', `${parameters['peripheralId']}`)
  if (parameters['peripheralId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: peripheralId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteDeviceByIdSatellitePeripheralsByPeripheralId_RAW_URL = function() {
  return '/device/{id}/satellite/peripherals/{peripheralId}'
}
export const deleteDeviceByIdSatellitePeripheralsByPeripheralId_TYPE = function() {
  return 'delete'
}
export const deleteDeviceByIdSatellitePeripheralsByPeripheralIdURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/device/{id}/satellite/peripherals/{peripheralId}'
  path = path.replace('{id}', `${parameters['id']}`)
  path = path.replace('{peripheralId}', `${parameters['peripheralId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Will upload the given config-backup and save it for later use
 * request: postDeviceByIdUpload
 * url: postDeviceByIdUploadURL
 * method: postDeviceByIdUpload_TYPE
 * raw_url: postDeviceByIdUpload_RAW_URL
 * @param id - device id
 * @param configBackup - .cfg-file which holds the device backup
 * @param additionalInformation - additional information you want to store along with this backup
 */
export const postDeviceByIdUpload = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/device/{id}/upload'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['configBackup'] !== undefined) {
    form['configBackup'] = parameters['configBackup']
  }
  if (parameters['configBackup'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: configBackup'))
  }
  if (parameters['additionalInformation'] !== undefined) {
    form['additionalInformation'] = parameters['additionalInformation']
  }
  if (parameters['additionalInformation'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: additionalInformation'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const postDeviceByIdUpload_RAW_URL = function() {
  return '/device/{id}/upload'
}
export const postDeviceByIdUpload_TYPE = function() {
  return 'post'
}
export const postDeviceByIdUploadURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/device/{id}/upload'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Will delete the given userId from the device
 * request: deleteDeviceByIdUserIdsByMailAddress
 * url: deleteDeviceByIdUserIdsByMailAddressURL
 * method: deleteDeviceByIdUserIdsByMailAddress_TYPE
 * raw_url: deleteDeviceByIdUserIdsByMailAddress_RAW_URL
 * @param id - device id
 * @param mailAddress - the user mailAddress
 */
export const deleteDeviceByIdUserIdsByMailAddress = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/device/{id}/userIds/{mailAddress}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  path = path.replace('{mailAddress}', `${parameters['mailAddress']}`)
  if (parameters['mailAddress'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: mailAddress'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteDeviceByIdUserIdsByMailAddress_RAW_URL = function() {
  return '/device/{id}/userIds/{mailAddress}'
}
export const deleteDeviceByIdUserIdsByMailAddress_TYPE = function() {
  return 'delete'
}
export const deleteDeviceByIdUserIdsByMailAddressURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/device/{id}/userIds/{mailAddress}'
  path = path.replace('{id}', `${parameters['id']}`)
  path = path.replace('{mailAddress}', `${parameters['mailAddress']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Will ideally result in a download-url for the corresponding voicemail
 * request: getDeviceByIdVoicemailsByVoicemailId
 * url: getDeviceByIdVoicemailsByVoicemailIdURL
 * method: getDeviceByIdVoicemailsByVoicemailId_TYPE
 * raw_url: getDeviceByIdVoicemailsByVoicemailId_RAW_URL
 * @param id - device id
 * @param voicemailId - id of the voicemail you want to download
 */
export const getDeviceByIdVoicemailsByVoicemailId = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/device/{id}/voicemails/{voicemailId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  path = path.replace('{voicemailId}', `${parameters['voicemailId']}`)
  if (parameters['voicemailId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: voicemailId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getDeviceByIdVoicemailsByVoicemailId_RAW_URL = function() {
  return '/device/{id}/voicemails/{voicemailId}'
}
export const getDeviceByIdVoicemailsByVoicemailId_TYPE = function() {
  return 'get'
}
export const getDeviceByIdVoicemailsByVoicemailIdURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/device/{id}/voicemails/{voicemailId}'
  path = path.replace('{id}', `${parameters['id']}`)
  path = path.replace('{voicemailId}', `${parameters['voicemailId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Get's you a list of every created distributor
 * request: getDistributor
 * url: getDistributorURL
 * method: getDistributor_TYPE
 * raw_url: getDistributor_RAW_URL
 */
export const getDistributor = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/distributor'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getDistributor_RAW_URL = function() {
  return '/distributor'
}
export const getDistributor_TYPE = function() {
  return 'get'
}
export const getDistributorURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/distributor'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Create a unconfirmed distributor
 * request: postDistributor
 * url: postDistributorURL
 * method: postDistributor_TYPE
 * raw_url: postDistributor_RAW_URL
 * @param distributor - distributor information
 */
export const postDistributor = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/distributor'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['distributor'] !== undefined) {
    body = parameters['distributor']
  }
  if (parameters['distributor'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: distributor'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const postDistributor_RAW_URL = function() {
  return '/distributor'
}
export const postDistributor_TYPE = function() {
  return 'post'
}
export const postDistributorURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/distributor'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Get's you the model of a specific distributor
 * request: getDistributorById
 * url: getDistributorByIdURL
 * method: getDistributorById_TYPE
 * raw_url: getDistributorById_RAW_URL
 * @param id - distributor id to receive
 */
export const getDistributorById = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/distributor/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getDistributorById_RAW_URL = function() {
  return '/distributor/{id}'
}
export const getDistributorById_TYPE = function() {
  return 'get'
}
export const getDistributorByIdURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/distributor/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Confirms a unconfirmed distributor
 * request: postDistributorByIdConfirm
 * url: postDistributorByIdConfirmURL
 * method: postDistributorByIdConfirm_TYPE
 * raw_url: postDistributorByIdConfirm_RAW_URL
 * @param id - distributor id to confirm
 */
export const postDistributorByIdConfirm = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/distributor/{id}/confirm'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const postDistributorByIdConfirm_RAW_URL = function() {
  return '/distributor/{id}/confirm'
}
export const postDistributorByIdConfirm_TYPE = function() {
  return 'post'
}
export const postDistributorByIdConfirmURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/distributor/{id}/confirm'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Denies a distributor
 * request: postDistributorByIdDeny
 * url: postDistributorByIdDenyURL
 * method: postDistributorByIdDeny_TYPE
 * raw_url: postDistributorByIdDeny_RAW_URL
 * @param id - distributor id to deny
 */
export const postDistributorByIdDeny = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/distributor/{id}/deny'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const postDistributorByIdDeny_RAW_URL = function() {
  return '/distributor/{id}/deny'
}
export const postDistributorByIdDeny_TYPE = function() {
  return 'post'
}
export const postDistributorByIdDenyURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/distributor/{id}/deny'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Get all devices for an existing distributor
 * request: getDistributorByIdDevices
 * url: getDistributorByIdDevicesURL
 * method: getDistributorByIdDevices_TYPE
 * raw_url: getDistributorByIdDevices_RAW_URL
 * @param id - distributor id you want to receive all devices for
 */
export const getDistributorByIdDevices = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/distributor/{id}/devices'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getDistributorByIdDevices_RAW_URL = function() {
  return '/distributor/{id}/devices'
}
export const getDistributorByIdDevices_TYPE = function() {
  return 'get'
}
export const getDistributorByIdDevicesURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/distributor/{id}/devices'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Create a new device for an existing distributor
 * request: postDistributorByIdDevices
 * url: postDistributorByIdDevicesURL
 * method: postDistributorByIdDevices_TYPE
 * raw_url: postDistributorByIdDevices_RAW_URL
 * @param id - distributor id you create a device for
 * @param device - device information
 */
export const postDistributorByIdDevices = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/distributor/{id}/devices'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['device'] !== undefined) {
    body = parameters['device']
  }
  if (parameters['device'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: device'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const postDistributorByIdDevices_RAW_URL = function() {
  return '/distributor/{id}/devices'
}
export const postDistributorByIdDevices_TYPE = function() {
  return 'post'
}
export const postDistributorByIdDevicesURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/distributor/{id}/devices'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Get all satellites for an existing distributor
 * request: getDistributorByIdSatellites
 * url: getDistributorByIdSatellitesURL
 * method: getDistributorByIdSatellites_TYPE
 * raw_url: getDistributorByIdSatellites_RAW_URL
 * @param id - distributor id you want to receive all satellites for
 */
export const getDistributorByIdSatellites = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/distributor/{id}/satellites'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getDistributorByIdSatellites_RAW_URL = function() {
  return '/distributor/{id}/satellites'
}
export const getDistributorByIdSatellites_TYPE = function() {
  return 'get'
}
export const getDistributorByIdSatellitesURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/distributor/{id}/satellites'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Gets you a list of subscriptions of a specific distributor
 * request: getDistributorByIdSubscriptions
 * url: getDistributorByIdSubscriptionsURL
 * method: getDistributorByIdSubscriptions_TYPE
 * raw_url: getDistributorByIdSubscriptions_RAW_URL
 * @param id - distributor id to receive
 */
export const getDistributorByIdSubscriptions = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/distributor/{id}/subscriptions'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getDistributorByIdSubscriptions_RAW_URL = function() {
  return '/distributor/{id}/subscriptions'
}
export const getDistributorByIdSubscriptions_TYPE = function() {
  return 'get'
}
export const getDistributorByIdSubscriptionsURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/distributor/{id}/subscriptions'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Creates you a subscription for a specific distributor
 * request: postDistributorByIdSubscriptions
 * url: postDistributorByIdSubscriptionsURL
 * method: postDistributorByIdSubscriptions_TYPE
 * raw_url: postDistributorByIdSubscriptions_RAW_URL
 * @param id - distributor id to receive
 * @param createSubscriptionPayload - subscription payload
 */
export const postDistributorByIdSubscriptions = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/distributor/{id}/subscriptions'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['createSubscriptionPayload'] !== undefined) {
    body = parameters['createSubscriptionPayload']
  }
  if (parameters['createSubscriptionPayload'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: createSubscriptionPayload'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const postDistributorByIdSubscriptions_RAW_URL = function() {
  return '/distributor/{id}/subscriptions'
}
export const postDistributorByIdSubscriptions_TYPE = function() {
  return 'post'
}
export const postDistributorByIdSubscriptionsURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/distributor/{id}/subscriptions'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Lets you request a subscription
 * request: postDistributorByIdSubscriptionsRequest
 * url: postDistributorByIdSubscriptionsRequestURL
 * method: postDistributorByIdSubscriptionsRequest_TYPE
 * raw_url: postDistributorByIdSubscriptionsRequest_RAW_URL
 * @param id - distributor id to receive
 */
export const postDistributorByIdSubscriptionsRequest = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/distributor/{id}/subscriptions/request'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const postDistributorByIdSubscriptionsRequest_RAW_URL = function() {
  return '/distributor/{id}/subscriptions/request'
}
export const postDistributorByIdSubscriptionsRequest_TYPE = function() {
  return 'post'
}
export const postDistributorByIdSubscriptionsRequestURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/distributor/{id}/subscriptions/request'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Authenticate here in order to receive a jwt for other operations
 * request: postLogin
 * url: postLoginURL
 * method: postLogin_TYPE
 * raw_url: postLogin_RAW_URL
 * @param credentials - username and password
 */
export const postLogin = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/login'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['credentials'] !== undefined) {
    body = parameters['credentials']
  }
  if (parameters['credentials'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: credentials'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const postLogin_RAW_URL = function() {
  return '/login'
}
export const postLogin_TYPE = function() {
  return 'post'
}
export const postLoginURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/login'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Gets you a list of every plan available for subscription
 * request: getPlan
 * url: getPlanURL
 * method: getPlan_TYPE
 * raw_url: getPlan_RAW_URL
 */
export const getPlan = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/plan'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getPlan_RAW_URL = function() {
  return '/plan'
}
export const getPlan_TYPE = function() {
  return 'get'
}
export const getPlanURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/plan'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Will send an e-mail to user identified by given e-mail-address in order to reset the password
 * request: postRecovery
 * url: postRecoveryURL
 * method: postRecovery_TYPE
 * raw_url: postRecovery_RAW_URL
 * @param recovery - information required to initiate a recovery
 */
export const postRecovery = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/recovery'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['recovery'] !== undefined) {
    body = parameters['recovery']
  }
  if (parameters['recovery'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: recovery'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const postRecovery_RAW_URL = function() {
  return '/recovery'
}
export const postRecovery_TYPE = function() {
  return 'post'
}
export const postRecoveryURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/recovery'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Set a new password for a user by pending recoveryID
 * request: postRecoveryById
 * url: postRecoveryByIdURL
 * method: postRecoveryById_TYPE
 * raw_url: postRecoveryById_RAW_URL
 * @param id - id of the pending recovery to finalize
 * @param recovery - information required to finalize
 */
export const postRecoveryById = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/recovery/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['recovery'] !== undefined) {
    body = parameters['recovery']
  }
  if (parameters['recovery'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: recovery'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const postRecoveryById_RAW_URL = function() {
  return '/recovery/{id}'
}
export const postRecoveryById_TYPE = function() {
  return 'post'
}
export const postRecoveryByIdURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/recovery/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Create a unverified user
 * request: postRegister
 * url: postRegisterURL
 * method: postRegister_TYPE
 * raw_url: postRegister_RAW_URL
 * @param user - user object
 */
export const postRegister = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/register'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['user'] !== undefined) {
    body = parameters['user']
  }
  if (parameters['user'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: user'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const postRegister_RAW_URL = function() {
  return '/register'
}
export const postRegister_TYPE = function() {
  return 'post'
}
export const postRegisterURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/register'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Fetch a list of satellites filterable by query params
 * request: getSatellite
 * url: getSatelliteURL
 * method: getSatellite_TYPE
 * raw_url: getSatellite_RAW_URL
 * @param distributorId - distributor id
 */
export const getSatellite = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/satellite'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['distributorId'] !== undefined) {
    queryParameters['distributorId'] = parameters['distributorId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getSatellite_RAW_URL = function() {
  return '/satellite'
}
export const getSatellite_TYPE = function() {
  return 'get'
}
export const getSatelliteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/satellite'
  if (parameters['distributorId'] !== undefined) {
    queryParameters['distributorId'] = parameters['distributorId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Create a satellite with your desired properties
 * request: postSatellite
 * url: postSatelliteURL
 * method: postSatellite_TYPE
 * raw_url: postSatellite_RAW_URL
 * @param satellite - satellite information
 */
export const postSatellite = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/satellite'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['satellite'] !== undefined) {
    body = parameters['satellite']
  }
  if (parameters['satellite'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: satellite'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const postSatellite_RAW_URL = function() {
  return '/satellite'
}
export const postSatellite_TYPE = function() {
  return 'post'
}
export const postSatelliteURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/satellite'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Fetch a specific satellite by its ID
 * request: getSatelliteById
 * url: getSatelliteByIdURL
 * method: getSatelliteById_TYPE
 * raw_url: getSatelliteById_RAW_URL
 * @param id - satellite id to receive
 */
export const getSatelliteById = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/satellite/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getSatelliteById_RAW_URL = function() {
  return '/satellite/{id}'
}
export const getSatelliteById_TYPE = function() {
  return 'get'
}
export const getSatelliteByIdURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/satellite/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Update a satellite with your desired properties
 * request: putSatelliteById
 * url: putSatelliteByIdURL
 * method: putSatelliteById_TYPE
 * raw_url: putSatelliteById_RAW_URL
 * @param id - satellite id
 * @param satellite - satellite information
 */
export const putSatelliteById = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/satellite/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['satellite'] !== undefined) {
    body = parameters['satellite']
  }
  if (parameters['satellite'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: satellite'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const putSatelliteById_RAW_URL = function() {
  return '/satellite/{id}'
}
export const putSatelliteById_TYPE = function() {
  return 'put'
}
export const putSatelliteByIdURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/satellite/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Delete a satellite
 * request: deleteSatelliteById
 * url: deleteSatelliteByIdURL
 * method: deleteSatelliteById_TYPE
 * raw_url: deleteSatelliteById_RAW_URL
 * @param id - satellite id
 */
export const deleteSatelliteById = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/satellite/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteSatelliteById_RAW_URL = function() {
  return '/satellite/{id}'
}
export const deleteSatelliteById_TYPE = function() {
  return 'delete'
}
export const deleteSatelliteByIdURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/satellite/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Update a satellite with your desired deviceId
 * request: putSatelliteByIdDeviceId
 * url: putSatelliteByIdDeviceIdURL
 * method: putSatelliteByIdDeviceId_TYPE
 * raw_url: putSatelliteByIdDeviceId_RAW_URL
 * @param id - satellite id
 * @param satelliteDeviceId - deviceID information
 */
export const putSatelliteByIdDeviceId = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/satellite/{id}/deviceId'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['satelliteDeviceId'] !== undefined) {
    body = parameters['satelliteDeviceId']
  }
  if (parameters['satelliteDeviceId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: satelliteDeviceId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const putSatelliteByIdDeviceId_RAW_URL = function() {
  return '/satellite/{id}/deviceId'
}
export const putSatelliteByIdDeviceId_TYPE = function() {
  return 'put'
}
export const putSatelliteByIdDeviceIdURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/satellite/{id}/deviceId'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Will serve you a json of the config for the satellite-client
 * request: getSatelliteClientBySatelliteId
 * url: getSatelliteClientBySatelliteIdURL
 * method: getSatelliteClientBySatelliteId_TYPE
 * raw_url: getSatelliteClientBySatelliteId_RAW_URL
 * @param satelliteId - satellite id
 */
export const getSatelliteClientBySatelliteId = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/satelliteClient/{satelliteId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{satelliteId}', `${parameters['satelliteId']}`)
  if (parameters['satelliteId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: satelliteId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getSatelliteClientBySatelliteId_RAW_URL = function() {
  return '/satelliteClient/{satelliteId}'
}
export const getSatelliteClientBySatelliteId_TYPE = function() {
  return 'get'
}
export const getSatelliteClientBySatelliteIdURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/satelliteClient/{satelliteId}'
  path = path.replace('{satelliteId}', `${parameters['satelliteId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Will add a status for the device to the monitoring module of your device
 * request: postSatelliteClientBySatelliteIdMonitoringDevice
 * url: postSatelliteClientBySatelliteIdMonitoringDeviceURL
 * method: postSatelliteClientBySatelliteIdMonitoringDevice_TYPE
 * raw_url: postSatelliteClientBySatelliteIdMonitoringDevice_RAW_URL
 * @param satelliteId - satellite id
 * @param peripheralId - peripheral id
 * @param addPeripheralStatus - peripheralStatusPayload
 */
export const postSatelliteClientBySatelliteIdMonitoringDevice = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/satelliteClient/{satelliteId}/monitoring/device'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{satelliteId}', `${parameters['satelliteId']}`)
  if (parameters['satelliteId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: satelliteId'))
  }
  path = path.replace('{peripheralId}', `${parameters['peripheralId']}`)
  if (parameters['peripheralId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: peripheralId'))
  }
  if (parameters['addPeripheralStatus'] !== undefined) {
    body = parameters['addPeripheralStatus']
  }
  if (parameters['addPeripheralStatus'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: addPeripheralStatus'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const postSatelliteClientBySatelliteIdMonitoringDevice_RAW_URL = function() {
  return '/satelliteClient/{satelliteId}/monitoring/device'
}
export const postSatelliteClientBySatelliteIdMonitoringDevice_TYPE = function() {
  return 'post'
}
export const postSatelliteClientBySatelliteIdMonitoringDeviceURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/satelliteClient/{satelliteId}/monitoring/device'
  path = path.replace('{satelliteId}', `${parameters['satelliteId']}`)
  path = path.replace('{peripheralId}', `${parameters['peripheralId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Will add a status for a peripheral to the monitoring module of your device
 * request: postSatelliteClientBySatelliteIdMonitoringPeripheralsByPeripheralId
 * url: postSatelliteClientBySatelliteIdMonitoringPeripheralsByPeripheralIdURL
 * method: postSatelliteClientBySatelliteIdMonitoringPeripheralsByPeripheralId_TYPE
 * raw_url: postSatelliteClientBySatelliteIdMonitoringPeripheralsByPeripheralId_RAW_URL
 * @param satelliteId - satellite id
 * @param peripheralId - peripheral id
 * @param addPeripheralStatus - peripheralStatusPayload
 */
export const postSatelliteClientBySatelliteIdMonitoringPeripheralsByPeripheralId = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/satelliteClient/{satelliteId}/monitoring/peripherals/{peripheralId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{satelliteId}', `${parameters['satelliteId']}`)
  if (parameters['satelliteId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: satelliteId'))
  }
  path = path.replace('{peripheralId}', `${parameters['peripheralId']}`)
  if (parameters['peripheralId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: peripheralId'))
  }
  if (parameters['addPeripheralStatus'] !== undefined) {
    body = parameters['addPeripheralStatus']
  }
  if (parameters['addPeripheralStatus'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: addPeripheralStatus'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const postSatelliteClientBySatelliteIdMonitoringPeripheralsByPeripheralId_RAW_URL = function() {
  return '/satelliteClient/{satelliteId}/monitoring/peripherals/{peripheralId}'
}
export const postSatelliteClientBySatelliteIdMonitoringPeripheralsByPeripheralId_TYPE = function() {
  return 'post'
}
export const postSatelliteClientBySatelliteIdMonitoringPeripheralsByPeripheralIdURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/satelliteClient/{satelliteId}/monitoring/peripherals/{peripheralId}'
  path = path.replace('{satelliteId}', `${parameters['satelliteId']}`)
  path = path.replace('{peripheralId}', `${parameters['peripheralId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Gets you a list with desired subscriptions
 * request: getSubscription
 * url: getSubscriptionURL
 * method: getSubscription_TYPE
 * raw_url: getSubscription_RAW_URL
 * @param distributorId - distributor id
 */
export const getSubscription = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/subscription'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['distributorId'] !== undefined) {
    queryParameters['distributorId'] = parameters['distributorId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getSubscription_RAW_URL = function() {
  return '/subscription'
}
export const getSubscription_TYPE = function() {
  return 'get'
}
export const getSubscriptionURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/subscription'
  if (parameters['distributorId'] !== undefined) {
    queryParameters['distributorId'] = parameters['distributorId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Adds you a satellite to the desired subscription
 * request: postSubscriptionByIdSatelliteIds
 * url: postSubscriptionByIdSatelliteIdsURL
 * method: postSubscriptionByIdSatelliteIds_TYPE
 * raw_url: postSubscriptionByIdSatelliteIds_RAW_URL
 * @param id - subscription id
 * @param addSatelliteId - satellite id
 */
export const postSubscriptionByIdSatelliteIds = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/subscription/{id}/satelliteIds'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['addSatelliteId'] !== undefined) {
    body = parameters['addSatelliteId']
  }
  if (parameters['addSatelliteId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: addSatelliteId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const postSubscriptionByIdSatelliteIds_RAW_URL = function() {
  return '/subscription/{id}/satelliteIds'
}
export const postSubscriptionByIdSatelliteIds_TYPE = function() {
  return 'post'
}
export const postSubscriptionByIdSatelliteIdsURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/subscription/{id}/satelliteIds'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Removes you a satellite from the desired subscription
 * request: deleteSubscriptionByIdSatelliteIdsBySatelliteId
 * url: deleteSubscriptionByIdSatelliteIdsBySatelliteIdURL
 * method: deleteSubscriptionByIdSatelliteIdsBySatelliteId_TYPE
 * raw_url: deleteSubscriptionByIdSatelliteIdsBySatelliteId_RAW_URL
 * @param id - subscription id
 * @param satelliteId - satellite id
 */
export const deleteSubscriptionByIdSatelliteIdsBySatelliteId = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/subscription/{id}/satelliteIds/{satelliteId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  path = path.replace('{satelliteId}', `${parameters['satelliteId']}`)
  if (parameters['satelliteId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: satelliteId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteSubscriptionByIdSatelliteIdsBySatelliteId_RAW_URL = function() {
  return '/subscription/{id}/satelliteIds/{satelliteId}'
}
export const deleteSubscriptionByIdSatelliteIdsBySatelliteId_TYPE = function() {
  return 'delete'
}
export const deleteSubscriptionByIdSatelliteIdsBySatelliteIdURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/subscription/{id}/satelliteIds/{satelliteId}'
  path = path.replace('{id}', `${parameters['id']}`)
  path = path.replace('{satelliteId}', `${parameters['satelliteId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Set the validity of a subscription (from and until)
 * request: putSubscriptionByIdValid
 * url: putSubscriptionByIdValidURL
 * method: putSubscriptionByIdValid_TYPE
 * raw_url: putSubscriptionByIdValid_RAW_URL
 * @param id - subscription id
 * @param updateSubscriptionValidPayload - updateSubscriptionValidPayload
 */
export const putSubscriptionByIdValid = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/subscription/{id}/valid'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['updateSubscriptionValidPayload'] !== undefined) {
    body = parameters['updateSubscriptionValidPayload']
  }
  if (parameters['updateSubscriptionValidPayload'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: updateSubscriptionValidPayload'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const putSubscriptionByIdValid_RAW_URL = function() {
  return '/subscription/{id}/valid'
}
export const putSubscriptionByIdValid_TYPE = function() {
  return 'put'
}
export const putSubscriptionByIdValidURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/subscription/{id}/valid'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Receive an array with all user models
 * request: getUser
 * url: getUserURL
 * method: getUser_TYPE
 * raw_url: getUser_RAW_URL
 */
export const getUser = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getUser_RAW_URL = function() {
  return '/user'
}
export const getUser_TYPE = function() {
  return 'get'
}
export const getUserURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Add a user with desired attributes
 * request: postUser
 * url: postUserURL
 * method: postUser_TYPE
 * raw_url: postUser_RAW_URL
 * @param user - user object
 */
export const postUser = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['user'] !== undefined) {
    body = parameters['user']
  }
  if (parameters['user'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: user'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const postUser_RAW_URL = function() {
  return '/user'
}
export const postUser_TYPE = function() {
  return 'post'
}
export const postUserURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Receive the whole user model
 * request: getUserById
 * url: getUserByIdURL
 * method: getUserById_TYPE
 * raw_url: getUserById_RAW_URL
 * @param id - user id to receive
 */
export const getUserById = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getUserById_RAW_URL = function() {
  return '/user/{id}'
}
export const getUserById_TYPE = function() {
  return 'get'
}
export const getUserByIdURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Update an existing user by specifying the fields you want to update
 * request: putUserById
 * url: putUserByIdURL
 * method: putUserById_TYPE
 * raw_url: putUserById_RAW_URL
 * @param id - user id to update
 * @param user - user object
 */
export const putUserById = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['user'] !== undefined) {
    body = parameters['user']
  }
  if (parameters['user'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: user'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const putUserById_RAW_URL = function() {
  return '/user/{id}'
}
export const putUserById_TYPE = function() {
  return 'put'
}
export const putUserByIdURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Delete given user by provided id
 * request: deleteUserById
 * url: deleteUserByIdURL
 * method: deleteUserById_TYPE
 * raw_url: deleteUserById_RAW_URL
 * @param id - user id to delete
 */
export const deleteUserById = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteUserById_RAW_URL = function() {
  return '/user/{id}'
}
export const deleteUserById_TYPE = function() {
  return 'delete'
}
export const deleteUserByIdURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Confirm a pending registration
 * request: postUserByIdConfirm
 * url: postUserByIdConfirmURL
 * method: postUserByIdConfirm_TYPE
 * raw_url: postUserByIdConfirm_RAW_URL
 * @param id - user id to confirm
 */
export const postUserByIdConfirm = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/{id}/confirm'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const postUserByIdConfirm_RAW_URL = function() {
  return '/user/{id}/confirm'
}
export const postUserByIdConfirm_TYPE = function() {
  return 'post'
}
export const postUserByIdConfirmURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/{id}/confirm'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Receive an array with all device models
 * request: getUserByIdDevices
 * url: getUserByIdDevicesURL
 * method: getUserByIdDevices_TYPE
 * raw_url: getUserByIdDevices_RAW_URL
 * @param id - user id to receive
 */
export const getUserByIdDevices = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/{id}/devices'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getUserByIdDevices_RAW_URL = function() {
  return '/user/{id}/devices'
}
export const getUserByIdDevices_TYPE = function() {
  return 'get'
}
export const getUserByIdDevicesURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/{id}/devices'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Receive an array with all distributor models
 * request: getUserByIdDistributors
 * url: getUserByIdDistributorsURL
 * method: getUserByIdDistributors_TYPE
 * raw_url: getUserByIdDistributors_RAW_URL
 * @param id - user id to receive
 */
export const getUserByIdDistributors = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/{id}/distributors'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getUserByIdDistributors_RAW_URL = function() {
  return '/user/{id}/distributors'
}
export const getUserByIdDistributors_TYPE = function() {
  return 'get'
}
export const getUserByIdDistributorsURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/{id}/distributors'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Finalize two factor for a user
 * request: postUserByIdOtpFinalize
 * url: postUserByIdOtpFinalizeURL
 * method: postUserByIdOtpFinalize_TYPE
 * raw_url: postUserByIdOtpFinalize_RAW_URL
 * @param id - user id to finalize two factor for
 * @param totp - totp
 */
export const postUserByIdOtpFinalize = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/{id}/otp/finalize'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['totp'] !== undefined) {
    body = parameters['totp']
  }
  if (parameters['totp'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: totp'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const postUserByIdOtpFinalize_RAW_URL = function() {
  return '/user/{id}/otp/finalize'
}
export const postUserByIdOtpFinalize_TYPE = function() {
  return 'post'
}
export const postUserByIdOtpFinalizeURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/{id}/otp/finalize'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Initialize to factor for a user and return recovery-keys as well as the finalize png
 * request: postUserByIdOtpInit
 * url: postUserByIdOtpInitURL
 * method: postUserByIdOtpInit_TYPE
 * raw_url: postUserByIdOtpInit_RAW_URL
 * @param id - user id to initialize two factor for
 */
export const postUserByIdOtpInit = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/{id}/otp/init'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const postUserByIdOtpInit_RAW_URL = function() {
  return '/user/{id}/otp/init'
}
export const postUserByIdOtpInit_TYPE = function() {
  return 'post'
}
export const postUserByIdOtpInitURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/{id}/otp/init'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * Update an existing user's role
 * request: putUserByIdRoleId
 * url: putUserByIdRoleIdURL
 * method: putUserByIdRoleId_TYPE
 * raw_url: putUserByIdRoleId_RAW_URL
 * @param id - user id to update
 * @param user - user roleId object
 */
export const putUserByIdRoleId = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/{id}/roleId'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['user'] !== undefined) {
    body = parameters['user']
  }
  if (parameters['user'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: user'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const putUserByIdRoleId_RAW_URL = function() {
  return '/user/{id}/roleId'
}
export const putUserByIdRoleId_TYPE = function() {
  return 'put'
}
export const putUserByIdRoleIdURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/{id}/roleId'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}