<template>
    <GenericCreate
        :action="create" :action-custom-errors="[{code: 400, message: $t('invalidBackupFile')}]"

        :action-error="error" :action-label="$t('upload')"

        :action-loading="loading"
        :action-success="created"

        :description="$t('uploadFileMessage', {name: $t('backupFile'), types: this.getFormattedTypesString(this.backupFileExtensions), mb: this.maxBackupFileSizeInMB})"
        :icon-tooltip="$t('uploadFile', {name: $t('backupFile')})" :modal-id="modalId"
        :success-description="$t('uploadFileSuccess', {name: $t('backupFile'), fileName: itemName})"
        :success-title="$t('fileUploaded', {name: $t('backupFile')})"

        :title="$t('uploadFile', {name: $t('backupFile')})" :trigger="trigger"
        icon="cloud-upload"
    >

        <LabelWithError :error="$t(fileError)" :label="$t('backupFile')"/>

        <div v-if="!file" id="backup-upload"
             :class="'js-upload uk-placeholder uk-flex uk-flex-middle uk-flex-center uk-text-center uk-margin-remove-top'+ (fileError ? ' error' : '')">

            <Icon class="uk-margin-small-right" icon="cloud-upload"/>
            <span class="uk-text-middle">{{ $t('dropOrSelectFile', {name: $t('backupFile')}) }}</span>
            <div uk-form-custom>
                <input id="backup-file" ref="file" :accept="'.'+this.backupFileExtensions.join(', .')" type="file"
                       v-on:change="handleFileUpload()">
                <span class="uk-link">{{ $t('searchAction') }}</span>
            </div>
        </div>

        <div v-else
             :class="'js-upload uk-placeholder uk-flex uk-flex-middle uk-flex-center uk-text-center uk-margin-remove-top'+ (fileError ? ' error' : '')">

            <div class="uk-width-1-1 uk-animation-fade">
                <div class="close-icon">
                    <span v-on:click="resetFile()">
                        <Icon :ratio="1.0" class="cursor-pointer" icon="close"/>
                    </span>
                </div>
                <Icon :ratio="1.2" icon="file-text"/>
                <div class="file-name">
                    {{ file.name }}
                </div>
                <div class="file-size">
                    ({{ ($options.filters.prettyBytes(file.size / 1024 * 1000)).replace(".", ",") }})
                </div>
            </div>
        </div>
        <progress :value="uploadPercentage" class="uk-progress" max="100"/>

        <div class="uk-margin-bottom"/>
        <span v-for="(inputField, key) in textFields" :key="inputField.name">
            <TextField v-model="inputField.value" :autocomplete="inputField.autocomplete"
                       :autofocus="inputField.autofocus" :field-key="key"
                       :hasPasswordMeter="inputField.hasPasswordMeter"
                       :icon="inputField.icon"
                       :label="inputField.label"
                       :name="inputField.name"
                       :onEnter="create"
                       :rules="inputField.rules"
                       :type="inputField.type"
                       :validate="trigger"
                       @setValid="setValid"
            />
            <div v-if="key !== textFields.length -1" class="uk-margin-bottom"/>
        </span>

    </GenericCreate>
</template>

<script>

import {postDeviceByIdConfigbackupsURL} from "@/client/vue-api-client";
import TextField from "../generic/TextField";
import UIkit from 'uikit'
import axios from 'axios'
import Icon from "../generic/Icon";
import GenericCreate from "@/components/creates/GenericCreate";
import LabelWithError from "@/components/generic/LabelWithError";


export default {
    name: 'CreateBackup',
    components: {
        LabelWithError,
        GenericCreate,
        Icon,
        TextField,
    },
    props: {
        deviceId: String,
    },
    mounted() {
        let self = this;
        let el = document.getElementById('backup-upload');

        window.addEventListener('dragenter', function (e) {
            e.stopPropagation();
            e.preventDefault();

            let el = document.getElementById('backup-upload');
            el.classList.add('dragover');
        });

        el.addEventListener("drop", function (e) {
            e.stopPropagation();
            e.preventDefault();

            self.handleFileUpload(e.dataTransfer.files)
        }, false);
    },
    data() {
        return {
            modalId: "modal-create-backup-" + this.deviceId,
            trigger: 0,

            textFields: [
                {
                    value: "",
                    valid: false,

                    autofocus: false,
                    name: "description",
                    label: "description",
                    rules: "required",
                    icon: "info",
                },
            ],

            created: false,
            loading: false,
            error: null,
            itemName: "",

            file: null,
            fileError: null,

            uploadPercentage: 0,
        }
    },
    methods: {
        resetFile() {
            this.file = null;
            this.fileError = this.$t("requiredError", {name: this.$t('backupFile')});
        },
        handleFileUpload(files) {

            if (files && files[0]) {
                this.file = files[0];

            } else {
                this.file = this.$refs.file.files[0];

            }

            let fileExtension = this.file.name.split('.').pop();

            if (!this.backupFileExtensions.includes(fileExtension)) {
                this.fileError = this.$t("fileTypeError", {
                    name: this.$t('backupFile'),
                    types: this.getFormattedTypesString(this.backupFileExtensions)
                });
                return;
            }

            if (this.file.size > 1048576 * this.maxBackupFileSizeInMB) {
                this.fileError = this.$t("fileSizeError", {name: this.$t('backupFile')});
                return;
            }

            let fileName = this.file.name;
            fileName = fileName.replace('.' + fileExtension, '');

            this.textFields[0].value = fileName;
            this.textFields[0].valid = true;

            this.fileError = null;

            let el = document.getElementById('backup-upload');
            el.classList.remove('dragover')

            let self = this;
            setTimeout(function () {
                self.trigger++;
            }, 50);

        },
        setValid(valid, fieldKey) {
            this.textFields[fieldKey].valid = valid
        },
        create() {
            this.trigger++;

            if (!this.file) {
                this.fileError = "backupFileRequiredError";
                return;
            }

            for (let i = 0, j = this.textFields.length; i < j; i++) {
                if (!this.textFields[i].valid) {
                    return;
                }
            }

            let self = this;
            self.loading = true;

            self.itemName = self.textFields[0].value + ".cfg";

            let formData = new FormData();
            formData.append("configBackup", this.file);
            formData.append("additionalInformation", self.textFields[0].value + ".cfg");

            let url = postDeviceByIdConfigbackupsURL({
                id: self.deviceId
            })

            const config = {
                onUploadProgress: function (progressEvent) {
                    self.uploadPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                }
            }

            let http = axios.create();
            http.post(url, formData, config).then(() => {
                for (let i = 0, j = self.textFields.length; i < j; i++) {
                    self.textFields[i].value = "";
                    self.textFields[i].valid = false;
                }

                self.file = null;
                self.uploadPercentage = 0;

                self.created = true;
                self.loading = false;

                UIkit.modal("#" + self.modalId + "-success").show();

                self.$emit('afterSuccess');

            }).catch((e) => {
                self.checkGenericError(e);

                self.error = e;
                self.loading = false;
                self.created = false;

            });

        },

    }
}

</script>

<style lang="scss" scoped>


.file-size {
    font-size: 15px;
}

.dragover {
    border-color: $theme-background;
}


.error {
    border-color: $danger-color;
    background: rgba(240, 80, 80, .15);
}

.close-icon {
    text-align: right;
    margin-top: -20px;
    margin-right: -20px;
}

.js-upload {
    border-radius: $border-radius;
    min-height: 56px;
}

.uk-form-custom {
    left: 5px !important;
    cursor: pointer !important;
}

.file-name {
    margin-top: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

</style>
