<template>
    <div>
        <GenericItem
            :actions="getActions()"
            :labels="getLabels()">

            <template v-slot:description>

                <div uk-grid>
                    <div class="name uk-width-auto">
                        {{ document.author }}
                    </div>
                    <div class="date uk-width-expand uk-flex uk-flex-middle uk-flex-right">
                        <IconText :uk-tooltip="'title:'+getFormattedDateByDateString(document.createdAt)"
                                  icon="history">{{ getDaysAgoMessage(getDaysAgoByDateString(document.createdAt)) }}
                        </IconText>
                    </div>
                </div>
                <hr class="divider"/>
                <div class="message">
                    {{ document.message }}
                </div>

            </template>

        </GenericItem>
    </div>
</template>


<script>

import GenericItem from "@/components/items/GenericItem";
import IconText from "@/components/generic/IconText";

export default {
    name: 'DocumentItem',
    components: {IconText, GenericItem},
    props: {
        document: {
            id: String,
            createdAt: String,
            author: String,
            message: String,
        },
    },
    methods: {
        getActions() {
            let actions = [];
            return actions;
        },
        getLabels() {
            let labels = [];
            return labels;
        },
    }
}

</script>


<style lang="scss" scoped>

.name {
    font-size: 20px;
}

.divider {
    margin-top: 10px;
}

</style>
