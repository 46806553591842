<template>
    <div :class="answered ? 'uk-animation-reverse uk-animation-fade uk-hidden' : (show ? '' : 'uk-hidden')">

        <div class="cookie-struct uk-padding">

            <div class="title uk-text-center">{{ $t('cookies') }}</div>
            <div class="description uk-margin-bottom">
                {{ $t('cookiesMessage') }}
            </div>

            <div class="uk-grid-small uk-flex uk-flex-center uk-flex-middle uk-grid-match uk-child-width-expand"
                 uk-grid>
                <div>
                    <Button :action="deny" :text="$t('deny')" has-action is-max mode="default"/>
                </div>
                <div>
                    <Button :action="accept" :text="$t('accept')" has-action is-max mode="primary"/>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

import Button from "@/components/generic/Button";

export default {
    name: 'CookieControl',
    components: {Button},
    data() {
        return {
            show: false,
            answered: false,
            cookieKey: 'cookieconsent',
        }
    },
    created() {
        let optOut = this.$cookie.get(this.cookieKey);
        if (optOut) {
            this.show = false;
            this.$store.state.cookieconsent = optOut;
        } else {
            this.show = true;
        }
    },
    methods: {
        accept() {
            this.answered = true;
            this.$store.state.cookieconsent = "accepted";
            this.$cookie.set(this.cookieKey, "accepted", {expires: this.cookieMaxExpiry});
        },
        deny() {
            this.answered = true;
            this.$store.state.cookieconsent = "denied";

            let allCookies = document.cookie.split(';');
            for (let i = 0, j = allCookies.length; i < j; i++) {
                this.$cookie.delete(allCookies[i]);
            }

            this.$cookie.set(this.cookieKey, "denied", {expires: this.cookieMaxExpiry});
        }
    }
}

</script>

<style lang="scss" scoped>

.title {
    font-size: 20px;
    font-weight: 500;
    color: $theme-color-hover;
    margin-bottom: 10px;
}

.cookie-struct {
    position: fixed;
    background-color: rgba(15, 59, 81, 0.72);
    backdrop-filter: saturate(180%) blur(20px);
    bottom: 24px;
    right: 24px;
    max-width: 316px;
    z-index: 1000;
    border-radius: $border-radius;
}

@media (max-width: 555px) {
    .cookie-struct {
        bottom: 20px;
        right: 20px;
    }
}

@media (max-width: 416px) {
    .cookie-struct {
        bottom: 20px;
        right: 20px;
        left: 20px
    }
}

.description {
    color: $theme-color-hover
}

</style>
