<template>
    <GenericItem
        :actions="getActions()" :additionals="getAdditionals()"
        :icon-tooltip="$t('satellite')"

        :labels="getLabels()"
        :name="item.ean"

        icon="rss">

        <UpdateSatelliteDistributor v-if="isAdminView" :distributors="distributors" :item="item" :modal-id="modalId"
                                    @afterSuccess="softUpdate"/>
        <UpdateSatelliteDevice v-else :devices="devices" :item="item" :modal-id="modalId" @afterSuccess="softUpdate"/>


        <Modal :id="modalIdSubscriptions" :title="$t('subscriptions.of.satellite') + ' '+item.ean">
            <div class="uk-child-width-auto uk-grid-match uk-margin-remove-top uk-grid-medium"
                 uk-grid>

                <div v-for="subscription in computedSubscriptionsLinked" :key="subscription.id">
                    <div v-on:click="$copyText(subscription.id)">
                        <InfoBox :uk-tooltip="'title:'+$t('subscription.copy.id')" class="cursor-pointer"
                                 icon="credit-card"
                                 mode="success"
                        >
                            <div>{{ $t(subscription.planId) }}</div>
                        </InfoBox>
                    </div>
                </div>

                <div v-if="computedSubscriptionsLinked.length === 0">
                    <InfoBox icon="info" mode="default">
                        {{ $t('satellite.nosubscriptions') }}
                    </InfoBox>
                </div>

            </div>
        </Modal>

    </GenericItem>
</template>


<script>

import GenericItem from "@/components/items/GenericItem";
import UIkit from "uikit";
import UpdateSatelliteDistributor from "@/components/updates/UpdateSatelliteDistributor";
import UpdateSatelliteDevice from "@/components/updates/UpdateSatelliteDevice";
import {deleteSatelliteById, putSatelliteById} from "@/client/vue-api-client";
import Modal from "@/components/generic/Modal";
import InfoBox from "@/components/generic/InfoBox";
import dayjs from "dayjs";

export default {
    name: 'SatelliteItem',
    components: {InfoBox, Modal, UpdateSatelliteDevice, UpdateSatelliteDistributor, GenericItem},
    props: {
        isAdminView: Boolean,

        distributors: Array,
        devices: Array,

        item: {
            apiToken: String,
            createdAt: String,
            description: String,
            details: {
                Subscriptions: {
                    monitoring: String,
                },
                satellite: Object
            },
            deviceId: String,
            distributorId: String,
            ean: String,
            emergency: {
                expiresAt: String,
                token: String,
            },
            expiry: String,
            hardwareGenId: Number,
            id: String,
            status: String,
        },
    },
    data() {
        return {
            modalId: "modal-update-satellite-" + this.item.id,
            modalIdSubscriptions: "modal-subscriptions-satellite-" + this.item.id,

            fromDistributor: true,
            distributor: {
                id: "",
                name: "",
                ownerId: "",
                deviceIds: [],
                createdAt: "",
                confirmedAt: "",
                brandingIds: [],
            },

            device: {
                device: {
                    id: "",
                    donglEsn: "",
                    kindId: "",
                    description: "",
                    satellite: {
                        id: "",
                        ipAddress: ""
                    },
                    configBackups: [],
                    voicemails: [],
                    createdAt: ""
                },
                users: [{name: "", mailAddress: ""}],
                distributor: {name: ""}
            },
        }
    },
    created() {
        if (this.isAdminView && this.item.distributorId && this.distributors) {

            for (let i = 0, j = this.distributors.length; i < j; i++) {
                if (this.item.distributorId === this.distributors[i].id) {
                    this.distributor = this.distributors[i];
                    break;
                }
            }

        } else if (this.item.deviceId && this.devices) {
            for (let i = 0, j = this.devices.length; i < j; i++) {
                if (this.item.deviceId === this.devices[i].id) {
                    this.device = this.devices[i];
                    break;
                }
            }

        }
    },
    computed: {
        computedSubscriptionsLinked() {
            let parsedSubscriptions = [];

            if (!this.item.details || !this.item.details.Subscriptions) {
                return parsedSubscriptions;
            }

            for (const [key, value] of Object.entries(this.item.details.Subscriptions)) {
                if (value !== '') {
                    parsedSubscriptions.push({
                        id: value,
                        planId: key,
                    })
                }
            }

            return parsedSubscriptions;
        },
    },
    methods: {
        softUpdate(item) {
            this.$emit('afterSuccess', item);

            if (this.isAdminView && item.distributorId && this.distributors) {

                for (let i = 0, j = this.distributors.length; i < j; i++) {
                    if (this.item.distributorId === this.distributors[i].id) {
                        this.distributor = this.distributors[i];
                        break;
                    }
                }

            } else if (item.deviceId && this.devices) {
                for (let i = 0, j = this.devices.length; i < j; i++) {
                    if (this.item.deviceId === this.devices[i].id) {
                        this.device = this.devices[i];
                        break;
                    }
                }

            }

        },
        openDetails() {
            UIkit.modal("#" + this.modalIdSubscriptions).show();
            event.preventDefault();
        },
        openUpdate() {
            UIkit.modal("#" + this.modalId).show();
            event.preventDefault();
        },
        detach() {
            let self = this;

            putSatelliteById({
                id: self.item.id,
                satellite: {
                    ean: self.item.ean,
                    description: self.item.description,
                    distributorId: "",
                    deviceId: "",
                },

            }).then(function () {
                self.softUpdate({
                    id: self.item.id,
                    hardwareGenId: self.item.hardwareGenId,
                    ean: self.item.ean,
                    description: self.item.description,
                    distributorId: "",
                    deviceId: "",
                    apiToken: self.item.apiToken,

                    emergency: {
                        token: self.item.emergency.token,
                        expiresAt: self.item.emergency.expiresAt,
                    },

                    status: self.item.status,
                    expiry: self.item.expiry,
                });

            }).catch(function (e) {
                self.checkGenericError(e);

            })
        },
        delete() {
            let self = this;

            deleteSatelliteById({
                id: self.item.id,

            }).then(function () {
                self.$emit('setDeleted', self.item.id);

            }).catch(function (e) {
                self.checkGenericError(e);

            })
        },
        getActions() {
            let actions = [];

            if (this.isAdminView) {

                if (this.item.distributorId || this.item.deviceId) {
                    actions.push({
                        action: this.detach,
                        icon: 'code',
                        text: this.$t('detach'),
                        mode: 'danger',

                        button: this.$t('detachSatellite'),
                        confirmTitle: this.$t('reallyDetach'),
                        confirmMessage: this.$t('reallyDetachSatellite', {
                            name: this.item.ean,
                            distributor: this.distributor.name
                        })
                    });

                } else {
                    actions.push({
                        action: this.delete,
                        icon: 'trash',
                        text: this.$t('delete'),
                        mode: 'danger',

                        button: this.$t('deleteSatellite'),
                        confirmTitle: this.$t('reallyDelete'),
                        confirmMessage: this.$t('reallyDeleteSatellite', {name: this.item.ean})
                    });

                }

                if (this.item.distributorId) {
                    actions.push({
                        action: this.openUpdate,
                        icon: 'cog',
                        text: this.$t('change'),
                        mode: 'default',
                    });

                    actions.push({
                        route: 'organisations/' + this.item.distributorId,
                        icon: 'social',
                        text: this.$t('distributor'),
                        mode: "default",
                        noAction: true,
                    });

                } else {
                    actions.push({
                        action: this.openUpdate,
                        icon: 'link',
                        text: this.$t('assign'),
                        mode: 'success',
                    });

                }

            } else {
                if (this.item.deviceId) {
                    actions.push({
                        action: this.openUpdate,
                        icon: 'cog',
                        text: this.$t('change'),
                        mode: 'default',
                    });

                    actions.push({
                        action: this.openDetails,
                        icon: 'credit-card',
                        text: this.$t('subscriptions'),
                        mode: 'default',
                    });

                    actions.push({
                        icon: 'server',
                        text: this.$t('device'),
                        mode: "default",
                        route: (this.fromDistributor ? 'organisations/' + this.item.distributorId + '/' : '') + 'devices/' + this.item.deviceId,
                        noAction: true,
                    });


                } else {
                    actions.push({
                        action: this.openUpdate,
                        icon: 'link',
                        text: this.$t('assign'),
                        mode: 'success',
                    });

                    actions.push({
                        action: this.openDetails,
                        icon: 'credit-card',
                        text: this.$t('subscriptions'),
                        mode: 'default',
                    });

                }

            }

            if (this.item.apiToken.startsWith('ey')) {
                actions.push({
                    action: this.downloadConfigFiles,
                    icon: 'cloud-download',
                    text: this.$t('configFiles'),
                    mode: 'primary',
                });
            }


            return actions;
        },
        downloadConfigFiles() {
            this.downloadSatelliteConfigFiles(this.item);
        },
        getAdditionals() {
            let additionals = [];

            if (this.isAdminView) {

                if (this.item.description) {
                    additionals.push({icon: 'tag', text: this.item.description, tooltip: this.$t('description')})
                } else {
                    additionals.push({icon: 'tag', text: this.$t('withoutDescription')})
                }

                if (this.item.distributorId) {
                    additionals.push({icon: 'social', text: this.distributor.name, copy: true});
                } else {
                    additionals.push({icon: 'social', text: this.$t("withoutDistributor")});
                }

                return additionals;
            }

            if (this.item.deviceId) {
                return [{
                    icon: 'hashtag',
                    text: this.device.kindId
                }];
            } else {
                return [{icon: 'server', text: this.$t("withoutDevice")}];
            }

        },
        getLabels() {
            let labels = [];

            if (this.isAdminView) {
                if (this.item.status === 'assigned') {
                    labels.push({
                        type: "success",
                        icon: 'link',
                        text: this.$t('assigned'),
                        tooltip: this.$t('withDistributorMessage', {name: this.distributor.name}),
                    });

                } else {
                    labels.push({
                        type: "warning",
                        icon: 'close',
                        text: this.$t('unassigned'),
                        tooltip: this.$t('withoutDistributorMessage'),
                    });

                }

            } else {
                if (this.item.status === 'assigned') {
                    labels.push({
                        type: "success",
                        icon: 'link',
                        text: this.$t('assigned'),
                        tooltip: this.$t('withDeviceMessage', {
                            name: this.device.description,
                            type: this.device.kindId
                        }),
                    });

                } else {
                    labels.push({
                        type: "warning",
                        icon: 'close',
                        text: this.$t('unassigned'),
                        tooltip: this.$t('withoutDeviceMessage'),
                    });

                }

            }


            let then = dayjs(this.item.emergency.expiresAt);
            let fromNow = then.fromNow(true)
            fromNow = this.removeFromNowSuffix(fromNow);

            if (this.item.expiry === "expired") {
                labels.push({
                    type: "danger",
                    icon: 'history',
                    tooltip: this.$t('on') + ' ' + this.getFormattedDateByDateString(then),
                    text: fromNow + " " + this.$t('expired'),
                });

            } else if (this.item.expiry === "expiring") {
                labels.push({
                    type: "warning",
                    icon: 'future',
                    tooltip: this.$t('to') + ' ' + this.getFormattedDateByDateString(then),
                    text: fromNow + " " + this.$t('expires'),
                });

            } else {
                labels.push({
                    type: "default",
                    icon: 'future',
                    tooltip: this.$t('to') + ' ' + this.getFormattedDateByDateString(then),
                    text: fromNow + " " + this.$t('valid'),
                });

            }

            return labels;
        },
    }
}

</script>


<style lang="scss" scoped>

</style>
