<template>
    <GenericCreate
        :action="update" :action-error="error" :action-label="$t('assign')"

        :action-loading="loading"

        :modal-id="modalId"
        :success-description="$t(hasDevice ? 'satellite.changed.message': 'satellite.assigned.device.message', {name: itemNameSatellite, device: itemNameDevice})"
        :success-title="$t(hasDevice ? 'satellite.changed': 'satellite.assigned')"
        :title="$t(hasDevice ? 'satellite.change': 'satellite.assign')"

        :trigger="trigger"
        is-button-hidden

        modal-is-medium
        @afterClose="afterClose"
    >

        <CardSelect v-model="textFields[0].value" :field-key="0" :label="textFields[0].label"
                    :label-name="textFields[0].name"
                    :no-items-message="$t(devices.length>0 ?'noDeviceWithoutSatellite' : 'noDevicesCreatedMessage')"
                    :optional="textFields[0].optional"
                    :options="getDeviceList()" :rules="textFields[0].rules" :validate="trigger" is-searchable
                    label-plural="devices"
                    @setValid="setCardValid"/>
        <div class="uk-margin-bottom"/>

        <span v-for="(inputField, key) in textFields" :key="inputField.name">
            <div v-if="key>0">
                <TextField v-model="inputField.value" :autocomplete="inputField.autocomplete"
                           :autofocus="inputField.autofocus" :field-key="key"
                           :hasPasswordMeter="inputField.hasPasswordMeter"
                           :icon="inputField.icon"
                           :is-optional="inputField.optional"
                           :label="inputField.label"
                           :name="inputField.name"
                           :onEnter="update"
                           :rules="inputField.rules"
                           :type="inputField.type"
                           :validate="trigger"
                           @setValid="setValid"
                />
            </div>
            <div v-if="key !== textFields.length -1" class="uk-margin-bottom"/>
        </span>

    </GenericCreate>
</template>

<script>

import {getDeviceById, putSatelliteByIdDeviceId} from "@/client/vue-api-client";
import TextField from "../generic/TextField";
import GenericCreate from "@/components/creates/GenericCreate";
import UIkit from "uikit";
import CardSelect from "@/components/CardSelect";
import Vue from "vue";

export default {
    name: 'UpdateSatelliteDevice',
    components: {
        CardSelect,
        GenericCreate,
        TextField,
    },
    props: {
        item: {
            id: String,
            ean: String,
            description: String,
            hardwareGenId: Number,
            distributorId: String,
            deviceId: String,
            apiToken: String,

            emergency: {
                token: String,
                expiresAt: String,
            },

            status: String,
            expiry: String,
        },

        modalId: String,
        devices: Array,
    },
    data() {
        return {
            trigger: 0,

            textFields: [
                {
                    value: this.item.deviceId,
                    valid: !!this.item.deviceId,

                    autofocus: false,
                    name: "device",
                    label: "device.withoutsatellite",
                    icon: "server",
                    rules: "required",
                },
            ],

            loading: false,
            error: null,
            itemNameSatellite: "",
            itemNameDevice: "",

            hasDevice: false,
        }
    },
    created() {
        if (this.item.deviceId) {
            this.hasDevice = true;
        }

        for (let i = 0, j = this.devices.length; i < j; i++) {
            this.getDevice(i);
        }
    },
    methods: {
        getDevice(key) {
            let self = this;

            let item = this.devices[key];

            getDeviceById({
                id: item.id,

            }).then(function (response) {
                let details = response.data;
                Vue.set(self.devices[key], 'details', details)

            }).catch(function (e) {
                self.checkGenericError(e);

            })
        },
        afterClose() {
            this.hasDevice = true;
        },
        setCardValid(valid) {
            this.textFields[0].valid = valid;
        },
        setValid(valid, fieldKey) {
            this.textFields[fieldKey].valid = valid
        },
        getDeviceList() {
            let items = [];

            for (let i = 0, j = this.devices.length; i < j; i++) {

                if (this.devices[i].details && this.devices[i].details.satelliteId && this.devices[i].details.satelliteId !== this.item.id) {
                    continue;
                }

                items.push({
                    name: this.devices[i].description + " (" + this.devices[i].kindId + ")",
                    value: this.devices[i].id,
                    nameIcon: "server",
                });
            }

            return items;
        },
        getDeviceDescription(id) {
            for (let i = 0, j = this.devices.length; i < j; i++) {
                if (this.devices[i].id === id) {
                    return this.devices[i].description;
                }
            }

            return null;
        },
        update() {
            this.trigger++;

            for (let i = 0, j = this.textFields.length; i < j; i++) {
                if (!this.textFields[i].valid) {
                    return;
                }
            }

            let self = this;
            self.loading = true;

            putSatelliteByIdDeviceId({
                id: self.item.id,
                satelliteDeviceId: {
                    id: self.textFields[0].value,
                },

            }).then(function () {
                self.itemNameSatellite = self.item.ean;
                self.itemNameDevice = self.getDeviceDescription(self.textFields[0].value);

                self.loading = false;

                UIkit.modal("#" + self.modalId + "-success").show();
                self.$emit('afterSuccess', {
                    id: self.item.id,
                    hardwareGenId: self.item.hardwareGenId,
                    distributorId: self.item.distributorId,
                    ean: self.item.ean,
                    description: self.item.description,
                    deviceId: self.textFields[0].value,
                    apiToken: self.item.apiToken,

                    emergency: {
                        token: self.item.emergency.token,
                        expiresAt: self.item.emergency.expiresAt,
                    },

                    status: self.item.status,
                    expiry: self.item.expiry,
                });

            }).catch(function (e) {
                self.checkGenericError(e);

                self.error = e;
                self.loading = false;

            })
        },
    }
}

</script>

<style lang="scss" scoped>

</style>
