<template>
    <Card :class="isBlank ? '' : 'uk-card-hover uk-height-1-1'" :is-blank="isBlank" mode="default">

        <div v-if="iconTooltip" class="type uk-text-center">
            <IconText :icon="icon" :ratio="1.0"/>
            <span class="type">{{ iconTooltip }}</span>
        </div>

        <slot name="description"/>

        <div class="uk-text-center">
            <div class="name">
                <span :uk-tooltip="'title:'+`'`+name+ `'`+' '+$t('copyToClipboard')"
                      class="cursor-pointer" v-on:click="copyToClipboard(true, name)">
                    <slot name="name"/>
                    {{ name }}
                </span>
            </div>
            <div v-if="additionals && additionals.length > 0" class="additional-struct">
                <div v-for="(additional, key) in additionals" :key="additional.icon" class="additional">

                    <span v-on:click="copyToClipboard(additional.copy, additional.text)">
                        <IconText :class="additional.copy ? 'cursor-pointer' : ''"
                                  :icon="additional.icon"
                                  :uk-tooltip="'title:'+ (additional.tooltip ? additional.tooltip : (additional.copy ?  `'` + additional.text + `'`+ ' ' +$t('copyToClipboard') : ''))"
                        >{{ additional.text }}
                        </IconText>
                    </span>

                    <div v-if="key !== additionals.length -1" class="additional-distance"/>
                </div>
            </div>
        </div>


        <div v-if="labels && labels.length>0">
            <div class="label-struct uk-flex-center uk-grid-small uk-child-width-1-1 uk-text-center" uk-grid>
                <div v-for="(label, key) in labels" :key="key">
                    <Label :icon="label.icon"
                           :text="label.text"
                           :tooltip="label.tooltip"
                           :type="label.type"
                    />
                </div>
            </div>
        </div>

        <slot v-if="$slots.label" name="label"/>

        <div class="last-struct"/>

        <template v-slot:footer>
            <div v-if="actions.length>0" class="uk-flex-center" uk-grid>
                <div v-for="action in actions" :key="action.description">

                    <div v-if="action.confirmMessage">

                        <ActionButton :icon="action.icon"
                                      :isDisabled="action.isDisabled"
                                      :loading="action.loading"
                                      :mode="action.mode"
                                      :noAction="action.noAction"
                                      :route="action.route"
                                      :text="action.text"
                                      :tooltip="action.tooltip"
                                      :uk-toggle="'target: #confirm-'+modalId"
                                      has-toggle
                        />

                        <Modal :id="'confirm-'+modalId" :title="action.confirmTitle" is-centered>
                            <Description :text="action.confirmMessage" class="uk-text-center"/>

                            <div class="uk-grid-small uk-child-width-1-2@s uk-child-width-1-1" uk-grid>
                                <div class="uk-flex uk-flex-center">
                                    <Button :text="$t('cancel')" class="uk-modal-close" mode="default"/>
                                </div>
                                <div class="uk-flex uk-flex-center">
                                    <Button :action="action.action" :text="action.button" class="uk-modal-close"
                                            has-action
                                            mode="danger"/>
                                </div>
                            </div>
                        </Modal>
                    </div>

                    <div v-else>

                        <span v-if="action.route">
                            <LinkObject :to="action.route">
                                <ActionButton
                                    :action="action.action"
                                    :icon="action.icon"
                                    :isDisabled="action.isDisabled"
                                    :loading="action.loading"
                                    :mode="action.mode"
                                    :noAction="action.noAction"
                                    :route="action.route"
                                    :text="action.text"
                                    :tooltip="action.tooltip"
                                />
                            </LinkObject>
                        </span>
                        <ActionButton v-else
                                      :action="action.action"
                                      :icon="action.icon"
                                      :isDisabled="action.isDisabled"
                                      :loading="action.loading"
                                      :mode="action.mode"
                                      :noAction="action.noAction"
                                      :route="action.route"
                                      :text="action.text"
                                      :tooltip="action.tooltip"
                        />

                    </div>

                </div>
            </div>

            <slot name="action"/>

            <slot/>

        </template>

    </Card>
</template>


<script>

import Card from "../generic/Card";
import Button from "../generic/Button";
import Modal from "../generic/Modal";
import Description from "../Description";
import IconText from "../generic/IconText";
import Label from "@/components/Label";
import ActionButton from "@/components/generic/ActionButton";
import LinkObject from "@/components/LinkObject";

export default {
    name: 'GenericItem',
    components: {LinkObject, ActionButton, Label, IconText, Description, Modal, Button, Card},
    props: {
        icon: String,
        iconTooltip: String,
        actions: Array,

        name: String,
        additionals: Array,

        labels: Array,

        isBlank: Boolean,
    },
    data() {
        return {
            modalId: "modal-" + (+new Date()),
            reallyDelete: false,
        }
    },
    methods: {
        copyToClipboard(enabled, text) {
            if (!enabled) {
                return;
            }

            this.$copyText(text)
        },
    }
}

</script>


<style lang="scss" scoped>

.name {
    margin-top: 8px;
    font-size: 20px;
    font-weight: 500;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.additional {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 3px;
}

.delete-struct {
    margin-top: 15px;
    margin-bottom: 5px;
    color: $site-color;
    font-size: 15px;
}

.additional-struct {
    margin-top: 5px;
}

.additional-distance {
    margin-top: 2px;
}

.type {
    margin-bottom: 15px;
}

.label-struct {
    margin-top: 15px;
}

.last-struct {
    margin-bottom: -15px !important;
}

</style>
