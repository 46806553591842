import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueI18n from 'vue-i18n'
import messages from './translations'
import 'uikit/dist/css/uikit.min.css'
import UIkit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'
import UKExtendedIcons from 'uikit-icons-extended/dist/js/uk-extended.min.js';
import {setDomain} from '@/client/vue-api-client'
import vueSeo from '@deveodk/vue-seo'
import VeeValidate, {Validator} from 'vee-validate'
import de from 'vee-validate/dist/locale/de'
import en from 'vee-validate/dist/locale/en'
import HighchartsVue from 'highcharts-vue'
import VueEllipseProgress from 'vue-ellipse-progress';
import VCalendar from 'v-calendar';
import "fontsource-barlow/latin-ext.css"

import VueCookie from 'vue-cookie'
import vueAwesomeCountdown from 'vue-awesome-countdown'
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import updateLocale from 'dayjs/plugin/updateLocale';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import isBetween from 'dayjs/plugin/isBetween';
import UUID from "vue-uuid";
import vueFilterPrettyBytes from 'vue-filter-pretty-bytes'
import VueSkeletonLoader from 'skeleton-loader-vue';
import VueClipboard from 'vue-clipboard2'

let baseUrl = 'https://portal.a365.systems';
let apiUrl = 'https://portal.a365.systems/rest/v1';

if (origin.includes("dev")) {
    baseUrl = 'https://portal.dev.a365.systems';
    apiUrl = 'https://portal.dev.a365.systems/rest/v1';
}

//Vue.prototype.languages = ['de', 'en', 'fr'];
Vue.prototype.languages = ['de'];
Vue.prototype.apiTimeout = 20000;
Vue.prototype.baseUrl = process.env.VUE_APP_BASE_URL || baseUrl;
Vue.prototype.appName = 'A365';
Vue.prototype.siteName = 'portal.a365.systems';
Vue.prototype.adminRoles = ['owner', 'Administrator'];
Vue.prototype.backupFileExtensions = ['cfg', 'xml'];
Vue.prototype.logoFileExtensions = ['jpg', 'png'];
Vue.prototype.maxLogoFileSizeInMB = 10;
Vue.prototype.maxBackupFileSizeInMB = 50;
Vue.prototype.whitelabel = process.env.VUE_APP_WHITELABEL || false;
Vue.prototype.notificationTimeout = 5000;
Vue.prototype.cookieMaxExpiry = new Date(2147483647 * 1000);
Vue.prototype.defaultShowLimit = 100;

Vue.use(VueI18n)
export const i18n = new VueI18n({
    locale: 'de',
    fallbackLocale: 'de',
    messages,
})


window.UIkit = UIkit
UIkit.use(Icons)

UIkit.use(UKExtendedIcons);


setDomain(process.env.VUE_APP_API_URL || apiUrl)

Vue.use(vueSeo)


Validator.localize({de: de, en: en})
Vue.use(VeeValidate, {locale: 'de'})


Vue.use(HighchartsVue)

Vue.use(VueEllipseProgress);

Vue.use(VCalendar);

Vue.use(VueCookie);

Vue.use(vueAwesomeCountdown, 'vac')

dayjs.extend(relativeTime);
dayjs.extend(isSameOrAfter);
dayjs.extend(updateLocale);
dayjs.extend(localizedFormat);
dayjs.extend(isBetween);

require('dayjs/locale/de')
dayjs.updateLocale('de', {
    relativeTime: {
        future: "in %s",
        past: "vor %s",
        s: '%d Sekunden',
        m: "1 Minute",
        mm: "%d Minuten",
        h: "1 Stunde",
        hh: "%d Stunden",
        d: "1 Tag",
        dd: "%d Tagen",
        M: "1 Monat",
        MM: "%d Monaten",
        y: "1 Jahr",
        yy: "%d Jahren"
    }
});

Vue.use(UUID);

Vue.use(vueFilterPrettyBytes)

Vue.component('VueSkeletonLoader', VueSkeletonLoader);

Vue.use(VueClipboard)

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
