<template>
    <div>

        <GenericItem
            :actions="getActions()"
            :additionals="userView ? [] : [{icon: 'user', text: user.name, copy: true}, {icon: 'mail', text: user.mailAddress, copy: true}]"
            :icon-tooltip="$t('organisation')"

            :labels="getLabels()"
            :name="distributor.name"

            icon="social">

            <Modal :id="modalIdDetails" :title="$t('distributor.details')">

                <Card :title="$t('data')" is-blank is-without-body mode="default">
                    <TextField v-model="distributor.name" disabled icon="hashtag" label="name" name="distributor-name"/>

                    <div class="uk-margin-top"/>
                    <TextField :value="getFormattedDateByDateString(distributor.createdAt)" disabled icon="calendar"
                               label="createdAt" name="created-at"/>

                    <div class="uk-margin-top"/>
                    <TextField v-model="user.name" disabled icon="user" label="owner" name="user-name"/>

                    <div class="uk-margin-top"/>
                    <TextField v-model="user.mailAddress" disabled icon="mail" name="user-email"/>
                </Card>

                <div class="uk-margin-medium-top"/>
                <CardSelect :no-items-message="$t('devices.notexist')"
                            :option-show-limit="6" :options="getDevicesList()"
                            disabled is-searchable is-title-large
                            label="devices"
                            label-name="devices" label-plural="devices"/>


                <div class="uk-margin-medium-top"/>
                <CardSelect :no-items-message="$t('satellites.notexist')"
                            :option-show-limit="6" :options="getSatellitesList()"
                            disabled is-searchable is-title-large
                            label="satellites"
                            label-name="satellites" label-plural="satellites"/>

                <div class="uk-margin-medium-top"/>
                <CardSelect :no-items-message="$t('subscriptions.notexist')"
                            :option-show-limit="6" :options="getSubscriptionsList()"
                            disabled is-searchable is-title-large
                            label="subscriptions"
                            label-name="subscriptions" label-plural="subscriptions"/>
            </Modal>

        </GenericItem>

    </div>
</template>

<script>

import GenericItem from "@/components/items/GenericItem";
import {
    getDistributorByIdDevices,
    getDistributorByIdSatellites,
    getDistributorByIdSubscriptions,
    postDistributorByIdConfirm,
    postDistributorByIdDeny
} from "@/client/vue-api-client";
import UIkit from "uikit";
import Modal from "@/components/generic/Modal";
import TextField from "@/components/generic/TextField";
import Card from "@/components/generic/Card";
import CardSelect from "@/components/CardSelect";

export default {
    name: 'DistributorItem',
    components: {CardSelect, Card, TextField, Modal, GenericItem},
    props: {
        distributor: {
            id: String,
            name: String,
            ownerId: String,
            deviceIds: Array,
            createdAt: String,
            confirmedAt: String,
            brandingIds: Array,
        },

        userView: Boolean,
        users: Array,
    },
    data() {
        return {
            modalIdDetails: "modal-details-distributor-" + this.distributor.id,

            user: {
                createdAt: "",
                id: "",
                mailAddress: "",
                name: "",
                permittedAt: "",
                roleId: "",
            },

            devices: [],
            subscriptions: [],
            satellites: [],
        }
    },
    created() {
        if (!this.userView) {
            for (let i = 0, j = this.users.length; i < j; i++) {
                if (this.distributor.ownerId === this.users[i].id) {
                    this.user = this.users[i];
                    break;
                }
            }
        }
    },
    methods: {
        getDevicesList() {
            let items = [];

            for (let i = 0, j = this.devices.length; i < j; i++) {
                items.push({
                    name: this.devices[i].description,
                    value: this.devices[i].id,
                });
            }

            return items;
        },
        getSubscriptionsList() {
            let items = [];

            for (let i = 0, j = this.subscriptions.length; i < j; i++) {
                items.push({
                    name: this.subscriptions[i].planId,
                    value: this.subscriptions[i].id,
                });
            }

            return items;
        },
        getSatellitesList() {
            let items = [];

            for (let i = 0, j = this.satellites.length; i < j; i++) {
                items.push({
                    name: this.satellites[i].description,
                    value: this.satellites[i].id,
                });
            }

            return items;
        },
        getDevices() {
            if (this.devices.length > 0) {
                return;
            }

            let self = this;
            getDistributorByIdDevices({
                id: self.distributor.id,

            }).then(function (response) {
                self.devices = response.data;

            }).catch(function (e) {
                self.checkGenericError(e);

            })
        },
        getSatellites() {
            if (this.satellites.length > 0) {
                return;
            }

            let self = this;
            getDistributorByIdSatellites({
                id: self.distributor.id,

            }).then(function (response) {
                self.satellites = response.data;

            }).catch(function (e) {
                self.checkGenericError(e);

            })
        },
        getSubscriptions() {
            if (this.subscriptions.length > 0) {
                return;
            }

            let self = this;
            getDistributorByIdSubscriptions({
                id: self.distributor.id,

            }).then(function (response) {
                self.subscriptions = response.data;

            }).catch(function (e) {
                self.checkGenericError(e);

            })
        },
        getActions() {
            let actions = [];

            if (this.userView) {
                if (this.distributor.deniedAt) {
                    actions.push({
                        icon: 'folder-open',
                        text: this.$t('open'),
                        tooltip: this.$t('distributorDeniedMessage'),
                        noAction: true,
                        isDisabled: true,
                    });

                } else if (!this.distributor.confirmedAt) {
                    actions.push({
                        icon: 'folder-open',
                        text: this.$t('open'),
                        tooltip: this.$t('distributorAccessAlreadyRequestedMessage'),
                        noAction: true,
                        isDisabled: true,
                    });

                } else {
                    actions.push({
                        route: 'organisations/' + this.distributor.id + '#devices',
                        icon: 'server',
                        text: this.$t('devices'),
                        mode: "success",
                    });

                    actions.push({
                        route: 'organisations/' + this.distributor.id + '#logger',
                        icon: 'rss',
                        text: this.$t('satellites'),
                        mode: "primary",
                    });

                    actions.push({
                        route: 'organisations/' + this.distributor.id + '#subscriptions',
                        icon: 'credit-card',
                        text: this.$t('subscriptions'),
                        mode: "primary",
                    });

                }

                return actions;
            }

            if (this.user.unverifiedSince) {
                return actions;
            }

            if (this.distributor.confirmedAt) {
                actions.push({
                    route: 'organisations/' + this.distributor.id,
                    icon: 'folder-open',
                    text: this.$t('open'),
                    mode: "primary",
                });
            }

            if (!this.distributor.deniedAt) {
                actions.push({
                    action: this.deny,
                    icon: 'ban',
                    text: this.$t(this.distributor.confirmedAt ? 'ban' : 'deny'),
                    mode: 'danger',

                    button: this.$t(this.distributor.confirmedAt ? 'banDistributor' : 'denyDistributor'),
                    confirmTitle: this.$t('reallyDeny'),
                    confirmMessage: this.$t(this.distributor.confirmedAt ? 'reallyBanDistributor' : 'reallyDenyDistributor', {
                        name: this.distributor.name,
                        user: this.user.name,
                        email: this.user.mailAddress
                    })
                });
            }

            if (!this.distributor.confirmedAt) {
                actions.push({
                    action: this.confirm,
                    icon: 'check',
                    text: this.$t('confirm'),
                    mode: 'success',
                });
            }

            return actions;
        },
        openDetails() {
            this.getDevices();
            this.getSatellites();
            this.getSubscriptions();

            UIkit.modal("#" + this.modalIdDetails).show();
            event.preventDefault();
        },
        getLabels() {
            let labels = [];

            if (this.distributor.confirmedAt) {

                if (!this.userView) {
                    labels.push({
                        type: "success",
                        icon: 'check',
                        text: this.$t("confirmed"),
                        tooltip: this.$t('At') + " " + this.getFormattedDateByDateString(this.distributor.confirmedAt),
                    });
                }

                labels.push({
                    type: "default",
                    icon: 'server',
                    text: (this.distributor.deviceIds ? this.distributor.deviceIds.length : 0) + ' ' + this.$t(this.distributor.deviceIds.length !== 1 ? 'devices' : 'device'),
                });

            } else if (this.distributor.deniedAt) {
                labels.push({
                    type: "danger",
                    icon: 'ban',
                    text: this.$t("denied"),
                    tooltip: this.$t('At') + " " + this.getFormattedDateByDateString(this.distributor.deniedAt),
                });

                if (!this.userView) {
                    labels.push({
                        type: "default",
                        icon: 'server',
                        text: (this.distributor.deviceIds ? this.distributor.deviceIds.length : 0) + ' ' + this.$t(this.distributor.deviceIds.length !== 1 ? 'devices' : 'device'),
                    });
                }

            } else {

                if (this.userView) {
                    labels.push({
                        type: "warning",
                        icon: 'info',
                        text: this.$t("inProgress"),
                        tooltip: this.$t('since') + " " + this.getFormattedDateByDateString(this.distributor.createdAt),
                    });

                } else {
                    labels.push({
                        type: "warning",
                        icon: 'info',
                        text: this.$t("unconfirmed"),
                        tooltip: this.$t('since') + " " + this.getFormattedDateByDateString(this.distributor.createdAt),
                    });

                }
            }

            return labels;
        },
        confirm() {
            let self = this;

            postDistributorByIdConfirm({
                id: self.distributor.id,

            }).then(function () {
                self.$emit('setConfirmed', self.distributor.id)

            }).catch(function (e) {
                self.checkGenericError(e);

            })
        },
        deny() {
            let self = this;

            postDistributorByIdDeny({
                id: self.distributor.id,

            }).then(function () {
                self.$emit('setDenied', self.distributor.id)

            }).catch(function (e) {
                self.checkGenericError(e);

            })
        },
    }
}

</script>


<style lang="scss" scoped>

.uk-panel {
    max-height: 500px;
    min-height: 300px;
}

</style>
