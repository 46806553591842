<template>
    <div>
        <!-- Veränderungen ab hier -->

        <div>
            <div>
                <div>
                    <h2> Allgemeine Geschäftsbedingungen der made.systems GbR</h2>
                </div>
            </div>
        </div>
        <div>
            <div>
                <div>
                    <div>
                        <div>
                            <p><strong>Abschnitt I: Allgemeine Regelungen </strong><br>
                                <br>
                                <strong>§ 1 Geltungsbereich</strong><br>
                                <br>
                                (1) Die nachstehenden Allgemeinen Geschäftsbedingungen (AGB) sind Bestandteil eines
                                jeden
                                Vertrages zwischen der Firma made.systems GbR, Hauptstr. 59, D-79730 Murg (im folgenden
                                made.systems) und deren Kunden (im folgenden Kunde).<br>
                                <br>
                                (2) Der Abschnitt I (Allgemeine Regelungen) enthält die Regelungen, die für alle von
                                made.systems angebotenen Leistungen und Dienste gelten. Im Abschnitt II (Domains),
                                Abschnitt
                                III (Webhosting) und Abschnitt IV (Mietserver) sind Besondere Regelungen enthalten, die
                                jeweils für einzelne Dienste gelten. Diese ergänzen und modifizieren ggf. die
                                Allgemeinen
                                Regelungen. Die Besonderen Regelungen gehen den Allgemeinen Regelungen im Falle eines
                                inhaltlichen Widerspruchs vor. <br>
                                <br>
                                (3) made.systems erbringt alle Dienste ausschließlich auf Grundlage dieser AGB. Dies
                                gilt
                                insbesondere auch dann, wenn der Kunde AGB verwendet und diese entgegenstehende oder von
                                den
                                hier aufgeführten AGB abweichende Bedingungen enthalten. Auch gelten die hier
                                aufgeführten
                                AGB, wenn made.systems in Kenntnis entgegenstehender oder von den hier aufgeführten
                                Bedingungen abweichender Bedingungen des Kunden den Auftrag vorbehaltlos ausführt. <br>
                                <br>
                                (4) Maßgebend ist stets die bei Vertragsschluss gültige Fassung der AGB. <br>
                                <br>
                                <strong>§ 2 Vertragsschluss</strong><br>
                                <br>
                                (1) Mit seiner Bestellung gibt der Kunde grundsätzlich ein verbindliches Angebot an
                                made.systems zum Abschluss eines Vertrages ab. made.systems versendet bei einer
                                Bestellung
                                über die Internetseite, zur Überprüfung der E-Mail-Adresse des Kunden, zunächst eine
                                Eingangsbestätigungsmail. Diese Eingangsbestätigungsmail stellt noch keine verbindliche
                                Annahme des Angebots durch made.systems dar. Der Vertrag zwischen dem Kunden und
                                made.systems kommt mit der Vornahme der vertraglichen Leistung durch made.systems
                                zustande.
                            </p>
                            <p>(2) Wählt der Kunde im Rahmen des Online-Bestellvorgangs &bdquo;PayPal&ldquo; als
                                Zahlungsart
                                aus, kommt der Vertragsschluss abweichend zu § 2 Abs.1 bereits in dem Moment zustande,
                                in
                                dem der Kunde die Bestellung aufgegeben hat.</p>
                            <p><br>
                                <strong>§ 3 Leistungen von made.systems</strong><br>
                                <br>
                                Der Leistungsumfang der einzelnen Dienste ergibt sich aus der zum Zeitpunkt der
                                Bestellung
                                aktuellen Leistungsbeschreibungen. <br>
                                <br>
                                <strong>§ 4 Zahlungsbedingungen, Eigentumsvorbehalt</strong> <br>
                                <br>
                                (1) Soweit die Preise sich nicht aus dem jeweiligen Vertrag ausdrücklich ergeben, sind
                                diese
                                der jeweils gültigen Preisliste zu entnehmen. Alle Preise verstehen sich einschließlich
                                der
                                jeweils geltenden gesetzlichen Mehrwertsteuer. <br>
                                <br>
                                (2) Die Fälligkeit der Forderung richtet sich nach der einzelnen vertraglichen
                                Vereinbarung.
                                <br>
                                <br>
                                (3) Soweit sich aus dem Einzelvertrag nichts Abweichendes ergibt, werden die jeweils
                                fälligen Entgelte turnusgemäß in Rechnung gestellt. Ein vereinbartes Pauschalentgelt
                                wird
                                jeweils im Voraus eingezogen; einmalige Entgelte, variable Entgelte sowie sonstige
                                Vergütungen werden nach Erbringung der Leistung eingezogen. <br>
                                <br>
                                (4) Gegen Forderungen von made.systems kann der Kunde nur mit unbestrittenen oder
                                rechtskräftig festgestellten Gegenansprüchen aufrechnen. <br>
                                <br>
                                (5) Bei der Überschreitung von eventuell in der Rechnung eingeräumten Zahlungsfristen
                                ist
                                made.systems auch ohne Mahnung berechtigt, Verzugszinsen zu berechnen, wenn hierauf in
                                der
                                Rechnung oder Zahlungsaufstellung zuvor besonders hingewiesen worden ist.<br>
                                <br>
                                (6) Ist der Kunde mit fälligen Zahlungen mehr als 10 Tage in Verzug, so ist made.systems
                                auch ohne Nachfristsetzung berechtigt, den Zugriff auf den Account bis zum Eingang des
                                offenen Betrages zu sperren. Bei Neukunden, die grundsätzlich vorleistungspflichtig
                                sind,
                                ist made.systems bei Nichteinhaltung der zur Vorleistung gesetzten Frist berechtigt, den
                                Zugriff auf den Account sofort zu sperren. <br>
                                <br>
                                (7) Kommt der Kunde für drei aufeinander folgende Wochen mit der Bezahlung eines nicht
                                unerheblichen Teils der Vergütung in Verzug, kann made.systems das Vertragsverhältnis
                                gemäß
                                § 7 Abs. 2 dieses Abschnitts aus wichtigem Grund ohne Einhaltung einer Frist kündigen.
                                Ein
                                wichtiger Grund zur fristlosen Kündigung für made.systems liegt insbesondere auch dann
                                vor,
                                wenn ein Insolvenzverfahren über das Vermögen des Kunden beantragt, eröffnet oder die
                                Eröffnung mangels Masse abgelehnt wird. <br>
                                <br>
                                (8) Bei der Lieferung von Waren (z. B. Hardware) erhält der Kunde erst mit der
                                vollständigen
                                Bezahlung das Eigentum an diesen. <br>
                                <br>
                                <strong>§ 5 Pflichten des Kunden</strong> <br>
                                <br>
                                (1) Der Kunde ist verpflichtet, die zur Vertragsabwicklung und Erbringung der Dienste
                                notwendigen Daten vollständig und richtig anzugeben. Änderungen sind made.systems
                                unverzüglich mitzuteilen. Dies gilt insbesondere für Adressdaten, die Bankverbindung und
                                die
                                E-Mail-Adresse. Die Änderungen sind direkt über das Kundencontrolcenter durchzuführen.
                                <br>
                                <br>
                                (2) Der Kunde ist verpflichtet, seine Systeme und Programme so einzurichten, dass weder
                                die
                                Sicherheit, die Integrität noch die Verfügbarkeit der Systeme, die made.systems zur
                                Erbringung ihrer Dienste einsetzt, beeinträchtigt wird. <br>
                                <br>
                                (3) made.systems kann Dienste ganz oder teilweise sperren, wenn Systeme abweichend vom
                                Regelbetriebsverhalten agieren oder reagieren und dadurch die Sicherheit, die Integrität
                                oder die Verfügbarkeit der made.systems Server-Systeme beeinträchtigt wird. <br>
                                <br>
                                (4) Der Kunde verpflichtet sich, Passwörter regelmäßig zu ändern. Er verwaltet
                                Passwörter
                                und sonstige Zugangsdaten sorgfältig und hält sie geheim. Dem Kunden ist bekannt, dass
                                er
                                bei Missbrauch seiner Kennung verpflichtet ist, auch solche Leistungen zu bezahlen, die
                                Dritte über seine Zugangsdaten und Passwörter nutzen oder bestellen, soweit er dies zu
                                vertreten hat. <br>
                                <br>
                                (5) Der Kunde ist für die ordnungsgemäße Sicherung seiner Daten verantwortlich. Er
                                erstellt
                                regelmäßig Sicherungskopien von allen Daten, die er auf made.systems-Server überspielt,
                                auf
                                anderen Datenträgern, die nicht bei made.systems liegen. made.systems bietet dem Kunden
                                im
                                Fall eines Datenverlustes Unterstützung bei der Wiederherstellung an. Der Kunde wird
                                hierzu
                                auf Verlangen von made.systems die betreffenden Datenbestände nochmals an made.systems
                                übertragen. Der Kunde stellt made.systems von sämtlichen Ansprüchen Dritter hinsichtlich
                                der
                                überlassenen Daten frei. <br>
                                <br>
                                <strong>§ 6 Nutzungsrechte</strong> <br>
                                <br>
                                (1) made.systems räumt den Kunden an zur Verfügung gestellter Software, Programmen oder
                                Skripten ein zeitlich auf die Laufzeit des dazugehörigen Vertragsverhältnisses
                                beschränktes
                                einfaches Nutzungsrecht ein. Es ist dem Kunden soweit nicht abweichend vereinbart, nicht
                                gestattet, Dritten Nutzungsrechte einzuräumen. Insbesondere eine Veräußerung der
                                Nutzungsrechte ist nicht erlaubt. Der Kunde wird Kopien von überlassener Software nach
                                Beendigung des Vertragsverhältnisses löschen und nicht weiter verwenden. <br>
                                <br>
                                (2) Für Open Source-Programme oder Software von Fremdanbietern gelten die vorstehenden
                                Bestimmungen aus § 6 Absatz 1 dieses Abschnitts nicht. Hier finden ausschließlich die
                                zugehörigen Lizenzbedingungen Anwendung. Dies gilt unabhängig davon, ob made.systems dem
                                Kunden die Software zur Verfügung stellt oder lediglich als Vermittler auftritt und den
                                Zugang vermittelt.</p>
                            <p>(3) Um die Funktionsfähigkeit des Betriebes aufrecht zu erhalten, ist made.systems
                                berechtigt, ausgehende Mails des Users automatisiert zu scannen, um so den Versand von
                                Spam-Nachrichten bei gehackten Mail-Accounts frühzeitig zu erkennen und zu unterbinden.
                                Der
                                Kunde erklärt ausdrücklich sein Einverständnis mit einem solchen Vorgehen.</p>
                            <p><br>
                                <strong>§ 7 Vertragslaufzeit, Kündigung</strong> <br>
                                <br>
                                (1) Soweit nicht anders vereinbart, verlängert sich der Vertrag jeweils automatisch um
                                die
                                jeweilige Mindestvertragslaufzeit / erste Vertragslaufzeit, solange er nicht von einer
                                Partei mit einer Frist von 30 Tagen zum jeweiligen Laufzeitende gekündigt wird. <br>
                                <br>
                                (2) Ist die erste Vertragslaufzeit länger als ein Jahr, verlängert sich der Vertrag
                                nicht um
                                die Vertragslaufzeit, sondern um ein Jahr. Dies gilt nicht für Domainregistrierungen,
                                die
                                aufgrund der Vorgaben der Vergabestelle für mindestens zwei Jahre erfolgen müssen. In
                                diesen
                                Fällen verlängert sich der Vertrag um diese zwei Jahre. <br>
                                <br>
                                (3) Das Recht zur Kündigung aus wichtigem Grund bleibt hiervon unberührt. <br>
                                <br>
                                (4) Ein solcher wichtiger Grund kann unter anderem darin liegen, dass der Kunde trotz
                                erfolgter Abmahnung gegen die Pflichten aus § 5 Abs. 1 des Abschnitts II, § 2 Abs. 1 und
                                Abs. 2 des Abschnitts III, § 5 Abs. 3 des Abschnitts IV verstößt. Ein weiterer wichtiger
                                Grund kann darin liegen, dass der Kunde Inhalte verwendet, welche das
                                Regelbetriebsverhalten
                                oder die Sicherheit des Servers beeinträchtigen könnten. <br>
                                <br>
                                (5) Kündigungen bedürfen der Textform.<br>
                                <br>
                                <strong>§ 8 Haftung von made.systems</strong></p>
                            <p>(1) Es besteht grundsätzlich das gesetzliche Mängelhaftungsrecht, es sei denn in
                                Abschnitt I
                                § 5, § 7, § 8 Abs. 2 bis Abs. 4; Abschnitt II § 4, § 6; Abschnitt III § 3, § 4;
                                Abschnitt IV
                                § 4 oder § 5 dieser AGB ist etwas anderes geregelt.</p>
                            <p>(2) Für andere als durch Verletzung von Leben, Körper und Gesundheit entstehende Schäden
                                haftet made.systems lediglich, soweit diese auf vorsätzlichem oder grob fahrlässigem
                                Handeln
                                oder auf schuldhafter Verletzung einer wesentlichen Vertragspflicht durch made.systems,
                                ihrer Mitarbeiter oder einer Verletzung von Pflichten bei Vertragsverhandlungen sowie
                                auf
                                der Vornahme von unerlaubten Handlungen basieren. Eine darüber hinausgehende Haftung auf
                                Schadensersatz ist ausgeschlossen. <br>
                                <br>
                                (3) Die Haftung ist außer bei vorsätzlichen oder grob fahrlässigen Verhalten, der
                                Verletzung
                                einer Kardinalspflicht oder der Verletzung von Leben, Körper und Gesundheit durch
                                made.systems, ihre Mitarbeiter oder ihre Erfüllungsgehilfen auf die bei Vertragsschluss
                                typischer Weise vorhersehbaren Schäden und im Übrigen der Höhe nach auf die
                                vertragstypischen Durchschnittsschäden, maximal jedoch auf 10.000 Euro, begrenzt. Dies
                                gilt
                                auch für mittelbare Schäden, insbesondere entgangenen Gewinn.<br>
                                <br>
                                (4) Die Bestimmungen des Produkthaftungsgesetzes bleiben unberührt. <br>
                                <br>
                                <strong>§ 9 Gerichtsstand, anwendbares Recht</strong> <br>
                                <br>
                                (1) Sofern der Kunde Kaufmann, eine juristische Person des öffentlichen Rechts oder
                                öffentlichrechtliches Sondervermögen ist, oder keinen allgemeinen Gerichtsstand im
                                Inland
                                hat oder nach Vertragsschluss seinen Wohnsitz ins Ausland verlegt oder sein Wohnsitz zum
                                Zeitpunkt der Klageerhebung nicht bekannt ist, ist der Erfüllungsort und der
                                Gerichtsstand
                                der Sitz von made.systems. <br>
                                <br>
                                (2) Für alle Ansprüche gleich welcher Art, die aus oder anlässlich dieses Vertrages
                                entstehen, gilt das Recht der Bundesrepublik Deutschland unter Ausschluss des
                                Einheitlichen
                                UN-Kaufrechts (CISG). Unberührt bleiben zwingende Bestimmungen des Staates, in dem der
                                Kunde
                                seinen gewöhnlichen Aufenthalt hat.</p>

                            <p><strong>§ 10 Änderungsvorbehalt</strong></p>
                            <p>made.systems ist berechtigt, diese Allgemeinen Geschäftsbedingungen zu ändern. Sollte der
                                Kunde nicht innerhalb der von made.systems gesetzten Frist der Änderung widersprechen,
                                so
                                gilt die Änderung als angenommen. Voraussetzung für eine solche Änderung sind, dass
                                made.systems dem Kunden eine angemessene Frist (mindestens drei Wochen) zur Abgabe
                                seiner
                                ausdrücklichen Erklärung einräumt und dass made.systems bei Beginn der Fristsetzung auf
                                sämtliche Änderungen und das bestehende Sonderkündigungsrecht hinweist.</p>

                            <p><strong>Abschnitt II: Besondere Regelungen für Domains</strong></p>
                            <p><br>
                                <strong>§ 1 Vertragsgegenstand</strong><br>
                                <br>
                                made.systems vermittelt die Registrierung von Internetdomains. Bezüglich der
                                Einzelheiten
                                wird auf die Leistungsbeschreibung von made.systems verwiesen. <br>
                                <br>
                                <strong>§ 2 Domainregistrierung</strong> <br>
                                <br>
                                (1) Das Vertragsverhältnis über die Registrierung der Domain kommt zwischen dem Kunden
                                und
                                der Vergabestelle direkt zustande. made.systems beantragt die Registrierung von Domains
                                im
                                Namen, im Auftrag und auf Rechnung des Kunden. Der Kunde kann von einer tatsächlichen
                                Registrierung erst ausgehen, wenn diese durch die Vergabestelle bestätigt ist. <br>
                                <br>
                                (2) Für bereits registrierte Domains führt made.systems im Namen, im Auftrag, unter
                                Mitwirkung und auf Rechnung des Kunden das jeweils einschlägige Verfahren zur Übernahme
                                der
                                Domain durch made.systems durch. <br>
                                <br>
                                (3) Top-Level-Domains werden von unterschiedlichen Organisationen registriert und
                                verwaltet.
                                Für jede Top Level Domain gelten unterschiedliche Vergabebedingungen. Mit der
                                Beauftragung
                                von made.systems stimmt der Kunde verbindlich den jeweiligen Vergaberichtlinien zu. <br>
                                <br>
                                (4) Die Daten zur Registrierung von Domains werden in einem automatisierten Verfahren an
                                die
                                jeweiligen Vergabestellen weitergeleitet. Der Kunde kann von einer tatsächlichen
                                Zuteilung
                                der Domain erst ausgehen, wenn der Internet-Service unter der gewünschten Domain
                                bereitgestellt wurde. Eine Gewähr für die Zuteilung von bestellten Domains wird nicht
                                übernommen. <br>
                                <br>
                                (5) Besteht bereits ein Account, können unter Eingabe der Zugangsdaten des Accounts
                                weitere
                                Domains online bestellt werden. Diese Domains werden bei einer Neuregistrierung (soweit
                                möglich) sofort zur Registrierung eingereicht. Ein Rücktritt von der Domainbestellung
                                ist
                                somit nicht möglich. Der Domaininhaber, welcher gleichzeitig als Admin-C eingetragen
                                wird,
                                kann bei einer solchen Bestellung von zusätzlichen Domains frei gewählt werden. Die
                                Kosten
                                für die Registrierung der Domain werden ausschließlich dem Accountinhaber (Kunden) in
                                Rechnung gestellt. Dieser trägt auch die Verantwortung im Hinblick auf alle rechtlichen
                                und
                                administrativen Fragen. <br>
                                <br>
                                <strong>§ 3 Entgelt</strong> <br>
                                <br>
                                Bei Domains ist das Entgelt erstmals bei der Registrierung fällig. made.systems ist
                                berechtigt, die Aktivierung einer Domain erst nach Zahlung des für die Registrierung
                                vereinbarten Entgelts vorzunehmen. Im Falle eines Providerwechsels trägt der Kunde die
                                Kosten, die sich aus der Übernahme der Domain durch made.systems und deren Weiterführung
                                ergeben. Sofern das Wechselverfahren wegen fehlender Freigabe der Domain oder anderer
                                Umstände wiederholt werden muss, ist der Kunde verpflichtet, die hierdurch entstehenden
                                Mehrkosten zu tragen.<br>
                                <br>
                                <strong>§ 4 Haftung</strong> <br>
                                <br>
                                (1) made.systems hat auf die Vergabe von Domains keinen Einfluss. made.systems übernimmt
                                keine Gewähr dafür, dass die beantragte Domain zugeteilt wird oder frei von Rechten
                                Dritter
                                ist. <br>
                                <br>
                                (2) Die Daten zur Registrierung werden von made.systems in einem automatisierten
                                Verfahren
                                an die Vergabestelle weitergeleitet. Der Kunde ist vor Absendung seines Auftrags
                                verpflichtet, seine Daten auf Richtigkeit und Vollständigkeit hin zu überprüfen.
                                made.systems haftet nicht für Schäden, die aufgrund einer Unrichtigkeit oder
                                Unvollständigkeit der Daten des Kunden entstehen. <br>
                                <br>
                                <strong>§ 5 Pflichten des Kunden, Unzulässige Nutzung, Reaktion von made.systems bei
                                    Gefährdungen</strong> <br>
                                <br>
                                (1) Der Kunde ist dafür verantwortlich, dass die Domain an sich weder gesetzliche
                                Vorschriften, noch Rechte Dritter verletzt. Der Kunde ist insbesondere verpflichtet,
                                keine
                                Domains registrieren zu lassen, die gegen gesetzliche Bestimmungen, die
                                Persönlichkeitsrechte und/oder Schutzrechte Dritter oder gegen die guten Sitten
                                verstoßen.
                                <br>
                                <br>
                                (2) Machen Dritte gegenüber made.systems glaubhaft, dass eine Domain an sich ihre Rechte
                                verletzt, oder erscheint es aufgrund objektiver Anhaltspunkte als wahrscheinlich, dass
                                durch
                                die Domain Rechtsvorschriften verletzt werden, kann made.systems die Internetseite
                                vorübergehend sperren, solange die Rechtsverletzung oder der Streit mit dem Dritten über
                                die
                                Rechtsverletzung andauert. <br>
                                <br>
                                (3) In den Fällen des § 5 Abs. 2 des II. Abschnitts kann made.systems alternativ auch
                                Maßnahmen ergreifen, die die Domain vorübergehend unerreichbar machen. <br>
                                <br>
                                <strong>§ 6 Freistellung</strong> <br>
                                <br>
                                Der Kunde ersetzt made.systems alle Schäden, die aus einer Verletzung der vorstehenden
                                Regeln, insbesondere einer Verletzung der Verpflichtungen aus § 5 des Abschnitts II
                                entstehen, soweit er diese zu vertreten hat.</p>

                            <p><strong>Abschnitt III: Besondere Regelungen für Webhosting</strong></p>
                            <p><br>
                                <strong>§ 1 Vertragsgegenstand</strong> <br>
                                <br>
                                (1) made.systems stellt dem Kunden auf dessen Antrag hin Speicherplatz (sog. Account)
                                auf
                                einem an das Internet angeschlossenen Server zur Veröffentlichung einer Website zur
                                Verfügung (sog. Shared Hosting). Bezüglich der Einzelheiten zum Vertragsgegenstand wird
                                auf
                                die Leistungsbeschreibung von made.systems verwiesen. <br>
                                <br>
                                (2) Der Account-Vertrag und der Vertrag bzgl. der Vermittlung einer Domain sind zwei
                                voneinander unabhängige Verträge. Dies ist auch dann der Fall, wenn die Verträge
                                zeitgleich
                                abgeschlossen wurden. <br>
                                <br>
                                (3) Kann eine Domain dem Kunden nicht zugeteilt werden, oder kann die Domain im Rahmen
                                eines
                                AuthInfo-Verfahrens nicht von made.systems übernommen werden, so bleibt die Wirksamkeit
                                des
                                Account-Vertrags hiervon unberührt. Dem Kunden steht es frei, eine andere, verfügbare
                                Domain
                                registrieren zu lassen oder das Webpaket zum nächstmöglichen Zeitpunkt zu kündigen. <br>
                                <br>
                                <strong>§ 2 Pflichten des Kunden, Reaktion von made.systems bei Gefährdungen</strong>
                                <br>
                                <br>
                                (1) Der Kunde ist dafür verantwortlich, dass die Inhalte der von ihm betriebenen
                                Webseiten
                                weder gesetzliche Vorschriften, noch Rechte Dritter verletzen, insbesondere ist der
                                Kunde
                                verpflichtet, <br>
                                <br>
                                a. keine Inhalte bzw. Informationen in das Internet einzubringen, durch die gegen
                                gesetzliche Bestimmungen, die Persönlichkeitsrechte und/oder Schutzrechte Dritter oder
                                gegen
                                die guten Sitten verstoßen wird; <br>
                                <br>
                                b. keine Inhalte zum Abruf anzubieten oder zu verlinken, die extremistischer
                                (insbesondere
                                rechtsextremistischer) Natur sind oder pornographische oder kommerzielle erotische
                                Angebote
                                beinhalten; <br>
                                <br>
                                c. eine übermäßige Belastung der Netze durch ungezielte oder unsachgemäße Verbreitung
                                von
                                Daten zu verhindern, insbesondere ohne ausdrückliches Einverständnis des jeweiligen
                                Empfängers keine E-Mails, die Werbung enthalten, zu versenden (Verbot von
                                Mail-Spamming);
                                <br>
                                <br>
                                d. sicherzustellen, dass seine auf einem Server von made.systems eingesetzten Skripte
                                und
                                Programme nicht mit Fehlern behaftet sind, welche die Leistungserbringung durch
                                made.systems
                                stören könnten; <br>
                                <br>
                                e. alle Personen, denen er eine Nutzung der Dienste von made.systems ermöglicht, in
                                geeigneter Weise auf die Einhaltung der zuvor genannten Pflichten hinzuweisen. <br>
                                <br>
                                (2) Der Kunde verpflichtet sich weiterhin, die Funktionsfähigkeit der Systeme von
                                made.systems und ihren Kunden nicht zu beinträchtigen (unzulässige Nutzung).
                                Verletzungen
                                der System- und Netzwerksicherheit stellen Vertragsverletzungen dar, für die der Kunde
                                haftet. Der Kunde verpflichtet sich insbesondere, <br>
                                <br>
                                a. keine Skripte oder Programme ablaufen zu lassen, die das Betriebsverhalten des
                                Servers
                                bei hohen Zugriffszahlen nicht nur unerheblich beeinträchtigen, z.B. Bannertausch oder
                                frei
                                zugängliche Besucherzähler, Top Listen, Massenmailversand, Mail- Bombing oder andere
                                Handlungen, die das System überlasten (Flooding); <br>
                                <br>
                                b. keine IRC Chat-Systeme (kleinere Perl sowie PHP-Chatsysteme sind zugelassen, sofern
                                das
                                Betriebsverhalten des Servers dadurch nicht beeinträchtigt wird), Video Streaming oder
                                Downloads von Video, Audio, inkl. mp3 Dateien für kommerzielle Zwecke aufzuschalten;
                                <br>
                                <br>
                                c. Steuerungsinformationen in TCP/IP Paketen (Paket-Header), etwa in elektronischen
                                Mitteilungen und Newsgroups- Einträgen, nicht zu manipulieren. <br>
                                <br>
                                (3) Werden made.systems Rechtsverletzungen, unzulässige Nutzungen oder Gefährdungen
                                bekannt,
                                so reagiert made.systems im Regelfall mit folgenden Maßnahmen: <br>
                                <br>
                                a. Machen Dritte gegenüber made.systems glaubhaft, dass Inhalte einer Internetpräsenz
                                ihre
                                Rechte verletzen, oder erscheint es aufgrund objektiver Anhaltspunkte als
                                wahrscheinlich,
                                dass durch Inhalte Rechtsvorschriften verletzt werden, kann made.systems die
                                Internetseite
                                vorübergehend sperren, solange die Rechtsverletzung oder der Streit mit dem Dritten über
                                die
                                Rechtsverletzung andauert. <br>
                                <br>
                                b. Bei extremistischen, pornographischen oder kommerziellen erotischen Inhalten kann
                                made.systems statt eine Sperrung vorzunehmen auch eine fristlose Kündigung aussprechen.
                                <br>
                                <br>
                                c. Bei unzulässiger Nutzung ist made.systems berechtigt, den Account ggf. ohne
                                Vorwarnung zu
                                sperren, sofern dies zur Sicherung der Funktionsfähigkeit angezeigt ist. <br>
                                <br>
                                <strong>§ 3 Freistellung</strong> <br>
                                <br>
                                Der Kunde haftet made.systems für alle Schäden, die aus einer Verletzung der
                                vorstehenden
                                Verpflichtungen des Kunden entstehen, soweit er die Verletzung zu vertreten hat. <br>
                                <br>
                                <strong>§ 4 Gewährleistung</strong> <br>
                                <br>
                                Für den Fall, dass die von made.systems erbrachte Leistung mangelhaft ist, wird auf die
                                Gewährleistungsvorschriften des Service Level Agreements (SLA) verwiesen.</p>

                            <p><strong>Abschnitt IV: Mietserver</strong></p>
                            <p><br>
                                <strong>§ 1 Vertragsgegenstand</strong> <br>
                                <br>
                                made.systems bietet seinen Kunden die Möglichkeit, Server von made.systems als
                                Managed-Server, Root-Server oder Reseller- Server anzumieten. Die von made.systems zu
                                erbringenden Leistungen und Dienste sind abhängig vom gebuchten Managed-, Root-, bzw.
                                Reseller-Serverpaket. Diesbezüglich wird auf die Leistungsbeschreibungen von
                                made.systems
                                verwiesen. <br>
                                <br>
                                <strong>§ 2 Einzelne Bestimmungen zu den Servern</strong> <br>
                                <br>
                                a) Managed-Server <br>
                                Bei der Anmietung eines Managed-Server ist eine Nutzungsüberlassung seitens des Kunden
                                an
                                Dritte ohne Zustimmung von made.systems nicht gestattet. <br>
                                <br>
                                b) Root-Server <br>
                                Root-Server werden vom Kunden in eigener Verantwortung verwaltet. Jeder Kunde ist
                                verpflichtet, seinen Server so einzurichten und zu verwalten, dass die Sicherheit,
                                Integrität und Verfügbarkeit der Netze, anderer Server, Software und Daten Dritter nicht
                                gefährdet werden. <br>
                                <br>
                                c) Reseller-Accounts <br>
                                Der Kunde haftet bei einem Verstoß seiner Abnehmer gegen diese AGB. Außerdem ist
                                made.systems berechtigt, im Falle einer außerordentlichen Kündigung des
                                Reseller-Vertrages
                                den Abnehmern eine Fortführung der Dienste anzubieten und diese zu diesem Zwecke direkt
                                zu
                                kontaktieren. <br>
                                <br>
                                <br>
                                <strong>§ 3 Reaktion von made.systems bei Gefährdungen</strong> <br>
                                <br>
                                (1) Gefährdet ein Kunde über seinen Server die Sicherheit, Integrität oder Verfügbarkeit
                                von
                                Netzen, Servern, Software oder Daten oder hat made.systems aufgrund objektiver
                                Anhaltspunkte
                                einen solchen Verdacht, kann made.systems den Server bis zur Klärung der Angelegenheit
                                vorübergehend sperren. Bei einer vorsätzlichen Handlung des Kunden, kann made.systems
                                das
                                Vertragsverhältnis fristlos kündigen. <br>
                                <br>
                                (2) Gefährdet ein Kunde über seinen Server wiederholt die Sicherheit, Integrität oder
                                Verfügbarkeit von Netzen, Servern, Software oder Daten und ist eine Wiederholung zu
                                erwarten, kann made.systems das Vertragsverhältnis fristlos kündigen, wenn zuvor bereits
                                eine Abmahnung erfolgt ist und wenn es für made.systems keine zumutbare Möglichkeit
                                gibt,
                                die zu erwartenden Gefährdungen oder deren Auswirkung auf andere Systeme zu unterbinden.
                                <br>
                                <br>
                                (3) Die Pflichten des Kunden aus § 2 Abs. 1 und Abs. 2 des Abschnitts III dieser AGB
                                gelten
                                entsprechend. made.systems kann in solchen Fällen Server entsprechend § 2 Abs. 3 des
                                Abschnitts III dieser AGB sperren oder kündigen. <br>
                                <br>
                                <strong>§ 4 Freistellung</strong> <br>
                                <br>
                                Der Kunde haftet made.systems für alle Schäden, die aus einer Verletzung der
                                vorstehenden
                                Verpflichtungen des Kunden entstehen, soweit er die Verletzung zu vertreten hat. <br>
                                <br>
                                <strong>§ 5 Gewährleistung</strong> <br>
                                <br>
                                (1) Für den Fall, dass die von made.systems erbrachte Leistung mangelhaft ist, wird auf
                                die
                                Gewährleistungsvorschriften des Service Level Agreements (SLA) verwiesen.</p>
                            <p>(2) Verweigert der Kunde im Rahmen des Vertrages für einen Managed-Server gegenüber
                                made.systems die Zustimmung zu Sicherheitsupdates, haftet made.systems nicht für
                                Schäden,
                                die durch die Nutzung des Servers entstehen, sofern der Kunde made.systems nicht eine
                                Pflichtverletzung nachweist.</p>
                            <p>Stand der AGB-Fassung: 01.06.2022</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Veränderungen bis hier -->
    </div>
</template>

<script>

export default {
    name: 'TermsAndConditionText',
}
</script>

<style lang="scss" scoped>

</style>
