<template>
    <div>

        <LoadingStruct :is-loading="loading">
            <GenericSiteTitle/>
            <GenericItemView
                :computed-items="computedItems"
                :error="error"
                :filters="getFilters()"
                :is-loading="loading"
                :is-search="items.length>0 && search.length>0"
                :items="items"
                is-searchable
                label-plural="devices"
                @search="setSearch"
            >

                <template v-slot:items>
                    <li v-for="item in computedItems" :key="item.id"
                        :data-role="item.role" :data-status="item.status">

                        <DeviceItem :device="item" :is-invited="item.invited" @afterInviteRejected="setDeleted"
                                    @setDeleted="setDeleted"/>
                    </li>
                </template>

                <template v-slot:noitems>
                    <InfoCard v-if="items.length === 0" icon="info">
                        <span v-if="isDistributor">
                            {{ $t('noDevicesMessageDistributor') }}
                        </span>
                        <span v-else>
                            {{ $t('noDevicesMessage') }}
                        </span>
                        <DistributorStatus is-with-hint/>
                    </InfoCard>
                </template>

            </GenericItemView>
        </LoadingStruct>


    </div>
</template>

<script>

import DeviceItem from "@/components/items/DeviceItem";
import InfoCard from "@/components/generic/InfoCard";
import {
    getDevice,
    getDeviceById,
    getDeviceByIdSatelliteModulesMonitoring,
} from "@/client/vue-api-client";
import GenericItemView from "@/views/GenericItemView";
import GenericSiteTitle from "@/views/GenericSiteTitle";
import LoadingStruct from "@/views/LoadingStruct";
import DistributorStatus from "@/components/DistributorStatus";
import Vue from "vue";

export default {
    name: 'DevicesAdministration',
    components: {
        DistributorStatus,
        LoadingStruct,
        GenericSiteTitle,
        GenericItemView, InfoCard, DeviceItem,
    },
    data() {
        return {
            loading: true,
            error: null,

            items: [],
            search: "",
        }
    },
    created() {
        this.getItems();
    },
    computed: {
        computedItems() {
            let items = this.items;
            if (this.search && this.search.length) {
                items = this.powerSearcher(items, this.search);
            }

            if (items.length > this.defaultShowLimit) {
                return items.slice(0, this.defaultShowLimit);
            }

            return items;
        },
    },
    methods: {
        setSearch(text) {
            this.search = text;
        },
        setDeleted(id) {
            this.items = this.items.filter(x => {
                return x.id !== id;
            });
        },
        getFilters() {
            let filters = [
                {
                    type: 'status',
                    values: [
                        {name: 'withoutSatellite', total: 0},
                        {name: 'withoutMonitoring', total: 0},
                        {name: 'offline', total: 0},
                        {name: 'warning', total: 0},
                        {name: 'determine', total: 0},
                        {name: 'online', total: 0},
                    ]
                },
            ];

            return filters;
        },
        getItems() {
            let self = this;

            getDevice().then(function (response) {
                let items = response.data;
                items.reverse();
                self.items = items;

                for (let i = 0, j = items.length; i < j; i++) {
                    items[i]['invited'] = items[i].invites.includes(self.getUserEmail);
                    items[i]['satelliteIpAddress'] = items[i].satellite.ipAddress;

                    self.getDevice(i);
                }

                if (items.length === 0) {
                    self.loading = false;
                }

            }).catch(function (e) {
                self.checkGenericError(e);
                self.error = e;
                self.loading = false;

            })
        },
        getDevice(key) {
            let self = this;

            let item = this.items[key];

            getDeviceById({
                id: item.id,

            }).then(function (response) {
                let details = response.data;
                Vue.set(self.items[key], 'details', details)
                Vue.set(self.items[key], 'distributorName', details.distributor.name)

                let requirementStatus = self.getRequirementStatusByDevice(details);
                if (!requirementStatus) {
                    self.getMonitoring(key);

                } else {
                    Vue.set(self.items[key], 'status', self.getDeviceStatus(details, null))

                    if (self.items.length - 1 === key) {
                        self.loading = false;
                    }

                }

            }).catch(function (e) {
                self.checkGenericError(e);
                self.loading = false;
                self.error = e;

            })
        },
        getMonitoring(key) {
            let self = this;

            let item = this.items[key];

            getDeviceByIdSatelliteModulesMonitoring({
                id: item.id,
                deviceEvents: true,

            }).then(function (response) {
                let monitoring = response.data;
                Vue.set(self.items[key], 'status', self.getDeviceStatus(item.details, monitoring))

                if (self.items.length - 1 === key) {
                    self.loading = false;
                }

            }).catch(function (e) {
                self.checkGenericError(e);
                self.loading = false;
                self.error = e;

            })
        },
    }
}

</script>

<style lang="scss" scoped>

</style>
