<template>
    <GenericItem
        :actions="getActions()" :additionals="[]"
        :icon-tooltip="$t('module')"

        :labels="getLabels()"
        :name="$t(module.id)"

        icon="cart">

    </GenericItem>
</template>


<script>

import GenericItem from "@/components/items/GenericItem";

export default {
    name: 'ModuleItem',
    components: {GenericItem},
    props: {
        module: {
            id: String,
        },
    },
    methods: {
        getActions() {
            let actions = [];
            return actions;
        },
        getLabels() {
            let labels = [];
            return labels;
        },
    }
}

</script>

<style lang="scss" scoped>
</style>
