<template>
    <div>

        <LoadingStruct :is-loading="loading">
            <GenericSiteTitle :title-poperties="[{key: 0, disabled: true}]"/>

            <Tabs :tabs="tabs">
                <li class="active">
                    <StatisticsBar :data="getData()" :title="$t('device.category.statistics')"/>
                </li>
                <li>
                    <StatisticPie :data="getData()" :title="$t('device.category.statistics')"/>
                </li>
            </Tabs>
        </LoadingStruct>

    </div>
</template>

<script>

import {getDevice} from "@/client/vue-api-client";
import GenericSiteTitle from "@/views/GenericSiteTitle";
import LoadingStruct from "@/views/LoadingStruct";
import Tabs from "@/components/Tabs";
import StatisticPie from "@/components/StatisticPie";
import StatisticsBar from "@/components/StatisticsBar";

export default {
    name: 'StatisticsAdministration',
    components: {
        StatisticsBar,
        StatisticPie,
        Tabs,
        LoadingStruct,
        GenericSiteTitle,
    },
    data() {
        return {
            loading: true,
            error: null,
            items: [],

            tabs: ['barChart', 'circleChart'],
        }
    },
    created() {
        this.getItems();
    },
    methods: {
        getData() {
            let items = this.items;

            let categories = [];
            for (let i = 0, j = items.length; i < j; i++) {
                let key = items[i].category.name;
                if (!key) {
                    key = this.$t("not.specified");

                }

                if (!categories[key]) {
                    categories[key] = 1;

                } else {
                    categories[key]++;

                }
            }

            let parsed = [];
            for (const [key, value] of Object.entries(categories)) {
                parsed.push({
                    name: key + " (" + this.getFormattedNumber(this.getPercentage(items.length, value)) + "%)",
                    y: value,
                });
            }

            parsed = parsed.sort(function (a, b) {
                return a['name'].localeCompare(b['name']);
            });

            parsed = parsed.sort(function (a, b) {
                return b.y - a.y;
            });

            return parsed;
        },
        getItems() {
            let self = this;

            getDevice().then(function (response) {
                self.items = response.data;
                self.loading = false;

            }).catch(function (e) {
                self.checkGenericError(e);
                self.error = e;
                self.loading = false;

            })
        },
    }
}

</script>

<style lang="scss" scoped>

</style>
