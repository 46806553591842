<template>

    <div>
        <GenericItem :actions="getActions()"
                     :additionals="[{icon: 'calendar', text: getDaysAgoMessage(getDaysAgoByDateString(voicemail.uploadedAt)), tooltip: getFormattedDateByDateString(voicemail.uploadedAt)+' Uhr'}]"
                     :icon-tooltip="$t('voicemail')"

                     :labels="getLabels()"

                     class="cursor-pointer"

                     icon="receiver">

            <template v-slot:name>
                {{ voicemail.remotePhoneNumber }}
                <Icon icon="arrow-right"/>
                {{ voicemail.localPhoneNumber }}
            </template>


        </GenericItem>

        <Modal :id="modalId" :title="$t('voicemail')" is-centered>

            <div uk-grid>
                <div class="uk-width-expand">

                    <Label is-bold is-medium>
                        {{ voicemail.remotePhoneNumber }}
                    </Label>

                    <Icon class="uk-margin-small-right uk-margin-small-left" icon="arrow-right"/>

                    <Label is-bold is-medium type="default">
                        {{ voicemail.localPhoneNumber }}
                    </Label>
                </div>
                <div class="uk-text-right uk-flex uk-flex-middle">
                    <span :uk-tooltip="'title:'+getFormattedDateByDateString(voicemail.uploadedAt)+' Uhr'">
                        {{ getDaysAgoMessage(getDaysAgoByDateString(voicemail.uploadedAt)) }}
                    </span>
                </div>
            </div>
            <div class="uk-margin-bottom"/>

            <LabelWithError :label="$t('voicemailInText')">
                ({{ getParsedReadingTime(voicemail.message) + " " + $t('min') + " " + $t("readTime") }})
            </LabelWithError>
            <TextArea :rows="8" :value="voicemail.message" is-disabled name="message"/>
            <div class="uk-margin-bottom"/>

            <LabelWithError :label="$t('voicemail')">
                ({{ getParsedDuration(voicemail.duration) + " " + $t('min') + " " + $t("duration") }})
            </LabelWithError>
            <div class="uk-grid-small uk-flex-middle" uk-grid>
                <div class="uk-width-expand">
                    <audio :id="'audio-' + voicemail.id" :src="audioSource"
                           :type="'audio/'+(voicemail.fileType).replace('.', '')" class="uk-width-1-1"
                           controls controlsList="nodownload noremoteplayback"
                           oncontextmenu="return false;" preload="metadata">
                        {{ $t('browserNoAudioSupportMessage') }}
                    </audio>
                </div>
                <div>
                    <div v-on:click="download()">
                        <Icon :ratio="1.2" :uk-tooltip="'title:'+$t('download')" class="cursor-pointer"
                              icon="cloud-download"/>
                    </div>
                </div>
            </div>

        </Modal>
    </div>

</template>

<script>

import GenericItem from "@/components/items/GenericItem";
import {getDeviceByIdVoicemailsByVoicemailId} from "@/client/vue-api-client";
import Modal from "@/components/generic/Modal";
import Icon from "@/components/generic/Icon";
import LabelWithError from "@/components/generic/LabelWithError";
import Label from "@/components/Label";
import TextArea from "@/components/TextArea";
import axios from "axios";
import UIkit from "uikit";

export default {
    name: 'VoicemailItem',
    components: {TextArea, Label, LabelWithError, Icon, Modal, GenericItem},
    data() {
        return {
            modalId: "",
            audioSource: null,
        }
    },
    props: {
        deviceId: String,
        voicemail: {
            uploadedAt: String,
            id: String,

            fileType: String,
            remotePhoneNumber: String,
            localPhoneNumber: String,
            message: String,
            duration: Number,
        },

        isLatest: Boolean,
    },
    created() {
        this.modalId = "voicemail-" + this.voicemail.id;
    },
    mounted() {
        let self = this;
        document.addEventListener("beforehide", function () {
            let audio = document.getElementById('audio-' + self.voicemail.id);
            if (audio && !audio.paused) {
                audio.pause();
            }
        });
    },
    methods: {
        getActions() {
            let actions = [];

            actions.push({
                icon: 'folder-open',
                text: this.$t('open'),
                action: this.getVoicemail,
                mode: 'primary',
            });

            actions.push({
                action: this.download,
                icon: 'cloud-download',
                text: this.$t('download'),
                mode: 'default',
            });

            return actions;
        },
        getParsedDuration(duration) {
            if (duration < 60) {
                return duration + " " + this.$t('sec');
            }

            let parsedDuration = duration / 60

            let parsedDurationDecimal = parsedDuration % 1;
            if (parsedDurationDecimal < 0.5) {
                parsedDuration = "<" + Math.ceil(parsedDuration);

            } else {
                parsedDuration = parseInt(parsedDuration + '')

            }

            return parsedDuration + " " + this.$t('min');
        },
        getLabels() {
            let labels = [];

            labels.push({
                type: "primary",
                icon: "clock",
                text: this.getParsedDuration(this.voicemail.duration) + " " + this.$t("duration"),
            })

            labels.push({
                type: "default",
                icon: "clock",
                text: this.getParsedReadingTime(this.voicemail.message) + " " + this.$t('min') + " " + this.$t("readTime"),
            })

            return labels;
        },
        getVoicemail() {
            let self = this;

            if (this.audioSource !== null) {
                UIkit.modal("#" + self.modalId).show();
                return;
            }

            axios.defaults.responseType = "arraybuffer";

            getDeviceByIdVoicemailsByVoicemailId({
                id: self.deviceId,
                voicemailId: self.voicemail.id,

            }).then(function (response) {
                axios.defaults.responseType = undefined;
                self.audioSource = window.URL.createObjectURL(new Blob([response.data]));
                UIkit.modal("#" + self.modalId).show();

            }).catch(function (e) {
                axios.defaults.responseType = undefined;
                self.checkGenericError(e);

            })
        },
        download() {
            event.stopPropagation();
            event.preventDefault();

            let self = this;

            axios.defaults.responseType = "arraybuffer";

            let fileName = self.$t('voicemailBy', {name: self.voicemail.remotePhoneNumber}) + self.voicemail.fileType;
            let fileLink = document.createElement('a');
            fileLink.setAttribute('download', fileName);

            if (this.audioSource !== null) {
                fileLink.href = this.audioSource;
                document.body.appendChild(fileLink);
                fileLink.click();
                return;
            }

            getDeviceByIdVoicemailsByVoicemailId({
                id: self.deviceId,
                voicemailId: self.voicemail.id,

            }).then(function (response) {
                axios.defaults.responseType = undefined;
                fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
                document.body.appendChild(fileLink);
                fileLink.click();

            }).catch(function (e) {
                axios.defaults.responseType = undefined;
                self.checkGenericError(e);

            })
        }
    }
}

</script>


<style lang="scss" scoped>

.phone-number {
    margin-top: 8px;
    font-size: 20px;
    font-weight: 500;
    text-overflow: ellipsis;
}

</style>
