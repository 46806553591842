import { render, staticRenderFns } from "./DeleteSubscriptionSatellite.vue?vue&type=template&id=4a605194&scoped=true&"
import script from "./DeleteSubscriptionSatellite.vue?vue&type=script&lang=js&"
export * from "./DeleteSubscriptionSatellite.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a605194",
  null
  
)

export default component.exports