<template>
    <div>

        <LoadingStruct :is-loading="loading">
            <GenericSiteTitle/>
            <GenericItemView
                :computed-items="computedLinks"
                :error="error"
                :is-loading="loading"
                :items="computedLinks"
            >

                <div
                    class="uk-child-width-1-3@l uk-child-width-1-2@m uk-child-width-1-1 uk-grid-match uk-margin-remove-top uk-grid-medium"
                    uk-grid>

                    <div v-for="(link, key) in computedLinks" :key="link.name">

                        <Card class="uk-card-hover uk-padding-remove-left" mode="default">

                            <LinkObject :to="link.route">
                                <div class="uk-text-center">
                                    <Icon :icon="link.icon" :ratio="1.8"/>
                                    <div class="link-name">
                                <span v-if="link.items">
                                    {{ link.items.length }}
                                    {{ $t(link.items.length !== 1 ? link.plural : link.singular) }}
                                </span>
                                        <span v-else>
                                    {{ $t(link.singular) }}
                                </span>

                                    </div>
                                </div>
                            </LinkObject>
                            <template v-slot:footer>

                                <div v-if="key === 0 && link.items.length===0">
                                    <span v-if="isDistributor">
                                        {{ $t('noDevicesMessageDistributor') }}
                                    </span>
                                    <span v-else>
                                        {{ $t('noDevicesMessage') }}
                                    </span>
                                    <DistributorStatus is-with-hint/>
                                </div>

                                <table class="uk-table uk-table-hover uk-table-divider">
                                    <tbody>
                                    <tr v-for="item in link.items" :key="item.id">
                                        <td>

                                            <span v-if="link.singular === 'device'">
                                                <LinkObject :to="'devices/'+item.id">

                                                    <Icon :icon="item.kindId=== 'COMtrexx' ? 'cloud' : 'server'"
                                                          :uk-tooltip="'title:'+item.kindId"
                                                          class="uk-margin-small-right"/>

                                                    <Label v-if="item.invited"
                                                           :tooltip="$t('invited.description')" class="cursor-pointer"
                                                           icon="mail"
                                                           type="primary"
                                                    />
                                                    <Label v-else-if="item.status === 'online'"
                                                           :tooltip="$t('Online')" class="cursor-pointer" icon="bolt"
                                                           type="success"
                                                    />
                                                    <Label v-else-if="item.status === 'offline'"
                                                           :tooltip="$t('Offline')" class="cursor-pointer"
                                                           icon="warning"
                                                           type="danger"
                                                    />
                                                    <Label v-else-if="item.status === 'withoutMonitoring'"
                                                           :tooltip="$t('WithoutMonitoring')" class="cursor-pointer"
                                                           icon="info"
                                                           type="default"
                                                    />
                                                    <Label v-else-if="item.status === 'withoutSatellite'"
                                                           :tooltip="$t('WithoutSatellite')" class="cursor-pointer"
                                                           icon="info"
                                                           type="default"
                                                    />
                                                    <Label v-else-if="item.status === 'warning'"
                                                           :tooltip="$t('warning')" class="cursor-pointer"
                                                           icon="warning"
                                                           type="warning"
                                                    />
                                                     <Label v-else-if="item.status === 'determine'"
                                                            :tooltip="$t('determine')" class="cursor-pointer"
                                                            icon="info"
                                                            type="default"
                                                     />

                                                    <span class="uk-margin-small-left">{{ item.description }}</span>

                                                </LinkObject>
                                            </span>

                                            <span v-else-if="link.singular === 'distributor'">
                                                <LinkObject :to="'organisations/'+item.id">

                                                    <Icon class="uk-margin-small-right" icon="social"/>

                                                    <Label :text="''+item.deviceIds.length"
                                                           :tooltip="item.deviceIds.length + ' ' + $t(item.deviceIds.length !== 1 ? 'devices' : 'device')"
                                                           class="cursor-pointer"
                                                           icon="server"
                                                           type="default"
                                                    />
                                                    <span class="uk-margin-small-left">{{ item.name }}</span>
                                                </LinkObject>
                                            </span>

                                        </td>
                                    </tr>
                                    </tbody>
                                </table>

                            </template>
                        </Card>

                    </div>


                    <div>
                        <div class="uk-child-width-1-1 uk-grid-match" uk-grid>
                            <div>
                                <Card class="uk-card-hover uk-padding-remove-left" mode="default">
                                    <div class="uk-text-center">
                                        <Icon :ratio="1.8" icon="commenting"/>
                                        <div class="link-name">
                                            {{ $t('news') }}
                                        </div>
                                    </div>

                                    <template v-slot:footer>
                                        <NewsText/>
                                    </template>
                                </Card>
                            </div>
                            <div>
                                <Card class="uk-card-hover uk-padding-remove-left" mode="default">
                                    <div class="uk-text-center">
                                        <Icon :ratio="1.8" icon="info"/>
                                        <div class="link-name">
                                            {{ $t('wiki') }}
                                        </div>
                                    </div>

                                    <template v-slot:footer>
                                        <table class="uk-table uk-table-hover uk-table-divider">
                                            <tbody>
                                            <tr v-for="item in wiki" :key="item.name">
                                                <td>
                                                    <LinkObject :to="'wiki#'+item.name">
                                                        <Icon :icon="item.icon" class="uk-margin-small-right"/>
                                                        <span class="uk-margin-small-left">{{ $t(item.name) }}</span>
                                                    </LinkObject>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </template>

                                </Card>
                            </div>
                        </div>
                    </div>

                </div>

            </GenericItemView>
        </LoadingStruct>

    </div>
</template>

<script>

import Card from "@/components/generic/Card";
import Icon from "@/components/generic/Icon";
import LinkObject from "@/components/LinkObject";
import Label from "@/components/Label";
import {
    getDeviceById,
    getDeviceByIdSatelliteModulesMonitoring,
    getUserByIdDevices,
    getUserByIdDistributors
} from "@/client/vue-api-client";
import GenericItemView from "@/views/GenericItemView";
import GenericSiteTitle from "@/views/GenericSiteTitle";
import LoadingStruct from "@/views/LoadingStruct";
import DistributorStatus from "@/components/DistributorStatus";
import NewsText from "@/assets/texts/de/News";
import Vue from "vue";

export default {
    name: 'Dashboard',
    components: {
        NewsText,
        DistributorStatus, LoadingStruct, GenericSiteTitle, GenericItemView, Label, LinkObject, Icon, Card
    },
    data() {
        return {
            loading: true,
            error: null,

            newsExample: {
                date: "2021-06-13T10:17:56.496Z",
                id: "1",
                media: "https://www.tk-telefoncall-gmbh.de/WebRoot/Store6/Shops/62658714/56B0/AC14/99E4/8160/1770/C0A8/2BB8/150E/PBX_Call_Assist_3_Lizenz.jpg",
                headline: "Heute startet der Launch von PBX Call Assist 4",
                message: "Am 15.06.2021 zum Launch der UCC-Lösung PBX Call Assist 4 startet Auerswald die Sales-Aktion Early Adopters. Sowohl Upgradelizenzen von PBX Call Assist 3 auf 4 als auch Userlizenzen für PBX Call Assist 4 bieten Sie bis Ende August vergünstigt an.",
            },

            links: [
                {
                    route: "devices",
                    icon: "server",
                    singular: "device",
                    plural: "devices",
                    items: [],
                },
                {
                    route: "distributors",
                    icon: "social",
                    singular: "distributor",
                    plural: "distributors",
                    items: [],
                },
            ],

            wiki: [
                {
                    icon: "bolt",
                    name: "monitoring",
                },
                {
                    icon: "receiver",
                    name: "vassist2",
                },
                {
                    icon: "history",
                    name: "backups",
                },
                {
                    icon: "dashboard",
                    name: "portal",
                },
                {
                    icon: "credit-card",
                    name: "subscriptions",
                },
                {
                    icon: "rss",
                    name: "satellites",
                },
                {
                    icon: "users",
                    name: "users",
                },
                {
                    icon: "lifesaver",
                    name: "support",
                },
                {
                    icon: "code",
                    name: "provisioning",
                }
            ],

        }
    },
    created() {
        this.getDistributors();
    },
    computed: {
        computedLinks() {
            let links = [];
            for (let i = 0, j = this.links.length; i < j; i++) {
                if (i === 0 || this.links[i].items.length) {
                    links.push(this.links[i]);
                }
            }

            return links;
        }
    },
    methods: {
        getDevices() {
            let self = this;

            getUserByIdDevices(
                {
                    id: self.getUserId,

                }
            ).then(function (response) {
                let items = response.data;
                items.reverse();
                self.links[0].items = items;

                for (let i = 0, j = items.length; i < j; i++) {
                    items[i]['invited'] = items[i].invites.includes(self.getUserEmail)
                    self.getDevice(i);
                }

                if (items.length === 0) {
                    self.loading = false;
                }

            }).catch(function (e) {
                self.checkGenericError(e);
                self.error = e;
                self.loading = false;

            })
        },
        getDevice(key) {
            let self = this;

            let item = self.links[0].items[key];

            getDeviceById({
                id: item.id,

            }).then(function (response) {
                let details = response.data;
                Vue.set(self.links[0].items[key], 'details', details)

                let requirementStatus= self.getRequirementStatusByDevice(details);
                if (!requirementStatus) {
                    self.getMonitoring(key);

                } else {
                    Vue.set(self.links[0].items[key], 'status', self.getDeviceStatus(details, null))

                    if (self.links[0].items.length - 1 === key) {
                        self.loading = false;
                    }
                }

            }).catch(function (e) {
                self.checkGenericError(e);
                self.error = e;
                self.loading = false;
            })
        },
        getMonitoring(key) {
            let self = this;

            let item = self.links[0].items[key];

            getDeviceByIdSatelliteModulesMonitoring({
                id: item.id,
                deviceEvents: true,

            }).then(function (response) {
                let monitoring = response.data;
                Vue.set(self.links[0].items[key], 'status', self.getDeviceStatus(item.details, monitoring))

                if (self.links[0].items.length - 1 === key) {
                    self.loading = false;
                }

            }).catch(function (e) {
                self.checkGenericError(e);
                self.error = e;
                self.loading = false;

            })
        },
        getDistributors() {
            let self = this;

            self.loading = true;
            getUserByIdDistributors({
                id: self.getUserId,

            }).then(function (response) {
                let items = response.data;

                let parsedItems = [];
                for (let i = 0, j = items.length; i < j; i++) {
                    if (items[i].confirmedAt) {
                        parsedItems.push(items[i]);
                    }
                }

                parsedItems.reverse();

                self.links[1].items = parsedItems;

                self.getDevices();

            }).catch(function (e) {
                self.checkGenericError(e);
                self.error = e;
                self.loading = false;

            })
        },
    },
}

</script>

<style lang="scss" scoped>

.link-name {
    margin-top: 8px;
    font-size: 17px;
}

.item-name {
    font-weight: 500;
}

.uk-table-hover > tr:hover,
.uk-table-hover tbody tr:hover {
    background: rgba(9, 38, 51, 0.05);
}

.uk-table td {
    padding: 0 !important;
}

</style>
