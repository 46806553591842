<template>
    <div class="register">

        <Seo :title="$t('register')"/>
        <SiteTitle :name="$t('registerMessage')" class="uk-text-center"/>
        <Description v-if="false" :text="$t('appDescription')" class="uk-text-center"/>

        <div v-if="registered" class="uk-animation-fade">
            <InfoBox>
                {{ $t('registerConfirmMessage') }}
            </InfoBox>

            <div class="uk-margin-medium-top"/>
            <LinkItem to="login">
                <Button :text="$t('gotoLogin')" is-large is-max mode="theme"/>
            </LinkItem>
        </div>
        <div v-else>

            <TextField v-model="name" :validate="trigger"
                       autofocus icon="user" label="foreAndSurname" name="name" rules="required|alpha_spaces"
                       @setValid="setNameValid"/>

            <div class="uk-margin-top"/>
            <TextField v-model="email" :validate="trigger"
                       icon="mail" label="emailAddress" name="email" rules="required|email" @setValid="setEmailValid"/>

            <div class="uk-margin-top"/>
            <TextField v-model="password" :onEnter="register" :validate="trigger" autocomplete="new-password"
                       hasPasswordMeter icon="lock" label="password" name="new-password" rules="required"
                       type="password" @setValid="setPasswordValid"/>

            <div class="uk-margin-top"/>
            <Checkbox v-model="confirmTerms" :validate="trigger"
                      label="confirmTermsAndConditionsMessage" name="terms" rules="required" @setValid="setTermsValid"/>


            <div v-if="registerError" class="uk-animation-fade">
                <div class="uk-margin-medium-top"/>
                <InfoBox mode="danger">
                    <ErrorMessage :custom-errors="[{code: 400, message: $t('registerFailedWhitelistMessage')}]"
                                  :error="registerError"/>
                </InfoBox>
            </div>

            <div class="uk-margin-medium-top"/>
            <Button :action="register" :is-loading="loading" :text="$t('register')" has-action is-large is-max
                    mode="theme"/>

            <div class="uk-margin-medium-top"/>
            <hr class="blur-fix"/>
            <div class="uk-margin-medium-top"/>


            <div class="uk-grid-small uk-grid-match uk-flex uk-flex-middle" uk-grid>
                <div class="uk-width-expand@s uk-width-1-1 hint">
                    {{ $t('accountLoginQuestion') }}
                </div>
                <div class="uk-width-auto@s uk-width-1-1 uk-text-center">
                    <LinkItem to="login">
                        <Button :text="$t('gotoLogin')" mode="default"/>
                    </LinkItem>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

import TextField from "@/components/generic/TextField";
import Button from "@/components/generic/Button";
import SiteTitle from "@/components/SiteTitle";
import LinkItem from "@/components/generic/LinkItem";
import InfoBox from "@/components/generic/InfoBox";
import {postRegister} from "@/client/vue-api-client";
import ErrorMessage from "@/components/generic/ErrorMessage";
import Seo from "@/components/generic/Seo";
import Description from "@/components/Description";
import Checkbox from "@/components/generic/Checkbox";

export default {
    name: 'Register',
    components: {Checkbox, Description, Seo, ErrorMessage, InfoBox, LinkItem, SiteTitle, Button, TextField},
    data() {
        return {
            name: "",
            email: "",
            password: "",
            registered: false,
            confirmTerms: false,

            nameValid: false,
            emailValid: false,
            passwordValid: false,
            termsValid: false,

            trigger: 0,
            loading: false,
            registerError: null,
        }
    },
    created() {
        if (this.getInvitedEmail) {
            this.email = this.getInvitedEmail;
        }
    },
    methods: {
        setNameValid(valid) {
            this.nameValid = valid;
        },
        setEmailValid(valid) {
            this.emailValid = valid;
        },
        setPasswordValid(valid) {
            this.passwordValid = valid;
        },
        setTermsValid(valid) {
            this.termsValid = valid;
        },
        register() {
            this.trigger++;

            if (!this.nameValid || !this.emailValid || !this.passwordValid || !this.termsValid) {
                return;
            }

            let self = this;
            self.loading = true;

            postRegister({
                user: {
                    name: this.name,
                    mailAddress: this.email,
                    password: this.password,
                },

            }).then(function () {
                self.registerError = null;
                self.registered = true;
                self.loading = false;

            }).catch(function (e) {
                self.checkGenericError(e);

                self.registerError = e;
                self.loading = false;

            })
        },
    },
}
</script>


<style lang="scss" scoped>

.hint {
    font-size: 15px;
}

@media (max-width: $breakpoint-xsmall-max) {
    .hint {
        justify-content: center;
    }
}

</style>
