<template>
    <div :class="generateClasses()">

        <div :class="isWithoutBody ? '' : 'uk-card-body'">
            <div v-if="title && title.length>0"
                 :class="'title' + (isDark ? ' dark' : '')+ (isCentered ? ' uk-text-center' : '')">
                {{ title }}

                <Icon v-if="info" :uk-tooltip="'title:'+info" icon="info"/>
            </div>

            <slot/>
        </div>

        <div v-if="$slots.labels">
            <slot name="labels"/>
        </div>

        <div v-if="$slots.footer" :class="'uk-card-footer '+footerClass">
            <slot name="footer"/>
        </div>

    </div>
</template>

<script>


import Icon from "./Icon";

export default {
    name: 'Card',
    components: {Icon},
    props: {
        title: String,
        info: String,
        mode: String,
        isDark: Boolean,
        footerClass: String,
        status: String,
        isBlank: Boolean,
        isCentered: Boolean,
        isWithoutBody: Boolean,
    },
    methods: {
        generateClasses() {
            let classes = "";

            if (this.isCentered) {
                classes += "uk-text-center";
            }

            if (this.isBlank) {
                return classes;
            }

            classes += ' card uk-card uk-card-' + this.mode;

            if (this.status) {
                classes += ' status-' + this.status
            }

            return classes
        },
    }
}

</script>


<style lang="scss" scoped>

.uk-card {
    border-radius: $border-radius;
}

.title {
    margin-bottom: 20px;
    text-align: left;
    font-size: 18px;
    font-weight: 500;
    //color: $theme-color-hover;
    color: $site-color-hover;

    &.dark {
        color: $site-color-hover;
    }
}

.uk-card-warning {
    background: $warning-color;
    color: $theme-color-hover;
}

.uk-card-success {
    background: $success-color;
    color: $theme-color-hover;
}

.uk-card-danger {
    background: $danger-color;
    color: $theme-color-hover;
}

@media (max-width: $breakpoint-xsmall-max) {
    .uk-card-body {
        padding: 25px;
    }
}

.uk-card-line {
    background: transparent;
    border: 1px solid $border-color;
}

.uk-card-add {
    background: transparent;
    border: 1px dashed #aaa;
    color: #666;
}

.uk-card-default {
    color: $site-color !important;
}

.uk-card-striped {
    background: $striped-color;
    color: $site-color;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
}

.status-success {
    box-shadow: 0 5px 15px -5px rgba(50, 210, 150, 0.6) !important;

    &:hover {
        box-shadow: 0 14px 25px -5px rgb(50, 210, 150, 0.8) !important;
    }
}

.status-danger {
    box-shadow: 0 5px 15px -5px rgba(240, 80, 110, 0.6) !important;

    &:hover {
        box-shadow: 0 14px 25px -5px rgba(240, 80, 110, 0.8) !important;
    }
}

.uk-card-footer {
    padding: 15px 15px !important;
}

</style>