<template>
    <GenericCreate
        :action="create" :action-custom-errors="[{code: 400, message: $t('authenticationCodeError')}]"

        :action-error="error" :action-label="$t('confirm')"

        :action-loading="loading"
        :button-action="init"

        :button-text="$t('activate')"
        :description="$t('activateTwoFactorAuthenticationMessage') + '<br/><br/>'+$t('recoveryCodesMessage')"
        :icon-tooltip="$t('activateTwoFactorAuthentication')" :is-button-round="false"
        :modal-id="modalId"

        :success-description="$t('twoFactorAuthenticationActivatedSuccess')"
        :success-title="$t('twoFactorAuthenticationActivated')"

        :title="$t('activateTwoFactorAuthentication')" :trigger="trigger" icon="plus"
    >
        <div class="uk-margin-bottom uk-text-center">
            <div v-if="initializing" class="">
                <div class="init-struct uk-flex uk-flex-center uk-flex-middle" uk-spinner/>
            </div>
            <img v-else :src="initOtp.image" height="200" width="200"/>
        </div>

        <span v-for="(inputField, key) in textFields" :key="inputField.name">
                <TextArea v-if="inputField.type==='textarea'"

                          v-model="inputField.value"
                          :action="inputField.action"
                          :autofocus="inputField.autofocus"
                          :field-key="key"
                          :icon="inputField.icon"
                          :is-disabled="inputField.disabled"
                          :is-loading="inputField.loading"
                          :label="inputField.label"
                          :name="inputField.name"
                          :rules="inputField.rules"
                          :tooltip="inputField.tooltip"
                          :type="inputField.type"
                          :validate="trigger"
                          @setValid="setValid"
                />
                <TextField v-else
                           v-model="inputField.value" :autocomplete="inputField.autocomplete"
                           :autofocus="inputField.autofocus" :field-key="key"
                           :hasPasswordMeter="inputField.hasPasswordMeter"
                           :icon="inputField.icon"
                           :label="inputField.label"
                           :name="inputField.name"
                           :onEnter="create"
                           :rules="inputField.rules"
                           :type="inputField.type"
                           :validate="trigger"
                           @setValid="setValid"
                />
                <div v-if="key !== textFields.length -1" class="uk-margin-bottom"/>
            </span>


    </GenericCreate>
</template>

<script>

import GenericCreate from "./GenericCreate";
import {postUserByIdOtpFinalize, postUserByIdOtpInit} from "@/client/vue-api-client";
import TextField from "../generic/TextField";
import UIkit from 'uikit'
import TextArea from "@/components/TextArea";

export default {
    name: 'CreateTwoFactorAuthentication',
    components: {
        TextArea,
        TextField,
        GenericCreate,
    },
    props: {
        userId: String,
    },
    data() {
        return {
            modalId: "modal-create-two-factor-authentication-" + this.userId,
            trigger: 0,

            textFields: [
                {
                    value: "",
                    valid: true,

                    autofocus: false,
                    name: "recoveryCodes",
                    label: "recoveryCodes",
                    rules: "required",
                    icon: "commenting",
                    loading: this.initializing,

                    type: "textarea",
                    disabled: true,
                    tooltip: this.$t('copyToClipboard'),
                    action: this.copyRecoveryCodes,
                },
                {
                    value: "",
                    valid: false,

                    autofocus: true,
                    name: "authenticationCode",
                    label: "authenticationCode",
                    rules: "required",
                    icon: "lock",
                },
            ],

            initializing: true,
            loading: false,
            error: null,
            itemName: "",

            initOtp: {
                image: "",
                recoveryKeys: [],
            }
        }
    },
    methods: {
        copyRecoveryCodes() {
            this.$copyText(this.textFields[0].value);
        },
        setValid(valid, fieldKey) {
            this.textFields[fieldKey].valid = valid;
        },
        init() {
            let self = this;

            if (self.initOtp.image.length > 0) {
                return;
            }

            postUserByIdOtpInit({
                id: self.userId,

            }).then(function (response) {
                self.initOtp = response.data;
                self.textFields[0].value = self.initOtp.recoveryKeys.join('\n' + '');
                self.initializing = false;

            }).catch(function (e) {
                self.checkGenericError(e);

                self.error = e;
                self.initializing = false;

            })
        },
        create() {
            this.trigger++;

            for (let i = 0, j = this.textFields.length; i < j; i++) {
                if (!this.textFields[i].valid) {
                    return;
                }
            }

            let self = this;
            self.loading = true;

            postUserByIdOtpFinalize({
                id: self.userId,
                totp: {
                    totp: (self.textFields[1].value).trim(),
                }

            }).then(function () {
                self.itemName = self.textFields[0].value;

                for (let i = 0, j = self.textFields.length; i < j; i++) {
                    self.textFields[i].value = "";
                    self.textFields[i].valid = false;
                }

                self.loading = false;

                UIkit.modal("#" + self.modalId + "-success").show();
                self.$emit('afterSuccess');

            }).catch(function (e) {
                self.checkGenericError(e);

                self.error = e;
                self.loading = false;

            })
        },

    }
}

</script>

<style lang="scss" scoped>

.init-struct {
    height: 200px;
}

</style>
