<template>
    <div>

        <LoadingStruct :is-loading="devicesLoading">
            <GenericSiteTitle :title-poperties="[{key: 1, name: distributor.name, tooltip: $t('distributor')}]"/>
            <Tabs :tabs="tabs">

                <li class="active">
                    <GenericItemView
                        :computed-items="computedDevices"
                        :error="devicesError"
                        :filters="getDeviceFilters()"
                        :is-loading="devicesLoading"
                        :is-search="searchDevices.length>0"
                        :items="devices"
                        :no-items-message="$t('noDevicesCreatedMessage')"
                        hide-divider
                        is-searchable
                        label-plural="devices"
                        @search="setSearchDevices"
                    >
                        <template v-slot:action>
                            <CreateDevice :distributor-id="distributorId" @afterSuccess="getDistributorById"/>
                        </template>

                        <template v-slot:items>
                <li v-for="item in computedDevices" :key="item.id" :data-status="item.status">
                    <DeviceItem :device="item" from-distributor/>
                </li>
</template>

</GenericItemView>
</li>

<li>
<GenericItemView
    :computed-items="computedSatellites"
    :error="satellitesError"
    :filters="getSatellitesFilters()"
    :is-loading="devicesLoading || satellitesLoading"
    :is-search=" searchSatellites.length>0"
    :items="satellites"
    :no-items-message="$t('satellite.distributor.description')"
    hide-divider
    is-searchable
    label-plural="satellites"
    @search="setSearchSatellites"
>
    <template v-slot:items>
<li v-for="item in computedSatellites" :key="item.id"
    :data-expiry="item.expiry"
    :data-status="item.status"
>
<SatelliteItem :devices="devices" :item="item" @afterSuccess="getDistributorById"/>
</li>
</template>

</GenericItemView>
</li>

<li>
<GenericItemView
    :computed-items="computedSubscriptions"
    :error="errorSubscriptions"
    :filters="getSubscriptionFilters()"
    :is-loading="subscriptionsLoading || satellitesLoading"
    :is-search=" searchSubscriptions.length>0"
    :items="subscriptions"
    :no-items-message="$t('subscriptions.notavailable')"
    hide-divider
    is-searchable
    label-plural="subscriptions"
    @search="setSearchSubscriptions"
>
    <template v-slot:action>
        <CreateSubscriptionRequest :distributorId="distributorId"/>
    </template>

    <template v-slot:items>
<li v-for="item in computedSubscriptions" :key="item.id"
    :data-expiry="item.expiry" :data-status="item.status">
<SubscriptionItem :item="item" :satellites="satellites" @afterSuccess="getDistributorById"/>
</li>
</template>

</GenericItemView>
</li>


<li class="active">
<GenericItemView
    :error="error"
    :is-loading="loading"
    hide-divider
>

    <div
        class="uk-child-width-1-3@xl uk-child-width-1-2@m uk-child-width-1-1 uk-grid-match uk-margin-remove-top uk-grid-medium"
        uk-grid>

        <div>
            <Card :title="$t('data')" mode="default">
                <TextField v-model="distributor.name" disabled icon="hashtag" label="name"
                           name="distributor-name"/>

                <div class="uk-margin-top"/>
                <TextField :value="getFormattedDateByDateString(distributor.createdAt)" disabled icon="calendar"
                           label="createdAt" name="created-at"/>
            </Card>
        </div>

        <div>
            <Card :title="$t('owner')" mode="default">
                <InfoBox borderless mode="theme">
                    <div class="owner-name">
                        {{ owner.name }}
                    </div>
                    <div>
                        {{ owner.mailAddress }}
                    </div>
                </InfoBox>
            </Card>
        </div>

        <div v-if="false">
            <Card :title="$t('logo')" mode="default">
                <span v-html="$t('logoMessage')"/>

                <div class="uk-margin-medium-top"/>
                <div class="uk-text-center">
                    <Icon :ratio="4.0" icon="image"/>
                    <div v-html="$t('noLogo')"/>
                </div>

                <div class="uk-margin-medium-top"/>
                <CreateLogo/>
            </Card>
        </div>

    </div>

</GenericItemView>
</li>


</Tabs>
</LoadingStruct>

</div>
</template>

<script>

import Tabs from "@/components/Tabs";
import TextField from "@/components/generic/TextField";
import {
    getDeviceById,
    getDeviceByIdSatelliteModulesMonitoring,
    getDistributorById,
    getDistributorByIdDevices,
    getDistributorByIdSatellites,
    getDistributorByIdSubscriptions,
    getSatelliteById,
    getUserById
} from "@/client/vue-api-client";
import Card from "@/components/generic/Card";
import Icon from "@/components/generic/Icon";
import DeviceItem from "@/components/items/DeviceItem";
import CreateDevice from "@/components/creates/CreateDevice";
import CreateSubscriptionRequest from "@/components/creates/CreateSubscriptionRequest";
import SubscriptionItem from "@/components/items/SubscriptionItem";
import GenericSiteTitle from "@/views/GenericSiteTitle";
import GenericItemView from "@/views/GenericItemView";
import CreateLogo from "@/components/creates/CreateLogo";
import InfoBox from "@/components/generic/InfoBox";
import LoadingStruct from "@/views/LoadingStruct";
import SatelliteItem from "@/components/items/SatellitItem";
import Vue from "vue";

export default {
    name: 'Distributor',
    components: {
        SatelliteItem,
        LoadingStruct,
        InfoBox,
        CreateLogo,
        GenericItemView,
        GenericSiteTitle,
        SubscriptionItem,
        CreateSubscriptionRequest,
        CreateDevice,
        DeviceItem,
        Icon,
        Card,
        TextField, Tabs,
    },
    data() {
        return {
            loading: true,
            subscriptionsLoading: true,
            devicesLoading: true,
            satellitesLoading: true,

            error: null,
            errorSubscriptions: null,
            devicesError: null,
            satellitesError: null,

            tabs: ['devices', 'logger', 'subscriptions', 'infos'],

            distributorId: null,

            devices: [],
            subscriptions: [],
            satellites: [],
            distributor: {
                confirmedAt: "",
                createdAt: "",
                id: "",
                name: "",
                ownerId: "",
            },

            owner: {
                createdAt: "",
                id: "",
                mailAddress: "",
                name: "",
                permittedAt: "",
                roleId: "",
            },

            searchDevices: "",
            searchSubscriptions: "",
            searchSatellites: "",
        }
    },
    created() {
        this.distributorId = this.$route.params.distributorId;
        this.getDistributorById();
    },
    computed: {
        computedDevices() {
            let items = this.devices;
            if (this.searchDevices && this.searchDevices.length) {
                items = this.powerSearcher(items, this.searchDevices);
            }

            if (items.length > this.defaultShowLimit) {
                return items.slice(0, this.defaultShowLimit);
            }

            return items;
        },
        computedSubscriptions() {
            let items = this.subscriptions;
            if (this.searchSubscriptions && this.searchSubscriptions.length) {
                items = this.powerSearcher(items, this.searchSubscriptions);
            }

            if (items.length > this.defaultShowLimit) {
                return items.slice(0, this.defaultShowLimit);
            }

            return items;
        },
        computedSatellites() {
            let items = this.satellites;
            if (this.searchSatellites && this.searchSatellites.length) {
                items = this.powerSearcher(items, this.searchSatellites);
            }

            if (items.length > this.defaultShowLimit) {
                return items.slice(0, this.defaultShowLimit);
            }

            return items;
        },
    },
    methods: {
        softUpdate(item) {
            for (let i = 0, j = this.satellites.length; i < j; i++) {
                if (this.satellites[i].id === item.id) {
                    Vue.set(this.satellites, i, item);
                    Vue.set(this.satellites[i], "status", this.getSatelliteStatus(item));
                    Vue.set(this.satellites[i], "expiry", this.getExpiryStatus(item));
                    break;
                }
            }
        },
        setSearchDevices(text) {
            this.searchDevices = text;
        },
        setSearchSubscriptions(text) {
            this.searchSubscriptions = text;
        },
        setSearchSatellites(text) {
            this.searchSatellites = text;
        },
        getDeviceFilters() {
            let filters = [
                {
                    type: 'status',
                    values: [
                        {name: 'withoutSatellite', total: 0},
                        {name: 'withoutMonitoring', total: 0},
                        {name: 'offline', total: 0},
                        {name: 'warning', total: 0},
                        {name: 'determine', total: 0},
                        {name: 'online', total: 0},
                    ]
                },
            ];

            return filters;
        },
        getSubscriptionFilters() {
            let filters = [
                {
                    type: 'status',
                    values: [
                        {name: 'available', total: 0},
                        {name: 'used', total: 0},
                    ]
                },
                {
                    type: 'expiry',
                    values: [
                        {name: 'valid', total: 0},
                        {name: 'expired', total: 0},
                    ]
                },
            ];

            return filters;
        },
        getSatellitesFilters() {
            let filters = [
                {
                    type: 'status',
                    values: [
                        {name: 'unassigned', total: 0},
                        {name: 'assigned', total: 0},
                    ]
                },
                {
                    type: 'expiry',
                    values: [
                        {name: 'expired', total: 0},
                        {name: 'expiring', total: 0},
                        {name: 'valid', total: 0},
                    ]
                },
            ];

            return filters;
        },
        getDistributorById() {
            let self = this;

            getDistributorById({
                id: self.distributorId,

            }).then(function (response) {
                self.distributor = response.data;
                self.getUser();
                self.getDevices();
                self.getSubscriptions();
                self.getSatellites();
                self.loading = false;

            }).catch(function (e) {
                self.checkGenericError(e);
                self.error = e;
                self.devicesError = e;
                self.errorSubscriptions = e;

                self.loading = false;
                self.devicesLoading = false;
                self.subscriptionsLoading = false;

            })

        },
        getUser() {
            let self = this;

            getUserById({
                id: self.distributor.ownerId,

            }).then(function (response) {
                self.owner = response.data;

            }).catch(function (e) {
                self.error = e;
                self.checkGenericError(e);

            })
        },
        getDevices() {
            let self = this;

            getDistributorByIdDevices({
                id: self.distributorId,

            }).then(function (response) {
                let items = response.data;
                items.reverse();
                self.devices = items;

                for (let i = 0, j = items.length; i < j; i++) {
                    items[i]['invited'] = items[i].invites.includes(self.getUserEmail)
                    self.getDevice(i);
                }

                if (items.length === 0) {
                    self.devicesLoading = false;
                }

            }).catch(function (e) {
                self.checkGenericError(e);
                self.devicesError = e;
                self.devicesLoading = false;

            })
        },
        getDevice(key) {
            let self = this;

            let item = this.devices[key];

            getDeviceById({
                id: item.id,

            }).then(function (response) {
                let details = response.data;
                Vue.set(self.devices[key], 'details', details)

                let requirementStatus = self.getRequirementStatusByDevice(details);
                if (!requirementStatus) {
                    self.getMonitoring(key);

                } else {
                    Vue.set(self.devices[key], 'status', self.getDeviceStatus(details, null))

                    if (self.devices.length - 1 === key) {
                        self.devicesLoading = false;
                    }

                }

            }).catch(function (e) {
                self.checkGenericError(e);
                self.devicesLoading = false;
                self.devicesError = e;

            })
        },
        getMonitoring(key) {
            let self = this;

            let item = this.devices[key];

            getDeviceByIdSatelliteModulesMonitoring({
                id: item.id,
                deviceEvents: true,

            }).then(function (response) {
                let monitoring = response.data;
                Vue.set(self.devices[key], 'status', self.getDeviceStatus(item.details, monitoring))

                if (self.devices.length - 1 === key) {
                    self.devicesLoading = false;
                }

            }).catch(function (e) {
                self.checkGenericError(e);
                self.devicesLoading = false;
                self.devicesError = e;

            })
        },
        softUpdateSubscription(item) {
            for (let i = 0, j = this.subscriptions.length; i < j; i++) {
                if (this.subscriptions[i].id === item.id) {
                    Vue.set(this.subscriptions, i, item);
                    Vue.set(this.subscriptions[i], "available", this.subscriptions[i]['quota'] - this.subscriptions[i]['satelliteIds'].length);
                    Vue.set(this.subscriptions[i], "expiry", this.getSubscriptionStatus(this.subscriptions[i]));
                    Vue.set(this.subscriptions[i], "status", this.subscriptions[i]['available'] === 0 ? 'used' : 'available');
                    break;
                }
            }
        },
        getSubscriptions() {
            let self = this;

            getDistributorByIdSubscriptions({
                id: self.distributorId,

            }).then(function (response) {
                let items = response.data;

                for (let i = 0, j = items.length; i < j; i++) {
                    items[i]['available'] = items[i]['quota'] - items[i]['satelliteIds'].length;
                    items[i]['expiry'] = self.getSubscriptionStatus(items[i]);
                    items[i]['status'] = self.getAvailableStatus(items[i]);
                }

                items.reverse();

                self.subscriptions = items;
                self.subscriptionsLoading = false;

            }).catch(function (e) {
                self.checkGenericError(e);
                self.errorSubscriptions = e;
                self.subscriptionsLoading = false;

            })
        },
        getExpiryStatus(item) {
            let daysLeft = this.getDaysLeftByDateString(item.emergency.expiresAt);
            if (daysLeft < 0) {
                return "expired"
            }

            if (daysLeft < 60) {
                return "expiring"
            }

            return 'valid';
        },
        getSatelliteStatus(item) {
            if (!item.deviceId) {
                return 'unassigned';
            }

            return 'assigned';
        },
        getDeviceById(id) {
            for (let i = 0, j = this.devices.length; i < j; i++) {
                if (id === this.devices[i].id) {
                    return this.devices[i];
                }
            }
        },
        getSatellites() {
            let self = this;
            getDistributorByIdSatellites({
                id: self.distributorId,

            }).then(function (response) {
                let items = response.data;
                items.reverse();
                self.satellites = items;

                for (let i = 0, j = self.satellites.length; i < j; i++) {
                    Vue.set(self.satellites[i], 'status', self.getSatelliteStatus(items[i]));
                    Vue.set(self.satellites[i], 'expiry', self.getExpiryStatus(items[i]));

                    let device = self.getDeviceById(self.satellites[i].deviceId);
                    if (device) {
                        Vue.set(self.satellites[i], 'deviceName', device.kindId);
                    }

                    self.getSatellite(i);
                }

                if (self.satellites.length === 0) {
                    self.satellitesLoading = false;
                }

            }).catch(function (e) {
                self.checkGenericError(e);
                self.satellitesError = e;
                self.satellitesLoading = false;

            })
        },
        getSatellite(key) {
            let self = this;
            getSatelliteById({
                id: self.satellites[key].id,

            }).then(function (response) {
                Vue.set(self.satellites[key], 'details', response.data);

                if (self.satellites.length - 1 === key) {
                    self.satellitesLoading = false;
                }

            }).catch(function (e) {
                self.checkGenericError(e);
                self.satellitesError = e;
                self.satellitesLoading = false;

            })
        },
    }
}

</script>

<style lang="scss" scoped>

.owner-name {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 5px;
}

</style>
