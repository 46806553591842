<template>
    <div>

        <LoadingStruct :is-loading="loading">
            <GenericSiteTitle>
                <template v-slot:action>
                    <CreateDistributor @afterSuccess="getItems"/>
                </template>
            </GenericSiteTitle>

            <GenericItemView
                :computed-items="computedItems"
                :error="error"
                :is-loading="loading"
                :is-search="search.length>0"
                :items="items"
                :no-items-message="$t('noDistributorsCreatedMessage')"
                is-searchable
                label-plural="distributors"
                @search="setSearch"
            >
                <template v-slot:items>
                    <li v-for="item in computedItems" :key="item.id"
                        :data-role="item.role" :data-status="item.status">
                        <DistributorItem :distributor="item" user-view/>
                    </li>
                </template>
            </GenericItemView>
        </LoadingStruct>

    </div>
</template>

<script>

import {getUserByIdDistributors} from "@/client/vue-api-client";
import DistributorItem from "@/components/items/DistributorItem";
import CreateDistributor from "@/components/creates/CreateDistributor";
import GenericItemView from "@/views/GenericItemView";
import GenericSiteTitle from "@/views/GenericSiteTitle";
import LoadingStruct from "@/views/LoadingStruct";

export default {
    name: 'Distributors',
    components: {
        LoadingStruct,
        GenericSiteTitle,
        GenericItemView, CreateDistributor, DistributorItem
    },
    data() {
        return {
            loading: true,
            error: null,

            items: [],
            search: "",
        }
    },
    created() {
        this.getItems();
    },
    computed: {
        computedItems() {
            let items = this.items;
            if (this.search && this.search.length) {
                items = this.powerSearcher(items, this.search);
            }

            if (items.length > this.defaultShowLimit) {
                return items.slice(0, this.defaultShowLimit);
            }

            return items;
        },
    },
    methods: {
        setSearch(text) {
            this.search = text;
        },
        getItems() {
            let self = this;

            getUserByIdDistributors({
                id: self.getUserId,

            }).then(function (response) {
                let items = response.data;

                items = items.sort(function (a, b) {
                    if (a['confirmedAt'] !== undefined && b['confirmedAt'] === undefined) {
                        return -1;
                    } else if (b['confirmedAt'] !== undefined && a['confirmedAt'] === undefined) {
                        return 1;

                    } else if (b['confirmedAt'] === undefined && a['deniedAt'] === undefined) {
                        return -1;

                    }

                    return 0;
                });

                self.items = items;
                self.loading = false;

            }).catch(function (e) {
                self.checkGenericError(e);
                self.error = e;
                self.loading = false;

            })

        },
    }
}

</script>

<style lang="scss" scoped>

</style>
