<template>
    <div>
        <div v-if="isLoading">
            <div :class="'uk-height-small uk-flex uk-flex-center uk-flex-middle' + (isDark ? '' : ' spinner')"
                 uk-spinner/>
            <div v-if="loadingMessage" class="message uk-text-center">{{ loadingMessage }}</div>
        </div>
        <div v-else
             :class="isAnimationDisabled? '' : 'uk-animation-fade'">
            <slot/>
        </div>
    </div>
</template>

<script>

export default {
    name: 'LoadingStruct',
    props: {
        isLoading: Boolean,
        loadingMessage: String,
        isDark: Boolean,
        isAnimationDisabled: Boolean,
    },
}

</script>

<style lang="scss" scoped>

.spinner {
    color: $site-background;
}

.message {
    color: $site-background;
    margin-top: -20px;
}

</style>
