<template>
    <div>
        <!-- Veränderungen ab hier -->

        {{ $t('inDevelopmentMessage') }}

        <!-- Veränderungen bis hier -->
    </div>
</template>

<script>

export default {
    name: 'SattelitesText',
}
</script>

<style lang="scss" scoped>

</style>
