<template>
    <InfoCard icon="warning" type="danger">
        {{ $t('unexpectedErrorMessage') }}

        <div class="uk-margin"/>
        <Button :action="reload" :text="$t('tryAgain')" has-action mode="success"/>
    </InfoCard>
</template>

<script>

import InfoCard from "@/components/generic/InfoCard";
import Button from "@/components/generic/Button";

export default {
    name: 'GenericError',
    components: {Button, InfoCard},
    props: {
        error: Error,
    }
}

</script>

<style lang="scss" scoped>

</style>
