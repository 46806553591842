<template>
    <span :class="'icon'+(mode ? ' uk-text-'+mode : '')">
        <span :class="classGenerator()" :uk-icon="'icon: ' + icon + '; ratio: ' + ratio"/>
    </span>
</template>

<script>
export default {
    name: 'Icon',
    props: {
        icon: String,
        ratio: {
            type: Number,
            default: 1.0
        },
        mode: String,
        disabled: Boolean,
    },
    methods: {
        classGenerator() {
            let classes = "";

            if (this.disabled) {
                classes += "disabled ";
            }

            return classes
        }
    },
}
</script>

<style lang="scss" scoped>

.disabled {
    color: #999;
    transition: color 0.25s linear;
}

</style>
