<template>
    <GenericCreate
        :action="update" :action-error="error" :action-label="$t('save.changes')"

        :action-loading="loading"

        :modal-id="modalId"
        :success-description="$t('cost.centres.changed.message')"
        :success-title="$t('cost.centres.changed')" :title="$t('cost.centres')"

        :trigger="trigger"

        icon="bag"
        modal-is-medium
    >

        <div class="uk-grid-collapse" uk-grid>
            <div>
                <div uk-grid>
                    <div class="unified-width">
                        {{ $t('participant') }}:
                    </div>
                    <div>
                        <span class="uk-label">100</span>
                    </div>
                </div>

                <div class="uk-margin-small-top" uk-grid>
                    <div class="unified-width">
                        {{ $t('general.salary') }}:
                    </div>
                    <div>
                        <span class="uk-label">0,49 €</span>
                    </div>
                </div>
            </div>
        </div>

        <table class="uk-table uk-table-hover uk-table-divider">
            <thead>
            <tr>
                <th>
                    {{ $t('number') }}
                </th>
                <th>
                    {{ $t('salary') }}
                </th>
                <th>
                    {{ $t('active') }}
                </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="costCentre in costCentres" :key="costCentre.number">
                <td>
                    {{ costCentre.number }}
                </td>
                <td>
                    {{ costCentre.salary }}
                </td>
                <td>
                    <SwitchButton :value="costCentre.active"/>
                </td>
            </tr>
            </tbody>
        </table>


    </GenericCreate>
</template>

<script>

import GenericCreate from "@/components/creates/GenericCreate";
import UIkit from "uikit";
import SwitchButton from "@/components/generic/SwitchButton";

export default {
    name: 'UpdateCostCentres',
    components: {SwitchButton, GenericCreate,},
    props: {
        deviceId: String,
    },
    data() {
        return {
            trigger: 0,
            modalId: "modal-update-cost-centres-" + this.deviceId,

            textFields: [],

            loading: false,
            error: null,

            costCentres: [
                {
                    number: "+30",
                    salary: "0,19 €",
                    active: false,
                },
                {
                    number: "+49160",
                    salary: "0,99 €",
                    active: true,
                },
                {
                    number: "+07761",
                    salary: "0,19 €",
                    active: false,
                },
            ],
        }
    },
    methods: {
        setCardValid(valid) {
            this.textFields[0].valid = valid;
        },
        update() {
            this.trigger++;

            for (let i = 0, j = this.textFields.length; i < j; i++) {
                if (!this.textFields[i].valid) {
                    return;
                }
            }

            let self = this;
            self.loading = true;


            self.loading = false;
            UIkit.modal("#" + self.modalId + "-success").show();
            self.$emit('afterSuccess');
        },
    }
}

</script>

<style lang="scss" scoped>

.unified-width {
    width: 150px;
}

</style>
