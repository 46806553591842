<template>
    <GenericCreate
        :action="update" :action-error="error" :action-label="$t('bill')"

        :action-loading="loading"

        :modal-id="modalId"
        :success-description="$t('bill.created.message')"
        :success-title="$t('bill.created')" :title="$t('bill')"

        :trigger="trigger"

        icon="credit-card"
        modal-is-medium
    >

        <div class="uk-grid-collapse" uk-grid>
            <div>
                <div uk-grid>
                    <div class="unified-width">
                        {{ $t('participant') }}:
                    </div>
                    <div>
                        <span class="uk-label">100</span>
                    </div>
                </div>

                <div class="uk-margin-small-top" uk-grid>
                    <div class="unified-width">
                        {{ $t('participant.name') }}:
                    </div>
                    <div>
                        <span class="uk-label">Hr. aus den Erlen</span>
                    </div>
                </div>

                <div class="uk-margin-small-top" uk-grid>
                    <div class="unified-width">
                        {{ $t('participant.number') }}:
                    </div>
                    <div>
                        <span class="uk-label">+49 776 19379002</span>
                    </div>
                </div>
            </div>
        </div>


        <table class="uk-table uk-table-hover uk-table-divider">
            <tbody>
            <tr v-for="call in calls" :key="call.number">
                <td>
                    {{ call.date }}
                </td>
                <td>
                    {{ call.tn }}
                </td>
                <td>
                    <Icon icon="chevron-right"/>
                </td>
                <td>
                    {{ call.number }}
                </td>
                <td>
                    {{ call.from }}
                </td>
                <td>
                    {{ call.until }}
                </td>
                <td>
                    {{ call.duration }}
                </td>
                <td>
                    {{ call.costs }}
                </td>
            </tr>
            <tr>
                <td/>
                <td/>
                <td/>
                <td/>
                <td/>
                <td>
                    <b>Summe</b>
                </td>
                <td>
                    <b>9 Minuten</b>
                </td>
                <td>
                    <b>0,17 €</b>
                </td>
            </tr>
            </tbody>
        </table>


        <div class="uk-margin-large-top"/>
        <b>{{ $t('invoices') }}</b>
        <div class="uk-margin-small-bottom"/>
        <div class="uk-panel uk-panel-scrollable">
            <table class="uk-table uk-table-hover uk-table-divider">
                <tbody>
                <tr v-for="(invoice, key) in invoices" :key="key">
                    <td>
                        {{ invoice.date }}
                    </td>
                    <td>
                        {{ invoice.time }}
                    </td>
                    <td>
                        {{ invoice.costs }}
                    </td>
                    <td>
                        <Icon class="cursor-pointer" icon="file-pdf" :ratio="1.5"/>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

    </GenericCreate>
</template>

<script>

import GenericCreate from "@/components/creates/GenericCreate";
import UIkit from "uikit";
import Icon from "@/components/generic/Icon";

export default {
    name: 'UpdateBill',
    components: {Icon, GenericCreate,},
    props: {
        deviceId: String,
    },
    data() {
        return {
            trigger: 0,
            modalId: "modal-update-bill-" + this.deviceId,

            textFields: [],

            loading: false,
            error: null,

            calls: [
                {
                    date: "01.07.2022",
                    tn: "100",
                    number: "+491602606873",
                    from: "18:03 Uhr",
                    until: "18:07 Uhr",
                    duration: "4 Minuten",
                    costs: "0,08 €",
                },
                {
                    date: "01.07.2022",
                    tn: "100",
                    number: "+4977619379002",
                    from: "18:31 Uhr",
                    until: "18:36 Uhr",
                    duration: "5 Minuten",
                    costs: "0,09 €",
                },
            ],

            invoices: [
                {
                    date: "01.06.2022",
                    time: "22:00:57 Uhr",
                    costs: "7,08 €",
                },
                {
                    date: "01.06.2022",
                    time: "22:00:57 Uhr",
                    costs: "7,08 €",
                },
                {
                    date: "01.06.2022",
                    time: "22:00:57 Uhr",
                    costs: "7,08 €",
                },
                {
                    date: "01.06.2022",
                    time: "22:00:57 Uhr",
                    costs: "7,08 €",
                },
                {
                    date: "01.06.2022",
                    time: "22:00:57 Uhr",
                    costs: "7,08 €",
                },
                {
                    date: "01.06.2022",
                    time: "22:00:57 Uhr",
                    costs: "7,08 €",
                },
                {
                    date: "01.06.2022",
                    time: "22:00:57 Uhr",
                    costs: "7,08 €",
                },
                {
                    date: "01.06.2022",
                    time: "22:00:57 Uhr",
                    costs: "7,08 €",
                },
                {
                    date: "01.06.2022",
                    time: "22:00:57 Uhr",
                    costs: "7,08 €",
                },
                {
                    date: "01.06.2022",
                    time: "22:00:57 Uhr",
                    costs: "7,08 €",
                },
            ],
        }
    },
    methods: {
        setCardValid(valid) {
            this.textFields[0].valid = valid;
        },
        update() {
            this.trigger++;

            for (let i = 0, j = this.textFields.length; i < j; i++) {
                if (!this.textFields[i].valid) {
                    return;
                }
            }

            let self = this;
            self.loading = true;


            self.loading = false;
            UIkit.modal("#" + self.modalId + "-success").show();
            self.$emit('afterSuccess');
        },
    }
}

</script>

<style lang="scss" scoped>

.unified-width {
    width: 150px;
}

.uk-panel {
    max-height: 500px;
    min-height: 300px;
}

</style>
