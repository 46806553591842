<template>
    <GenericCreate
        :action="update" :action-error="error" :action-label="$t('saveChanges')"

        :action-loading="loading"

        :modal-id="modalId"
        :success-description="$t(hasDistributor ? 'satellite.changed.message': 'satellite.assigned.organisation.message', {name: itemNameSatellite, organisation: itemNameDistributor})"
        :success-title="$t(hasDistributor ? 'satellite.changed': 'satellite.assigned')"
        :title="$t(hasDistributor ? 'satellite.change': 'satellite.assign')"

        :trigger="trigger"
        is-button-hidden

        modal-is-medium
        @afterClose="afterClose"
    >

        <span v-for="(inputField, key) in textFields" :key="inputField.name">
            <div v-if="key < textFields.length-1">
                <TextField v-model="inputField.value" :autocomplete="inputField.autocomplete"
                           :autofocus="inputField.autofocus" :disabled="inputField.disabled" :field-key="key"
                           :hasPasswordMeter="inputField.hasPasswordMeter"
                           :icon="inputField.icon"
                           :is-optional="inputField.optional"
                           :label="inputField.label"
                           :name="inputField.name"
                           :onEnter="update"
                           :rules="inputField.rules"
                           :type="inputField.type"
                           :validate="trigger"
                           @setValid="setValid"
                />
            </div>
            <div v-if="key !== textFields.length -1" class="uk-margin-bottom"/>
        </span>

        <div class="uk-margin-bottom"/>
        <CardSelect v-model="textFields[2].value" :field-key="0" :label="textFields[2].label"
                    :label-name="textFields[2].name" :no-items-message="$t('noDistributorsMessage')"
                    :optional="textFields[2].optional"
                    :options="getDistributorList()" :rules="textFields[2].rules" :validate="trigger" is-searchable
                    label-plural="distributors"
                    @setValid="setCardValid"/>

    </GenericCreate>
</template>

<script>

import {putSatelliteById} from "@/client/vue-api-client";
import TextField from "../generic/TextField";
import GenericCreate from "@/components/creates/GenericCreate";
import UIkit from "uikit";
import CardSelect from "@/components/CardSelect";

export default {
    name: 'UpdateSatelliteDistributor',
    components: {
        CardSelect,
        GenericCreate,
        TextField,
    },
    props: {
        distributors: Array,

        item: {
            id: String,
            ean: String,
            description: String,
            hardwareGenId: Number,
            distributorId: String,
            deviceId: String,
            apiToken: String,

            emergency: {
                token: String,
                expiresAt: String,
            },

            status: String,
            expiry: String,
        },

        modalId: String,
    },
    data() {
        return {
            trigger: 0,

            textFields: [
                {
                    value: this.item.ean,
                    valid: true,

                    autofocus: true,
                    name: "serialNumber",
                    label: "serialNumber",
                    rules: "required",
                    icon: "code",
                },
                {
                    value: this.item.description,
                    valid: true,

                    autofocus: true,
                    name: "description",
                    label: "description",
                    icon: "tag",
                },
                {
                    value: this.item.distributorId,
                    valid: true,

                    autofocus: false,
                    name: "distributor",
                    label: "distributor",
                    icon: "social",
                    rules: "",
                    optional: true,
                },
            ],

            loading: false,
            error: null,

            itemNameSatellite: "",
            itemNameDistributor: "",

            parsedDistributors: [],
            hasDistributor: false,
        }
    },
    created() {
        let parsedItems = [];
        for (let i = 0, j = this.distributors.length; i < j; i++) {
            if (this.distributors[i].confirmedAt) {
                parsedItems.push(this.distributors[i]);
            }
        }

        this.parsedDistributors = parsedItems;

        if (this.item.distributorId) {
            this.hasDistributor = true;
        }
    },
    methods: {
        setCardValid(valid) {
            this.textFields[2].valid = valid;
        },
        setValid(valid, fieldKey) {
            this.textFields[fieldKey].valid = valid
        },
        getDistributorName(id) {
            for (let i = 0, j = this.distributors.length; i < j; i++) {
                if (this.distributors[i].id === id) {
                    return this.distributors[i].name;
                }
            }

            return null;
        },
        getDistributorList() {
            let items = [];

            items.push({
                name: this.$t("noDistributor"),
                value: "",
            });

            for (let i = 0, j = this.parsedDistributors.length; i < j; i++) {
                items.push({
                    name: this.parsedDistributors[i].name,
                    value: this.parsedDistributors[i].id,
                    nameIcon: "social",
                });
            }

            return items;
        },
        afterClose() {
            if (this.textFields[2].value) {
                this.hasDistributor = true;
            }
        },
        update() {
            this.trigger++;

            for (let i = 0, j = this.textFields.length; i < j; i++) {
                if (!this.textFields[i].valid) {
                    return;
                }
            }

            let self = this;
            self.loading = true;

            putSatelliteById({
                id: self.item.id,
                satellite: {
                    ean: self.textFields[0].value,
                    description: self.textFields[1].value,
                    distributorId: self.textFields[2].value,
                },

            }).then(function () {
                self.itemNameSatellite = self.item.ean;
                self.itemNameDistributor = self.getDistributorName(self.textFields[2].value);
                self.loading = false;

                UIkit.modal("#" + self.modalId + "-success").show();
                self.$emit('afterSuccess', {
                    id: self.item.id,
                    hardwareGenId: self.item.hardwareGenId,
                    ean: self.textFields[0].value,
                    description: self.textFields[1].value,
                    distributorId: self.textFields[2].value,
                    deviceId: self.item.deviceId,
                    apiToken: self.item.apiToken,

                    emergency: {
                        token: self.item.emergency.token,
                        expiresAt: self.item.emergency.expiresAt,
                    },

                    status: self.item.status,
                    expiry: self.item.expiry,
                });

            }).catch(function (e) {
                self.checkGenericError(e);

                self.error = e;
                self.loading = false;

            })
        },
    }
}

</script>

<style lang="scss" scoped>

</style>
