<template>
    <div>
        <!-- Veränderungen ab hier -->

        Die Nutzung unserer Internetseiten ist grundsätzlich ohne jede Angabe personenbezogener Daten
        möglich. Sofern eine betroffene Person besondere Services unseres Unternehmens über unsere Internetseite in
        Anspruch nehmen möchte, könnte jedoch eine Verarbeitung personenbezogener Daten erforderlich werden. Ist die
        Verarbeitung personenbezogener Daten erforderlich und besteht für eine solche Verarbeitung keine gesetzliche
        Grundlage, holen wir generell eine Einwilligung der betroffenen Person ein.

        <br/><br/>
        <b>Einleitung</b>
        <br/>
        Mit der folgenden Datenschutzerklärung möchten wir Sie darüber aufklären, welche Arten Ihrer personenbezogenen
        Daten (nachfolgend auch kurz als “Daten“ bezeichnet) wir zu welchen Zwecken und in welchem Umfang verarbeiten.
        Die Datenschutzerklärung gilt für alle von uns durchgeführten Verarbeitungen personenbezogener Daten, sowohl im
        Rahmen der Erbringung unserer Leistungen als auch insbesondere auf unseren Webseiten, in mobilen Applikationen
        sowie innerhalb externer Onlinepräsenzen, wie z.B. unserer Social-Media-Profile und App Deinstleistungen
        (nachfolgend zusammenfassend bezeichnet als “Onlineangebot“).

        <br/><br/>
        <b>Verantwortlicher für diese Internetseite</b>
        <br/>
        made.systems GbR<br/>
        Frederic aus den Erlen, Frank Mutter<br/>
        Hauptstr. 59<br/>
        D- 79730 Murg<br/><br/>
        Weitere Angaben können Sie dem Impressum entnehmen.


        <h3>Übersicht der Verarbeitungen</h3>
        Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und die Zwecke ihrer Verarbeitung zusammen
        und verweist auf die betroffenen Personen.

        <br/><br/>
        <b>Arten der verarbeiteten Daten</b>
        <ul class="uk-list uk-list-disc">
            <li>Bestandsdaten (z.B. Namen, Adressen).</li>
            <li>Inhaltsdaten (z.B. Texteingaben, Netzwerkauswertungen).</li>
            <li>Kontaktdaten (z.B. E-Mail, Telefonnummern).</li>
            <li> Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).</li>
            <li>Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten).</li>
            <li>Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit, Kundenkategorie).</li>
            <li>Zahlungsdaten (z.B. Bankverbindungen, Rechnungen, Zahlungshistorie).</li>
        </ul>

        <br/>
        <b>Kategorien betroffener Personen</b>
        <ul class="uk-list uk-list-disc">
            <li>Beschäftigte (z.B. Angestellte, Bewerber, ehemalige Mitarbeiter).</li>
            <li>Geschäfts- und Vertragspartner.</li>
            <li>Interessenten.</li>
            <li>Kommunikationspartner.</li>
            <li>Kunden.</li>
            <li>Nutzer (Besucher unserer Webseite, Nutzer von Onlinediensten, etc.).</li>
        </ul>

        <br/>
        <b>Zwecke der Verarbeitung</b>
        <ul class="uk-list uk-list-disc">
            <li>Affiliate-Nachverfolgung.</li>
            <li>Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.</li>
            <li>Besuchsaktionsauswertung.</li>
            <li>Büro- und Organisationsverfahren.</li>
            <li>Direktmarketing (z.B. per E-Mail oder postalisch).</li>
            <li>Feedback (z.B. Sammeln von Feedback via Online-Formular).</li>
            <li>Interessenbasiertes und verhaltensbezogenes Marketing.</li>
            <li>Kontaktanfragen und Kommunikation.</li>
            <li>Konversionsmessung (Messung der Effektivität von Marketingmaßnahmen).</li>
            <li>Profiling (Erstellen von Nutzerprofilen).</li>
            <li>Remarketing.</li>
            <li>Reichweitenmessung (z.B. Zugriffsstatistiken, Erkennung wiederkehrender Besucher).</li>
            <li>Sicherheitsmaßnahmen.</li>
            <li>Tracking (z.B. interessens-/verhaltensbezogenes Profiling, Nutzung von Cookies).</li>
            <li>Vertragliche Leistungen und Service.</li>
            <li>Verwaltung und Beantwortung von Anfragen.</li>
        </ul>

        <br/>
        <b>Maßgebliche Rechtsgrundlagen</b>
        <br/>
        Im Folgenden teilen wir die Rechtsgrundlagen der Datenschutzgrundverordnung (DSGVO), auf deren Basis wir die
        personenbezogenen Daten verarbeiten, mit. Bitte beachten Sie, dass zusätzlich zu den Regelungen der DSGVO die
        nationalen Datenschutzvorgaben in Ihrem bzw. unserem Wohn- und Sitzland gelten können.
        Einwilligung (Art. 6 Abs. 1 lit. a) DSGVO)– Die betroffene Person hat ihre Einwilligung in die Verarbeitung der
        sie betreffenden personenbezogenen Daten für einen spezifischen Zweck oder mehrere bestimmte Zwecke gegeben.
        Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 lit. b) DSGVO)– Die Verarbeitung ist für die
        Erfüllung eines Vertrags, dessen Vertragspartei die betroffene Person ist, oder zur Durchführung
        vorvertraglicher Maßnahmen erforderlich, die auf Anfrage der betroffenen Person erfolgen.
        Rechtliche Verpflichtung (Art. 6 Abs. 1 lit. c) DSGVO)– Die Verarbeitung ist zur Erfüllung einer rechtlichen
        Verpflichtung erforderlich, der der Verantwortliche unterliegt.
        Berechtigte Interessen (Art. 6 Abs. 1 lit. f) DSGVO)– Die Verarbeitung ist zur Wahrung der berechtigten
        Interessen des Verantwortlichen oder eines Dritten erforderlich, sofern nicht die Interessen oder Grundrechte
        und Grundfreiheiten der betroffenen Person, die den Schutz personenbezogener Daten erfordern, überwiegen.
        Nationale Datenschutzregelungen in Deutschland: Zusätzlich zu den Datenschutzregelungen der
        Datenschutz-Grundverordnung gelten nationale Regelungen zum Datenschutz in Deutschland. Hierzu gehört
        insbesondere das Gesetz zum Schutz vor Missbrauch personenbezogener Daten bei der Datenverarbeitung
        (Bundesdatenschutzgesetz – BDSG). Das BDSG enthält insbesondere Spezialregelungen zum Recht auf Auskunft, zum
        Recht auf Löschung, zum Widerspruchsrecht, zur Verarbeitung besonderer Kategorien personenbezogener Daten, zur
        Verarbeitung für andere Zwecke und zur Übermittlung sowie automatisierten Entscheidungsfindung im Einzelfall
        einschließlich Profiling. Des Weiteren regelt es die Datenverarbeitung für Zwecke des
        Beschäftigungsverhältnisses (§ 26 BDSG), insbesondere im Hinblick auf die Begründung, Durchführung oder
        Beendigung von Beschäftigungsverhältnissen sowie die Einwilligung von Beschäftigten. Ferner können
        Landesdatenschutzgesetze der einzelnen Bundesländer zur Anwendung gelangen.

        <br/><br/>
        <b>Sicherheitsmaßnahmen</b>
        <br/>
        Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter Berücksichtigung des Stands der Technik, der
        Implementierungskosten und der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der
        unterschiedlichen Eintrittswahrscheinlichkeiten und des Ausmaßes der Bedrohung der Rechte und Freiheiten
        natürlicher Personen geeignete technische und organisatorische Maßnahmen, um ein dem Risiko angemessenes
        Schutzniveau zu gewährleisten.
        Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und Verfügbarkeit von Daten
        durch Kontrolle des physischen und elektronischen Zugangs zu den Daten als auch des sie betreffenden Zugriffs,
        der Eingabe, der Weitergabe, der Sicherung der Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir
        Verfahren eingerichtet, die eine Wahrnehmung von Betroffenenrechten, die Löschung von Daten und Reaktionen auf
        die Gefährdung der Daten gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener Daten bereits
        bei der Entwicklung bzw. Auswahl von Hardware, Software sowie Verfahren entsprechend dem Prinzip des
        Datenschutzes, durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.
        Kürzung der IP-Adresse: Sofern es uns möglich ist oder eine Speicherung der IP-Adresse nicht erforderlich ist,
        kürzen wir oder lassen Ihre IP-Adresse kürzen. Im Fall der Kürzung der IP-Adresse, auch als “IP-Masking”
        bezeichnet, wird das letzte Oktett, d.h., die letzten beiden Zahlen einer IP-Adresse, gelöscht (die IP-Adresse
        ist in diesem Kontext eine einem Internetanschluss durch den Online-Zugangs-Provider individuell zugeordnete
        Kennung). Mit der Kürzung der IP-Adresse soll die Identifizierung einer Person anhand ihrer IP-Adresse
        verhindert oder wesentlich erschwert werden.
        SSL-Verschlüsselung (https): Um Ihre via unser Online-Angebot übermittelten Daten zu schützen, nutzen wir eine
        SSL-Verschlüsselung. Sie erkennen derart verschlüsselte Verbindungen an dem Präfix https:// in der Adresszeile
        Ihres Browsers.

        <br/><br/>
        <b>Übermittlung und Offenbarung von personenbezogenen Daten</b>
        <br/>
        Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt es vor, dass die Daten an andere Stellen,
        Unternehmen, rechtlich selbstständige Organisationseinheiten oder Personen übermittelt oder sie ihnen gegenüber
        offengelegt werden. Zu den Empfängern dieser Daten können z.B. Zahlungsinstitute im Rahmen von
        Zahlungsvorgängen, mit IT-Aufgaben beauftragte Dienstleister oder Anbieter von Diensten und Inhalten, die in
        eine Webseite eingebunden werden, gehören. In solchen Fall beachten wir die gesetzlichen Vorgaben und schließen
        insbesondere entsprechende Verträge bzw. Vereinbarungen, die dem Schutz Ihrer Daten dienen, mit den Empfängern
        Ihrer Daten ab.

        <br/><br/>
        <b>Datenverarbeitung in Drittländern</b>
        <br/>
        Sofern wir Daten in einem Drittland (d.h., außerhalb der Europäischen Union (EU), des Europäischen
        Wirtschaftsraums (EWR)) verarbeiten oder die Verarbeitung im Rahmen der Inanspruchnahme von Diensten Dritter
        oder der Offenlegung bzw. Übermittlung von Daten an andere Personen, Stellen oder Unternehmen stattfindet,
        erfolgt dies nur im Einklang mit den gesetzlichen Vorgaben.
        Vorbehaltlich ausdrücklicher Einwilligung oder vertraglich oder gesetzlich erforderlicher Übermittlung
        verarbeiten oder lassen wir die Daten nur in Drittländern mit einem anerkannten Datenschutzniveau, zu denen die
        unter dem “Privacy-Shield” zertifizierten US-Verarbeiter gehören, oder auf Grundlage besonderer Garantien, wie
        z.B. vertraglicher Verpflichtung durch sogenannte Standardschutzklauseln der EU-Kommission, des Vorliegens von
        Zertifizierungen oder verbindlicher interner Datenschutzvorschriften, verarbeiten (Art. 44 bis 49 DSGVO,
        Informationsseite der EU-Kommission:
        https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection_de).

        <br/><br/>
        <b>Einsatz von Cookies</b>
        <br/>
        Als “Cookies“ werden kleine Dateien bezeichnet, die auf Geräten der Nutzer gespeichert werden. Mittels Cookies
        können unterschiedliche Angaben gespeichert werden. Zu den Angaben können z.B. die Spracheinstellungen auf einer
        Webseite, der Loginstatus, ein Warenkorb oder die Stelle, an der ein Video geschaut wurde, gehören.
        Cookies werden im Regelfall auch dann eingesetzt, wenn die Interessen eines Nutzers oder sein Verhalten (z.B.
        Betrachten bestimmter Inhalte, Nutzen von Funktionen etc.) auf einzelnen Webseiten in einem Nutzerprofil
        gespeichert werden. Solche Profile dienen dazu, den Nutzern z.B. Inhalte anzuzeigen, die ihren potentiellen
        Interessen entsprechen. Dieses Verfahren wird auch als “Tracking”, d.h., Nachverfolgung der potentiellen
        Interessen der Nutzer bezeichnet. Zu dem Begriff der Cookies zählen wir ferner andere Technologien, die die
        gleichen Funktionen wie Cookies erfüllen (z.B., wenn Angaben der Nutzer anhand pseudonymer Onlinekennzeichnungen
        gespeichert werden, auch als “Nutzer-IDs” bezeichnet).
        Soweit wir Cookies oder “Tracking”-Technologien einsetzen, informieren wir Sie gesondert in unserer
        Datenschutzerklärung.
        Hinweise zu Rechtsgrundlagen: Auf welcher Rechtsgrundlage wir Ihre personenbezogenen Daten mit Hilfe von Cookies
        verarbeiten, hängt davon ab, ob wir Sie um eine Einwilligung bitten. Falls dies zutrifft und Sie in die Nutzung
        von Cookies einwilligen, ist die Rechtsgrundlage der Verarbeitung Ihrer Daten die erklärte Einwilligung.
        Andernfalls werden die mithilfe von Cookies verarbeiteten Daten auf Grundlage unserer berechtigten Interessen
        (z.B. an einem betriebswirtschaftlichen Betrieb unseres Onlineangebotes und dessen Verbesserung) verarbeitet
        oder, wenn der Einsatz von Cookies erforderlich ist, um unsere vertraglichen Verpflichtungen zu erfüllen.
        Widerruf und Widerspruch (Opt-Out): Unabhängig davon, ob die Verarbeitung auf Grundlage einer Einwilligung oder
        gesetzlichen Erlaubnis erfolgt, haben Sie jederzeit die Möglichkeit, eine erteilte Einwilligung zu widerrufen
        oder der Verarbeitung Ihrer Daten durch Cookie-Technologien zu widersprechen (zusammenfassend als “Opt-Out”
        bezeichnet).
        Sie können Ihren Widerspruch zunächst mittels der Einstellungen Ihres Browsers erklären, z.B., indem Sie die
        Nutzung von Cookies deaktivieren (wobei hierdurch auch die Funktionsfähigkeit unseres Onlineangebotes
        eingeschränkt werden kann).
        Ein Widerspruch gegen den Einsatz von Cookies zu Zwecken des Onlinemarketings kann mittels einer Vielzahl von
        Diensten, vor allem im Fall des Trackings, über die US-amerikanische Seite http://www.aboutads.info/choices/
        oder die EU-Seite http://www.youronlinechoices.com/ oder generell auf http://optout.aboutads.infoerklärt werden.
        Verarbeitung von Cookie-Daten auf Grundlage einer Einwilligung: Bevor wir Daten im Rahmen der Nutzung von
        Cookies verarbeiten oder verarbeiten lassen, bitten wir die Nutzer um eine jederzeit widerrufbare Einwilligung.
        Bevor die Einwilligung nicht ausgesprochen wurde, werden allenfalls Cookies eingesetzt, die für den Betrieb
        unseres Onlineangebotes erforderlich sind. Deren Einsatz erfolgt auf der Grundlage unseres Interesses und des
        Interesses der Nutzer an der erwarteten Funktionsfähigkeit unseres Onlineangebotes.

        Verarbeitete Datenarten:Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten),
        Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
        Betroffene Personen:Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).
        Rechtsgrundlagen:Einwilligung Art. 6 Abs. 1 lit. a) DSGVO, Berechtigte Interessen Art. 6 Abs.1 lit. f) DSGVO.

        <br/><br/>
        <b>Kommerzielle und geschäftliche Leistungen</b>
        <br/>
        Wir verarbeiten Daten unserer Vertrags- und Geschäftspartner, z.B. Kunden und Interessenten (zusammenfassend
        bezeichnet als “Vertragspartner”) im Rahmen von vertraglichen und vergleichbaren Rechtsverhältnissen sowie damit
        verbundenen Maßnahmen und im Rahmen der Kommunikation mit den Vertragspartnern (oder vorvertraglich), z.B., um
        Anfragen zu beantworten.
        Diese Daten verarbeiten wir zur Erfüllung unserer vertraglichen Pflichten, zur Sicherung unserer Rechte und zu
        Zwecken der mit diesen Angaben einhergehenden Verwaltungsaufgaben sowie der unternehmerischen Organisation. Die
        Daten der Vertragspartner geben wir im Rahmen des geltenden Rechts nur insoweit an Dritte weiter, als dies zu
        den vorgenannten Zwecken oder zur Erfüllung gesetzlicher Pflichten erforderlich ist oder mit Einwilligung der
        Vertragspartner erfolgt (z.B. an beteiligte Telekommunikations-, Transport- und sonstige Hilfsdienste sowie
        Subunternehmer, Banken, Steuer- und Rechtsberater, Zahlungsdienstleister oder Steuerbehörden). Über weitere
        Verarbeitungsformen, z.B. zu Zwecken des Marketings, werden die Vertragspartner im Rahmen dieser
        Datenschutzerklärung informiert.
        Welche Daten für die vorgenannten Zwecke erforderlich sind, teilen wir den Vertragspartnern vor oder im Rahmen
        der Datenerhebung, z.B. in Onlineformularen, durch besondere Kennzeichnung (z.B. Farben) bzw. Symbole (z.B.
        Sternchen o.ä.), oder persönlich mit.
        Wir löschen die Daten nach Ablauf gesetzlicher Gewährleistungs- und vergleichbarer Pflichten, d.h.,
        grundsätzlich nach Ablauf von 4 Jahren, es sei denn, dass die Daten in einem Kundenkonto gespeichert werden,
        z.B., solange sie aus gesetzlichen Gründen der Archivierung aufbewahrt werden müssen (z.B. für Steuerzwecke im
        Regelfall 10 Jahre). Daten, die uns im Rahmen eines Auftrags durch den Vertragspartner offengelegt wurden,
        löschen wir entsprechend den Vorgaben des Auftrags, grundsätzlich nach Ende des Auftrags.
        Soweit wir zur Erbringung unserer Leistungen Drittanbieter oder Plattformen einsetzen, gelten im Verhältnis
        zwischen den Nutzern und den Anbietern die Geschäftsbedingungen und Datenschutzhinweise der jeweiligen
        Drittanbieter oder Plattformen.
        Wirtschaftliche Analysen und Marktforschung: Aus betriebswirtschaftlichen Gründen und um Markttendenzen, Wünsche
        der Vertragspartner und Nutzer erkennen zu können, analysieren wir die uns vorliegenden Daten zu
        Geschäftsvorgängen, Verträgen, Anfragen, etc., wobei in die Gruppe der betroffenen Personen Vertragspartner,
        Interessenten, Kunden, Besucher und Nutzer unseres Onlineangebotes fallen können.
        Die Analysen erfolgen zum Zweck betriebswirtschaftlicher Auswertungen, des Marketings und der Marktforschung
        (z.B. zur Bestimmung von Kundengruppen mit unterschiedlichen Eigenschaften). Dabei können wir, sofern vorhanden,
        die Profile von registrierten Nutzern samt ihrer Angaben, z.B. zu in Anspruch genommenen Leistungen,
        berücksichtigen. Die Analysen dienen alleine uns und werden nicht extern offenbart, sofern es sich nicht um
        anonyme Analysen mit zusammengefassten, also anonymisierten Werten handelt. Ferner nehmen wir Rücksicht auf die
        Privatsphäre der Nutzer und verarbeiten die Daten zu den Analysezwecken möglichst pseudonym und, sofern machbar,
        anonym (z.B. als zusammengefasste Daten).
        Weitere Informationen zu kommerziellen Dienstleistungen: Wir verarbeiten die Daten unserer Kunden sowie
        Auftraggeber (nachfolgend einheitlich als “Kunden” bezeichnet), um ihnen die Auswahl, den Erwerb bzw. die
        Beauftragung der gewählten Leistungen oder Werke sowie verbundener Tätigkeiten als auch deren Bezahlung und
        Zustellung bzw. Ausführung oder Erbringung zu ermöglichen.
        Die erforderlichen Angaben sind als solche im Rahmen des Auftrags-, Bestell- bzw. vergleichbaren
        Vertragsschlusses gekennzeichnet und umfassen die zur Leistungserbringung und Abrechnung benötigten Angaben
        sowie Kontaktinformationen, um etwaige Rücksprachen halten zu können.

        <br/><br/>
        <b>Verarbeitete Datenarten</b>
        <br/>
        Bestandsdaten (z.B. Namen, Adressen), Zahlungsdaten (z.B. Bankverbindungen, Rechnungen,
        Zahlungshistorie), Kontaktdaten (z.B. E-Mail, Telefonnummern), Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit,
        Kundenkategorie), Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten),
        Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
        Betroffene Personen:Interessenten, Geschäfts- und Vertragspartner, Kunden.
        Zwecke der Verarbeitung:Vertragliche Leistungen und Service, Kontaktanfragen und Kommunikation, Büro- und
        Organisationsverfahren, Verwaltung und Beantwortung von Anfragen, Besuchsaktionsauswertung, Interessenbasiertes
        und verhaltensbezogenes Marketing, Profiling (Erstellen von Nutzerprofilen).
        Rechtsgrundlagen:Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 lit. b) DSGVO), Rechtliche
        Verpflichtung (Art. 6 Abs. 1 lit. c) DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 lit. f) DSGVO).

        <br/><br/>
        <b>Blogs und Publikationsmedien</b>
        <br/>
        Wir nutzen Blogs oder vergleichbare Mittel der Onlinekommunikation und Publikation (nachfolgend
        “Publikationsmedium”). Die Daten der Leser werden für die Zwecke des Publikationsmediums nur insoweit
        verarbeitet, als es für dessen Darstellung und die Kommunikation zwischen Autoren und Lesern oder aus Gründen
        der Sicherheit erforderlich ist. Im Übrigen verweisen wir auf die Informationen zur Verarbeitung der Besucher
        unseres Publikationsmediums im Rahmen dieser Datenschutzhinweise.
        Kommentare und Beiträge: Wenn Nutzer Kommentare oder sonstige Beiträge hinterlassen, können ihre IP-Adressen auf
        Grundlage unserer berechtigten Interessen gespeichert werden. Das erfolgt zu unserer Sicherheit, falls jemand in
        Kommentaren und Beiträgen widerrechtliche Inhalte hinterlässt (Beleidigungen, verbotene politische Propaganda
        etc.). In diesem Fall können wir selbst für den Kommentar oder Beitrag belangt werden und sind daher an der
        Identität des Verfassers interessiert.
        Des Weiteren behalten wir uns vor, auf Grundlage unserer berechtigten Interessen die Angaben der Nutzer zwecks
        Spamerkennung zu verarbeiten.
        Auf derselben Rechtsgrundlage behalten wir uns vor, im Fall von Umfragen die IP-Adressen der Nutzer für deren
        Dauer zu speichern und Cookies zu verwenden, um Mehrfachabstimmungen zu vermeiden.
        Die im Rahmen der Kommentare und Beiträge mitgeteilten Informationen zur Person, etwaige Kontakt- sowie
        Webseiteninformationen als auch die inhaltlichen Angaben werden von uns bis zum Widerspruch der Nutzer dauerhaft
        gespeichert.

        <br/><br/>
        <b>Kommunikation via Messenger</b>
        <br/>
        Wir setzen zu Zwecken der Kommunikation Messenger-Dienste ein und bitten daher darum, die nachfolgenden Hinweise
        zur Funktionsfähigkeit der Messenger, zur Verschlüsselung, zur Nutzung der Metadaten der Kommunikation und zu
        Ihren Widerspruchsmöglichkeiten zu beachten.
        Sie können uns auch auf alternativen Wegen, z.B. via Telefon oder E-Mail, kontaktieren. Bitte nutzen Sie die
        Ihnen mitgeteilten Kontaktmöglichkeiten oder die innerhalb unseres Onlineangebotes angegebenen
        Kontaktmöglichkeiten.
        Im Fall einer Ende-zu-Ende-Verschlüsselung von Inhalten (d.h., der Inhalt Ihrer Nachricht und Anhänge) weisen
        wir darauf hin, dass die Kommunikationsinhalte (d.h., der Inhalt der Nachricht und angehängte Bilder) von Ende
        zu Ende verschlüsselt werden. Das bedeutet, dass der Inhalt der Nachrichten nicht einsehbar ist, nicht einmal
        durch die Messenger-Anbieter selbst. Sie sollten immer eine aktuelle Version der Messenger mit aktivierter
        Verschlüsselung nutzen, damit die Verschlüsselung der Nachrichteninhalte sichergestellt ist.
        Wir weisen unsere Kommunikationspartner jedoch zusätzlich darauf hin, dass die Anbieter der Messenger zwar nicht
        den Inhalt einsehen, aber in Erfahrung bringen können, dass und wann Kommunikationspartner mit uns kommunizieren
        sowie technische Informationen zum verwendeten Gerät der Kommunikationspartner und je nach Einstellungen ihres
        Gerätes auch Standortinformationen (sogenannte Metadaten) verarbeitet werden.
        Hinweise zu Rechtsgrundlagen: Sofern wir Kommunikationspartner vor der Kommunikation mit ihnen via Messenger um
        eine Erlaubnis bitten, ist die Rechtsgrundlage unserer Verarbeitung ihrer Daten deren Einwilligung. Im Übrigen,
        falls wir nicht um eine Einwilligung bitten und sie z.B. von sich aus Kontakt mit uns aufnehmen, nutzen wir
        Messenger im Verhältnis zu unseren Vertragspartnern sowie im Rahmen der Vertragsanbahnung als eine vertragliche
        Maßnahme und im Fall anderer Interessenten und Kommunikationspartner auf Grundlage unserer berechtigten
        Interessen an einer schnellen und effizienten Kommunikation und Erfüllung der Bedürfnisse unser
        Kommunikationspartner an der Kommunikation via Messengern. Ferner weisen wir Sie darauf hin, dass wir die uns
        mitgeteilten Kontaktdaten ohne Ihre Einwilligung nicht erstmalig an die Messenger übermitteln.
        Widerruf, Widerspruch und Löschung: Sie können jederzeit eine erteilte Einwilligung widerrufen und der
        Kommunikation mit uns via Messenger jederzeit widersprechen. Im Fall der Kommunikation via Messenger löschen wir
        die Nachrichten entsprechend unseren generellen Löschrichtlinien (d.h. z.B., wie oben beschrieben, nach Ende
        vertraglicher Beziehungen, im Kontext von Archivierungsvorgaben etc.) und sonst, sobald wir davon ausgehen
        können, etwaige Auskünfte der Kommunikationspartner beantwortet zu haben, wenn kein Rückbezug auf eine
        vorhergehende Konversation zu erwarten ist und der Löschung keine gesetzlichen Aufbewahrungspflichten
        entgegenstehen.
        Vorbehalt des Verweises auf andere Kommunikationswege: Zum Abschluss möchten wir darauf hinweisen, dass wir uns
        aus Gründen Ihrer Sicherheit vorbehalten, Anfragen über Messenger nicht zu beantworten. Das ist der Fall, wenn
        z.B. Vertragsinterna besonderer Geheimhaltung bedürfen oder eine Antwort über den Messenger den formellen
        Ansprüchen nicht genügt. In solchen Fällen verweisen wir Sie auf adäquatere Kommunikationswege.

        <br/><br/>
        <b>Verarbeitete Datenarten</b>
        <br/>
        Kontaktdaten (z.B. E-Mail, Telefonnummern), Nutzungsdaten (z.B. besuchte Webseiten,
        Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen),
        Inhaltsdaten (z.B. Texteingaben).
        Betroffene Personen: Kommunikationspartner
        Zwecke der Verarbeitung:Kontaktanfragen und Kommunikation, Direktmarketing (z.B. per E-Mail oder postalisch).
        Rechtsgrundlagen:Einwilligung (Art. 6 Abs. 1 lit. a) DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 lit. f)
        DSGVO).

        <br/><br/>
        <b>Eingesetzte Dienste und Diensteanbieter</b>
        <br/>
        WhatsApp:WhatsApp Messenger mit Ende-zu-Ende-Verschlüsselung; Dienstanbieter: WhatsApp Inc. WhatsApp Legal 1601
        Willow Road Menlo Park, California 94025, USA; Website: https://www.whatsapp.com/; Datenschutzerklärung:
        https://www.whatsapp.com/legal; Privacy Shield (Gewährleistung Datenschutzniveau bei Verarbeitung von Daten in
        den USA): https://www.privacyshield.gov/participant?id=a2zt0000000TSnwAAG&status=Active.

        <br/><br/>
        <b>Bereitstellung des Onlineangebotes und Webhosting</b>
        <br/>
        Um unser Onlineangebot sicher und effizient bereitstellen zu können, nehmen wir die Leistungen von einem oder
        mehreren Webhosting-Anbietern in Anspruch, von deren Servern (bzw. von ihnen verwalteten Servern) das
        Onlineangebot abgerufen werden kann. Zu diesen Zwecken können wir Infrastruktur- und Plattformdienstleistungen,
        Rechenkapazität, Speicherplatz und Datenbankdienste sowie Sicherheitsleistungen und technische
        Wartungsleistungen in Anspruch nehmen.
        Zu den im Rahmen der Bereitstellung des Hostingangebotes verarbeiteten Daten können alle die Nutzer unseres
        Onlineangebotes betreffenden Angaben gehören, die im Rahmen der Nutzung und der Kommunikation anfallen. Hierzu
        gehören regelmäßig die IP-Adresse, die notwendig ist, um die Inhalte von Onlineangeboten an Browser ausliefern
        zu können, und alle innerhalb unseres Onlineangebotes oder von Webseiten getätigten Eingaben.
        E-Mail-Versand und -Hosting: Die von uns in Anspruch genommenen Webhosting-Leistungen umfassen ebenfalls den
        Versand, den Empfang sowie die Speicherung von E-Mails. Zu diesen Zwecken werden die Adressen der Empfänger
        sowie Absender als auch weitere Informationen betreffend den E-Mailversand (z.B. die beteiligten Provider) sowie
        die Inhalte der jeweiligen E-Mails verarbeitet. Die vorgenannten Daten können ferner zu Zwecken der Erkennung
        von SPAM verarbeitet werden. Wir bitten darum, zu beachten, dass E-Mails im Internet grundsätzlich nicht
        verschlüsselt versendet werden. Im Regelfall werden E-Mails zwar auf dem Transportweg verschlüsselt, aber
        (sofern kein sogenanntes Ende-zu-Ende-Verschlüsselungsverfahren eingesetzt wird) nicht auf den Servern, von
        denen sie abgesendet und empfangen werden. Wir können daher für den Übertragungsweg der E-Mails zwischen dem
        Absender und dem Empfang auf unserem Server keine Verantwortung übernehmen.
        Erhebung von Zugriffsdaten und Logfiles: Wir selbst (bzw. unser Webhostinganbieter) erheben Daten zu jedem
        Zugriff auf den Server (sogenannte Serverlogfiles). Zu den Serverlogfiles können die Adresse und Name der
        abgerufenen Webseiten und Dateien, Datum und Uhrzeit des Abrufs, übertragene Datenmengen, Meldung über
        erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte
        Seite) und im Regelfall IP-Adressen und der anfragende Provider gehören.
        Die Serverlogfiles können zum einen zu Zwecken der Sicherheit eingesetzt werden, z.B., um eine Überlastung der
        Server zu vermeiden (insbesondere im Fall von missbräuchlichen Angriffen, sogenannten DDoS-Attacken) und zum
        anderen, um die Auslastung der Server und ihre Stabilität sicherzustellen.

        <br/><br/>
        <b>Verarbeitete Datenarten</b>
        <br/>
        Inhaltsdaten (z.B. Texteingaben), Nutzungsdaten (z.B. besuchte Webseiten, Interesse an
        Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
        Betroffene Personen:Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).
        Rechtsgrundlagen:Berechtigte Interessen (Art. 6 Abs. 1 lit. f) DSGVO).

        <br/><br/>
        <b>Cloud-Dienste</b>
        <br/>
        Wir nutzen über das Internet zugängliche und auf den Servern ihrer Anbieter ausgeführte Softwaredienste
        (sogenannte “Cloud-Dienste”, auch bezeichnet als “Software as a Service”) für die folgenden Zwecke:
        Dokumentenspeicherung und Verwaltung, Kalenderverwaltung, E-Mail-Versand, Tabellenkalkulationen und
        Präsentationen, Austausch von Dokumenten, Inhalten und Informationen mit bestimmten Empfängern oder
        Veröffentlichung von Webseiten, Formularen oder sonstigen Inhalten und Informationen sowie Chats und Teilnahme
        an Audio- und Videokonferenzen.
        In diesem Rahmen können personenbezogenen Daten verarbeitet und auf den Servern der Anbieter gespeichert werden,
        soweit diese Bestandteil von Kommunikationsvorgängen mit uns sind oder von uns sonst, wie im Rahmen dieser
        Datenschutzerklärung dargelegt, verarbeitet werden. Zu diesen Daten können insbesondere Stammdaten und
        Kontaktdaten der Nutzer, Daten zu Vorgängen, Verträgen, sonstigen Prozessen und deren Inhalte gehören. Die
        Anbieter der Cloud-Dienste verarbeiten ferner Nutzungsdaten und Metadaten, die von ihnen zu Sicherheitszwecken
        und zur Serviceoptimierung verwendet werden.
        Sofern wir mit Hilfe der Cloud-Dienste für andere Nutzer oder öffentlich zugängliche Webseiten Formulare o.a.
        Dokumente und Inhalte bereitstellen, können die Anbieter Cookies auf den Geräten der Nutzer für Zwecke der
        Webanalyse oder, um sich Einstellungen der Nutzer (z.B. im Fall der Mediensteuerung) zu merken, speichern.
        Hinweise zu Rechtsgrundlagen: Sofern wir um eine Einwilligung in den Einsatz der Cloud-Dienste bitten, ist die
        Rechtsgrundlage der Verarbeitung die Einwilligung. Ferner kann deren Einsatz ein Bestandteil unserer
        (vor)vertraglichen Leistungen sein, sofern der Einsatz der Cloud-Dienste in diesem Rahmen vereinbart wurde.
        Ansonsten werden die Daten der Nutzer auf Grundlage unserer berechtigten Interessen (d.h., Interesse an
        effizienten und sicheren Verwaltungs- und Kollaborationsprozessen) verarbeitet

        <br/><br/>
        <b>Verarbeitete Datenarten</b>
        <ul class="uk-list uk-list-disc">
            <li>Bestandsdaten (z.B. Namen, Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern),
                Inhaltsdaten (z.B. Texteingaben, Fotografien, Videos), Nutzungsdaten (z.B. besuchte Webseiten, Interesse
                an
                Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen),
                Vertragsdaten
                (z.B. Vertragsgegenstand, Laufzeit, Kundenkategorie).
            </li>
            <li>Betroffene Personen: Kunden, Beschäftigte (z.B. Angestellte, Bewerber, ehemalige Mitarbeiter),
                Interessenten,
                Kommunikationspartner.
            </li>
            <li>Zwecke der Verarbeitung: Büro- und Organisationsverfahren.</li>
            <li>Rechtsgrundlagen: Einwilligung (Art. 6 Abs. 1 lit. a) DSGVO), Vertragserfüllung und vorvertragliche
                Anfragen
                (Art. 6 Abs. 1 lit. b) DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 lit. f) DSGVO).
            </li>
        </ul>

        <br/>
        <b>Newsletter und Breitenkommunikation</b>
        <br/>
        Wir versenden Newsletter, E-Mails und weitere elektronische Benachrichtigungen (nachfolgend “Newsletter“) nur
        mit der Einwilligung der Empfänger oder einer gesetzlichen Erlaubnis. Sofern im Rahmen einer Anmeldung zum
        Newsletter dessen Inhalte konkret umschrieben werden, sind sie für die Einwilligung der Nutzer maßgeblich. Im
        Übrigen enthalten unsere Newsletter Informationen zu unseren Leistungen und uns.
        Um sich zu unseren Newslettern anzumelden, reicht es grundsätzlich aus, wenn Sie Ihre E-Mail-Adresse angeben.
        Wir können Sie jedoch bitten, einen Namen, zwecks persönlicher Ansprache im Newsletter, oder weitere Angaben,
        sofern diese für die Zwecke des Newsletters erforderlich sind, zu tätigen.
        Double-Opt-In-Verfahren: Die Anmeldung zu unserem Newsletter erfolgt grundsätzlich in einem sogenannte
        Double-Opt-In-Verfahren. D.h., Sie erhalten nach der Anmeldung eine E-Mail, in der Sie um die Bestätigung Ihrer
        Anmeldung gebeten werden. Diese Bestätigung ist notwendig, damit sich niemand mit fremden E-Mail-Adressen
        anmelden kann. Die Anmeldungen zum Newsletter werden protokolliert, um den Anmeldeprozess entsprechend den
        rechtlichen Anforderungen nachweisen zu können. Hierzu gehört die Speicherung des Anmelde- und des
        Bestätigungszeitpunkts als auch der IP-Adresse. Ebenso werden die Änderungen Ihrer bei dem Versanddienstleister
        gespeicherten Daten protokolliert.
        Löschung und Einschränkung der Verarbeitung: Wir können die ausgetragenen E-Mail-Adressen bis zu drei Jahren auf
        Grundlage unserer berechtigten Interessen speichern, bevor wir sie löschen, um eine ehemals gegebene
        Einwilligung nachweisen zu können. Die Verarbeitung dieser Daten wird auf den Zweck einer möglichen Abwehr von
        Ansprüchen beschränkt. Ein individueller Löschungsantrag ist jederzeit möglich, sofern zugleich das ehemalige
        Bestehen einer Einwilligung bestätigt wird. Im Fall von Pflichten zur dauerhaften Beachtung von Widersprüchen
        behalten wir uns die Speicherung der E-Mail-Adresse alleine zu diesem Zweck in einer Sperrliste (sogenannte
        “Blacklist”) vor.
        Die Protokollierung des Anmeldeverfahrens erfolgt auf Grundlage unserer berechtigten Interessen zu Zwecken des
        Nachweises seines ordnungsgemäßen Ablaufs. Soweit wir einen Dienstleister mit dem Versand von E-Mails
        beauftragen, erfolgt dies auf Grundlage unserer berechtigten Interessen an einem effizienten und sicheren
        Versandsystem.
        Hinweise zu Rechtsgrundlagen: Der Versand der Newsletter erfolgt auf Grundlage einer Einwilligung der Empfänger
        oder, falls eine Einwilligung nicht erforderlich ist, auf Grundlage unserer berechtigten Interessen am
        Direktmarketing, sofern und soweit diese gesetzlich, z.B. im Fall von Bestandskundenwerbung, erlaubt ist. Soweit
        wir einen Dienstleister mit dem Versand von E-Mails beauftragen, geschieht dies auf der Grundlage unserer
        berechtigten Interessen. Das Registrierungsverfahren wird auf der Grundlage unserer berechtigten Interessen
        aufgezeichnet, um nachzuweisen, dass es in Übereinstimmung mit dem Gesetz durchgeführt wurde.

        <br/><br/>
        <b>Inhalte</b>
        <br/>
        Informationen zu uns, unseren Leistungen, Aktionen und Angeboten.
        Informationen über Datenpannen und Datensicherheit.
        Erfolgsmessung: Die Newsletter enthalten einen sogenannte “web-beacon“, d.h., eine pixelgroße Datei, die beim
        Öffnen des Newsletters von unserem Server, bzw., sofern wir einen Versanddienstleister einsetzen, von dessen
        Server abgerufen wird. Im Rahmen dieses Abrufs werden zunächst technische Informationen, wie Informationen zum
        Browser und Ihrem System, als auch Ihre IP-Adresse und der Zeitpunkt des Abrufs, erhoben.
        Diese Informationen werden zur technischen Verbesserung unseres Newsletters anhand der technischen Daten oder
        der Zielgruppen und ihres Leseverhaltens auf Basis ihrer Abruforte (die mit Hilfe der IP-Adresse bestimmbar
        sind) oder der Zugriffszeiten genutzt. Diese Analyse beinhaltet ebenfalls die Feststellung, ob die Newsletter
        geöffnet werden, wann sie geöffnet werden und welche Links geklickt werden. Diese Informationen können aus
        technischen Gründen zwar den einzelnen Newsletterempfängern zugeordnet werden. Es ist jedoch weder unser
        Bestreben noch, sofern eingesetzt, das des Versanddienstleisters, einzelne Nutzer zu beobachten. Die
        Auswertungen dienen uns vielmehr dazu, die Lesegewohnheiten unserer Nutzer zu erkennen und unsere Inhalte an sie
        anzupassen oder unterschiedliche Inhalte entsprechend den Interessen unserer Nutzer zu versenden.
        Die Auswertung des Newsletters und die Erfolgsmessung erfolgen, vorbehaltlich einer ausdrücklichen Einwilligung
        der Nutzer, auf Grundlage unserer berechtigten Interessen zu Zwecken des Einsatzes eines nutzerfreundlichen
        sowie sicheren Newslettersystems, welches sowohl unseren geschäftlichen Interessen dient, als auch den
        Erwartungen der Nutzer entspricht.
        Ein getrennter Widerruf der Erfolgsmessung ist leider nicht möglich, in diesem Fall muss das gesamte
        Newsletterabonnement gekündigt, bzw. muss ihm widersprochen werden.
        Versand via SMS: Unsere Kommunikationen werden via SMS-Textnachrichten (auch) versendet.

        Verarbeitete Datenarten:Bestandsdaten (z.B. Namen, Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern),
        Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen), Nutzungsdaten (z.B. besuchte Webseiten,
        Interesse an Inhalten, Zugriffszeiten).
        Betroffene Personen:
        Zwecke der Verarbeitung:Direktmarketing (z.B. per E-Mail oder postalisch), Kontaktanfragen und Kommunikation.
        Rechtsgrundlagen:Einwilligung (Art. 6 Abs. 1 lit. a) DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 lit. f)
        DSGVO).
        Widerspruchsmöglichkeit (Opt-Out):Sie können den Empfang unseres Newsletters jederzeit kündigen, d.h. Ihre
        Einwilligungen widerrufen, bzw. dem weiteren Empfang widersprechen. Einen Link zur Kündigung des Newsletters
        finden Sie entweder am Ende eines jeden Newsletters oder können sonst eine der oben angegebenen
        Kontaktmöglichkeiten, vorzugswürdig E-Mail, hierzu nutzen.

        <br/><br/>
        <b>Werbliche Kommunikation via Post, Fax oder Telefon</b>
        <br/>
        Wir verarbeiten personenbezogene Daten zu Zwecken der werblichen Kommunikation, die über diverse Kanäle, wie
        z.B. E-Mail, Telefon, Post oder Fax, erfolgen kann. In diesem Zusammenhang beachten wir die gesetzlichen
        Vorgaben und holen die erforderlichen Einwilligungen ein, sofern die Kommunikation nicht gesetzlich erlaubt ist.
        Die Empfänger haben das Recht, erteilte Einwilligungen jederzeit zu widerrufen oder der werblichen Kommunikation
        jederzeit zu widersprechen.
        Nach Widerruf bzw. Widerspruch können wir die zum Nachweis der Einwilligung erforderlichen Daten bis zu drei
        Jahren auf Grundlage unserer berechtigten Interessen speichern, bevor wir sie löschen. Die Verarbeitung dieser
        Daten wird auf den Zweck einer möglichen Abwehr von Ansprüchen beschränkt. Ein individueller Löschungsantrag ist
        jederzeit möglich, sofern zugleich das ehemalige Bestehen einer Einwilligung bestätigt wird.

        Verarbeitete Datenarten:Bestandsdaten (z.B. Namen, Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern).
        Betroffene Personen:
        Zwecke der Verarbeitung:Direktmarketing (z.B. per E-Mail oder postalisch).
        Rechtsgrundlagen:Einwilligung (Art. 6 Abs. 1 lit. a) DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 lit. f)
        DSGVO).

        <br/><br/>
        <b>Affiliate-Programme und Affiliate-Links</b>
        <br/>
        In unser Onlineangebot binden wir sogenannte Affiliate-Links oder andere Verweise (zu denen z.B. Rabatt-Codes
        gehören können) auf die Angebote und Leistungen von Drittanbietern ein (zusammenfassend bezeichnet als
        “Affiliate-Links”). Wenn Nutzer den Affiliate-Links folgen, bzw. anschließend die Angebote wahrnehmen, können
        wir von diesen Drittanbietern eine Provision oder sonstige Vorteile erhalten (zusammenfassend bezeichnet als
        “Provision”).
        Um nachverfolgen zu können, ob die Nutzer die Angebote eines von uns eingesetzten Affiliate-Links wahrgenommen
        haben, ist es notwendig, dass die jeweiligen Drittanbieter erfahren, dass die Nutzer einem innerhalb unseres
        Onlineangebotes eingesetzten Affiliate-Link gefolgt sind. Die Zuordnung der Affiliate-Links zu den jeweiligen
        Geschäftsabschlüssen oder zu sonstigen Aktionen (z.B. Käufen) dient alleine dem Zweck der Provisionsabrechnung
        und wird aufgehoben, sobald sie für den Zweck nicht mehr erforderlich ist.
        Für die Zwecke der vorgenannten Zuordnung der Affiliate-Links können die Affiliate-Links um bestimmte Werte
        ergänzt werden, die ein Bestandteil des Links sind oder anderweitig, z.B. in einem Cookie, gespeichert werden
        können. Zu den Werten können insbesondere die Ausgangswebseite (Referrer), der Zeitpunkt, eine Online-Kennung
        der Betreiber der Webseite, auf der sich der Affiliate-Link befand, eine Online-Kennung des jeweiligen
        Angebotes, die Art des verwendeten Links, die Art des Angebotes und eine Online-Kennung des Nutzers gehören.
        Hinweise zu Rechtsgrundlagen: Sofern wir die Nutzer um deren Einwilligung in den Einsatz der Drittanbieter
        bitten, ist die Rechtsgrundlage der Verarbeitung von Daten die Einwilligung. Ferner kann deren Einsatz ein
        Bestandteil unserer (vor)vertraglichen Leistungen sein, sofern der Einsatz der Drittanbieter in diesem Rahmen
        vereinbart wurde. Ansonsten werden die Daten der Nutzer auf Grundlage unserer berechtigten Interessen (d.h.
        Interesse an effizienten, wirtschaftlichen und empfängerfreundlichen Leistungen) verarbeitet. In diesem
        Zusammenhang möchten wir Sie auch auf die Informationen zur Verwendung von Cookies in dieser
        Datenschutzerklärung hinweisen.

        <br/><br/>
        <b>Verarbeitete Datenarten</b>
        <br/>
        Vertragsdaten (z.B. Vertragsgegenstand, Laufzeit, Kundenkategorie), Nutzungsdaten (z.B.
        besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B.
        Geräte-Informationen, IP-Adressen).
        Betroffene Personen:Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).
        Zwecke der Verarbeitung:Affiliate-Nachverfolgung.
        Rechtsgrundlagen:Einwilligung (Art. 6 Abs. 1 lit. a) DSGVO), Vertragserfüllung und vorvertragliche Anfragen
        (Art. 6 Abs. 1 lit. b) DSGVO), Berechtigte Interessen (Art. 6 Abs. 1 lit. f) DSGVO).

        <br/><br/>
        <b>Präsenzen in sozialen Netzwerken</b>
        <br/>
        Wir unterhalten Onlinepräsenzen innerhalb sozialer Netzwerke, um mit den dort aktiven Nutzern zu kommunizieren
        oder um dort Informationen über uns anzubieten.
        Wir weisen darauf hin, dass dabei Daten der Nutzer außerhalb des Raumes der Europäischen Union verarbeitet
        werden können. Hierdurch können sich für die Nutzer Risiken ergeben, weil so z.B. die Durchsetzung der Rechte
        der Nutzer erschwert werden könnte. Im Hinblick auf US-Anbieter, die unter dem Privacy-Shield zertifiziert sind
        oder vergleichbare Garantien eines sicheren Datenschutzniveaus bieten, weisen wir darauf hin, dass sie sich
        damit verpflichten, die Datenschutzstandards der EU einzuhalten.
        Ferner werden die Daten der Nutzer innerhalb sozialer Netzwerke im Regelfall für Marktforschungs- und
        Werbezwecke verarbeitet. So können z.B. anhand des Nutzungsverhaltens und sich daraus ergebender Interessen der
        Nutzer Nutzungsprofile erstellt werden. Die Nutzungsprofile können wiederum verwendet werden, um z.B.
        Werbeanzeigen innerhalb und außerhalb der Netzwerke zu schalten, die mutmaßlich den Interessen der Nutzer
        entsprechen. Zu diesen Zwecken werden im Regelfall Cookies auf den Rechnern der Nutzer gespeichert, in denen das
        Nutzungsverhalten und die Interessen der Nutzer gespeichert werden. Ferner können in den Nutzungsprofilen auch
        Daten unabhängig der von den Nutzern verwendeten Geräte gespeichert werden (insbesondere, wenn die Nutzer
        Mitglieder der jeweiligen Plattformen sind und bei diesen eingeloggt sind).
        Für eine detaillierte Darstellung der jeweiligen Verarbeitungsformen und der Widerspruchsmöglichkeiten (Opt-Out)
        verweisen wir auf die Datenschutzerklärungen und Angaben der Betreiber der jeweiligen Netzwerke.
        Auch im Fall von Auskunftsanfragen und der Geltendmachung von Betroffenenrechten weisen wir darauf hin, dass
        diese am effektivsten bei den Anbietern geltend gemacht werden können. Nur die Anbieter haben jeweils Zugriff
        auf die Daten der Nutzer und können direkt entsprechende Maßnahmen ergreifen und Auskünfte geben. Sollten Sie
        dennoch Hilfe benötigen, dann können Sie sich an uns wenden.

        Verarbeitete Datenarten:Bestandsdaten (z.B. Namen, Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern),
        Inhaltsdaten (z.B. Texteingaben, Fotografien, Videos), Nutzungsdaten (z.B. besuchte Webseiten, Interesse an
        Inhalten, Zugriffszeiten), Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen).
        Betroffene Personen:Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten).
        Zwecke der Verarbeitung:Kontaktanfragen und Kommunikation, Tracking (z.B. interessens-/verhaltensbezogenes
        Profiling, Nutzung von Cookies), Remarketing, Reichweitenmessung (z.B. Zugriffsstatistiken, Erkennung
        wiederkehrender Besucher).
        Rechtsgrundlagen:Berechtigte Interessen (Art. 6 Abs. 1 lit. f) DSGVO).


        Eingesetzte Dienste und Diensteanbieter:
        Instagram :Soziales Netzwerk; Dienstanbieter: Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025, USA;
        Website: https://www.instagram.com; Datenschutzerklärung: http://instagram.com/about/legal/privacy.
        Facebook:Soziales Netzwerk; Dienstanbieter: Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour,
        Dublin 2, Irland, Mutterunternehmen: Facebook, 1 Hacker Way, Menlo Park, CA 94025, USA; Website:
        https://www.facebook.com; Datenschutzerklärung: https://www.facebook.com/about/privacy; Privacy Shield
        (Gewährleistung Datenschutzniveau bei Verarbeitung von Daten in den USA):
        https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active; Widerspruchsmöglichkeit
        (Opt-Out): Einstellungen für Werbeanzeigen: https://www.facebook.com/settings?tab=ads; Zusätzliche Hinweise zum
        Datenschutz: Vereinbarung über gemeinsame Verarbeitung personenbezogener Daten auf Facebook-Seiten:
        https://www.facebook.com/legal/terms/page_controller_addendum, Datenschutzhinweise für Facebook-Seiten:
        https://www.facebook.com/legal/terms/information_about_page_insights_data.
        LinkedIn:Soziales Netzwerk; Dienstanbieter: LinkedIn Ireland Unlimited Company, Wilton Place, Dublin 2, Irland;
        Website: https://www.linkedin.com; Datenschutzerklärung: https://www.linkedin.com/legal/privacy-policy; Privacy
        Shield (Gewährleistung Datenschutzniveau bei Verarbeitung von Daten in den USA):
        https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&status=Active; Widerspruchsmöglichkeit
        (Opt-Out): https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out.
        Twitter:Soziales Netzwerk; Dienstanbieter: Twitter Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103,
        USA; Datenschutzerklärung: https://twitter.com/de/privacy, (Einstellungen) https://twitter.com/personalization;
        Privacy Shield (Gewährleistung Datenschutzniveau bei Verarbeitung von Daten in den USA):
        https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&status=Active.
        YouTube:Soziales Netzwerk; Dienstanbieter: Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
        Irland, Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA;
        Datenschutzerklärung: https://policies.google.com/privacy; Privacy Shield (Gewährleistung Datenschutzniveau bei
        Verarbeitung von Daten in den USA):
        https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active; Widerspruchsmöglichkeit
        (Opt-Out): https://adssettings.google.com/authenticated.
        Xing:Soziales Netzwerk; Dienstanbieter: XING AG, Dammtorstraße 29-32, 20354 Hamburg, Deutschland; Website:
        https://www.xing.de; Datenschutzerklärung: https://privacy.xing.com/de/datenschutzerklaerung.

        <br/><br/>
        <b>Plugins und eingebettete Funktionen sowie Inhalte</b>
        <br/>
        Wir binden in unser Onlineangebot Funktions- und Inhaltselemente ein, die von den Servern ihrer jeweiligen
        Anbieter (nachfolgend bezeichnet als “Drittanbieter”) bezogen werden. Dabei kann es sich zum Beispiel um
        Grafiken, Videos oder Social-Media-Schaltflächen sowie Beiträge handeln (nachfolgend einheitlich bezeichnet als
        “Inhalte”).
        Die Einbindung setzt immer voraus, dass die Drittanbieter dieser Inhalte die IP-Adresse der Nutzer verarbeiten,
        da sie ohne die IP-Adresse die Inhalte nicht an deren Browser senden könnten. Die IP-Adresse ist damit für die
        Darstellung dieser Inhalte oder Funktionen erforderlich. Wir bemühen uns, nur solche Inhalte zu verwenden, deren
        jeweilige Anbieter die IP-Adresse lediglich zur Auslieferung der Inhalte verwenden. Drittanbieter können ferner
        sogenannte Pixel-Tags (unsichtbare Grafiken, auch als “Web Beacons” bezeichnet) für statistische oder
        Marketingzwecke verwenden. Durch die “Pixel-Tags” können Informationen, wie der Besucherverkehr auf den Seiten
        dieser Webseite, ausgewertet werden. Die pseudonymen Informationen können ferner in Cookies auf dem Gerät der
        Nutzer gespeichert werden und unter anderem technische Informationen zum Browser und zum Betriebssystem, zu
        verweisenden Webseiten, zur Besuchszeit sowie weitere Angaben zur Nutzung unseres Onlineangebotes enthalten als
        auch mit solchen Informationen aus anderen Quellen verbunden werden.
        Hinweise zu Rechtsgrundlagen: Sofern wir die Nutzer um deren Einwilligung in den Einsatz der Drittanbieter
        bitten, ist die Rechtsgrundlage der Verarbeitung von Daten die Einwilligung. Ansonsten werden die Daten der
        Nutzer auf Grundlage unserer berechtigten Interessen (d.h. Interesse an effizienten, wirtschaftlichen und
        empfängerfreundlichen Leistungen) verarbeitet. In diesem Zusammenhang möchten wir Sie auch auf die Informationen
        zur Verwendung von Cookies in dieser Datenschutzerklärung hinweisen.

        Verarbeitete Datenarten: Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten),
        Meta-/Kommunikationsdaten (z.B. Geräte-Informationen, IP-Adressen), Kontaktdaten (z.B. E-Mail, Telefonnummern),
        Inhaltsdaten (z.B. Texteingaben, Fotografien, Videos), Bestandsdaten (z.B. Namen, Adressen).
        Betroffene Personen: Nutzer (z.B. Webseitenbesucher, Nutzer von Onlinediensten), Kommunikationspartner.
        Zwecke der Verarbeitung: Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit, Vertragliche
        Leistungen und Service, Kontaktanfragen und Kommunikation, Direktmarketing (z.B. per E-Mail oder postalisch),
        Tracking (z.B. interessens-/verhaltensbezogenes Profiling, Nutzung von Cookies), Interessenbasiertes und
        verhaltensbezogenes Marketing, Profiling (Erstellen von Nutzerprofilen), Feedback (z.B. Sammeln von Feedback via
        Online-Formular), Sicherheitsmaßnahmen, Verwaltung und Beantwortung von Anfragen.
        Rechtsgrundlagen: Berechtigte Interessen (Art. 6 Abs. 1 lit. f) DSGVO), Einwilligung (Art. 6 Abs.1 lit. a)
        DSGVO), Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 lit. b) DSGVO).

        Google Fonts: Wir binden die Schriftarten (\”Google Fonts\”) des Anbieters Google ein, wobei die Daten der
        Nutzer allein zu Zwecken der Darstellung der Schriftarten im Browser der Nutzer verwendet werden. Die Einbindung
        erfolgt auf Grundlage unserer berechtigten Interessen an einer technisch sicheren, wartungsfreien und
        effizienten Nutzung von Schriftarten, deren einheitlicher Darstellung sowie unter Berücksichtigung möglicher
        lizenzrechtlicher Restriktionen für deren Einbindung. Dienstanbieter: Google Ireland Limited, Gordon House,
        Barrow Street, Dublin 4, Irland, Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA
        94043, USA; Website: https://fonts.google.com/; Datenschutzerklärung: https://policies.google.com/privacy;
        Privacy Shield (Gewährleistung Datenschutzniveau bei Verarbeitung von Daten in den USA):
        https://www.privacyshield.gov/participant?id=a2zt0000000TRkEAAW&status=Active.
        Instagram Plugins und -Schaltflächen:Instagram Plugins und -Schaltflächen – Hierzu können z.B. Inhalte wie
        Bilder, Videos oder Texte und Schaltflächen gehören, mit denen Nutzer Inhalte dieses Onlineangebotes innerhalb
        von Instagram teilen können. Dienstanbieter: https://www.instagram.com, Instagram Inc., 1601 Willow Road, Menlo
        Park, CA, 94025, USA; Website: https://www.instagram.com; Datenschutzerklärung:
        http://instagram.com/about/legal/privacy.
        LinkedIn Plugins und -Schaltflächen:LinkedIn Plugins und -Schaltflächen – Hierzu können z.B. Inhalte wie Bilder,
        Videos oder Texte und Schaltflächen gehören, mit denen Nutzer Inhalte dieses Onlineangebotes innerhalb von
        LinkedIn teilen können. Dienstanbieter: LinkedIn Ireland Unlimited Company, Wilton Place, Dublin 2, Irland;
        Website: https://www.instagram.com; Datenschutzerklärung: https://www.linkedin.com/legal/privacy-policy; Privacy
        Shield (Gewährleistung Datenschutzniveau bei Verarbeitung von Daten in den USA):
        https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&status=Active; Widerspruchsmöglichkeit
        (Opt-Out): https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out.
        ReCaptcha:Wir binden die Funktion \”ReCaptcha\” zur Erkennung von Bots, z.B. bei Eingaben in Onlineformularen,
        ein. Die Verhaltensangaben der Nutzer (z.B. Mausbewegungen oder Abfragen) werden ausgewertet, um Menschen von
        Bots unterscheiden zu können. Dienstanbieter: Google Ireland Limited, Gordon House, Barrow Street, Dublin 4,
        Irland, Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; Website:
        https://www.google.com/recaptcha/; Datenschutzerklärung: https://policies.google.com/privacy; Privacy Shield
        (Gewährleistung Datenschutzniveau bei Verarbeitung von Daten in den USA):
        https://www.privacyshield.gov/participant?id=a2zt0000000TRkEAAW&status=Active; Widerspruchsmöglichkeit
        (Opt-Out): Opt-Out-Plugin: http://tools.google.com/dlpage/gaoptout?hl=de, Einstellungen für die Darstellung von
        Werbeeinblendungen: https://adssettings.google.com/authenticated.
        Shariff: Wir verwenden die datenschutzsicheren \”Shariff“-Schaltflächen. \”Shariff“ wurde entwickelt, um mehr
        Privatsphäre im Netz zu ermöglichen und die üblichen \”Share\”-Buttons der sozialen Netzwerke zu ersetzen. Dabei
        stellt nicht der Browser der Nutzer, sondern der Server, auf dem sich dieses Onlineangebot befindet, eine
        Verbindung mit dem Server der jeweiligen Social-Media-Plattformen her und fragt z.B. die Anzahl von Likes ab.
        Der Nutzer bleibt anonym. Mehr Informationen zum Shariff-Projekt finden Sie bei den Entwicklern des Magazins
        c\’t: https://www.heise.de/ct/artikel/Shariff-Social-Media-Buttons-mit-Datenschutz-2467514.html; Dienstanbieter:
        Heise Medien GmbH & Co. KG, Karl-Wiechert-Allee 10, 30625 Hannover, Deutschland; Website:
        https://www.heise.de/ct/artikel/Shariff-Social-Media-Buttons-mit-Datenschutz-2467514.html; Datenschutzerklärung:
        https://www.heise.de/Datenschutzerklaerung-der-Heise-Medien-GmbH-Co-KG-4860.html.
        Twitter Plugins und -Schaltflächen: Twitter Plugins und -Schaltflächen – Hierzu können z.B. Inhalte wie Bilder,
        Videos oder Texte und Schaltflächen gehören, mit denen Nutzer Inhalte dieses Onlineangebotes innerhalb von
        Twitter teilen können. Dienstanbieter: Twitter Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103,
        USA; Website: https://twitter.com/de; Datenschutzerklärung: https://twitter.com/de/privacy.
        YouTube: Videos; Dienstanbieter: Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland,
        Mutterunternehmen: Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; Website:
        https://www.youtube.com; Datenschutzerklärung: https://policies.google.com/privacy; Privacy Shield
        (Gewährleistung Datenschutzniveau bei Verarbeitung von Daten in den USA):
        https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active; Widerspruchsmöglichkeit
        (Opt-Out): Opt-Out-Plugin: http://tools.google.com/dlpage/gaoptout?hl=de, Einstellungen für die Darstellung von
        Werbeeinblendungen: https://adssettings.google.com/authenticated.
        Xing Plugins und -Schaltflächen: Xing Plugins und -Schaltflächen – Hierzu können z.B. Inhalte wie Bilder, Videos
        oder Texte und Schaltflächen gehören, mit denen Nutzer Inhalte dieses Onlineangebotes innerhalb von Xing teilen
        können. Dienstanbieter: XING AG, Dammtorstraße 29-32, 20354 Hamburg, Deutschland; Website: https://www.xing.com;
        Datenschutzerklärung: https://privacy.xing.com/de/datenschutzerklaerung.
        Borlabs Cookie:Diese Website verwendet Borlabs Cookie, dass ein technisch notwendige Cookies (borlabs-cookie)
        setzt, um Ihre Cookie-Einwilligungen zu speichern.
        Borlabs Cookie verarbeitet keinerlei personenbezogenen Daten.
        Im Cookie borlabs-cookie werden Ihre Einwilligungen gespeichert, die Sie beim Betreten der Website gegeben
        haben. Möchten Sie diese Einwilligungen widerrufen, löschen Sie einfach das Cookie in Ihrem Browser. Wenn Sie
        die Website neu betreten/neu laden, werden Sie erneut nach Ihrer Cookie-Einwilligung gefragt.

        <br/><br/>
        <b>Webinare & Onlinemeeting</b>
        <br/>
        Wir bieten auf dieser Website auch Webinare und Onlinemeetings an. Hier benötigen wir eine Technologie, mit der
        die Webinare und Onlinemeetings durchgeführt werden.
        Aktuell verwenden wir „Microsoft Teams“, ein Dienst des US-Anbieters Microsoft, Inc..
        Rechtsgrundlage für die Verarbeitung von Daten via „Microsoft Teams“ ist Art. 6 Abs. 1 lit. b) DSGVO. Ich
        erbringe mit „Microsoft Teams“ meine vertraglichen Leistungen, insbesondere für meine Datenschutz-Kunde. Dies
        beinhaltet neben der Durchführung von Webinaren und Onlinemeetings auch die Nachbereitung von Teilnahmedaten für
        die Erstellung von Dokumentationen (z.B. Onlineschulungen).
        Einsatz von Teamviewer
        Für den Remote-Support nutzen wir ausschließlich die Software und Dienstleistung der TeamViewer GmbH. Hierzu
        biete ich Ihnen den Download einer für diese Zwecke geeigneten TeamViewer-Software von unserer Website an.
        Mittels dieser Software können wir uns auf Ihren PC oder Server aufschalten, um den von Ihnen angefragten
        Support zu erbringen. Die Verbindung läuft dabei über Server der TeamViewer GmbH. Mit der Nutzung des
        Remote-Support unter Nutzung von TeamViewer willigen Sie in die damit ggf. im Zusammenhang stehende Verarbeitung
        personenbezogener Daten ein. Die Nutzung von TeamViewer erfolgt zur Erfüllung des zwischen Ihnen und uns
        bestehenden Vertrags und im beiderseitigen Interesse der effizienten und kurzfristigen Erbringung von
        Support-Dienstleistungen durch uns. Rechtsgrundlage für die Nutzung des Plug-ins ist Art. 6 Abs. 1 S. 1 lit. a,
        b und f DSGVO.
        Durch den Besuch der Unterseite unserer Website, auf der ich den Download der TeamViewer-Software anbieten,
        erhält TeamViewer die Information, dass Sie die entsprechende Unterseite unserer Website aufgerufen haben. Wir
        haben keine Kenntnis darüber, für ob und ggf. für welche eigenen Zwecke TeamViewer diese Informationen nutzt.
        Die Nutzung von TeamViewer erfolgt gemäß der TeamViewer-Datenschutzerklärung. Weiter Informationen hierzu
        erhalten Sie bei TeamViewer GmbH, Jahnstraße 30, 73037 Göppingen; Datenschutzhinweise:
        https://www.teamviewer.com/de/privacy-policy/ ; Sicherheitshinweise: https://www.teamviewer.com/de/security/
        Löschung von Daten
        Die von uns verarbeiteten Daten werden nach Maßgabe der gesetzlichen Vorgaben gelöscht, sobald deren zur
        Verarbeitung erlaubten Einwilligungen widerrufen werden oder sonstige Erlaubnisse entfallen (z.B., wenn der
        Zweck der Verarbeitung dieser Daten entfallen ist oder sie für den Zweck nicht erforderlich sind).
        Sofern die Daten nicht gelöscht werden, weil sie für andere und gesetzlich zulässige Zwecke erforderlich sind,
        wird deren Verarbeitung auf diese Zwecke beschränkt. D.h., die Daten werden gesperrt und nicht für andere Zwecke
        verarbeitet. Das gilt z.B. für Daten, die aus handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen
        oder deren Speicherung zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der
        Rechte einer anderen natürlichen oder juristischen Person erforderlich ist.
        Weitere Hinweise zu der Löschung von personenbezogenen Daten können ferner im Rahmen der einzelnen
        Datenschutzhinweise dieser Datenschutzerklärung erfolgen.
        Aktualisierung und Aktualität der Datenschutzerklärung
        Wir bitten Sie, sich regelmäßig über den Inhalt unserer Datenschutzerklärung zu informieren. Wir passen die
        Datenschutzerklärung an, sobald die Änderungen der von uns durchgeführten Datenverarbeitungen dies erforderlich
        machen. Wir informieren Sie, sobald durch die Änderungen eine Mitwirkungshandlung Ihrerseits (z.B. Einwilligung)
        oder eine sonstige individuelle Benachrichtigung erforderlich wird.
        Diese Datenschutzerklärung ist aktuell gültig und hat den Stand Mai 2022. Durch die Weiterentwicklung unserer
        Website und Angebote darüber oder aufgrund geänderter gesetzlicher beziehungsweise behördlicher Vorgaben kann es
        notwendig werden, diese Datenschutzerklärung zu ändern. Die jeweils aktuelle Datenschutzerklärung kann jederzeit
        auf unserer Website unter dem Menüpunkt „Datenschutz“ von Ihnen abgerufen und ausgedruckt werden.
        Rechte der betroffenen Personen
        Ihnen stehen als Betroffene nach der DSGVO verschiedene Rechte zu, die sich insbesondere aus Art. 15 bis 18 und
        21 DS-GVO ergeben:

        Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit
        gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder
        f DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling.
        Werden die Sie betreffenden personenbezogenen Daten verarbeitet, um Direktwerbung zu betreiben, haben Sie das
        Recht, jederzeit Widerspruch gegen die Verarbeitung der Sie betreffenden personenbezogenen Daten zum Zwecke
        derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in
        Verbindung steht.
        Widerrufsrecht bei Einwilligungen:Sie haben das Recht, erteilte Einwilligungen jederzeit zu widerrufen.
        Auskunftsrecht:Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob betreffende Daten verarbeitet
        werden und auf Auskunft über diese Daten sowie auf weitere Informationen und Kopie der Daten entsprechend den
        gesetzlichen Vorgaben.
        Recht auf Berichtigung:Sie haben entsprechend den gesetzlichen Vorgaben das Recht, die Vervollständigung der Sie
        betreffenden Daten oder die Berichtigung der Sie betreffenden unrichtigen Daten zu verlangen.
        Recht auf Löschung und Einschränkung der Verarbeitung:Sie haben nach Maßgabe der gesetzlichen Vorgaben das
        Recht, zu verlangen, dass Sie betreffende Daten unverzüglich gelöscht werden, bzw. alternativ nach Maßgabe der
        gesetzlichen Vorgaben eine Einschränkung der Verarbeitung der Daten zu verlangen.
        Recht auf Datenübertragbarkeit:Sie haben das Recht, Sie betreffende Daten, die Sie uns bereitgestellt haben,
        nach Maßgabe der gesetzlichen Vorgaben in einem strukturierten, gängigen und maschinenlesbaren Format zu
        erhalten oder deren Übermittlung an einen anderen Verantwortlichen zu fordern.
        Beschwerde bei Aufsichtsbehörde:Sie haben ferner nach Maßgabe der gesetzlichen Vorgaben das Recht, bei einer
        Aufsichtsbehörde, insbesondere in dem Mitgliedstaat Ihres gewöhnlichen Aufenthaltsorts, Ihres Arbeitsplatzes
        oder des Orts des mutmaßlichen Verstoßes, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden
        personenbezogenen Daten gegen die DSGVO verstößt.

        <br/><br/>
        <b>Begriffsdefinitionen</b>
        <br/>
        In diesem Abschnitt erhalten Sie eine Übersicht über die in dieser Datenschutzerklärung verwendeten
        Begrifflichkeiten. Viele der Begriffe sind dem Gesetz entnommen und vor allem im Art. 4 DSGVO definiert. Die
        gesetzlichen Definitionen sind verbindlich. Die nachfolgenden Erläuterungen sollen dagegen vor allem dem
        Verständnis dienen. Die Begriffe sind alphabetisch sortiert.

        Affiliate-Nachverfolgung:Im Rahmen der Affiliate-Nachverfolgung werden Links, mit deren Hilfe die verlinkenden
        Webseiten Nutzer zu Webseiten mit Produkt- oder sonstigen Angeboten verweisen, protokolliert. Die Betreiber der
        jeweils verlinkenden Webseiten können eine Provision erhalten, wenn Nutzer diesen sogenannten Affiliate-Links
        folgen und anschließend die Angebote wahrnehmen (z.B. Waren kaufen oder Dienstleistungen in Anspruch nehmen).
        Hierzu ist es erforderlich, dass die Anbieter nachverfolgen können, ob Nutzer, die sich für bestimmte Angebote
        interessieren, diese anschließend auf die Veranlassung der Affiliate-Links wahrnehmen. Daher ist es für die
        Funktionsfähigkeit von Affiliate-Links erforderlich, dass sie um bestimmte Werte ergänzt werden, die ein
        Bestandteil des Links werden oder anderweitig, z.B. in einem Cookie, gespeichert werden. Zu den Werten gehören
        insbesondere die Ausgangswebseite (Referrer), der Zeitpunkt, eine Online-Kennung der Betreiber der Webseite, auf
        der sich der Affiliate-Link befand, eine Online-Kennung des jeweiligen Angebotes, eine Online-Kennung des
        Nutzers als auch nachverfolgungsspezifische Werte, wie, z.B. Werbemittel-ID, Partner-ID und Kategorisierungen.
        Besuchsaktionsauswertung:“Besuchsaktionsauswertung” (englisch “Conversion Tracking”) bezeichnet ein Verfahren,
        mit dem die Wirksamkeit von Marketingmaßnahmen festgestellt werden kann. Dazu wird im Regelfall ein Cookie auf
        den Geräten der Nutzer innerhalb der Webseiten, auf denen die Marketingmaßnahmen erfolgen, gespeichert und dann
        erneut auf der Zielwebseite abgerufen. Beispielsweise können wir so nachvollziehen, ob die von uns auf anderen
        Webseiten geschalteten Anzeigen erfolgreich waren).
        IP-Masking:Als “IP-Masking” wird eine Methode bezeichnet, bei der das letzte Oktett, d.h., die letzten beiden
        Zahlen einer IP-Adresse, gelöscht wird, damit die IP-Adresse nicht mehr der eindeutigen Identifizierung einer
        Person dienen kann. Daher ist das IP-Masking ein Mittel zur Pseudonymisierung von Verarbeitungsverfahren,
        insbesondere im Onlinemarketing
        Interessenbasiertes und verhaltensbezogenes Marketing:Von interessens- und/oder verhaltensbezogenem Marketing
        spricht man, wenn potentielle Interessen von Nutzern an Anzeigen und sonstigen Inhalten möglichst genau
        vorbestimmt werden. Dies geschieht anhand von Angaben zu deren Vorverhalten (z.B. Aufsuchen von bestimmten
        Webseiten und Verweilen auf diesen, Kaufverhaltens oder Interaktion mit anderen Nutzern), die in einem
        sogenannten Profil gespeichert werden. Zu diesen Zwecken werden im Regelfall Cookies eingesetzt.
        Konversionsmessung:Die Konversionsmessung ist ein Verfahren, mit dem die Wirksamkeit von Marketingmaßnahmen
        festgestellt werden kann. Dazu wird im Regelfall ein Cookie auf den Geräten der Nutzer innerhalb der Webseiten,
        auf denen die Marketingmaßnahmen erfolgen, gespeichert und dann erneut auf der Zielwebseite abgerufen.
        Beispielsweise können wir so nachvollziehen, ob die von uns auf anderen Webseiten geschalteten Anzeigen
        erfolgreich waren.
        Personenbezogene Daten:“Personenbezogene Daten“ sind alle Informationen, die sich auf eine identifizierte oder
        identifizierbare natürliche Person (im Folgenden “betroffene Person“) beziehen; als identifizierbar wird eine
        natürliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem
        Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu einem oder mehreren
        besonderen Merkmalen identifiziert werden kann, die Ausdruck der physischen, physiologischen, genetischen,
        psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind.
        Profiling:Als “Profiling“ wird jede Art der automatisierten Verarbeitung personenbezogener Daten bezeichnet, die
        darin besteht, dass diese personenbezogenen Daten verwendet werden, um bestimmte persönliche Aspekte, die sich
        auf eine natürliche Person beziehen (je nach Art des Profilings gehören dazu Informationen betreffend das Alter,
        das Geschlecht, Standortdaten und Bewegungsdaten, Interaktion mit Webseiten und deren Inhalten,
        Einkaufsverhalten, soziale Interaktionen mit anderen Menschen) zu analysieren, zu bewerten oder, um sie
        vorherzusagen (z.B. die Interessen an bestimmten Inhalten oder Produkten, das Klickverhalten auf einer Webseite
        oder den Aufenthaltsort). Zu Zwecken des Profilings werden häufig Cookies und Web-Beacons eingesetzt.
        Reichweitenmessung:Die Reichweitenmessung (auch als Web Analytics bezeichnet) dient der Auswertung der
        Besucherströme eines Onlineangebotes und kann das Verhalten oder Interessen der Besucher an bestimmten
        Informationen, wie z.B. Inhalten von Webseiten, umfassen. Mit Hilfe der Reichweitenanalyse können
        Webseiteninhaber z.B. erkennen, zu welcher Zeit Besucher ihre Webseite besuchen und für welche Inhalte sie sich
        interessieren. Dadurch können sie z.B. die Inhalte der Webseite besser an die Bedürfnisse ihrer Besucher
        anpassen. Zu Zwecken der Reichweitenanalyse werden häufig pseudonyme Cookies und Web-Beacons eingesetzt, um
        wiederkehrende Besucher zu erkennen und so genauere Analysen zur Nutzung eines Onlineangebotes zu erhalten.
        Remarketing:Vom “Remarketing“ bzw. “Retargeting“ spricht man, wenn z.B. zu Werbezwecken vermerkt wird, für
        welche Produkte sich ein Nutzer auf einer Webseite interessiert hat, um den Nutzer auf anderen Webseiten an
        diese Produkte, z.B. in Werbeanzeigen, zu erinnern.
        Tracking:Vom “Tracking“ spricht man, wenn das Verhalten von Nutzern über mehrere Onlineangebote hinweg
        nachvollzogen werden kann. Im Regelfall werden im Hinblick auf die genutzten Onlineangebote Verhaltens- und
        Interessensinformationen in Cookies oder auf Servern der Anbieter der Trackingtechnologien gespeichert
        (sogenanntes Profiling). Diese Informationen können anschließend z.B. eingesetzt werden, um den Nutzern
        Werbeanzeigen anzuzeigen, die voraussichtlich deren Interessen entsprechen.
        Verantwortlicher:Als “Verantwortlicher“ wird die natürliche oder juristische Person, Behörde, Einrichtung oder
        andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von
        personenbezogenen Daten entscheidet, bezeichnet.
        Verarbeitung:“Verarbeitung” ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder
        jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten. Der Begriff reicht weit und umfasst
        praktisch jeden Umgang mit Daten, sei es das Erheben, das Auswerten, das Speichern, das Übermitteln oder das
        Löschen.

        <br/><br/><br/>
        Diese Datenschutzerklärung wurde von made.systems GbR erstellt und wurde durch unterschiedliche
        Datenschutzerklärungs-Generatoren von Dr. Solmecke und Dr. Schwenke ergänzt.
        <br/><br/>
        v. 1.01 Stand 05/2022

        <!-- Veränderungen bis hier -->
    </div>
</template>

<script>

export default {
    name: 'DataPrivacyText',
}
</script>

<style lang="scss" scoped>

</style>
