<template>
    <div class="title-struct">

        <Seo :properties="titlePoperties"/>
        <div class="uk-grid-small uk-margin-remove uk-padding-remove uk-grid-match" uk-grid>
            <div class="uk-width-expand@s uk-width-1-1 uk-padding-remove">
                <NestedTitle :properties="titlePoperties"/>
            </div>
            <div v-if="$slots.action"
                 class="uk-text-right uk-visible@s">
                <slot name="action"/>
            </div>
            <div v-if="$slots.action"
                 class="uk-hidden@s uk-padding-remove">
                <slot name="action"/>
            </div>
        </div>

    </div>
</template>

<script>

import Seo from "@/components/generic/Seo";
import NestedTitle from "@/components/NestedTitle";

export default {
    name: 'GenericSiteTitle',
    components: {NestedTitle, Seo},
    props: {
        titlePoperties: Array,
    },
}

</script>

<style lang="scss" scoped>

.title-struct {
    margin-bottom: 25px;
}


</style>
