<template>
    <div>

        <LoadingStruct :is-loading="loading">
            <GenericSiteTitle :title-poperties="[{key: 0, disabled: true}]">
                <template v-slot:action>
                    <CreateSatellite :distributors="distributors" @afterSuccess="getItems"/>
                </template>
            </GenericSiteTitle>

            <GenericItemView
                :computed-items="computedItems"
                :error="error"
                :filters="filters"
                :is-loading="loading"
                :is-search="search.length>0"
                :items="items"
                :no-items-message="$t('noSatellitesMessage')"
                is-searchable
                label-plural="satellites"
                @search="setSearch"
            >
                <template v-slot:items>
                    <li v-for="item in computedItems" :key="item.id"
                        :data-expiry="item.expiry"
                        :data-status="item.status"
                    >
                        <SatelliteItem :distributors="distributors" :item="item" is-admin-view
                                       @afterSuccess="softUpdate" @setDeleted="setDeleted"/>
                    </li>
                </template>

            </GenericItemView>
        </LoadingStruct>

    </div>
</template>

<script>

import {getDistributor, getSatellite} from "@/client/vue-api-client";
import GenericItemView from "@/views/GenericItemView";
import GenericSiteTitle from "@/views/GenericSiteTitle";
import LoadingStruct from "@/views/LoadingStruct";
import SatelliteItem from "@/components/items/SatellitItem";
import CreateSatellite from "@/components/creates/CreateSatellite";
import Vue from "vue";

export default {
    name: 'SatellitesAdministration',
    components: {
        CreateSatellite,
        SatelliteItem,
        LoadingStruct,
        GenericSiteTitle,
        GenericItemView,
    },
    data() {
        return {
            loading: true,
            error: null,
            items: [],
            search: "",

            filters: [
                {
                    type: 'status',
                    values: [
                        {name: 'unassigned', total: 0},
                        {name: 'assigned', total: 0},
                    ]
                },
                {
                    type: 'expiry',
                    values: [
                        {name: 'expired', total: 0},
                        {name: 'expiring', total: 0},
                        {name: 'valid', total: 0},
                    ]
                },
            ],

            distributors: [],
        }
    },
    created() {
        this.getDistributors();
    },
    computed: {
        computedItems() {
            let items = this.items;
            if (this.search && this.search.length) {
                items = this.powerSearcher(items, this.search);
            }

            if (items.length > this.defaultShowLimit) {
                return items.slice(0, this.defaultShowLimit);
            }

            return items;
        },
    },
    methods: {
        softUpdate(item) {
            for (let i = 0, j = this.items.length; i < j; i++) {
                if (this.items[i].id === item.id) {
                    Vue.set(this.items, i, item);
                    Vue.set(this.items[i], "status", this.getSatelliteStatus(item));
                    Vue.set(this.items[i], "expiry", this.getExpiryStatus(item));
                    break;
                }
            }
        },
        setSearch(text) {
            this.search = text;
        },
        getExpiryStatus(item) {
            let daysLeft = this.getDaysLeftByDateString(item.emergency.expiresAt);
            if (daysLeft < 0) {
                return "expired"
            }

            if (daysLeft < 60) {
                return "expiring"
            }

            return 'valid';
        },
        getSatelliteStatus(item) {
            if (!item.distributorId) {
                return 'unassigned';
            }

            return 'assigned';
        },
        getDistributors() {
            let self = this;

            getDistributor().then(function (response) {
                self.distributors = response.data;
                self.getItems();

            }).catch(function (e) {
                self.checkGenericError(e);
                self.error = e;
                self.loading = false;

            })

        },
        setDeleted(id) {
            this.items = this.items.filter(x => {
                return x.id !== id;
            });
        },
        getDistributorById(id) {
            for (let i = 0, j = this.distributors.length; i < j; i++) {
                if (id === this.distributors[i].id) {
                    return this.distributors[i];
                }
            }
        },
        getItems(satellite = null) {
            let self = this;

            getSatellite().then(function (response) {
                let items = response.data;

                if (satellite) {
                    for (let i = 0, j = items.length; i < j; i++) {
                        if (items[i].id === satellite.id) {
                            items[i] = satellite;
                        }
                    }
                }

                for (let i = 0, j = items.length; i < j; i++) {
                    items[i]['status'] = self.getSatelliteStatus(items[i]);
                    items[i]['expiry'] = self.getExpiryStatus(items[i]);

                    let distributor = self.getDistributorById(items[i].distributorId);
                    if (distributor) {
                        items[i]['distributorName'] = distributor.name;
                    } else {
                        items[i]['distributorName'] = self.$t('withoutDistributor');
                    }
                }

                items.reverse();

                /*items = items.sort(function (a, b) {
                    return b['status'].localeCompare(a['status'])
                });

                items = items.sort(function (a, b) {
                    return a['expiry'].localeCompare(b['expiry'])
                });*/

                self.items = items;
                self.loading = false;

            }).catch(function (e) {
                self.checkGenericError(e);
                self.error = e;
                self.loading = false;

            })
        },
    }
}

</script>

<style lang="scss" scoped>

</style>
