<template>
    <div>

        <div :class="'label'+(isLabelLarge ? ' large' : '')">
            <div v-if="error">
                <div class="input-error">{{ removeSentenceEndingChar() }}.</div>
            </div>
            <div v-else>
                {{ label }}
                <slot/>
            </div>
        </div>

    </div>
</template>

<script>

export default {
    name: 'LabelWithError',
    props: {
        label: String,
        error: String,
        isLabelLarge: Boolean,
    },
    methods: {
        removeSentenceEndingChar() {
            if (this.label.endsWith(".")) {
                return this.error.replace(".", "");
            }

            return this.error;
        },
    }
}
</script>

<style lang="scss" scoped>

.label {
    margin-bottom: 8px;
    font-size: $site-font-size-small;
    color: $border-color-hover;

    &.large {
        text-align: left;
        font-size: 18px;
        font-weight: 500;
        //color: $theme-color-hover;
        color: $site-color-hover;

        &.dark {
            color: $site-color-hover;
        }
    }
}

.input-error {
    color: $danger-color;
}

</style>
