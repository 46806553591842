<template>
    <GenericItem
        :actions="getActions()" :additionals="[{icon: 'mail', text: member.mailAddress, copy: true}]"
        :icon="member.name ? 'user' : 'incognito'"

        :icon-tooltip="$t(member.name ?'member' : 'invitedMember')"
        :labels="getLabels()"

        :name="member.name ? member.name : member.mailAddress.split('@')[0]">

    </GenericItem>
</template>


<script>

import GenericItem from "@/components/items/GenericItem";
import {deleteDeviceByIdInvitesByMailAddress, deleteDeviceByIdUserIdsByMailAddress,} from "@/client/vue-api-client";

export default {
    name: 'MemberItem',
    components: {GenericItem},
    props: {
        deviceId: String,
        isOwner: Boolean,

        member: {
            id: String,
            mailAddress: String,
            name: String,
            invited: Boolean,
        },
    },
    methods: {
        getActions() {
            let actions = [];

            if (!this.isOwner) {
                actions.push({
                    icon: 'trash',
                    text: this.$t('delete'),
                    mode: 'disabled',
                    isDisabled: true,
                    noAction: true,
                    tooltip: this.$t('owner.only.action', {description: this.$t('members.delete')}),
                });

                return actions;
            }

            if (this.member.invited) {
                actions.push({
                    action: this.deleteInvite,
                    icon: 'trash',
                    text: this.$t('delete'),
                    mode: 'danger',

                    button: this.$t('deleteInvitation'),
                    confirmTitle: this.$t('reallyDelete'),
                    confirmMessage: this.$t('reallyDeleteInvite', {email: this.member.mailAddress})
                });

            } else if (this.member.status !== 'owner') {
                actions.push({
                    action: this.deleteMember,
                    icon: 'trash',
                    text: this.$t('delete'),
                    mode: 'danger',

                    button: this.$t('member.delete'),
                    confirmTitle: this.$t('reallyDelete'),
                    confirmMessage: this.$t('member.delete.confirm', {email: this.member.mailAddress})
                });

            } else {
                actions.push({
                    icon: 'trash',
                    text: this.$t('delete'),
                    mode: 'disabled',
                    isDisabled: true,
                    tooltip: this.$t('ownerDeletionNotAllowedMessage'),
                });

            }

            return actions;
        },
        getLabels() {
            let labels = [];

            if (this.member.status === 'owner') {
                labels.push({
                    type: "theme",
                    icon: "user",
                    text: this.$t('owner'),
                })

            } else if (this.member.status === 'invited') {
                labels.push({
                    type: "warning",
                    icon: "user",
                    text: this.$t('invited'),
                })

            } else {
                labels.push({
                    type: "member",
                    icon: "user",
                    text: this.$t('member'),
                })

            }

            return labels;
        },
        deleteInvite() {
            let self = this;

            deleteDeviceByIdInvitesByMailAddress({
                id: self.deviceId,
                mailAddress: self.member.mailAddress,

            }).then(function () {
                self.$emit('setDeleted', self.member.mailAddress);

            }).catch(function (e) {
                self.checkGenericError(e);

            })
        },
        deleteMember() {
            let self = this;
            deleteDeviceByIdUserIdsByMailAddress({
                id: self.deviceId,
                mailAddress: self.member.mailAddress,

            }).then(function () {
                self.$emit('setDeleted', self.member.mailAddress);

            }).catch(function (e) {
                self.checkGenericError(e);

            })
        },
    }
}

</script>


<style lang="scss" scoped>

</style>
