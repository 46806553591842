<template>

    <div uk-grid>
        <div v-if="label && !isLabelHidden" class="uk-flex uk-flex-middle uk-width-expand">
            <label v-on:click="updateValue()" :for="'switcher'+label" class="cursor-pointer label-text">{{label}}</label>
        </div>

        <div class="uk-flex uk-flex-middle">
            <label :for="'switcher'+label" class="uk-switch">
                <input :id="'switcher'+label" :checked="val" type="checkbox">
                <div class="uk-switch-slider"/>
            </label>
        </div>
    </div>


</template>

<script>

export default {
    name: 'SwitchButton',
    props: {
        label: String,
        value: Boolean,
        isLabelHidden: Boolean,
    },
    data() {
        return {
            val: false,
        }
    },
    created() {
      this.val=  JSON.parse(JSON.stringify(this.value));
    },
    methods: {
        updateValue() {
            this.val = !this.val;
            this.$emit('input', !this.value)
        },
    }
}

</script>


<style lang="scss" scoped>

.label-text {
    font-weight: 400;
    font-size: 16px;
}

.uk-switch {
    position: relative;
    display: inline-block;
    height: 34px;
    width: 60px;
}

/* Hide default HTML checkbox */
.uk-switch input {
    display: none;
}

/* Slider */
.uk-switch-slider {
    background-color: rgba(0, 0, 0, 0.22);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 500px;
    bottom: 0;
    cursor: pointer;
    transition-property: background-color;
    transition-duration: .2s;
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.07);
}

/* Switch pointer */
.uk-switch-slider:before {
    content: '';
    background-color: #fff;
    position: absolute;
    width: 30px;
    height: 30px;
    left: 2px;
    bottom: 2px;
    border-radius: 50%;
    transition-property: transform, box-shadow;
    transition-duration: .2s;
}

/* Slider active color */
input:checked + .uk-switch-slider {
    background-color: $theme-background !important;
}

/* Pointer active animation */
input:checked + .uk-switch-slider:before {
    transform: translateX(26px);
}

/* Modifiers */
.uk-switch-slider.uk-switch-on-off {
    background-color: $danger-color;
}

input:checked + .uk-switch-slider.uk-switch-on-off {
    background-color: $success-color !important;
}

/* Style Modifier */
.uk-switch-slider.uk-switch-big:before {
    transform: scale(1.2);
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.22);
}

.uk-switch-slider.uk-switch-small:before {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.22);
}

input:checked + .uk-switch-slider.uk-switch-big:before {
    transform: translateX(26px) scale(1.2);
}

/* Inverse Modifier - affects only default */
.uk-light .uk-switch-slider:not(.uk-switch-on-off) {
    background-color: rgba(255, 255, 255, 0.22);
}

</style>