import Vue from 'vue'
import Vuex from 'vuex'
import VueLocalStorage from 'vue-localstorage'

Vue.use(Vuex)
Vue.use(VueLocalStorage)

export default new Vuex.Store({
    state: {
        token: null,
        userId: null,
        userEmail: null,
        roleId: null,

        distributor: false,

        allowedRoles: ["User", "Administrator"],

        requestedPath: null,
        invitedPath: null,
        invitedEmail: "",

        cookieconsent: null,
    },
    mutations: {},
    actions: {},
    modules: {}
})
