<template>
    <div>
    </div>
</template>

<script>

import {getUserById} from "@/client/vue-api-client";

export default {
    name: 'Main',
    created() {
        let token = this.$cookie.get('token');
        let userId = this.$cookie.get("userId");

        if (token && userId) {

            let self = this;
            getUserById({
                id: userId,

            }).then(function (response) {
                self.$store.state.roleId = response.data.roleId;
                self.$store.state.userEmail = response.data.mailAddress;

                if (self.getInvitedTo) {
                    self.$router.push(self.getInvitedTo);
                    self.$store.state.invitedPath = null;
                    self.$store.state.invitedEmail = "";
                    self.$store.state.requestedPath = null;
                    return;
                }

                if (self.getPathTo) {
                    self.$router.push(self.getPathTo);
                    self.$store.state.requestedPath = null;
                    return;
                }

                self.routeTo('dashboard');

            }).catch(function (e) {
                self.checkGenericError(e);

                self.routeTo('login');

            })
        }

        this.routeTo('login');
    }
}

</script>

<style lang="scss" scoped>

</style>
