<template>
    <div class="info-card">

        <Card mode="default">

            <div class="uk-grid-small" uk-grid>
                <div class="uk-width-auto">
                    <Icon :icon="icon" :ratio="1.5" class="icon"/>
                </div>
                <div class="uk-width-expand uk-flex uk-flex-middle">
                    <div class="text">
                        <slot/>
                    </div>
                </div>
            </div>

        </Card>

    </div>
</template>

<script>

import Icon from "./Icon";
import Card from "./Card";

export default {
    name: 'InfoCard',
    components: {Card, Icon},
    props: {
        icon: String,
    }
}

</script>


<style lang="scss" scoped>

.text {
    font-size: 17px;
}

.icon {
    color: $theme-background;
}

</style>