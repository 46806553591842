<template>
    <div class="reset-password">


        <SiteTitle :name="$t('resetPassword')" class="uk-text-center"/>

        <div v-if="reset" class="uk-animation-fade">
            <InfoBox>
                {{ $t('resetMessage') }}
            </InfoBox>

            <div class="uk-margin-medium-top"/>
            <LinkItem to="login">
                <Button :text="$t('gotoLogin')" is-large is-max mode="theme"/>
            </LinkItem>
        </div>
        <div v-else>

            <div class="uk-margin-medium-top"/>

            <TextField v-model="email" :onEnter="resetPassword" :validate="trigger" autofocus
                       icon="mail" label="emailAddress" name="email" rules="required|email" @setValid="setEmailValid"/>


            <div v-if="resetError" class="uk-animation-fade">
                <div class="uk-margin-medium-top"/>
                <InfoBox mode="danger">
                    <ErrorMessage :error="resetError"/>
                </InfoBox>
            </div>

            <div class="uk-margin-medium-top"/>
            <Button :action="resetPassword" :text="$t('reset')" has-action is-large is-max mode="theme"/>


            <div class="uk-margin-medium-top"/>
            <hr class="blur-fix"/>
            <div class="uk-margin-medium-top"/>


            <LinkItem to="login">
                <Button :text="$t('goBackToLogin')" is-max mode="default"/>
            </LinkItem>


        </div>


    </div>
</template>

<script>

import TextField from "@/components/generic/TextField";
import Button from "@/components/generic/Button";
import SiteTitle from "@/components/SiteTitle";
import InfoBox from "@/components/generic/InfoBox";
import LinkItem from "@/components/generic/LinkItem";
import {postRecovery} from "@/client/vue-api-client";
import ErrorMessage from "@/components/generic/ErrorMessage";

export default {
    name: 'ResetPassword',
    components: {ErrorMessage, LinkItem, InfoBox, SiteTitle, Button, TextField},
    data() {
        return {
            email: "",
            reset: false,

            emailValid: false,

            trigger: 0,
            loading: false,
            resetError: null,
        }
    },
    methods: {
        setEmailValid(valid) {
            this.emailValid = valid;
        },
        resetPassword() {
            this.trigger++;

            if (!this.emailValid) {
                return;
            }

            let self = this;
            self.loading = true;

            postRecovery({
                recovery: {
                    mailAddress: this.email,
                },

            }).then(function () {
                self.resetError = null;
                self.reset = true;
                self.loading = false;

            }).catch(function (e) {
                self.checkGenericError(e);

                self.resetError = e;
                self.loading = false;

            })
        },
    }
}

</script>


<style lang="scss" scoped>

</style>
