<template>
    <div>

        <GenericItem :actions="getActions()"
                     :additionals="getAdditionals()"
                     :icon="isComtrexx ? 'cloud' : 'server'"
                     :icon-tooltip="$t(isComtrexx ? 'comtrexxDevice' : 'device')"

                     :is-blank="isBlank"
                     :labels="getLabels()"

                     :name="device.description">

            <template v-slot:description>
                <div class="image-struct uk-flex uk-flex-middle uk-flex-center">

                    <ImageEnhanced :src="require('@/assets/devices/'+device.kindId+'.png')"
                                   :uk-tooltip="'title:'+device.kindId"
                                   class="image"
                                   uk-img/>

                </div>
            </template>

        </GenericItem>

        <CreateDeviceSupportRequest is-button-hidden/>

    </div>
</template>

<script>

import GenericItem from "@/components/items/GenericItem";
import UIkit from 'uikit'
import ImageEnhanced from "@/components/ImageEnhanced";
import CreateDeviceSupportRequest from "@/components/creates/CreateDeviceSupportRequest";
import {postDeviceByIdInvitesAccept, postDeviceByIdInvitesReject} from "@/client/vue-api-client";

export default {
    name: 'DeviceItem',
    components: {CreateDeviceSupportRequest, ImageEnhanced, GenericItem},
    props: {
        isBlank: Boolean,

        device: {
            details: Object,

            createdAt: String,
            description: String,
            distributorId: String,
            donglEsn: String,
            id: String,
            kindId: String,
            userIds: Array,

            satellite:
                {
                    ipAddress: String,
                    monitoring:
                        {
                            statusUpdatedAt: String,
                            allAlive: Boolean
                        }
                }
        },

        fromDistributor: Boolean,
        isInvited: Boolean,
    },
    data() {
        return {
            isComtrexx: false,
            invited: false,
        }
    },
    created() {
        this.isComtrexx = this.device.kindId === 'COMtrexx';
        this.invited = this.isInvited;
    },
    methods: {
        openSupportRequest() {
            UIkit.modal("#modal-create-device-support-request").show();
            event.preventDefault();
        },
        rejectInvite() {
            let self = this;

            postDeviceByIdInvitesReject({
                id: self.device.id,

            }).then(function () {
                self.$emit('afterInviteRejected', self.device.id);

            }).catch(function (e) {
                self.checkGenericError(e);

            })
        },
        acceptInvite() {
            let self = this;

            postDeviceByIdInvitesAccept({
                id: self.device.id,

            }).then(function () {
                self.invited = false;
                self.$emit('afterInviteAccepted', self.device.id);

            }).catch(function (e) {
                self.checkGenericError(e);

            })
        },
        getActions() {
            let actions = [];

            if (this.invited) {
                actions.push({
                    action: this.rejectInvite,
                    icon: 'close',
                    text: this.$t('invite.reject'),
                    mode: 'danger',

                    button: this.$t('invite.reject'),
                    confirmTitle: this.$t('reallyDelete'),
                    confirmMessage: this.$t('invite.reject.confirm', {name: this.device.description})
                });

                actions.push({
                    action: this.acceptInvite,
                    icon: 'check',
                    text: this.$t('invite.accept'),
                    mode: 'success',
                });

                return actions;
            }

            actions.push({
                icon: 'folder-open',
                text: this.$t('open'),
                mode: "primary",
                route: (this.fromDistributor ? 'organisations/' + this.device.distributorId + '/' : '') + 'devices/' + this.device.id,
                noAction: true,
            });

            /*actions.push({
                icon: 'lifesaver',
                text: this.$t('support'),
                action: this.openSupportRequest,
                mode: 'default',
            });*/

            return actions;
        },
        getAdditionals() {
            let additionals = [];

            additionals.push({
                icon: 'world',
                text: this.device.satellite.ipAddress,
                copy: true,
            });

            if (!this.fromDistributor && this.device.details && this.device.details.distributor)
                additionals.push(
                    {
                        icon: 'social',
                        text: this.device.details.distributor.name,
                        tooltip: this.$t('distributor'),
                    }
                );

            return additionals;
        },
        getLabels() {
            let labels = [];

            if (this.invited) {
                labels.push({
                    type: "primary",
                    icon: "mail",
                    text: this.$t('invited'),
                    tooltip: this.$t('invited.description'),
                });

                return labels;
            }

            let type = "default";
            let icon = "info";
            let tooltip = this.$t(this.fromDistributor ? 'noSatellitDistributorMessage' : 'noSatellitMessage');

            if (this.device.status === 'offline') {
                type = "danger";
                icon = "warning";
                tooltip = this.$t('offline.description.device');
            }

            if (this.device.status === 'online') {
                type = "success";
                icon = "bolt";
                tooltip = this.$t('online.description.device');
            }

            if (this.device.status === 'determine') {
                type = "default";
                icon = "info";
                tooltip = this.$t('determine.description');
            }

            if (this.device.status === 'withoutMonitoring') {
                type = "default";
                icon = "info";
                tooltip = this.$t(this.fromDistributor ? 'noSubscriptionForModulDistributor' : 'noSubscriptionForModul', {name: 'Monitoring'});
            }

            labels.push({
                type: type,
                icon: icon,
                text: this.$t(this.capitalize(this.device.status)),
                tooltip: tooltip,
            });

            return labels;
        },
    }
}

</script>


<style lang="scss" scoped>

.image {
    max-height: 100%;
    max-width: 250px;
}

.image-struct {
    margin-top: 8px;
    height: 140px;
    margin-bottom: 10px;
}

</style>