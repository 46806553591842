<template>
    <GenericCreate
        :action="create" :action-error="error" :action-label="$t('create')"

        :action-loading="loading" :is-disabled="!isOwner"

        :is-disabled-message="$t('owner.only.action', {description: $t('peripherals.create')})" :modal-id="modalId"

        :success-description="$t('peripheral.created.success', {name: itemName})"
        :success-title="$t('peripheral.created')" :title="$t('peripheral.create')" :trigger="trigger"

        icon="plus"
        modal-is-medium
    >

        <CardSelect v-model="textFields[0].value" :field-key="0" :label="textFields[0].label"
                    :label-name="textFields[0].name" :optionShowLimit="deviceTypes.length" :options="deviceTypes"
                    :rules="textFields[0].rules" :validate="trigger" label-plural="peripherals"
                    @setValid="setCardValid"/>
        <div class="uk-margin-bottom"/>

        <span v-for="(inputField, key) in textFields" :key="inputField.name">
            <div v-if="key>0">
                <TextField v-model="inputField.value" :autocomplete="inputField.autocomplete"
                           :autofocus="inputField.autofocus" :field-key="key"
                           :hasPasswordMeter="inputField.hasPasswordMeter"
                           :icon="inputField.icon"
                           :is-optional="inputField.optional"
                           :label="inputField.label"
                           :name="inputField.name"
                           :onEnter="create"
                           :rules="inputField.rules"
                           :type="inputField.type"
                           :validate="trigger"
                           @setValid="setValid"
                />
            </div>
            <div v-if="key !== textFields.length -1" class="uk-margin-bottom"/>
        </span>

    </GenericCreate>
</template>

<script>

import {postDeviceByIdSatellitePeripherals} from "@/client/vue-api-client";
import TextField from "../generic/TextField";
import CardSelect from "../CardSelect";
import GenericCreate from "@/components/creates/GenericCreate";
import UIkit from "uikit";

export default {
    name: 'CreatePeripheral',
    components: {GenericCreate, CardSelect, TextField},
    props: {
        deviceId: String,
        value: Boolean,
        isOwner: Boolean,
    },
    data() {
        return {
            modalId: "modal-create-peripheral-" + this.deviceId,
            trigger: 0,

            textFields: [
                {
                    value: "",
                    valid: false,

                    autofocus: true,
                    name: "peripheral",
                    label: "peripheral",
                    rules: "required",
                    icon: "hashtag",
                },
                {
                    value: "",
                    valid: false,

                    autofocus: false,
                    name: "device-description",
                    label: "description",
                    rules: "required",
                    icon: "hashtag",
                },
                {
                    value: "",
                    valid: false,

                    autofocus: false,
                    name: "local-ip",
                    label: "localeIP",
                    rules: "required|ip|private_ip",
                    icon: "world",
                },
            ],

            loading: false,
            error: null,
            itemName: "",

            deviceTypes: [
                {
                    name: "COMfortel D-600",
                    value: "COMfortel D-600",
                    media: true,
                },
                {
                    name: "COMfortel D-400",
                    value: "COMfortel D-400",
                    media: true,
                },
                {
                    name: "COMfortel D-210",
                    value: "COMfortel D-210",
                    media: true,
                },
                {
                    name: "COMfortel D-200",
                    value: "COMfortel D-200",
                    media: true,
                },
                {
                    name: "COMfortel D-110",
                    value: "COMfortel D-110",
                    media: true,
                },
                {
                    name: "COMfortel D-100",
                    value: "COMfortel D-100",
                    media: true,
                },

                {
                    name: "COMfortel 3600 IP",
                    value: "COMfortel 3600 IP",
                    media: true,
                },
                {
                    name: "COMfortel 2600 IP",
                    value: "COMfortel 2600 IP",
                    media: true,
                },
                {
                    name: "COMfortel 1400 IP",
                    value: "COMfortel 1400 IP",
                    media: true,
                },

                {
                    name: "COMfortel WS-500S",
                    value: "COMfortel WS-500S",
                    media: true,
                },
                {
                    name: "COMfortel WS-500M",
                    value: "COMfortel WS-500M",
                    media: true,
                },
            ],
        }
    },
    methods: {
        setCardValid(valid) {
            this.textFields[0].valid = valid;
        },
        setValid(valid, fieldKey) {
            this.textFields[fieldKey].valid = valid
        },
        create() {
            this.trigger++;

            for (let i = 0, j = this.textFields.length; i < j; i++) {
                if (!this.textFields[i].valid) {
                    return;
                }
            }

            this.itemName = this.textFields[0].value;

            let self = this;
            self.loading = true;

            postDeviceByIdSatellitePeripherals({
                id: self.deviceId,
                peripheralInformation: {
                    peripherals: [{
                        kindId: self.textFields[0].value,
                        description: self.textFields[1].value,
                        ipAddress: self.textFields[2].value,
                    }],
                }

            }).then(function () {
                for (let i = 0, j = self.textFields.length; i < j; i++) {
                    self.textFields[i].value = "";
                    self.textFields[i].valid = false;
                }

                self.loading = false;

                UIkit.modal("#" + self.modalId + "-success").show();
                self.$emit('afterSuccess')

            }).catch(function (e) {
                self.checkGenericError(e);

                self.error = e;
                self.loading = false;

            })
        },
    }
}

</script>

<style lang="scss" scoped>

</style>
