<template>
    <span :uk-tooltip="'title:' +tooltip ? tooltip : ''">

        <span class="text uk-visible@s">{{ text }}</span>

        <div
            :class="'round-button uk-icon-button' +(isDark ? ' dark' : '')+(isDisabled ? ' uk-button-disabled cursor-notallowed' : '')"
            v-on:click="callAction">
            <Icon :icon="icon" :ratio="ratio"/>
        </div>

    </span>
</template>

<script>

import Icon from "@/components/generic/Icon";

export default {
    name: 'RoundButton',
    components: {Icon},
    props: {
        icon: String,
        isDark: Boolean,
        ratio: {
            type: Number,
            default: 1.2,
        },
        text: String,
        action: {
            type: Function,
            default: function () {
            },
        },

        isDisabled: Boolean,
        tooltip: String,
    },
    methods: {
        callAction() {
            if (this.isDisabled) {
                return;
            }

            this.action();
        },
    }
}

</script>

<style lang="scss" scoped>

.text {
    font-size: 15px;
    font-weight: 400;
    color: #ccc;
    margin-right: 12px;
}

.round-button {
    cursor: pointer;
    width: 48px;
    height: 48px;
    color: $theme-background;
    background-color: $theme-color;
    transition: border-color 0.25s linear, color 0.25s linear, background-color 0.25s linear !important;

    &:hover {
        color: $theme-background-hover;
        background-color: $theme-color-hover;
    }

    &.dark {
        color: $theme-color;
        background-color: $theme-background;

        &:hover {
            color: $theme-color-hover;
            background-color: $theme-background-hover;
        }
    }
}

.uk-button-disabled {
    background-color: #999 !important;
    cursor: not-allowed !important;
}

</style>
