<template>
    <div class="language-selector">

        <CountryFlag :country="$i18n.locale === 'en' ? 'us' : $i18n.locale"
                     class="cursor-pointer"
                     size='normal'/>

        <div id="languages" class="uk-navbar-dropdown" uk-dropdown="mode: click">
            <ul class="uk-nav uk-navbar-dropdown-nav admin-struct">
                <li v-for="(language, key) in this.languages"
                    :key="language">

                    <label class="cursor-pointer">
                        <div uk-grid>
                            <div class="uk-width-expand language uk-flex uk-flex-middle">
                                <CountryFlag
                                    :country="language === 'en' ? 'us' : language"
                                    size='normal'/>
                                <span class="uk-margin-small-left">
                                    {{ $t(language, language) }}
                                </span>
                            </div>
                            <div class="uk-flex uk-flex-middle">
                                <input v-model="$i18n.locale" :name="'language-'+id" :value="language"
                                       class="uk-radio" type="radio"
                                       uk-toggle="target: #languages"
                                       v-on:click="switchLanguage(language)">
                            </div>
                        </div>
                    </label>

                    <hr v-if="key !== languages.length-1"/>
                </li>
            </ul>
        </div>

    </div>
</template>

<script>

import CountryFlag from "vue-country-flag";

export default {
    name: 'LanguageSelector',
    components: {CountryFlag},
    props: {
        id: String
    }
}

</script>

<style lang="scss" scoped>

</style>
