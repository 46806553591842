<template>
    <div>

        <LoadingStruct :is-loading="loading">
            <GenericSiteTitle
                :title-poperties="isDistributorView ?
                [{key: 1, name: device.distributor.name, tooltip: $t('distributor')}, {key: 2, hidden: true}, {key: 3, name: device.device.description, tooltip: $t('device')}] :
                [{key: 1, name: device.device.description, tooltip: $t('device')}]">
                <template v-slot:action>
                    <CreateDeviceSupportRequest v-if="false"/>
                </template>
            </GenericSiteTitle>

            <div v-if="isInvited">
                <GenericItemView
                    :error="error"
                    :is-loading="loading">
                    <div
                        class="uk-flex uk-flex-center uk-child-width-1-2@m uk-child-width-1-1 uk-grid-match uk-margin-remove-top uk-grid-medium"
                        uk-grid>

                        <div>
                            <Card :title="$t('invited')" is-centered mode="default">
                                <div v-html="$t('invited.description')"/>

                                <DeviceItem :device="device.device" is-blank is-invited
                                            @afterInviteAccepted="softInviteAccepted"
                                            @afterInviteRejected="softInviteRejected"/>
                            </Card>
                        </div>

                    </div>
                </GenericItemView>
            </div>
            <div v-else :class="wasInvited ? 'uk-animation-fade' :''">
                <Tabs :id="tabId" :tabs="tabs">


                    <li :class="whitelabel ? 'active' : ''">
                        <GenericItemView
                            :computed-items="computedItemsConfigBackups"
                            :error="error"
                            :filters="[]"
                            :is-loading="loading"
                            :is-search="searchConfigBackups.length>0"
                            :items="device.device.configBackups"
                            :no-items-message="$t('noBackupsMessage')"
                            hide-divider
                            is-searchable
                            label-plural="backups"
                            @search="setSearchConfigBackups"
                        >
                            <template v-slot:action>
                                <div class="uk-grid-small" uk-grid>
                                    <div>
                                        <UpdateBackupSettings/>
                                    </div>
                                    <div>
                                        <CreateBackup :device-id="deviceId" @afterSuccess="getDevice()"/>
                                    </div>
                                </div>
                            </template>

                            <template v-slot:items>
                    <li v-for="(item, key) in computedItemsConfigBackups" :key="item.id" :data-role="item.role"
                        :data-status="item.status">
                        <BackupItem :backup="item" :device-id="deviceId" :is-latest="key === 0"/>
                    </li>
</template>
</GenericItemView>
</li>


<li v-if="!whitelabel">
<div v-if="hasSatellite">
    <div v-if="hasLicense('monitoring')">

        <GenericItemView
            :computed-items="computedItemsMonitoring"
            :error="errorMonitorings"
            :filters="filtersMonitoring"
            :is-loading="loadingMonitorings"
            :is-search="searchMonitorings.length>0"
            :items="device.device.monitoring"
            :no-items-message="$t('noMonitoringDataMessage')"
            hide-divider
            is-searchable
            label-plural="monitorings"
            @search="setSearchMonitorings"
        >
            <template v-slot:itemsinfo>
                <Card :mode="unifiedStatus === 'determine' ? 'default' : unifiedStatus">
                    <IconText :icon="getIconByMode()" class="status-title">
                        {{ $t(unifiedStatusText) }}
                    </IconText>
                </Card>
            </template>

            <template v-slot:items>
<li v-for="(item,key) in computedItemsMonitoring" :key="item.id" :class="key === 0 ? 'uk-width-1-1' : ''"
    :data-status="item.status">
<MonitoringItem :device="device.device" :item="item"/>
</li>
</template>
</GenericItemView>

</div>
<div v-else>
<InfoCard icon="info">
            <span v-if="isDistributorView">
                <div>{{ $t('noSubscriptionForModulDistributor', {name: $t('monitoring')}) }}</div>
                <br/>
                <LinkObject :to="getSubscriptionManagementLink()">
                         <Button :text="$t('toSubscriptions')" mode="theme"/>
                </LinkObject>
            </span>
    <span v-else>
                {{ $t('noSubscriptionForModul', {name: $t('monitoring')}) }}
            </span>
</InfoCard>
</div>
</div>
<div v-else>
<InfoCard icon="info">
    <span
        v-html="$t(isDistributorView ? 'satellitRequiredForModulDistributorMessage' : 'satellitRequiredForModulMessage', {name: 'Monitoring'})"/>
</InfoCard>
</div>
</li>

<li v-if="false">
<div v-if="device.device.licences">
</div>
<div v-else>
    <InfoCard icon="info">
            <span v-if="isDistributorView">
                <div>{{ $t('noSubscriptionForModulDistributor', {name: $t('UpdateServer')}) }}</div>
                <br/>
                <LinkObject :to="getSubscriptionManagementLink()">
                         <Button :text="$t('toSubscriptions')" mode="theme"/>
                </LinkObject>
            </span>
        <span v-else>
                {{ $t('noSubscriptionForModul', {name: $t('UpdateServer')}) }}
            </span>
    </InfoCard>
</div>
</li>

<li v-if="true">
<div v-if="device.device.licences || true">

    <div class="uk-child-width-1-1" uk-grid>
        <div>
            <DocumentItem
                :document="{id: 1, createdAt: '2022-09-21T14:41:51.34Z', author: 'Besitzer #Frederic', message:'Backup angelegt, Firmware von 8.2 -> 8.4A006 geupdated, Teilnehmer 23 Amtsberechtigung auf Internation umgestellt. Anbieter VOIP Template Sipgate Basic auf V200 geupdated. Mit Studioleitung Konfiguration geprüft.'}"/>
        </div>
        <div>
            <DocumentItem
                :document="{id: 1, createdAt: '2022-09-20T09:15:20.34Z', author: 'Servicetechniker #Frank Mutter', message:'Rufverteilung MSN 077619379002 auf Rückfall umgestellt. Gruppe 8703 Gerät TN. 23,150, 101 hinzugefügt. TN. 23 als Fest Eingeloggt hinterlegt. WS 500M von 2.36 -> 2.48 geupdatet. TN. 26 Angelegt IPUI Nummer ausgetasucht für TN 26 - Gerät angemeldet. Mit Studioleitung Konfiguration geprüft.'}"/>
        </div>
    </div>



</div>
<div v-else>
    <InfoCard icon="info">
            <span v-if="isDistributorView">
                <div>{{ $t('noSubscriptionForModulDistributor', {name: $t('documentation')}) }}</div>
                <br/>
                <LinkObject :to="getSubscriptionManagementLink()">
                         <Button :text="$t('toSubscriptions')" mode="theme"/>
                </LinkObject>
            </span>
        <span v-else>
                {{ $t('noSubscriptionForModul', {name: $t('documentation')}) }}
            </span>
    </InfoCard>
</div>
</li>


<li v-if="!whitelabel">
<GenericItemView
    :computed-items="computedItemsPeripherals"
    :error="errorMonitorings"
    :filters="filterPeripherals"
    :is-loading="loadingMonitorings"
    :is-search="searchPeripherals.length>0"
    :items="device.device.satellite.peripherals"
    :no-items-message="$t('peripheral.notcreated')"
    hide-divider
    is-searchable
    label-plural="peripherals"
    @search="setSearchPeripherals"
>
    <template v-slot:action>
        <CreatePeripheral :device-id="deviceId" :is-owner="isOwner" @afterSuccess="getDevice()"/>
    </template>

    <template v-slot:items>
<li v-for="item in computedItemsPeripherals" :key="item.id" :data-status="item.status">
<PeripheralItem :device-id="deviceId" :is-owner="isOwner" :item="item" @setDeleted="setPeripheralDeleted"/>
</li>
</template>
</GenericItemView>
</li>


<li>
<GenericItemView
    :computed-items="computedItemsUsers"
    :error="error"
    :filters="filtersUsers"
    :is-loading="loading"
    :is-search="searchUsers.length>0"
    :items="device.users"
    :no-items-message="$t('noUsersMessage')"
    hide-divider
    is-searchable
    label-plural="users"
    @search="setSearchUsers"
>
    <template v-slot:action>
        <CreateInvite :device-id="deviceId" :is-owner="isOwner" :member-emails="getMemberEmails()"
                      @afterSuccess="getDevice()"/>
    </template>

    <template v-slot:items>
<li v-for="item in computedItemsUsers" :key="item.id" :data-role="item.role" :data-status="item.status">
<MemberItem :device-id="device.device.id" :is-owner="isOwner" :member="item" @setDeleted="setDeleted"/>
</li>
</template>
</GenericItemView>
</li>

<li v-if="!whitelabel">
<RemoteAccessFrame/>
</li>

<li v-if="!whitelabel">
<GenericItemView
    :computed-items="computedItemsVoicemails"
    :error="error"
    :filters="[]"
    :is-loading="loading"
    :is-search="searchVoicemails.length>0"
    :items="device.device.voicemails"
    :no-items-message="$t('moduleInDevelopmentMessage', {name: $t('vassist2')})"
    hide-divider
    is-searchable
    label-plural="voicemails"
    @search="setSearchVoicemails"
>
    <template v-slot:items>
<li v-for="(item, key) in computedItemsVoicemails" :key="item.id" :data-role="item.role"
    :data-status="item.status">
<VoicemailItem :device-id="deviceId" :is-latest="key === 0" :voicemail="item"/>
</li>
</template>
</GenericItemView>
</li>


<li v-if="!whitelabel">

<GenericItemView
    hide-divider
>
    <template v-slot:action>
        <div class="uk-grid-small" uk-grid>
            <div>
                <UpdateCostCentres :device-id="deviceId"/>
            </div>
            <div>
                <UpdateBill :device-id="deviceId"/>
            </div>
        </div>
    </template>

    <template v-slot:itemsinfo>

        <div uk-grid
             class="uk-child-width-1-2@m uk-child-width-1-1 uk-grid-match uk-margin-remove-top">
            <div>
                <Card :title="$t('callVolume')" mode="default">
                    <div class="uk-grid-collapse" uk-grid>
                        <div>
                            <div uk-grid>
                                <div class="uk-width-xsmall">
                                    {{ $t('current.month') }}:
                                </div>
                                <div>
                                    <span class="uk-label">120 Minutenn</span>
                                </div>
                            </div>

                            <div class="uk-margin-small-top" uk-grid>
                                <div class="uk-width-xsmall">
                                    {{ $t('last.month') }}:
                                </div>
                                <div>
                                    <span class="uk-label">103 Minuten</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <StatisticPie is-blank is-without-body :data="getCallData()"/>
                        </div>
                    </div>

                </Card>
            </div>
            <div>
                <Card :title="$t('analysis')" mode="default">
                    <div class="uk-grid-collapse" uk-grid>
                        <div>
                            <div uk-grid>
                                <div class="uk-width-xsmall">
                                    {{ $t('participants') }}:
                                </div>
                                <div>
                                    <span class="uk-label">100</span>
                                </div>
                            </div>

                            <div class="uk-margin-small-top" uk-grid>
                                <div class="uk-width-xsmall">
                                    {{ $t('total') }}:
                                </div>
                                <div>
                                    <span class="uk-label">11 Minuten</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <StatisticPie is-blank is-without-body :data="getCallData()"/>
                        </div>
                    </div>

                </Card>
            </div>
        </div>

    </template>

    <template v-slot:items>
<li v-for="(item, key) in computedItemsConfigBackups" :key="item.id" :data-role="item.role"
    :data-status="item.status">
<CLMItem :clm="item" :device-id="deviceId" :is-latest="key === 0"/>
</li>
</template>
</GenericItemView>


<Card mode="default" class="uk-margin-medium-top">
<table class="uk-table uk-table-hover uk-table-divider">
    <thead>
    <tr>
        <th/>
        <th>
            {{ $t('date') }}
        </th>
        <th>
            {{ $t('participant') }}
        </th>
        <th/>
        <th>
            {{ $t('number') }}
        </th>
        <th>
            {{ $t('from') }}
        </th>
        <th>
            {{ $t('until') }}
        </th>
        <th>
            {{ $t('duration') }}
        </th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="call in calls" :key="call.number">
        <td class="uk-flex uk-flex-center">
            <Checkbox/>
        </td>
        <td>
            {{ call.date }}
        </td>
        <td>
            {{ call.tn }}
        </td>
        <td>
            <span v-if="call.direction === 'to'" class="uk-label uk-label-success">
                <Icon icon="chevron-right"/>
            </span>
            <span v-else class="uk-label ">
                <Icon icon="chevron-left"/>
            </span>
        </td>
        <td>
            {{ call.number }}
        </td>
        <td>
            {{ call.from }}
        </td>
        <td>
            {{ call.until }}
        </td>
        <td>
            {{ call.duration }}
        </td>
    </tr>
    </tbody>
</table>
</Card>


</li>


<li v-if="!whitelabel">
<GenericItemView
    :error="error"
    :is-loading="loading"
    hide-divider
>

    <div
        class="uk-child-width-1-3@xl uk-child-width-1-2@m uk-child-width-1-1 uk-grid-match uk-margin-remove-top uk-grid-medium"
        uk-grid>

        <div class="uk-width-1-1">
            <Card :title="$t('status')" mode="default">
                <SatelliteStruct :has-monitoring="hasLicense('monitoring')" :has-satellite="hasSatellite"
                                 :is-distributor-view="isDistributorView"
                                 :monitoring="itemsMonitoring"/>
            </Card>
        </div>

        <div v-if="hasSatellite && false">
            <Card :title="$t('settings')" mode="default">
                <SwitchButton name="automatic-updates" :label="$t('automaticUpdates')"/>
                <hr/>
                <SwitchButton name="network-scan" :label="$t('networkScanForDevices')"/>
            </Card>
        </div>

    </div>
</GenericItemView>
</li>


<li>
<GenericItemView
    :error="error"
    :is-loading="loading"
    hide-divider
>

    <div
        class="uk-child-width-1-3@xl uk-child-width-1-2@m uk-child-width-1-1 uk-grid-match uk-margin-remove-top uk-grid-medium"
        uk-grid>

        <div>
            <Card :title="$t('device')" mode="default">

                <div class="image-struct uk-flex uk-flex-middle uk-flex-center">
                    <ImageEnhanced v-if="device && device.device.kindId"
                                   :src="require('@/assets/devices/'+device.device.kindId+'.png')"
                                   class="image" uk-img/>
                </div>

                <div class="uk-margin-top"/>
                <TextField :value="(device.device.kindId)" disabled icon="hashtag"
                           label="deviceType" name="device-name"/>

                <div class="uk-margin-top"/>
                <TextField :value="getFormattedDateByDateString(device.device.createdAt)" disabled icon="calendar"
                           label="createdAt" name="created-at"/>
            </Card>
        </div>

        <div>
            <Card :title="$t('data')" mode="default">

                <span v-for="(inputField, key) in textFields" :key="inputField.name">
                    <TextField v-model="inputField.value" :autocomplete="inputField.autocomplete"
                               :autofocus="inputField.autofocus" :disabled="inputField.disabled" :field-key="key"
                               :hasPasswordMeter="inputField.hasPasswordMeter"
                               :icon="inputField.icon"
                               :label="inputField.label"
                               :name="inputField.name"
                               :onEnter="inputField.onEnter"
                               :rules="inputField.rules"
                               :type="inputField.type"
                               :validate="trigger"
                               @setValid="setValid"
                    />
                    <div v-if="key !== textFields.length -1" class="uk-margin-bottom"/>
                </span>

                <div v-if="updateSuccess || updateError" class="uk-animation-fade">
                    <div class="uk-margin-medium-top"/>
                    <InfoBox v-if="updateSuccess">
                        {{ $t('updateSuccessMessage') }}
                    </InfoBox>
                    <InfoBox v-else mode="danger">
                        <ErrorMessage :error="updateError"/>
                    </InfoBox>
                </div>

                <div class="uk-margin-medium-top"/>
                <Button :action="updateDevice" :is-disabled="!isOwner" :is-loading="updateLoading"
                        :text="$t('saveChanges')"
                        :tooltip="isOwner ? '' : this.$t('owner.only.action', {description: this.$t('change.data')})"
                        has-action
                        is-large is-max mode="theme"/>
            </Card>
        </div>

        <div>
            <Card :title="$t('subscriptions')" mode="default">

                <div class="uk-child-width-auto uk-grid-match uk-margin-remove-top uk-grid-medium"
                     uk-grid>

                    <div v-for="(subscription, key) in computedSubscriptionsActive" :key="key">
                        <InfoBox :mode="subscription.active ? 'success' : 'danger'" icon="credit-card">
                            {{ $t(subscription.planId) }} ({{ $t(subscription.active ? 'valid' : 'expired') }})
                        </InfoBox>
                    </div>

                    <div v-if="computedSubscriptionsActive.length === 0">
                        <InfoBox icon="info" mode="default">
                            {{ $t('device.nomodules') }}
                        </InfoBox>
                    </div>
                </div>

            </Card>
        </div>

        <div>
            <Card :title="$t('distributor')" mode="default">

                <InfoBox borderless mode="theme">
                    <div class="owner-name">
                        {{ device.distributor.name }}
                    </div>
                </InfoBox>

            </Card>
        </div>

    </div>

</GenericItemView>
</li>


</Tabs>
</div>
</LoadingStruct>

</div>
</template>

<script>

import Tabs from "@/components/Tabs";
import {
    getDeviceById,
    getDeviceByIdSatelliteModulesMonitoring,
    putDeviceById,
    putDeviceByIdSatellite
} from "@/client/vue-api-client";
import Card from "@/components/generic/Card";
import TextField from "@/components/generic/TextField";
import InfoBox from "@/components/generic/InfoBox";
import CreateInvite from "@/components/creates/CreateInvite";
import BackupItem from "@/components/items/BackupItem";
import MemberItem from "../components/items/MemberItem";
import CreateBackup from "../components/creates/CreateBackup";
import VoicemailItem from "@/components/items/VoicemailItem";
import ImageEnhanced from "@/components/ImageEnhanced";
import UIkit from "uikit";
import MonitoringItem from "../components/items/MonitoringItem";
import CreateDeviceSupportRequest from "@/components/creates/CreateDeviceSupportRequest";
import GenericSiteTitle from "@/views/GenericSiteTitle";
import GenericItemView from "@/views/GenericItemView";
import DocumentItem from "@/components/items/DocumentItem";
import LoadingStruct from "@/views/LoadingStruct";
import SwitchButton from "@/components/generic/SwitchButton";
import ErrorMessage from "@/components/generic/ErrorMessage";
import Button from "@/components/generic/Button";
import LinkObject from "@/components/LinkObject";
import CreatePeripheral from "@/components/creates/CreatePeripheral";
import PeripheralItem from "@/components/items/PeripheralItem";
import DeviceItem from "@/components/items/DeviceItem";
import InfoCard from "@/components/generic/InfoCard";
import SatelliteStruct from "@/components/SatelliteStruct";
import UpdateBackupSettings from "@/components/updates/UpdateBackupSettings";
import IconText from "@/components/generic/IconText";
import RemoteAccessFrame from "@/components/RemoteAccessFrame";
import StatisticPie from "@/components/StatisticPie";
import CLMItem from "@/components/items/CLMItem";
import UpdateCostCentres from "@/components/updates/UpdateCostCentres";
import UpdateBill from "@/components/updates/UpdateBill";
import Icon from "@/components/generic/Icon";
import Checkbox from "@/components/generic/Checkbox";

export default {
    name: 'Device',
    components: {
        Checkbox,
        Icon,
        UpdateBill,
        UpdateCostCentres,
        CLMItem,
        StatisticPie,
        RemoteAccessFrame,
        IconText,
        UpdateBackupSettings,
        SatelliteStruct,
        InfoCard,
        DeviceItem,
        PeripheralItem,
        CreatePeripheral,
        LinkObject,
        Button,
        ErrorMessage,
        SwitchButton,
        LoadingStruct,
        DocumentItem,
        GenericItemView,
        GenericSiteTitle,
        CreateDeviceSupportRequest,
        MonitoringItem,
        ImageEnhanced,
        VoicemailItem,
        CreateBackup,
        MemberItem,
        BackupItem,
        CreateInvite,
        InfoBox,
        TextField,
        Card,
        Tabs
    },
    data() {
        return {
            loading: true,
            error: null,

            itemsMonitoring: [],
            loadingMonitorings: true,
            errorMonitorings: null,
            monitoringItems: [],

            deviceId: null,
            isDistributorView: false,

            tabId: "tab-device",
            tabs: ['backups', 'monitoring', 'documentation', 'peripherals', 'members', 'remote.access', 'vassist2', 'clm', 'logger', 'infos'],

            device: {
                device: {
                    configBackups: [],
                    createdAt: "",
                    description: "",
                    donglEsn: "",
                    id: "",
                    invites: [],
                    kindId: "",
                    satellite: {
                        ipAddress: "",
                        peripherals: [
                            {
                                description: "",
                                id: "",
                                ipAddress: "",
                                kindId: ""
                            }
                        ],
                        subscriptions: {
                            monitoring: ""
                        }
                    },
                    voicemails: [],
                },

                users: [
                    {
                        name: "",
                        mailAddress: ""
                    }
                ],

                distributor: {
                    name: ""
                },

                satelliteId: "",
            },

            filterPeripherals: [
                {
                    type: 'status',
                    values: [
                        {name: 'withoutSatellite', total: 0},
                        {name: 'withoutMonitoring', total: 0},
                        {name: 'offline', total: 0},
                        {name: 'warning', total: 0},
                        {name: 'determine', total: 0},
                        {name: 'online', total: 0},
                    ]
                },
            ],

            filtersUsers: [
                {
                    type: 'status',
                    values: [
                        {name: 'invited', total: 0},
                        {name: 'member', total: 0},
                        {name: 'owner', total: 0},
                    ]
                },
            ],

            filtersMonitoring: [
                {
                    type: 'status',
                    values: [
                        {name: 'offline', total: 0},
                        {name: 'warning', total: 0},
                        {name: 'determine', total: 0},
                        {name: 'online', total: 0},
                    ]
                },
            ],

            searchVoicemails: "",
            searchConfigBackups: "",
            searchPeripherals: "",
            searchUsers: "",
            searchMonitorings: "",

            updateLoading: false,
            updateError: null,
            updateSuccess: false,

            trigger: 0,
            textFields: [
                {
                    value: "",
                    valid: false,

                    autofocus: false,
                    name: "device-description",
                    label: "description",
                    rules: "required",
                    icon: "hashtag",
                    disabled: true
                },
                {
                    value: "",
                    valid: false,

                    autofocus: false,
                    name: "local-ip",
                    label: "localeIP",
                    rules: "required|ip|private_ip",
                    icon: "world",
                    disabled: true
                },
                {
                    value: "",
                    valid: false,

                    autofocus: false,
                    name: "dongle-id",
                    label: "dongleId",
                    rules: "required",
                    icon: "usb",
                    disabled: true
                },
            ],

            isOwner: false,
            isInvited: false,
            wasInvited: false,

            unifiedStatus: "",
            unifiedStatusText: "",

            intervalId: null,

            calls: [
                {
                    date: "01.07.2022",
                    tn: 100,
                    direction: "to",
                    number: "+491602606873",
                    from: "18:03 Uhr",
                    until: "18:07 Uhr",
                    duration: "4 Minuten"
                },
                {
                    date: "01.07.2022",
                    tn: 100,
                    direction: "to",
                    number: "+4977619379002",
                    from: "18:31 Uhr",
                    until: "18:36 Uhr",
                    duration: "5 Minuten"
                },
                {
                    date: "03.07.2022",
                    tn: 100,
                    direction: "from",
                    number: "+4077619379002",
                    from: "07:15 Uhr",
                    until: "07:25 Uhr",
                    duration: "10 Minuten"
                },
            ]
        }
    },
    created() {
        this.deviceId = this.$route.params.deviceId;

        if (this.isAdmin) {
            this.isOwner = true;

            for (let i = 0, j = this.textFields.length; i < j; i++) {
                this.textFields[i].disabled = false;
            }
        }

        this.isDistributorView = this.$route.path.includes('/organisations/');

        if (this.whitelabel) {
            this.tabs = ['backups', 'members', 'infos'];
        }

        this.getMonitoring();
    },
    computed: {
        computedSubscriptionsActive() {
            let parsedSubscriptions = [];

            if (!this.device.subscriptions) {
                return parsedSubscriptions;
            }

            for (const [key, value] of Object.entries(this.device.subscriptions)) {
                if (value.id) {
                    parsedSubscriptions.push(
                        {
                            planId: key,
                            active: value.active,
                        }
                    )
                }
            }

            return parsedSubscriptions;
        },
        computedItemsMonitoring() {
            let items = [];

            items.push(this.device.device.monitoring);

            let peripherals = this.device.device.satellite.peripherals;
            for (let i = 0, j = peripherals.length; i < j; i++) {
                items.push(peripherals[i].monitoring);
            }

            if (this.searchMonitorings && this.searchMonitorings.length) {
                return this.powerSearcher(items, this.searchMonitorings);
            }

            return items;
        },
        hasSatellite() {
            return this.device && this.device.satelliteId !== "";
        },
        computedItemsVoicemails() {
            let items = this.device.device.voicemails;
            if (this.searchVoicemails && this.searchVoicemails.length) {
                items = this.powerSearcher(items, this.searchVoicemails);
            }

            if (items.length > this.defaultShowLimit) {
                return items.slice(0, this.defaultShowLimit);
            }

            return items;
        },
        computedItemsConfigBackups() {
            let items = this.device.device.configBackups;
            if (this.searchConfigBackups && this.searchConfigBackups.length) {
                items = this.powerSearcher(items, this.searchConfigBackups);
            }

            if (items.length > this.defaultShowLimit) {
                return items.slice(0, this.defaultShowLimit);
            }

            return items;
        },
        computedItemsUsers() {
            let items = this.device.users;
            if (this.searchUsers && this.searchUsers.length) {
                items = this.powerSearcher(items, this.searchUsers);
            }

            if (items.length > this.defaultShowLimit) {
                return items.slice(0, this.defaultShowLimit);
            }

            return items;
        },
        computedItemsPeripherals() {
            let items = this.device.device.satellite.peripherals;
            if (!items) {
                items = [];
            }

            if (this.searchPeripherals && this.searchPeripherals.length) {
                return this.powerSearcher(items, this.searchPeripherals);
            }

            if (items.length > this.defaultShowLimit) {
                return items.slice(0, this.defaultShowLimit);
            }

            return items;
        }
    },
    methods: {
        getIconByMode() {
            let mode = this.unifiedStatus;

            if (mode === "success") {
                return "bolt";
            }

            if (mode === "danger") {
                return "warning";
            }

            return "info";
        },
        setAllInfo() {
            if (this.device.device.monitoring.status === "offline") {
                this.unifiedStatus = "danger";
                this.unifiedStatusText = "offline.description.device";
                return;
            }

            if (this.device.device.monitoring.status === "determine") {
                this.unifiedStatus = "determine";
                this.unifiedStatusText = "determine.description";
                return;
            }


            let peripheralsOffline = 0;

            let peripherals = this.device.device.satellite.peripherals
            for (let i = 0, j = peripherals.length; i < j; i++) {
                if (peripherals[i].monitoring.status === "offline") {
                    peripheralsOffline++;
                }
            }

            if (peripherals.length > 0 && peripherals.length === peripheralsOffline) {
                this.unifiedStatus = "danger";
                this.unifiedStatusText = "peripherals.all.offline";
                return
            }

            if (peripheralsOffline > 0) {
                this.unifiedStatus = "warning";
                this.unifiedStatusText = "peripherals.partially.offline";
                return;
            }

            this.unifiedStatus = "success";
            this.unifiedStatusText = "systems.all.online";
        },
        checkForExpiredSubscriptions() {
            for (const [key, value] of Object.entries(this.device.subscriptions)) {
                if (value.id && !value.active) {
                    UIkit.notification({
                        message: this.$t('subscription.device.expired', {planId: this.capitalize(key)}),
                        status: 'info',
                        pos: 'top-center',
                        timeout: this.notificationTimeout,
                    });
                }
            }
        },
        getMonitoring() {
            let self = this;

            getDeviceByIdSatelliteModulesMonitoring({
                id: self.deviceId,
                deviceEvents: true,
                peripheralEvents: true,

            }).then(function (response) {
                self.itemsMonitoring = response.data;
                self.loadingMonitorings = false;

                self.getDevice();

            }).catch(function (e) {
                self.checkGenericError(e);
                self.error = e;
                self.errorMonitorings = e;

                self.loading = false;
                self.loadingMonitorings = false;

                clearInterval(self.intervalId);

            })
        },
        setPeripheralDeleted(id) {
            this.device.device.satellite.peripherals = this.device.device.satellite.peripherals.filter(x => {
                return x.id !== id;
            });
        },
        setValid(valid, fieldKey) {
            this.textFields[fieldKey].valid = valid
        },
        updateDevice() {
            this.trigger++;

            for (let i = 0, j = this.textFields.length; i < j; i++) {
                if (!this.textFields[i].valid) {
                    return;
                }
            }

            let self = this;
            self.updateLoading = true;
            putDeviceById({
                id: self.device.device.id,
                deviceInformation: {
                    description: self.textFields[0].value,
                    donglEsn: self.textFields[2].value,
                },

            }).then(function () {
                self.updateIp();

            }).catch(function (e) {
                self.checkGenericError(e);

                self.updateError = e;
                self.updateLoading = false;

            })
        },
        updateIp() {
            let self = this;
            putDeviceByIdSatellite({
                id: self.device.device.id,
                satelliteInformation: {
                    ipAddress: self.textFields[1].value,
                },

            }).then(function () {
                self.updateLoading = false;
                self.updateSuccess = true;

            }).catch(function (e) {
                self.checkGenericError(e);

                self.updateError = e;
                self.updateLoading = false;

            })
        },
        getSubscriptionManagementLink() {
            return 'organisations/' + this.$route.params.distributorId + '#subscriptions';
        },
        softInviteAccepted() {
            this.isInvited = false;
            this.getDevice();
        },
        softInviteRejected() {
            this.routeTo('devices');
        },
        setSearchMonitorings(text) {
            this.searchMonitorings = text;
        },
        setSearchVoicemails(text) {
            this.searchVoicemails = text;
        },
        setSearchConfigBackups(text) {
            this.searchConfigBackups = text;
        },
        setSearchUsers(text) {
            this.searchUsers = text;
        },
        setSearchPeripherals(text) {
            this.searchPeripherals = text;
        },
        setDeleted(id) {
            this.device.users = this.device.users.filter(x => {
                return x.mailAddress !== id;
            });
        },
        getMemberEmails() {
            let emails = [];
            for (let i = 0, j = this.device.users.length; i < j; i++) {
                emails.push(this.device.users[i].mailAddress);
            }

            return emails;
        },
        getItemStatus(events) {
            let requirementStatus = this.getRequirementStatusByDevice(this.device);
            if (requirementStatus) {
                return requirementStatus;
            }

            let lastItem = events[events.length - 1];
            let status = this.getMonitoringStatusByEvent(lastItem);

            if (status === "offline.time") {
                return "offline";
            }

            if (status === "warning.time") {
                return "warning";
            }

            return status;
        },
        getParsedEvents(events) {
            return events;
        },
        getUserStatus(user) {
            if (!user.name) {
                return "invited";
            }

            return "member";
        },
        hasLicense(planId) {
            if (this.device.subscriptions && this.device.subscriptions[planId] && this.device.subscriptions[planId].active) {
                return true;
            }

            return false;
        },
        getDevice() {
            let self = this;

            getDeviceById({
                id: self.deviceId,

            }).then(function (response) {
                let item = response.data;

                let invited = item.device.invites.includes(self.getUserEmail);
                if (invited) {
                    self.isInvited = true;
                    self.wasInvited = true;
                }

                if (item.users[0].mailAddress === self.getUserEmail) {
                    self.isOwner = true;
                }

                let parsedInvites = [];

                if (item.device.invites) {
                    for (let i = 0, j = item.device.invites.length; i < j; i++) {
                        parsedInvites.push({
                            mailAddress: item.device.invites[i],
                            invited: true,
                        });
                    }
                }

                item.users = item.users.concat(parsedInvites);

                if (item.device.configBackups) {
                    item.device.configBackups.reverse();
                }

                if (item.device.voicemails) {
                    item.device.voicemails.reverse();
                }

                for (let i = 0, j = item.users.length; i < j; i++) {

                    if (i === 0) {
                        item.users[i]['status'] = "owner";

                    } else {
                        item.users[i]['status'] = self.getUserStatus(item.users[i]);

                    }
                }

                self.device = item;

                self.textFields[0].value = self.device.device.description;
                if (self.device.device.description) {
                    self.textFields[0].valid = true;
                }

                self.textFields[1].value = self.device.device.satellite.ipAddress;
                if (self.device.device.satellite.ipAddress) {
                    self.textFields[1].valid = true;
                }

                self.textFields[2].value = self.device.device.donglEsn;
                if (self.device.device.donglEsn) {
                    self.textFields[2].valid = true;
                }

                // monitoring
                let events = self.getEventsById(item.device.id);
                let itemStatus = self.getItemStatus(events);
                let parsedEvents = self.getParsedEvents(events);

                self.device.device.monitoring = {
                    id: item.device.id,
                    events: events,
                    type: "device",
                    deviceDescription: item.device.description,
                    device: self.device.device,
                    status: itemStatus,
                    parsedEvents: parsedEvents,
                }

                for (let i = 0, j = item.device.satellite.peripherals.length; i < j; i++) {
                    let peripheral = item.device.satellite.peripherals[i];
                    let events = self.getEventsById(peripheral.id);
                    let itemStatus = self.getItemStatus(events);
                    let parsedEvents = self.getParsedEvents(events);

                    self.device.device.satellite.peripherals[i].monitoring = {
                        id: peripheral.id,
                        events: events,
                        type: "peripheral",
                        device: peripheral,
                        deviceDescription: peripheral.description,
                        status: itemStatus,
                        parsedEvents: parsedEvents,
                    }

                    self.device.device.satellite.peripherals[i].status = itemStatus;
                }

                if (self.intervalId === null) {
                    let requirement = self.getRequirementStatusByDevice(item);
                    if (!requirement) {
                        self.intervalId = setInterval(function () {
                            self.getMonitoring();
                        }, 15000);
                    }
                }

                self.checkForExpiredSubscriptions();
                self.setAllInfo();

                self.loading = false;

            }).catch(function (e) {
                self.checkGenericError(e);
                self.error = e;
                self.loading = false;

            })

        },
        getEventsById(id) {
            if (this.itemsMonitoring.device.id === id) {
                return this.itemsMonitoring.device.events;
            }

            let peripherals = this.itemsMonitoring.peripherals;
            for (let i = 0, j = peripherals.length; i < j; i++) {
                if (peripherals[i].id === id) {
                    return peripherals[i].events;
                }
            }

            return [];
        },
        getCallData() {

            let categories = [];
            categories["Aktueller Monat"] = 120;
            categories["Letzter Monat"] = 103;

            let parsed = [];
            for (const [key, value] of Object.entries(categories)) {
                parsed.push({
                    name: key + " (" + this.getFormattedNumber(this.getPercentage(223, value)) + "%)",
                    y: value,
                });
            }

            parsed = parsed.sort(function (a, b) {
                return a['name'].localeCompare(b['name']);
            });

            parsed = parsed.sort(function (a, b) {
                return b.y - a.y;
            });

            return parsed;
        },
    }
}

</script>

<style lang="scss" scoped>

.image {
    max-height: 100%;
    max-width: 250px;
}

.image-struct {
    margin-top: 8px;
    height: 140px;
    margin-bottom: 10px;
}

.owner-name {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 5px;
}

.status-title {
    font-size: 18px;
    font-weight: 500;
}

</style>
