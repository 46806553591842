<template>
    <span class="textarea">

        <LabelWithError v-if="label" :error="errors.first(name)" :label="$t(label)"/>
        <div v-on:click="action">
            <textarea ref="ref"
                      v-validate="rules"
                      :autofocus="autofocus"
                      :class="classGenerator(name)"
                      :data-vv-as="$t(label)"
                      :disabled="isDisabled"
                      :name="name"
                      :rows="rows"
                      :uk-tooltip="tooltip? 'title:'+tooltip : ''"
                      :value="value"

                      v-on:input="updateValue($event.target.value)"/>
        </div>

    </span>
</template>


<script>

import LabelWithError from "@/components/generic/LabelWithError";

export default {
    name: 'TextArea',
    components: {LabelWithError},
    props: {
        validate: Number,
        value: String,
        icon: String,
        autofocus: {
            type: Boolean,
            default: false
        },
        rows: {
            type: Number,
            default: 5
        },

        label: String,
        name: String,
        rules: String,
        fieldKey: Number,
        isDisabled: Boolean,

        action: {
            type: Function,
            default: function () {

            }
        },
        tooltip: String,
        isLoading: Boolean,
    },
    watch: {
        validate() {
            this.validation();
        },
    },
    methods: {
        validation() {
            this.$validator.validate().then(valid => {
                if (!valid && this.autofocus) {
                    this.$refs.ref.focus();
                }

                this.$emit('setValid', valid, this.fieldKey)
            });
        },
        updateValue(value) {
            this.$emit('input', value, this.fieldKey)
            this.validation();
        },
        classGenerator(name) {
            let classes = "uk-textarea";

            if (this.errors.first(name)) {
                classes += " danger";
            }

            if (this.action) {
                //classes += " cursor-pointer";
            }

            return classes;
        },
    }
}

</script>


<style lang="scss" scoped>

textarea {
    border-radius: $border-radius;
    color: $site-color !important;

    &:hover, &:focus {
        border-color: $theme-background !important;
    }

    &.dark {
        &:hover, &:focus {
            border-color: $theme-color-hover !important;
        }
    }

    &.danger {
        background: rgba(240, 80, 80, .15);
        border-color: $theme-background !important;
    }

    &.simple {
        border-radius: 0;
        border-top: 0;
        border-left: 0;
        border-right: 0;
    }

    &:disabled {
        cursor: auto;
    }

}

</style>
