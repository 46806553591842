<template>


    <Card mode="default">

        <div class="uk-text-center">
            <Icon icon="server" :ratio="3.0"/>
            <div class="description">
                {{ $t('remote.access') }}
            </div>
            <SwitchButton v-if="false" v-model="textFields[0].value" :name="textFields[0].name"
                          :label="$t(textFields[0].label)"/>

            <div class="uk-margin-top"/>
            <div v-if="!initialized">
                <Button is-min-width is-large :is-loading="loading" has-action :action="activate" mode="primary"
                        :text="$t('activate')"/>
            </div>
            <div v-else>
                <a :href="url" target="_blank">
                    <Button is-min-width is-large mode="success" :text="$t('open')"/>
                </a>
            </div>
        </div>

        <InfoBox v-if="loading" class="uk-margin-medium-top" icon="info" mode="theme">
            <div class="uk-animation-fade">
                <span v-html="$t('remote.access.loading.message')"/>
            </div>
        </InfoBox>
        <InfoBox v-else-if="err" mode="danger" icon="warning" class="uk-margin-medium-top">
            <ErrorMessage
                :generic-error-message="$t('remote.access.loading.error')"
                :error="err"/>
        </InfoBox>
        <InfoBox v-else-if="initialized" class="uk-margin-medium-top" icon="check" mode="success">
            <div class="uk-animation-fade">
                <span v-html="$t('remote.access.loaded.message')"/>
            </div>
        </InfoBox>
        <InfoBox v-else class="uk-margin-medium-top" icon="info" mode="info">
            <div class="">
                <span v-html="$t('remote.access.activate.message')"/>
            </div>
        </InfoBox>

        <Card v-if="false"
              class="container"
              mode="default" is-without-body>
            <iframe class="responsive" :src="url"></iframe>
        </Card>

    </Card>
</template>

<script>

import Card from "@/components/generic/Card";
import axios from "axios";
import Button from "@/components/generic/Button";
import InfoBox from "@/components/generic/InfoBox";
import ErrorMessage from "@/components/generic/ErrorMessage";
import Icon from "@/components/generic/Icon";
import SwitchButton from "@/components/generic/SwitchButton";

export default {
    name: 'RemoteAccessFrame',
    components: {SwitchButton, Icon, ErrorMessage, InfoBox, Button, Card},
    data() {
        return {
            loading: false,
            initialized: false,
            url: "http://control.dev.a365.systems",
            intervalId: null,
            err: null,
            tries: 0,
            maxTries: 5,

            textFields: [
                {
                    value: false,
                    valid: true,
                    //label: 'remote.access',
                },
            ],
        }
    },
    created() {
        // this.isSrcAlive()
    },
    methods: {
        activate() {
            this.loading = true;

            let self = this;
            // self.isSrcAlive();
            this.intervalId = setInterval(function () {
                self.isSrcAlive();
            }, 2000);
        },
        isSrcAlive() {
            let http = axios.create({
                timeout: this.$store.state.apiTimeout,
            });

            http.get(this.url).then(() => {

                if (!this.initialized) {
                    this.initialized = true;
                    this.loading = false;
                    window.open(this.url, '_blank').focus();
                }

            }).catch(e => {

                if (e && e.toString() === "Error: Network Error") {
                    if (!this.initialized) {
                        this.initialized = true;
                        this.loading = false;
                        window.open(this.url, '_blank').focus();
                    }

                    return;
                }

                this.initialized = false;
                this.loading = true;

                this.tries++;

                if (this.tries > this.maxTries) {
                    clearInterval(this.intervalId);
                    this.initialized = false;
                    this.loading = false;
                    this.err = e;
                    this.maxTries = 0;
                }

            });
        }
    }
}

</script>

<style lang="scss" scoped>

.container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
}

.responsive {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.description {
    font-size: 16px;
    margin-top: -2px;
    font-weight: 500;

    &.satellite {
        font-size: 15px;
    }
}

</style>
