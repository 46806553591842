<template>
    <GenericItem
        :actions="getActions()" :additionals="getAdditionals()"
        :icon-tooltip="$t('subscription')"

        :labels="getLabels()"
        :name="item.planId"

        icon="credit-card">

        <UpdateSubscription v-if="isAdminView"
                            :distributors="distributors" :item="item"
                            :modal-id="modalIdUpdate" :plans="plans" :satellites="satellites"
                            @afterSuccess="softUpdate"/>

        <UpdateSubscriptionSatellite v-if="!isAdminView"
                                     :item="item" :modal-id="modalIdAssign" :satellites="satellites"
                                     @afterSuccess="softUpdate"/>

        <DeleteSubscriptionSatellite v-if="!isAdminView" :item="item" :modal-id="modalIdDelete" :satellites="satellites"
                                     @afterSuccess="softUpdate"/>

        <Modal v-if="item.satelliteIds && item.satelliteIds.length>0 && this.item.expiry === 'expired'"
               :id="modalIdDetails" :title="$t('satellites')"
               is-medium>
            <Description :text="$t('subscriptions.linked.satellites')" class="uk-text-center"/>

            <CardSelect :options="getSatelliteList()" disabled label="satellites"
                        label-name="satellites"/>

            <div class="uk-margin-medium-top"/>
            <Button :text="$t('close')" class="success-button uk-modal-close" is-large is-max mode="theme"/>
        </Modal>

    </GenericItem>
</template>


<script>

import GenericItem from "@/components/items/GenericItem";
import UIkit from "uikit";
import UpdateSubscriptionSatellite from "@/components/updates/UpdateSubscriptionSatellite";
import DeleteSubscriptionSatellite from "@/components/deletes/DeleteSubscriptionSatellite";
import Modal from "@/components/generic/Modal";
import Description from "@/components/Description";
import Button from "@/components/generic/Button";
import CardSelect from "@/components/CardSelect";
import UpdateSubscription from "@/components/updates/UpdateSubscription";
import dayjs from "dayjs";

export default {
    name: 'SubscriptionItem',
    components: {
        UpdateSubscription,
        CardSelect,
        Button, Description, Modal, DeleteSubscriptionSatellite, UpdateSubscriptionSatellite, GenericItem
    },
    data() {
        return {
            modalIdUpdate: "modal-update-subscription-" + this.item.id,
            modalIdAssign: "modal-assign-subscription-" + this.item.id,
            modalIdDelete: "modal-delete-subscription-" + this.item.id,
            modalIdDetails: "modal-details-subscription-" + this.item.id,

            distributor: {
                id: "",
                name: "",
                ownerId: "",
                deviceIds: [],
                createdAt: "",
                confirmedAt: "",
                brandingIds: [],
            },
        }
    },
    created() {
        if (this.distributors) {

            for (let i = 0, j = this.distributors.length; i < j; i++) {
                if (this.item.distributorId === this.distributors[i].id) {
                    this.distributor = this.distributors[i];
                    break;
                }
            }

        }
    },
    props: {
        isAdminView: Boolean,
        satellites: Array,
        distributors: Array,
        plans: Array,

        item: {
            id: String,
            quota: Number,
            satelliteIds: Array,
            available: Number,
            distributorId: String,
            planId: String,
            valid: {from: String, until: String},
            expiry: String,
            status: String,
        },
    },
    methods: {
        getSatelliteById(id) {
            for (let i = 0, j = this.satellites.length; i < j; i++) {
                if (this.satellites[i].id === id) {
                    return this.satellites[i];
                }
            }

            return null;
        },
        getSatelliteList() {
            let items = [];


            for (let i = 0, j = this.item.satelliteIds.length; i < j; i++) {
                let item = this.getSatelliteById(this.item.satelliteIds[i]);

                if (item) {
                    let description = "";
                    if (item.description) {
                        description = " (" + item.description + ")";
                    }

                    items.push({
                        name: item.ean + description,
                        value: item.id,
                        nameIcon: "rss",
                    });

                } else {
                    items.push({
                        name: this.item.satelliteIds[i],
                        value: this.item.satelliteIds[i],
                        nameIcon: "rss",
                    });

                }
            }

            return items;
        },
        softUpdate(item) {
            this.$emit('afterSuccess', item);
        },
        getAdditionals() {
            let additionals = [];

            additionals.push({
                icon: 'hashtag',
                text: this.item.id,
                copy: true,
                tooltip: this.$t('subscription.copy.id')
            });

            if (this.isAdminView) {
                additionals.push({icon: 'social', text: this.distributor.name, copy: true});

            }

            return additionals
        },
        openUpdate() {
            UIkit.modal("#" + this.modalIdUpdate).show();
            event.preventDefault();
        },
        openAssign() {
            UIkit.modal("#" + this.modalIdAssign).show();
            event.preventDefault();
        },
        openDelete() {
            UIkit.modal("#" + this.modalIdDelete).show();
            event.preventDefault();
        },
        openDetails() {
            UIkit.modal("#" + this.modalIdDetails).show();
            event.preventDefault();
        },
        getActions() {
            let actions = [];

            if (this.isAdminView) {
                actions.push({
                    action: this.openUpdate,
                    icon: 'cog',
                    text: this.$t('change'),
                    mode: 'primary',
                });

                actions.push({
                    route: 'organisations/' + this.item.distributorId,
                    icon: 'social',
                    text: this.$t('distributor'),
                    mode: "default",
                    noAction: true,
                });

            } else {
                if (this.item.status === 'available' && this.item.expiry === 'valid') {
                    actions.push({
                        action: this.openAssign,
                        icon: 'link',
                        text: this.$t('assign'),
                        mode: 'primary',
                    });

                } else if (this.item.status === 'used') {
                    actions.push({
                        icon: 'link',
                        text: this.$t('assign'),
                        noAction: true,
                        isDisabled: true,
                        tooltip: this.$t('subscription.used'),
                    });

                } else if (this.item.expiry === 'expired') {
                    actions.push({
                        icon: 'link',
                        text: this.$t('assign'),
                        noAction: true,
                        isDisabled: true,
                        tooltip: this.$t('subscription.expired'),
                    });

                    if (this.item.satelliteIds && this.item.satelliteIds.length > 0) {
                        actions.push({
                            action: this.openDetails,
                            icon: 'rss',
                            text: this.$t('satellites'),
                            mode: 'default',
                        });
                    }
                }

                if (this.item.satelliteIds && this.item.satelliteIds.length > 0 && this.item.expiry === 'valid') {
                    actions.push({
                        action: this.openDelete,
                        icon: 'code',
                        text: this.$t('detach'),
                        mode: 'danger',
                    });

                }
            }

            return actions;
        },
        getLabels() {
            let labels = [];

            let start = dayjs(this.item.valid.from);
            let then = dayjs(this.item.valid.until);
            let fromNow = then.fromNow(true);
            let isSameOrAfter = dayjs().isSameOrAfter(then);

            let fromNowSuffix = then.fromNow();
            let isBefore = dayjs().isBefore(start);

            if (this.item.expiry === 'expired' || isSameOrAfter) {
                labels.push({
                    icon: 'cart',
                    type: 'danger',
                    text: '0/' + this.item.quota + ' ' + this.$t('available').toLowerCase(),
                    tooltip: this.$t('available') + '/' + this.$t('quota')
                });

            } else if (this.item.available > 0) {
                labels.push({
                    icon: 'cart',
                    type: 'success',
                    text: this.item.available + '/' + this.item.quota + ' ' + this.$t('available').toLowerCase(),
                    tooltip: this.$t('available') + '/' + this.$t('quota')
                });

            } else {
                labels.push({
                    icon: 'cart',
                    type: 'default',
                    text: this.item.available + '/' + this.item.quota + ' ' + this.$t('available').toLowerCase(),
                    tooltip: this.$t('available') + '/' + this.$t('quota')
                });
            }

            if (isBefore) {
                fromNowSuffix = this.removeFromNowSuffix(fromNowSuffix);

                labels.push({
                    type: "primary",
                    icon: 'future',
                    tooltip: this.$t('from') + ' ' + this.getFormattedDateByDateString(this.item.valid.from) + ' ' + this.$t('to') + ' ' + this.getFormattedDateByDateString(then),
                    text: fromNowSuffix + " " + this.$t('valid'),
                });

            } else if (isSameOrAfter) {
                labels.push({
                    type: "danger",
                    icon: 'history',
                    tooltip: this.$t('from') + ' ' + this.getFormattedDateByDateString(this.item.valid.from) + ' ' + this.$t('to') + ' ' + this.getFormattedDateByDateString(then),
                    text: fromNow + " " + this.$t('expired'),
                });

            } else {
                fromNow = this.removeFromNowSuffix(fromNow);

                labels.push({
                    type: "success",
                    icon: 'future',
                    tooltip: this.$t('from') + ' ' + this.getFormattedDateByDateString(this.item.valid.from) + ' ' + this.$t('to') + ' ' + this.getFormattedDateByDateString(then),
                    text: fromNow + " " + this.$t('valid'),
                });

            }

            return labels;
        },
    }
}

</script>


<style lang="scss" scoped>
</style>
