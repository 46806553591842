<template>
    <div>

        <GenericItem
            :actions="getActions()"
            :additionals="getAdditionals()"
            :icon-tooltip="$t('peripheral')"

            :labels="getLabels()"
            :name="item.description"

            icon="phone">

            <template v-slot:description>
                <div class="image-struct uk-flex uk-flex-middle uk-flex-center">

                    <ImageEnhanced :src="require('@/assets/devices/'+item.kindId+'.png')" :uk-tooltip="item.kindId"
                                   class="image"
                                   uk-img/>

                </div>
            </template>

        </GenericItem>

    </div>
</template>

<script>

import GenericItem from "@/components/items/GenericItem";
import ImageEnhanced from "@/components/ImageEnhanced";
import {deleteDeviceByIdSatellitePeripheralsByPeripheralId} from "@/client/vue-api-client";

export default {
    name: 'PeripheralItem',
    components: {ImageEnhanced, GenericItem},
    props: {
        isOwner: Boolean,
        deviceId: String,
        item: {
            description: String,
            id: String,
            ipAddress: String,
            kindId: String,
            status: String,
        },
    },
    methods: {
        delete() {
            let self = this;

            deleteDeviceByIdSatellitePeripheralsByPeripheralId({
                id: self.deviceId,
                peripheralId: self.item.id,

            }).then(function () {
                self.$emit('setDeleted', self.item.id);

            }).catch(function (e) {
                self.checkGenericError(e);

            })
        },
        getActions() {
            let actions = [];

            if (!this.isOwner) {
                actions.push({
                    icon: 'trash',
                    text: this.$t('delete'),
                    mode: 'disabled',
                    isDisabled: true,
                    noAction: true,
                    tooltip: this.$t('owner.only.action', {description: this.$t('peripheral.delete')}),
                });

                return actions;
            }

            actions.push({
                action: this.delete,
                icon: 'trash',
                text: this.$t('delete'),
                mode: 'danger',

                button: this.$t('peripheral.delete'),
                confirmTitle: this.$t('reallyDelete'),
                confirmMessage: this.$t('peripheral.delete.confirm', {name: this.item.description ? this.item.description : this.item.kindId()})
            });

            return actions;
        },
        getAdditionals() {
            let additionals = [];

            additionals.push({
                icon: 'world',
                text: this.item.ipAddress,
                copy: true,
            });

            return additionals;
        },
        getLabels() {
            let labels = [];

            let type = "default";
            let icon = "info";
            let tooltip = this.$t(this.fromDistributor ? 'noSatellitDistributorMessage' : 'noSatellitMessage');
            let text = this.item.monitoring.status;

            if (text === 'withoutMonitoring') {
                tooltip = this.$t(this.fromDistributor ? 'noSubscriptionForModulDistributor' : 'noSubscriptionForModul', {name: 'Monitoring'});
            }

            if (text === 'offline') {
                type = "danger";
                icon = "warning";
                tooltip = this.$t('offline.description.peripheral');
            }

            if (text === 'online') {
                type = "success";
                icon = "bolt";
                tooltip = this.$t('online.description.peripheral');
            }

            if (text === 'determine') {
                type = "default";
                icon = "info";
                tooltip = this.$t('determine.description');
            }

            if (text === 'withoutMonitoring') {
                type = "default";
                icon = "info";
                tooltip = this.$t(this.fromDistributor ? 'noSubscriptionForModulDistributor' : 'noSubscriptionForModul', {name: 'Monitoring'});
            }

            labels.push({
                type: type,
                icon: icon,
                text: this.$t(this.capitalize(text)),
                tooltip: tooltip,
            });

            return labels;
        },
    }
}

</script>


<style lang="scss" scoped>

.image {
    max-height: 100%;
    max-width: 250px;
}

.image-struct {
    margin-top: 8px;
    height: 140px;
    margin-bottom: 10px;
}

</style>