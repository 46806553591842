<template>
    <div>

        <LoadingStruct :is-loading="loading">
            <GenericSiteTitle :title-poperties="[{key: 0, disabled: true}]">
                <template v-slot:action>
                    <CreateSubscription :distributors="distributors" :plans="plans" @afterSuccess="getItems"/>
                </template>
            </GenericSiteTitle>

            <GenericItemView
                :computed-items="computedItems"
                :error="error"
                :filters="filters"
                :is-loading="loading"
                :is-search="search.length>0"
                :items="items"
                :no-items-message="$t('subscriptions.notcreated')"
                is-searchable
                label-plural="subscriptions"
                @search="setSearch"
            >
                <template v-slot:items>
                    <li v-for="item in computedItems" :key="item.id"
                        :data-expiry="item.expiry" :data-status="item.status"
                    >
                        <SubscriptionItem :distributors="distributors" :item="item" :plans="plans"
                                          :satellites="satellites"
                                          is-admin-view @afterSuccess="getItems"/>
                    </li>
                </template>

            </GenericItemView>
        </LoadingStruct>

    </div>
</template>

<script>

import {getDistributor, getPlan, getSatellite, getSubscription} from "@/client/vue-api-client";
import GenericItemView from "@/views/GenericItemView";
import GenericSiteTitle from "@/views/GenericSiteTitle";
import LoadingStruct from "@/views/LoadingStruct";
import CreateSubscription from "@/components/creates/CreateSubscription";
import SubscriptionItem from "@/components/items/SubscriptionItem";

export default {
    name: 'SubscriptionsAdministration',
    components: {
        SubscriptionItem,
        CreateSubscription,
        LoadingStruct,
        GenericSiteTitle,
        GenericItemView,
    },
    data() {
        return {
            loading: true,
            error: null,
            items: [],
            search: "",

            filters: [
                {
                    type: 'expiry',
                    values: [
                        {name: 'valid', total: 0},
                        {name: 'expired', total: 0},
                    ]
                },
                {
                    type: 'status',
                    values: [
                        {name: 'available', total: 0},
                        {name: 'used', total: 0},
                    ]
                },
            ],

            distributors: [],
            plans: [],
            satellites: [],
        }
    },
    created() {
        this.getDistributors();
    },
    computed: {
        computedItems() {
            let items = this.items;
            if (this.search && this.search.length) {
                items = this.powerSearcher(items, this.search);
            }

            if (items.length > this.defaultShowLimit) {
                return items.slice(0, this.defaultShowLimit);
            }

            return items;
        },
    },
    methods: {
        setSearch(text) {
            this.search = text;
        },
        getDistributors() {
            let self = this;

            getDistributor().then(function (response) {
                self.distributors = response.data;
                self.getItems();

            }).catch(function (e) {
                self.checkGenericError(e);
                self.error = e;
                self.loading = false;

            })

        },
        getDistributorById(id) {
            for (let i = 0, j = this.distributors.length; i < j; i++) {
                if (id === this.distributors[i].id) {
                    return this.distributors[i];
                }
            }
        },
        getItems() {
            let self = this;

            getSubscription().then(function (response) {
                let items = response.data;
                items.reverse();

                for (let i = 0, j = items.length; i < j; i++) {
                    items[i]['available'] = items[i]['quota'] - items[i]['satelliteIds'].length;
                    items[i]['expiry'] = self.getSubscriptionStatus(items[i]);
                    items[i]['status'] = self.getAvailableStatus(items[i]);
                    let distributor = self.getDistributorById(items[i].distributorId);
                    if (distributor) {
                        items[i]['distributorName'] = distributor.name;
                    }
                }

                self.items = items;

                self.getPlans();

            }).catch(function (e) {
                self.checkGenericError(e);
                self.error = e;
                self.loading = false;

            })
        },
        getPlans() {
            let self = this;

            getPlan().then(function (response) {
                self.plans = response.data;
                self.getSatellites();

            }).catch(function (e) {
                self.checkGenericError(e);
                self.error = e;
                self.loading = false;

            })
        },
        getSatellites() {
            let self = this;

            getSatellite().then(function (response) {
                let items = response.data;
                self.satellites = items;
                self.loading = false;

            }).catch(function (e) {
                self.checkGenericError(e);
                self.error = e;
                self.loading = false;

            })
        },
    }
}

</script>

<style lang="scss" scoped>

</style>
