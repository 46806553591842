import { render, staticRenderFns } from "./UpdateSatelliteDevice.vue?vue&type=template&id=2843b046&scoped=true&"
import script from "./UpdateSatelliteDevice.vue?vue&type=script&lang=js&"
export * from "./UpdateSatelliteDevice.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2843b046",
  null
  
)

export default component.exports