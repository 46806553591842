<template>
    <span :uk-tooltip="'title:' +tooltip ? tooltip : ''" v-on:click="callAction">

        <button :class="'uk-button' + generateClassesByProperties()">
            <span :class="isLoading ? 'uk-invisible' : ''">
                {{ text }}
                <slot/>
            </span>

            <div v-if="isLoading" class="loading-item">
                <div uk-spinner/>
            </div>
        </button>

    </span>
</template>

<script>
export default {
    name: 'Button',
    props: {
        tooltip: String,
        text: String,
        mode: String,

        isLarge: Boolean,
        isSmall: Boolean,
        isDisabled: Boolean,
        isRound: Boolean,
        isLoading: Boolean,
        isMax: Boolean,
        isSelected: Boolean,
        isMinWidth: Boolean,

        action: Function,
        hasAction: Boolean,
    },
    methods: {
        callAction() {
            if (this.isLoading) {
                return;
            }

            if (this.isDisabled) {
                return;
            }

            if (!this.hasAction) {
                return;
            }

            this.action();
        },
        generateClassesByProperties() {
            let classes = ''

            if (this.isLarge) {
                classes += ' large'
            }

            if (this.isSmall) {
                classes += ' small'
            }

            if (this.isRound) {
                classes += ' round'
            }

            if (this.isMax) {
                classes += ' max'
            }

            if (this.isMinWidth) {
                classes += ' min-width'
            }

            if (this.isSelected) {
                classes += ' selected'
            }

            if (this.isLoading) {
                classes += ' loading'
            }

            if (this.isDisabled) {
                classes += ' uk-button-disabled'
            } else {
                classes += ' uk-button-' + this.mode
            }

            return classes
        },
    },
}
</script>

<style lang="scss" scoped>

.uk-button {
    text-transform: initial !important;
    color: #fff !important;
    font-size: 16px;

    border-radius: $border-radius;

    transition: border-color 0.25s linear, color 0.25s linear, background-color 0.25s linear !important;
}

.small {
    font-size: 0.875rem !important;
    line-height: 1.33337;
    padding: 4px 11px 4px 11px;
}

.uk-button-disabled {
    background-color: #999 !important;
    cursor: not-allowed !important;
}

.max {
    width: 100%;
}

.round {
    border-radius: 24px;
}

.large {
    font-size: 17px !important;
    padding: 0 28px !important;
    min-height: 50px !important;
    font-weight: 500 !important;
    line-height: 28px;
}

.min-width {
    min-width: 180px;
}

.loading-item {
    margin-top: -30px;
}


.uk-button-warning {
    background-color: $warning-color !important;
    transition: background-color 0.25s linear, color 0.25s linear;
    color: #fff !important;
    border: 1px solid transparent;

    &.hover,
    &:hover,
    &.active,
    &:active,
    &.focus,
    &:focus {
        color: #fff;
        background-color: #fda964 !important;
    }
}

.uk-button-theme {
    color: $theme-color;
    background-color: $theme-background;
    transition: border-color 0.25s linear, color 0.25s linear;

    &:hover, &:focus {
        color: $theme-color-hover;
        background-color: $theme-background-hover;
    }
}

.uk-button-default-alt {
    border: 2px solid #fff;
    background-color: transparent;
    transition: background-color 0.25s linear, color 0.25s linear;

    &.hover,
    &:hover,
    &.active,
    &:active,
    &.focus,
    &:focus {
        color: #333 !important;
        background-color: #fff;
    }
}

.uk-button-default-dark {
    border: 2px solid #999;

    color: #333 !important;
    background-color: transparent;
    transition: border-color 0.25s linear, color 0.25s linear, border-width 0.25s linear;

    &.hover,
    &:hover,
    &.active,
    &:active,
    &.focus,
    &:focus {
        border-color: #333;
        color: #000 !important;
    }

    &.selected {
        transition: border-color 0.25s linear, color 0.25s linear;
        border-width: 3px;
        border-color: #0071e3;
    }
}

.uk-button-default {
    color: #333 !important;
    background-color: #fff;
    transition: border-color 0.25s linear, color 0.25s linear;

    &.hover,
    &:hover,
    &.active,
    &:active,
    &.focus,
    &:focus {
        background-color: #fff;
        color: #000 !important;
    }
}

.uk-button-primary {
    background-color: #0071e3 !important;
    transition: background-color 0.25s linear, color 0.25s linear;

    &.hover,
    &:hover,
    &.active,
    &:active,
    &.focus,
    &:focus {
        background-color: #147ce5 !important;
    }
}

.uk-button-success {
    background-color: #28a778 !important;
    transition: background-color 0.25s linear, color 0.25s linear;
    color: #fff;
    border: 1px solid transparent;

    &.hover,
    &:hover,
    &.active,
    &:active,
    &.focus,
    &:focus {
        color: #fff;
        background-color: #2bb37f !important;
    }
}

</style>
