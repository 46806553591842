<template>
    <div id="app">

        <div class="theme-struct"/>

        <Navigation v-if="!startForms.includes($route.name)" :links="links"/>

        <div :class="'content' + (startForms.includes($route.name) ? ' mobile' : '')">
            <router-view/>
        </div>

        <CookieControl/>

    </div>
</template>

<script>

import Vue from "vue";
import {i18n} from "@/main";
import Navigation from "@/components/Navigation";
import axios from "axios";
import UIkit from "uikit";
import {getUserById, getUserByIdDistributors} from "@/client/vue-api-client";
import {readingTime} from 'reading-time-estimator';
import CookieControl from "@/components/CookieControl";
import dayjs from "dayjs";

Vue.mixin({
    computed: {
        getInvitedTo() {
            return this.$store.state.invitedPath;
        },
        getInvitedEmail() {
            return this.$store.state.invitedEmail;
        },
        getPathTo() {
            return this.$store.state.requestedPath;
        },
        isAdmin() {
            return this.$store.state.roleId === 'Administrator';
        },
        isDistributor() {
            return this.$store.state.distributor;
        },
        getToken() {
            return this.$store.state.token;
        },
        getUserEmail() {
            return this.$store.state.userEmail;
        },
        getUserId() {
            return this.$store.state.userId;
        },
        getAllowedRoles() {
            return this.$store.state.allowedRoles;
        },
        getCookieConsetAccepted() {
            return this.$store.state.cookieconsent === 'accepted';
        },
    },
    methods: {
        getPercentage(total, value) {
            return value / total * 100;
        },
        getFormattedNumber(number) {
            return new Intl.NumberFormat('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(number);
        },
        downloadSatelliteConfigFiles(satellite) {
            let satelliteYml =
                "# put this on your satellite-device in `/srv/auerswald365/satellite.yml`\n" +
                "satelliteId: " + satellite.id + "\n" +
                "apiToken: " + satellite.apiToken + "\n" +
                "portalUri: " + this.baseUrl + "\n";
            this.downloadData('satellite-' + satellite.id + '.yml', satelliteYml);

            let emergencyYml =
                "# put this on your usb-device `/satellite-emergency.yml`\n" +
                "token: " + satellite.emergency.token + "\n" +
                "portalUri: " + this.baseUrl + "\n";
            this.downloadData('satellite-' + satellite.id + '-emergency.yml', emergencyYml);
        },
        getDeviceStatus(deviceDetails, monitoring) {
            let requirementStatus = this.getRequirementStatusByDevice(deviceDetails);
            if (requirementStatus) {
                return requirementStatus;
            }

            let events = monitoring.device.events;
            let lastItem = events[events.length - 1];
            return this.getMonitoringStatusByEvent(lastItem);
        },
        getRequirementStatusByDevice(device) {
            if (!device.satelliteId) {
                return "withoutSatellite";
            }

            if (!device.subscriptions || !device.subscriptions.monitoring || !device.subscriptions.monitoring.active) {
                return "withoutMonitoring";
            }

            return "";
        },
        capitalize(str) {
            if (!str) {
                return str;
            }

            return str.charAt(0).toUpperCase() + str.substring(1);
        },
        removeFromNowSuffix(fromNow) {
            if (fromNow.includes(this.$t('days.suffix')) || fromNow.includes(this.$t('months.suffix')) || fromNow.includes(this.$t('years.suffix'))) {
                return fromNow.slice(0, -1);
            }

            return fromNow;
        },
        getMonitoringStatusByEvent(event) {
            if (!event) {
                return 'determine';
            }

            if (!event.alive) {
                return "offline";
            }

            /*            let secondsAgo = this.getSecondsAgoByDateString(event.timeStamp);
                        if (secondsAgo > (60 * 15) && secondsAgo < (60 * 30)) {
                            return "warning.time";
                        }

                        if (secondsAgo >= (60 * 30)) {
                            return "offline.time";
                        }*/

            return "online";
        },
        downloadData(name, data) {
            let fileURL = window.URL.createObjectURL(new Blob([data]));
            let fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', name);
            document.body.appendChild(fileLink);
            fileLink.click();
        },
        reload() {
            location.reload();
        },
        getRouteParts(properties) {
            let pathParts = this.$route.path.split('/');
            let breadCrums = [];
            let skipItem = false;
            let nameSet = false;
            for (let i = 2, j = pathParts.length; i < j; i++) {

                let route = pathParts[i];
                if (i > 2) {
                    route = "";
                    for (let k = 2, l = i + 1; k < l; k++) {
                        route += pathParts[k] + "/";
                    }
                }

                let name = pathParts[i];
                let disabled = false;

                let tooltip = null;

                if (properties) {
                    for (let k = 0, l = properties.length; k < l; k++) {

                        if (properties[k] && properties[k].key === i - 2) {

                            if (properties[k].tooltip) {
                                tooltip = properties[k].tooltip;
                            }

                            if (properties[k].name) {
                                name = properties[k].name;
                                nameSet = true;
                            }

                            if (properties[k].disabled) {
                                disabled = properties[k].disabled;
                            }

                            if (properties[k].hidden) {
                                skipItem = true;
                                continue;
                            }

                        }

                        if (!nameSet) {
                            name = this.$t(pathParts[i]);
                        }

                    }

                    if (skipItem) {
                        skipItem = false;
                        continue;
                    }

                } else {
                    name = this.$t(pathParts[i]);

                }

                route.trim();
                if (route.charAt(route.length - 1) === "/") {
                    route = route.slice(0, -1);
                }

                breadCrums.push({
                    name: name.trim(),
                    route: route,
                    disabled: disabled,
                    tooltip: tooltip,
                    active: false,
                })

            }

            breadCrums[breadCrums.length - 1].active = true;

            return breadCrums;
        },
        getFormattedTypesString(types) {
            let formatted = "";

            if (types.length === 1) {
                formatted += "." + types[0];
                return formatted;
            }

            for (let i = 0, j = types.length; i < j; i++) {
                if (i === types.length - 1) {
                    formatted += " " + this.$t('or') + " ";
                }

                formatted += "." + types[i];

                if (i < types.length - 1) {
                    formatted += ", ";
                }
            }

            return formatted;
        },
        switchLanguage(language) {
            if (this.$i18n.locale === language) {
                Vue.localStorage.set('language', language)
                return
            }

            let currentRoute = this.$router.currentRoute.path
            let languages = this.languages

            if (!languages.includes(language)) {
                language = 'de';
            }

            for (let i = 0, j = languages.length; i < j; i++) {
                if (languages[i] !== language) {
                    currentRoute = currentRoute.replace(languages[i], language)
                }
            }

            this.$i18n.locale = language
            Vue.localStorage.set('language', language)
            if (currentRoute !== this.$router.currentRoute.path) {
                this.$router.push(currentRoute)
            }
        },
        routeTo(to) {
            this.$router.push("/" + i18n.locale + '/' + to);
        },
        checkGenericError(e) {
            console.log(e);

            this.$store.state.requestedPath = this.$route.path;
            if (e && e.response && (e.response.status === 401 || e.response.status === 403 || (e.response.data && e.response.data.message === 'Token is expired'))) {
                this.logout(false);
                return true;
            }

            return false;
        },
        logout(notify = true) {
            this.$cookie.delete("token");
            this.$cookie.delete("userId");
            this.$cookie.delete("userEmail");
            this.$cookie.delete("roleId");
            this.$cookie.delete("distributor");

            this.$store.state.token = null;
            this.$store.state.userId = null;
            this.$store.state.userEmail = null;
            this.$store.state.roleId = null;
            this.$store.state.distributor = false;

            if (notify) {
                UIkit.notification({
                    message: this.$t('successfullyLoggedOut'),
                    status: 'info',
                    pos: 'top-center',
                    timeout: this.notificationTimeout,
                });
            }

            this.routeTo('login');
        },
        getRandomInt(max) {
            return Math.floor(Math.random() * Math.floor(max));
        },
        getDiffByDateStrings(from, until) {
            from = dayjs(from);
            until = dayjs(until);

            return this.removeFromNowSuffix(from.from(until, true));
        },
        getFormattedDateByDateString(genericDate, slim = false) {
            let dateLang = 'en-US';
            if (this.$i18n.locale === 'de') {
                dateLang = 'de-DE';
            }

            return dayjs(genericDate).locale(dateLang).format("LLL" + (slim ? '' : 'L')) + " " + this.$t('clock');
        },
        getDaysAgoMessage(daysAgo) {
            if (daysAgo === 0) {
                return this.$t('today');
            }

            if (daysAgo === 1) {
                return this.$t('yesterday');
            }

            return this.$t('xDaysAgo', {days: daysAgo})
        },
        getSecondsAgoByDateString(genericDate) {
            let now = new Date();
            let date = new Date(genericDate);

            return parseInt((now.getTime() - date.getTime()) / (1000));
        },
        getDaysAgoByDateString(genericDate) {
            let now = new Date();
            let date = new Date(genericDate);

            now.setHours(0, 0, 0, 0);
            date.setHours(0, 0, 0, 0);

            return parseInt((now.getTime() - date.getTime()) / (1000 * 3600 * 24));
        },
        getAvailableStatus(subscription) {
            let status = this.getSubscriptionStatus(subscription);

            if (status === "expired") {
                return status;
            }

            return subscription.available === 0 ? 'used' : 'available';
        },
        getSubscriptionStatus(subscription) {
            let then = dayjs(subscription.valid.until);
            let isSameOrAfter = dayjs().isSameOrAfter(then);

            if (isSameOrAfter) {
                return "expired";
            }

            return "valid";
        },
        getDaysLeftByDateString(genericDate) {
            return dayjs(genericDate).fromNow(true)
        },
        getParsedReadingTime(text) {
            const result = readingTime(text);
            return result.minutes < 1 ? "<1" : result.minutes;
        },
        powerSearcher(array, value, targetProp = "") {
            let i, j, hash = [], item;
            for (i = 0, j = array.length; i < j; i++) {
                item = array[i];

                for (let prop in item) {

                    if (targetProp && prop !== targetProp) {
                        continue;
                    }

                    let target = item[prop] + "";
                    if (target.toLowerCase().includes(value.toLowerCase())) {
                        hash.push(item);
                        break;
                    }
                }
            }
            return hash;
        },
        hasConfirmedDistributor(member, expiresAt) {
            let token = this.getToken;
            let userId = this.getUserId;

            if (token && userId) {
                let self = this;
                getUserByIdDistributors({
                    id: userId,

                }).then(function (response) {
                    let distributors = response.data;
                    for (let i = 0, j = distributors.length; i < j; i++) {
                        if (distributors[i].confirmedAt) {
                            self.$store.state.distributor = true;
                            if (member) {
                                self.$cookie.set("distributor", true, {expires: expiresAt});
                            }

                            return;
                        }
                    }

                    self.$store.state.distributor = false;
                    self.$cookie.delete("distributor");


                }).catch(function (e) {
                    self.checkGenericError(e);

                    self.$store.state.distributor = false;
                    self.$cookie.delete("distributor");

                })
            }

        },
    }
})

export default {
    name: 'App',
    components: {CookieControl, Navigation},
    data() {
        return {
            startForms: ['login', 'register', 'resetpassword', 'resetpasswordconfirm', 'registerconfirm', 'invites'],
            links: [
                {route: "dashboard", name: "dashboard", icon: "dashboard"},
                // {route: "news", name: "news", icon: "file-text"},
                {route: "devices", name: "devices", icon: "server"},
                {route: "organisations", name: "organisations", icon: "social", permission: "distributor"},
                /*
                {
                    route: "", name: "services", icon: "nut",
                    subLinks: [
                        {route: "ticketsystem", name: "ticketsystem", icon: "mail"},
                        {route: "updateserver", name: "updateserver", icon: "future"},
                        {route: "sbc", name: "sbc", icon: "terminal"},
                        {route: "dyndns", name: "dynDns", icon: "world"},
                        {route: "wiki", name: "wiki", icon: "info"},
                    ]
                },
                */
                {
                    route: "", name: "administration", icon: "settings", permission: "administrator",
                    mode: "click",
                    subLinks: [
                        {route: "administration/users", name: "users", icon: "users"},
                        {route: "administration/organisations", name: "organisations", icon: "social"},
                        {route: "administration/devices", name: "devices", icon: "server"},
                        {route: "administration/logger", name: "logger", icon: "rss"},
                        {route: "administration/subscriptions", name: "subscriptions", icon: "credit-card"},
                        {route: "administration/modules", name: "modules", icon: "cart"},
                        {route: "administration/statistics", name: "statistics", icon: "chart-bar"},
                        // {route: "news", name: "news", icon: "list"},
                    ]
                },
                // {route: "", name: "support", icon: "lifesaver", action: this.openSupportRequest, hasAction: true},
                {
                    route: "wiki", name: "wiki", icon: "info",
                    mode: "hover",
                    subLinks: [
                        {
                            route: "wiki#monitoring",
                            icon: "bolt",
                            name: "monitoring",
                        },
                        {
                            route: "wiki#vassist2",
                            icon: "receiver",
                            name: "vassist2",
                        },
                        {
                            route: "wiki#backups",
                            icon: "history",
                            name: "backups",
                        },
                        {
                            route: "wiki#portal",
                            icon: "dashboard",
                            name: "portal",
                        },
                        {
                            route: "wiki#subscriptions",
                            icon: "credit-card",
                            name: "subscriptions",
                        },
                        {
                            route: "wiki#satellites",
                            icon: "rss",
                            name: "satellites",
                        },
                        {
                            route: "wiki#users",
                            icon: "users",
                            name: "users",
                        },
                        {
                            route: "wiki#support",
                            icon: "lifesaver",
                            name: "support",
                        },
                        {
                            route: "wiki#provisioning",
                            icon: "code",
                            name: "provisioning",
                        },
                    ]
                },
                {route: "account", name: "myAccount", icon: "user"},
                // {route: "", name: "logout", icon: "sign-out", action: this.logout, hasAction: true},
            ],
        };
    },
    created() {
        axios.defaults.timeout = this.apiTimeout;

        let token = this.$cookie.get("token");
        if (token) {
            this.$store.state.token = token;
            axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
        }

        let userId = this.$cookie.get("userId");
        if (userId) {
            this.$store.state.userId = userId;
        }

        let userEmail = this.$cookie.get("userEmail");
        if (userEmail) {
            this.$store.state.userEmail = userEmail;
        }

        let roleId = this.$cookie.get("roleId");
        if (roleId) {
            this.$store.state.roleId = roleId;
        }

        let distributor = this.$cookie.get("distributor");
        if (distributor) {
            this.$store.state.distributor = distributor;
        }

        if (this.$route.name !== 'login') {
            this.hasConfirmedDistributor();
            this.getUserDetails();
        }

        if (this.whitelabel) {
            this.links.splice(3, 1);
        }
    },
    mounted() {
        window.addEventListener("dragover", function (e) {
            e.stopPropagation();
            e.preventDefault();
        }, false);
        window.addEventListener("drop", function (e) {
            e.stopPropagation();
            e.preventDefault();
        }, false);
    },
    methods: {
        getUserDetails() {
            let token = this.$cookie.get("token");
            let userId = this.$cookie.get("userId");

            if (token && userId) {
                let self = this;
                getUserById({
                    id: userId,

                }).then(function (response) {
                    self.$store.state.roleId = response.data.roleId;
                    self.$store.state.userEmail = response.data.mailAddress;

                }).catch(function (e) {
                    self.checkGenericError(e);

                })
            }

        },
        openSupportRequest() {
            UIkit.modal("#modal-create-support-request").show();
            event.preventDefault();
        },
    }
}

</script>

<style lang="scss">

.uk-label {
    text-transform: initial !important;
    cursor: default !important;
}

html, body {
    font-family: "Barlow", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    min-height: 100%;
    background-color: $site-background !important;
    color: $site-color;
    font-size: $site-font-size;
    min-width: 350px;
}

.cursor-pointer {
    cursor: pointer !important;
}

.theme-struct {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100vw;
    height: 470px;
    user-select: none;
    background: $theme-background;
}


@media (max-width: $breakpoint-xsmall-max) {
    .theme-struct {
        height: 580px;
    }
}


.content {
    color: $site-color;
    max-width: calc(#{$max-site-width} + 180px);
    margin: auto;
    padding: 20px 60px 80px 60px;
    overflow-y: auto;
}

@media (max-width: $breakpoint-medium-max) {
    .content {
        padding: 20px 40px 80px 40px;
    }
}

@media (max-width: $breakpoint-xsmall-max) {
    .content {
        padding: 50px 20px 80px 20px;

        &.mobile {
            // padding: 30px 20px 60px 20px;
        }
    }
}


@media (max-width: 445px) {
    .content {
        padding: 0px 20px 80px 20px;
    }
}

.menu {
    width: 80px;
    background-color: #fff;
    -webkit-box-shadow: 0 14px 25px #000;
    box-shadow: 0 14px 25px #000;
    color: #000 !important;
    padding-top: 30px !important;
    z-index: 1000;
}

.half-struct {
    max-width: $max-site-width-single;
    margin: auto;
}

.uk-input {
    border-color: #999 !important;

    &:hover {
        border-color: #1e87f0 !important;
    }
}


.section-distance {
    margin-top: 150px;
}

.blur-fix {
    height: 1px;
}

.blurfix-text {
    height: 22px;
}

.uk-sticky-placeholder {
    height: 0 !important;
}

.uk-label {
    font-size: 16px !important;
    border-radius: $border-radius !important;
    padding: 3px 15px 3px 15px !important;

    &.uk-label-success {
        background-color: $success-color !important;
    }

    &.uk-label-info {
        background-color: $info-color !important;
    }
}

.uk-card-hovered {
    box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16) !important;
}

.Password {
    max-width: initial !important;
}

.uk-table th {
    text-transform: initial !important;
}

table tr td a {
    display: block;
    height: 100%;
    width: 100%;
}

.uk-notification-message {
    border-radius: $border-radius;
}

.uk-nav-header {
    text-transform: initial !important;
}

.uk-label-theme {
    background-color: $theme-background !important;
    color: #fff;
}

.uk-progress::-webkit-progress-value {
    background-color: $success-color !important;
}

.uk-progress::-moz-progress-bar {
    background-color: $success-color !important;
}

.uk-progress::-ms-fill {
    background-color: $success-color !important;
}

.uk-form-success {
    color: $success-color !important;
}

.uk-navbar-dropdown {
    border-radius: $border-radius !important;
}


img::selection {
    color: initial;
    background: none;
}

.cursor-initial {
    cursor: initial !important;
}

.cursor-notallowed {
    cursor: not-allowed !important;
}

.uk-padding-remove-left {
    padding-left: 0 !important;
}

.uk-padding-remove-right {
    padding-right: 0 !important;
}

.dot {

    margin-top: 8px;

    height: 6px;
    width: 6px;
    background-color: $theme-color-hover;
    border-radius: 50%;

    &.desktop {
        margin-left: -11px;
    }

    &.mobile {
        //  margin-top: 25px;

    }
}

table tr td a {
    padding: 16px 0 16px 0;
}

.highcharts-credits, .highcharts-legend {
    display: none !important;
}

.uk-width-xsmall {
    width: 160px !important;
}

</style>
