<template>
    <GenericCreate
        :action="create" :action-error="error" :action-label="$t('create')"

        :action-loading="loading" :icon-tooltip="$t('addNewDevice')"

        :modal-id="modalId"

        :success-description="$t('deviceAddedSuccessfully', {name: itemName})" :success-title="$t('addedDevice')"
        :title="$t('addNewDevice')" :trigger="trigger"

        icon="plus"
        modal-is-medium
    >

        <CardSelect v-model="textFields[0].value" :field-key="0" :label="textFields[0].label"
                    :label-name="textFields[0].name" :options="deviceTypes" :rules="textFields[0].rules"
                    :validate="trigger" label-plural="devices" @setValid="setCardValid"/>
        <div class="uk-margin-bottom"/>

        <span v-for="(inputField, key) in textFields" :key="inputField.name">
            <div v-if="key>0 && key < textFields.length-1">
                <TextField v-model="inputField.value" :autocomplete="inputField.autocomplete"
                           :autofocus="inputField.autofocus" :field-key="key"
                           :hasPasswordMeter="inputField.hasPasswordMeter"
                           :icon="inputField.icon"
                           :is-optional="inputField.optional"
                           :label="inputField.label"
                           :name="inputField.name"
                           :onEnter="create"
                           :rules="inputField.rules"
                           :type="inputField.type"
                           :validate="trigger"
                           @setValid="setValid"
                />
            </div>
            <div v-if="key !== textFields.length -1" class="uk-margin-bottom"/>
        </span>

        <div class="uk-margin-top"/>
        <SelectField :label="textFields[4].label" v-model="textFields[4].value" :name="textFields[4].name"
                     :options="getCategoryList()" @setValid="setValidCategory"
                     icon="tag" type="number"
                     :validate="trigger" :rules="textFields[4].rules"
        />

    </GenericCreate>
</template>

<script>

import {getDeviceCategories, postDistributorByIdDevices} from "@/client/vue-api-client";
import TextField from "../generic/TextField";
import CardSelect from "../CardSelect";
import GenericCreate from "@/components/creates/GenericCreate";
import UIkit from "uikit";
import SelectField from "@/components/SelectField";

export default {
    name: 'CreateDevice',
    components: {SelectField, GenericCreate, CardSelect, TextField},
    props: {
        distributorId: String,
        value: Boolean,
    },
    data() {
        return {
            modalId: "modal-create-device-" + this.distributorId,
            trigger: 0,

            textFields: [
                {
                    value: "",
                    valid: false,

                    autofocus: true,
                    name: "device",
                    label: "device",
                    rules: "required",
                    icon: "hashtag",
                },
                {
                    value: "",
                    valid: false,

                    autofocus: false,
                    name: "device-description",
                    label: "description",
                    rules: "required",
                    icon: "hashtag",
                },
                {
                    value: "",
                    valid: false,

                    autofocus: false,
                    name: "local-ip",
                    label: "localeIP",
                    rules: "required|ip|private_ip",
                    icon: "world",
                },
                {
                    value: "",
                    valid: false,

                    autofocus: false,
                    name: "dongle-id",
                    label: "dongleId",
                    rules: "required",
                    icon: "usb",
                },
                {
                    value: "",
                    valid: false,

                    autofocus: false,
                    name: "category",
                    label: "category",
                    rules: "required",
                    icon: "usb",
                },
            ],

            loading: false,
            error: null,
            itemName: "",

            deviceTypes: [
                {
                    name: "COMtrexx",
                    value: "COMtrexx",
                    media: true,
                },
                {
                    name: "COMpact 4000",
                    value: "COMpact 4000",
                    media: true,
                },
                {
                    name: "COMpact 5200",
                    value: "COMpact 5200",
                    media: true,
                },
                {
                    name: "COMpact 5200R",
                    value: "COMpact 5200R",
                    media: true,
                },
                {
                    name: "COMpact 5500R",
                    value: "COMpact 5500R",
                    media: true,
                },
            ],

            categories: [],
            categoriesLoading: true,
        }
    },
    created() {
        this.getCategories();
    },
    methods: {
        getCategoryList() {
            let categories = [];
            for (let i = 0, j = this.categories.length; i < j; i++) {
                categories.push({
                    nameIcon: "tag",
                    name: this.categories[i].name,
                    value: this.categories[i].key,
                });
            }

            categories = categories.sort(function (a, b) {
                return a['name'].localeCompare(b['name'])
            });

            return categories;
        },
        setValidCategory(valid) {
            this.textFields[4].valid = valid;
        },
        setCardValid(valid) {
            this.textFields[0].valid = valid;
        },
        setValid(valid, fieldKey) {
            this.textFields[fieldKey].valid = valid
        },
        getCategories() {
            let self = this;

            getDeviceCategories().then(function (response) {
                self.categories = response.data;
                self.categoriesLoading = false;

            }).catch(function (e) {
                self.checkGenericError(e);
                self.error = e;
                self.categoriesLoading = false;

            })
        },
        create() {
            this.trigger++;

            for (let i = 0, j = this.textFields.length; i < j; i++) {
                if (!this.textFields[i].valid) {
                    return;
                }
            }

            this.itemName = this.textFields[1].value + " (" + this.$t(this.textFields[0].value) + ")";

            let self = this;
            self.loading = true;

            postDistributorByIdDevices({
                id: self.distributorId,

                device: {
                    kindId: self.textFields[0].value,
                    description: self.textFields[1].value,
                    ipAddress: self.textFields[2].value,
                    dongelId: self.textFields[3].value,
                    categoryId: self.textFields[4].value,
                }

            }).then(function () {
                for (let i = 0, j = self.textFields.length; i < j; i++) {
                    self.textFields[i].value = "";
                    self.textFields[i].valid = false;
                }

                self.loading = false;

                UIkit.modal("#" + self.modalId + "-success").show();
                self.$emit('afterSuccess')

            }).catch(function (e) {
                self.checkGenericError(e);

                self.error = e;
                self.loading = false;

            })
        },
    }
}

</script>

<style lang="scss" scoped>

</style>
