<template>
    <div :class="'info-box uk-card uk-card-body ' + mode + (borderless ? ' borderless' : '')">

        <div class="uk-grid-small" uk-grid>
            <div v-if="icon" class="uk-width-auto">
                <Icon :icon="icon" :ratio="1.5" class="icon"/>
            </div>
            <div class="uk-width-expand">
                <slot/>
            </div>
        </div>


    </div>
</template>

<script>

import Icon from "@/components/generic/Icon";

export default {
    name: 'InfoBox',
    components: {Icon},
    props: {
        mode: String,
        borderless: Boolean,
        icon: String,
    }
}

</script>


<style lang="scss" scoped>

.info-box {
    border-left: 4px solid $theme-background;
    border-radius: 0 !important;
    box-shadow: 0 14px 25px rgba(0, 0, 0, 0.16);

    &.borderless {
        box-shadow: 0 0 0 0 transparent;
    }

}

.danger {
    border-color: $danger-color;
    color: $danger-color;
}

.success {
    border-color: $success-color;
}

.default {
    background-color: $theme-color-hover;
    border-color: $theme-background;
    color: $theme-background;
}

</style>