<template>
    <div class="start">

        <div class="uk-hidden@m uk-text-center app-title">
            <div class="uk-animation-slide-bottom-small">
                <LinkItem class="" is-dark to="login">
                    <Logo class="uk-visible@s" is-medium is-with-slogan route="login"/>
                    <Logo class="uk-hidden@s" is-small is-with-slogan route="login"/>
                </LinkItem>
            </div>
        </div>

        <div class="struct">
            <div class="uk-grid uk-flex-center uk-grid-collapse uk-grid-match grid" uk-grid>

                <div class="uk-visible@m uk-width-expand uk-cover-container cover-struct">
                    <ImageEnhanced :src="require('@/assets/start.jpg')" alt="cover" background-color="#121212"
                                   class="image"
                                   uk-cover
                    />

                    <div class="uk-position-top uk-text-center uk-light cover-text-struct uk-animation-fade">
                        <Card class="card-struct">

                            <SiteTitle class="uk-text-center">
                                <LinkItem to="login">
                                    <Logo is-dark is-with-slogan/>
                                </LinkItem>
                            </SiteTitle>

                        </Card>
                    </div>

                    <div class="uk-position-bottom footer-struct uk-animation-fade">
                        <div class="title-struct">
                            <span class="title">a365</span>

                        </div>

                        <div class="description-struct">
                            <span class="description">{{ $t('start.subdescription') }}</span>
                        </div>

                        <div v-if="false" class="subdescription-struct">
                            <span class="description">{{ $t('start.subdescription') }}</span>
                        </div>
                    </div>
                </div>

                <div class="form-struct uk-width-auto@m uk-width-1-1">
                    <Card class="card-struct" mode="default">
                        <Login v-if="$route.name === 'login'" class="uk-animation-fade"/>
                        <Register v-if="$route.name === 'register'" class="uk-animation-fade"/>
                        <ResetPassword v-if="$route.name === 'resetpassword'" class="uk-animation-fade"/>
                        <ResetPasswordConfirm v-if="$route.name === 'resetpasswordconfirm'" class="uk-animation-fade"/>
                        <RegisterConfirm v-if="$route.name === 'registerconfirm'" class="uk-animation-fade"/>

                        <div class="footer-struct"/>

                        <div class="uk-animation-fade uk-position-bottom uk-margin-medium-bottom">
                            <div class="uk-grid-small uk-child-width-auto uk-flex-center uk-animation-fade"
                                 uk-grid>
                                <div>
                                    <ToggleItem toggle="target: #termsAndConditions">
                                        {{ $t('termsAndConditions') }}
                                    </ToggleItem>
                                    <Modal id="termsAndConditions" :title="$t('termsAndConditions')" is-extended>
                                        <TermsAndConditionText/>
                                    </Modal>
                                </div>

                                <div>
                                    <ToggleItem toggle="target: #dataPrivacy">
                                        {{ $t('dataPrivacy') }}
                                    </ToggleItem>
                                    <Modal id="dataPrivacy" :title="$t('dataPrivacy')" is-extended>
                                        <DataPrivacyText/>
                                    </Modal>
                                </div>

                                <div>
                                    <ToggleItem toggle="target: #imprint">
                                        {{ $t('imprint') }}
                                    </ToggleItem>
                                    <Modal id="imprint" :title="$t('imprint')" is-extended>
                                        <SiteNoticeText/>
                                    </Modal>
                                </div>

                                <div>
                                    <LanguageSelector/>
                                </div>
                            </div>

                        </div>

                    </Card>
                </div>

            </div>
        </div>

    </div>
</template>


<script>

import Login from "@/views/forms/Login";
import Register from "@/views/forms/Register";
import ResetPassword from "@/views/forms/ResetPassword";
import ResetPasswordConfirm from "@/views/forms/ResetPasswordConfirm";
import RegisterConfirm from "@/views/forms/RegisterConfirm";
import Card from "@/components/generic/Card";
import LinkItem from "@/components/generic/LinkItem";
import SiteTitle from "@/components/SiteTitle";
import LanguageSelector from "@/components/LanguageSelector";
import ImageEnhanced from "@/components/ImageEnhanced";
import Modal from "@/components/generic/Modal";
import ToggleItem from "@/components/generic/ToggleItem";
import Logo from "@/components/Logo";
import TermsAndConditionText from "@/assets/texts/de/TermsAndConditions";
import DataPrivacyText from "@/assets/texts/de/DataPrivacy";
import SiteNoticeText from "@/assets/texts/de/SiteNotice";

export default {
    name: 'Start',
    components: {
        SiteNoticeText,
        DataPrivacyText,
        TermsAndConditionText,
        Logo,
        ToggleItem,
        Modal,
        ImageEnhanced,
        LanguageSelector,
        SiteTitle,
        LinkItem,
        Card,
        RegisterConfirm,
        ResetPasswordConfirm,
        ResetPassword,
        Register,
        Login
    },
}

</script>


<style lang="scss" scoped>

.start {
    margin-top: -30px;
}

@media (max-width: 1199px) {
    .start {
        margin-top: -60px;
    }
}

@media (max-width: 639px) {
    .start {
        margin-top: -90px;
    }
}


@media (max-width: 445px) {

}

.struct {
    margin: auto;
}

.grid {
    margin: auto;

}

@media (min-width: $breakpoint-medium) {
    .grid {
        height: calc(100vh - 60px - 60px);
        min-height: 931px;
    }
}

@media (min-width: $breakpoint-large) {
    .grid {
        height: calc(100vh - 90px - 90px);
    }
}


.form-struct {
    width: $max-content-width;
}

.app-title {
    margin-bottom: 50px;
    height: 70px;
}

@media (max-width: 640px) {
    .app-title {
        height: 60px;
    }
}

.bottom-struct {
    padding: 0 0 40px 0;
}

@media (max-width: $breakpoint-xsmall-max) {
    .app-title {
        margin-top: 15px;
        margin-bottom: 35px;
    }
}

@media (max-width: 445px) {
    .app-title {
        margin-top: 65px;
        margin-bottom: 35px;
    }
}

.navbar-struct-sticky {
    height: $navigation-height;
    color: $site-color;

    background-color: rgba(9, 38, 51, 1);
    border-bottom: 1px solid rgba(15, 59, 81, 0.16);
    backdrop-filter: saturate(180%) blur(20px);

    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.32);
}

.navbar-struct {
    background-color: rgba(9, 38, 51, 1);
}

.imprint {
    font-size: 14px;
}

.cover-struct {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    background: #000;
}

@media (min-width: $breakpoint-medium) {
    .card-struct {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
}

.footer-struct {
    margin-top: 120px !important;
}

.image {
    opacity: 0.95;
}

.uk-dark {
    color: $site-color;
}

.start {
    padding-top: 60px;
}


@media (max-width: $breakpoint-medium-max) {
    .start {
        padding-top: 80px;
    }
}

@media (max-width: $breakpoint-xsmall-max) {
    .start {
        padding-top: 40px;
    }
}


@media (max-width: 445px) {
    .start {
        padding-top: 40px;
    }
}

.footer-struct {
    margin-bottom: 100px !important;
    margin-left: 40px;
}

@media (max-width: $breakpoint-medium-max) {
    .footer-struct {
        margin-left: 10px;
    }
}

.title {
    background-color: #fff;
    color: #000;
    font-weight: 600;
    padding: 3px 20px;
    font-size: 28px;
    position: relative;
}

.description {
    background-color: $theme-background;
    color: #fff;
    font-weight: 500;
    padding: 10px 12px;
}

.title-struct {
    z-index: 999;
}

.description-struct {
    z-index: -1;
    margin-top: -25px;
    margin-left: 95px;
}

@media (max-width: $breakpoint-medium-max) {
    .description-struct {
        margin-top: 0px;
        margin-left: 0px;
    }
}


.subdescription-struct {
    margin-top: 12px;
    transform: rotate(-1deg);
    transform-origin: 50% 50%;
}

@media (max-width: $breakpoint-medium-max) {
    .subdescription-struct {
        margin-top: 6px;
        transform: rotate(-1deg);
        transform-origin: 80% 80%;
    }
}

@media (max-width: $breakpoint-medium-max) {
    .title {
        font-size: 24px;
    }

    .description {
        font-size: 14px;
    }
}

</style>
