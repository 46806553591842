<template>
    <GenericCreate
        :action="create" :action-error="error"

        :action-label="$t('send')" :action-loading="loading"

        :description="$t('supportDeviceRequestMessage')" :icon-tooltip="$t('requestDeviceSupport')"

        :is-button-hidden="isButtonHidden" :modal-id="modalId" :success-description="$t('supportDeviceRequested')"
        :success-title="$t('supportDeviceRequested')"

        :title="$t('requestDeviceSupport')" :trigger="trigger"

        icon="lifesaver"
    >

        <span v-for="(inputField, key) in textFields" :key="inputField.name">

            <TextArea v-if="inputField.type==='textarea'"

                      v-model="inputField.value"
                      :autofocus="inputField.autofocus"
                      :field-key="key"
                      :icon="inputField.icon"
                      :label="inputField.label"
                      :name="inputField.name"
                      :rules="inputField.rules"
                      :type="inputField.type"
                      :validate="trigger"
                      @setValid="setValid"
            />
            <TextField v-else
                       v-model="inputField.value" :autocomplete="inputField.autocomplete"
                       :autofocus="inputField.autofocus" :field-key="key"
                       :hasPasswordMeter="inputField.hasPasswordMeter"
                       :icon="inputField.icon"
                       :label="inputField.label"
                       :name="inputField.name"
                       :onEnter="create"
                       :rules="inputField.rules"
                       :type="inputField.type"
                       :validate="trigger"
                       @setValid="setValid"
            />
            <div v-if="key !== textFields.length -1" class="uk-margin-bottom"/>
        </span>

    </GenericCreate>
</template>

<script>

import TextField from "../generic/TextField";
import GenericCreate from "@/components/creates/GenericCreate";
import TextArea from "@/components/TextArea";

export default {
    name: 'CreateDeviceSupportRequest',
    components: {
        TextArea,
        GenericCreate,
        TextField,
    },
    props: {
        isButtonHidden: Boolean,
    },
    data() {
        return {
            modalId: "modal-create-device-support-request",

            trigger: 0,
            textFields: [
                {
                    value: "",
                    valid: false,

                    autofocus: true,
                    name: "subject",
                    label: "subject",
                    rules: "required",
                    icon: "commenting",
                },
                {
                    value: "",
                    valid: false,

                    autofocus: false,
                    name: "message",
                    label: "message",
                    rules: "required",
                    icon: "commenting",

                    type: "textarea",
                },
            ],

            loading: false,
            error: null,
            itemName: "",
        }
    },
    methods: {
        setValid(valid, fieldKey) {
            this.textFields[fieldKey].valid = valid;
        },
        create() {
            this.trigger++;

            for (let i = 0, j = this.textFields.length; i < j; i++) {
                if (!this.textFields[i].valid) {
                    return;
                }
            }

            let self = this;
            self.loading = true;
        },
    },
}

</script>

<style lang="scss" scoped>

</style>
