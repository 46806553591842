<template>
    <div class="ellipse-progress uk-text-center">

        <vue-ellipse-progress
            :color="color"
            :determinate="false"
            :empty-color="emptyColor"
            :emptyColorFill="emptyColorFill"
            :legend="false"
            :loading="loading"
            :no-data="noData"
            :progress="progress"

            :size="180"
            dash="strict 60 0.8"
            emptyThickness="5%"
            thickness="3%"
        >
            <span v-if="value" slot="legend-value">/{{ value }}</span>

            <div slot="legend-caption" class="caption">
                <slot name="caption"/>
            </div>

        </vue-ellipse-progress>
        <slot name="description"/>

    </div>
</template>

<script>

import "vue-ellipse-progress"

export default {
    name: 'EllipseStatus',
    props: {
        progress: Number,
        value: Number,
        color: String,
        loading: Boolean,
        emptyColor: String,
        noData: Boolean,
    },
    data() {
        return {
            emptyColorFill: {
                radial: true,
                colors: [
                    {
                        color: this.emptyColor,
                        offset: "50",
                        opacity: "1.0",
                    },
                    {
                        color: this.emptyColor,
                        offset: "50",
                        opacity: "0.8",
                    },
                    {
                        color: this.emptyColor,
                        offset: "70",
                        opacity: "0.8",
                    },
                    {
                        color: this.emptyColor,
                        offset: "70",
                        opacity: "0.7",
                    },
                    {
                        color: this.emptyColor,
                        offset: "90",
                        opacity: "0.7",
                    },
                    {
                        color: "transparent",
                        offset: "90",
                        opacity: "0.1",
                    },
                    {
                        color: "transparent",
                        offset: "95",
                        opacity: "0.1",
                    },
                    {
                        color: "transparent",
                        offset: "95",
                        opacity: "0.1",
                    },
                ],
            },
        }
    }
}

</script>


<style lang="scss" scoped>

</style>
