<template>
    <span>

        <div v-if="!isButtonHidden" class="text">
            <slot name="button-description"/>
            <RoundButton v-if="isButtonRound" :action="runActions" :icon="icon"
                         :is-disabled="isDisabled" :text="isButtonTitleHidden ? '' : title"
                         :tooltip="isDisabled ? isDisabledMessage : ''"/>
            <Button v-else :action="runActions" :is-disabled="isDisabled" :text="buttonText"
                    :tooltip="isDisabled ? isDisabledMessage : ''" has-action is-large is-max mode="theme"/>
        </div>

        <Modal :id="modalId" :is-medium="modalIsMedium" :title="title">
            <LoadingStruct :is-loading="isLoading" is-animation-disabled is-dark>
                <slot name="modal-title"/>

                <Description v-if="description" :text="description" class="uk-text-center"/>

                <slot/>

                <div v-if="action">
                    <div v-if="actionError" class="uk-animation-fade">
                        <div class="uk-margin-medium-top"/>
                        <InfoBox mode="danger">
                            <ErrorMessage :custom-errors="actionCustomErrors" :error="actionError"/>
                        </InfoBox>
                    </div>

                    <div class="uk-margin-medium-top"/>
                    <Button :action="action" :is-loading="actionLoading" :text="actionLabel" has-action is-large
                            is-max mode="theme"/>
                </div>
            </LoadingStruct>
        </Modal>

        <Modal :id="modalId+'-success'">
            <div class="uk-text-center">
                <Icon :icon="successIcon" :ratio="5.0"/>
            </div>
            <ModalTitle v-if="successTitle" :title="successTitle" class="uk-text-center"/>
            <Description :text="successDescription" class="uk-text-center"/>

            <slot name="modal"/>

            <Button :action="emitAfterClose" :text="$t('close')" class="success-button uk-modal-close" has-action
                    is-large is-max mode="theme"/>
        </Modal>

    </span>
</template>

<script>

import Modal from "@/components/generic/Modal";
import Description from "@/components/Description";
import InfoBox from "@/components/generic/InfoBox";
import ErrorMessage from "@/components/generic/ErrorMessage";
import Button from "@/components/generic/Button";
import RoundButton from "@/components/RoundButton";
import Icon from "../generic/Icon";
import ModalTitle from "@/components/generic/ModalTitle";
import UIkit from "uikit";
import LoadingStruct from "@/views/LoadingStruct";

export default {
    name: 'GenericCreate',
    components: {
        LoadingStruct,
        ModalTitle,
        Icon,
        RoundButton,
        Button,
        ErrorMessage,
        InfoBox,
        Description,
        Modal
    },
    props: {
        modalId: String,
        modalIsMedium: Boolean,
        trigger: Number,

        icon: String,

        buttonText: String,
        buttonAction: {
            type: Function,
            default: function () {
            }
        },

        title: String,
        description: String,

        actionLabel: String,
        action: Function,
        actionLoading: Boolean,
        actionError: Error,
        actionCustomErrors: Array,

        successTitle: String,
        successDescription: String,
        successIcon: {
            type: String,
            default: "check",
        },

        isButtonHidden: Boolean,
        isButtonRound: {
            type: Boolean,
            default: true,
        },

        isDisabledMessage: String,
        isDisabled: Boolean,
        isLoading: Boolean,
        isButtonTitleHidden: Boolean,
    },
    methods: {
        emitAfterClose() {
            this.$emit('afterClose');
        },
        runActions() {
            this.buttonAction();
            UIkit.modal("#" + this.modalId).show();
        }
    }
}

</script>

<style lang="scss" scoped>

.success-button {
    margin-top: -10px;
}

.text {
    font-size: 15px;
    font-weight: 400;
    color: #ccc;
    margin-right: 12px;
}

</style>
