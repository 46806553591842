<template>
    <div>

        <LoadingStruct :is-animation-disabled="isAnimationDisabled" :is-loading="isLoading">

            <div uk-filter="target: .js-filter">
                <div v-if="isSearchable || filters || $slots.action" class="uk-grid-match" uk-grid>

                    <div v-if="$slots.action"
                         class="uk-hidden@m uk-width-1-1 uk-flex uk-flex-middle uk-text-left">
                        <slot name="action"/>
                    </div>

                    <div class="uk-width-expand uk-flex uk-flex-middle">

                        <div class="uk-grid-small uk-grid-divider uk-child-width-auto" uk-grid>
                            <div v-if="isSearchable" class="uk-width-auto@s uk-width-1-1">
                                <form class="uk-search uk-search-default"
                                      @submit.prevent>
                                    <span uk-search-icon/>
                                    <input v-model="search"
                                           :placeholder="$t('search')"
                                           class="uk-search-input filter-input"
                                           type="search"
                                           v-on:input="updateValue($event.target.value)">
                                    <span v-if="search.length>0" class="uk-form-icon uk-form-icon-flip cursor-pointer"
                                          uk-icon="icon: close" v-on:click="search=''; updateValue('')"/>
                                </form>
                            </div>
                            <div v-if="filters">
                                <ul class="uk-subnav uk-subnav-pill" uk-margin>
                                    <li class="uk-active" uk-filter-control><a href="#">{{ $t('all') }}</a></li>
                                </ul>
                            </div>
                            <div v-for="filter in computedFilters" :key="filter.type">
                                <ul class="uk-subnav uk-subnav-pill" uk-margin>
                                    <li v-for="value in filter.values" :key="value.name"
                                        :class="value.active ? 'uk-active' : ''"
                                        :uk-filter-control="'[data-'+filter.type+'='+value.name+']'">
                                        <a href="#">{{ value.total + " " + capitalize($t(value.name)) }}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div v-if="$slots.action"
                         class="uk-visible@m uk-width-auto uk-flex uk-flex-middle uk-text-right">
                        <slot name="action"/>
                    </div>

                </div>

                <hr v-if="!hideDivider" class="divider-struct"/>
                <div v-else class="divider-struct"/>

                <GenericError v-if="error" :error="error"/>

                <span v-else>
                    <div v-if="$slots.itemsinfo" uk-grid>
                        <div class="uk-width-1-1">
                            <slot name="itemsinfo"/>
                        </div>
                    </div>

                    <ul v-if="computedItems && computedItems.length"
                        class="uk-child-width-1-4@xl uk-child-width-1-3@m uk-child-width-1-2@s uk-child-width-1-1 js-filter uk-grid-match"
                        uk-grid>
                        <slot name="items"/>

                        <li v-if="items.length> showLimit && computedItems.length === showLimit"
                            class="uk-width-1-1">
                            <InfoCard icon="info">
                                {{ $t('searchForMore', {count: items.length - showLimit, name: $t(labelPlural)}) }}
                            </InfoCard>
                        </li>
                    </ul>

                    <div v-else-if="isSearch" class="uk-grid">
                        <div v-if="$slots.itemsinfo" class="uk-grid-margin uk-grid"/>
                        <InfoCard class="uk-width-1-1" icon="info">{{ $t('noSearchResult') }}</InfoCard>
                    </div>
                    <div v-else-if="noItemsMessage" class="uk-grid">
                        <div v-if="$slots.itemsinfo" class="uk-grid uk-grid-margin uk-grid"/>
                        <InfoCard class="uk-width-1-1" icon="info"><span v-html="noItemsMessage"/></InfoCard>
                    </div>
                    <div v-else-if="$slots.noitems" class="uk-grid">
                        <div v-if="$slots.itemsinfo" class="uk-grid-margin uk-grid"/>
                        <slot class="uk-width-1-1" name="noitems"/>
                    </div>

                    <slot/>
                </span>
            </div>

        </LoadingStruct>

    </div>
</template>

<script>

import InfoCard from "@/components/generic/InfoCard";
import LoadingStruct from "@/views/LoadingStruct";
import GenericError from "@/components/GenericError";

export default {
    name: 'GenericItemView',
    components: {GenericError, LoadingStruct, InfoCard},
    data() {
        return {
            search: "",
        }
    },
    props: {
        noItemsMessage: String,
        isSearch: Boolean,
        items: [Object, Array],
        computedItems: Array,
        filters: Array,
        hideDivider: Boolean,
        isLoading: Boolean,
        error: Error,
        isSearchable: Boolean,
        showLimit: {
            type: Number,
            default: 6,
        },
        labelPlural: String,
        isAnimationDisabled: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        computedFilters() {
            let filters = this.filters;
            return this.getFilters(filters);
        }
    },
    methods: {
        updateValue(value) {
            this.$emit('search', value)
        },
        getFilters(filters) {
            if (this.isLoading || !filters || !this.computedItems || !this.computedItems.length) {
                return [];
            }

            for (let i = 0, j = this.computedItems.length; i < j; i++) {
                for (let k = 0, l = filters.length; k < l; k++) {
                    for (let m = 0, n = filters[k].values.length; m < n; m++) {
                        filters[k].values[m].total = 0;
                    }
                }
            }

            for (let i = 0, j = this.computedItems.length; i < j; i++) {
                for (let k = 0, l = filters.length; k < l; k++) {
                    for (let m = 0, n = filters[k].values.length; m < n; m++) {

                        if (this.computedItems[i][filters[k].type] === filters[k].values[m].name) {
                            filters[k].values[m].total++;
                        }

                    }
                }
            }

            for (let k = 0, l = filters.length; k < l; k++) {
                for (let m = 0, n = filters[k].values.length; m < n; m++) {
                    if (filters[k].values[m] && filters[k].values[m].total === 0) {
                        filters[k].values.splice(m, 1);
                    }
                }
            }

            for (let k = 0, l = filters.length; k < l; k++) {
                for (let m = 0, n = filters[k].values.length; m < n; m++) {
                    if (filters[k].values[m] && filters[k].values[m].total === 0) {
                        filters[k].values.splice(m, 1);
                    }
                }
            }

            for (let k = 0, l = filters.length; k < l; k++) {
                for (let m = 0, n = filters[k].values.length; m < n; m++) {
                    if (filters[k].values[m] && filters[k].values[m].total === 0) {
                        filters[k].values.splice(m, 1);
                    }
                }
            }

            let empty = true;
            for (let k = 0, l = filters.length; k < l; k++) {
                for (let m = 0, n = filters[k].values.length; m < n; m++) {
                    if (filters[k].values.length) {
                        empty = false;
                        break;
                    }
                }
            }

            if (empty) {
                return [];
            }

            return filters;
        },
    }
}

</script>

<style lang="scss" scoped>

.spinner {
    color: $site-background;
}

.uk-subnav-pill > li > * {
    text-transform: initial !important;
    transition: border-color 0.25s linear, color 0.25s linear, background-color 0.25s linear !important;
}

.uk-subnav-pill > * > a {
    border-radius: $border-radius !important;
    background-color: $theme-color;
    color: $site-color !important;
}

.uk-subnav-pill > .uk-active > a {
    background-color: $theme-color-hover !important;
    color: $site-color-hover !important;
}

@media (max-width: $breakpoint-xsmall-max) {
    .uk-subnav-pill > li > * {
        padding: 5px 6px
    }
}

.divider-struct {
    margin-bottom: 30px;
}

.filter-input {
    height: 31px !important;
    border-radius: $border-radius;
    background: #fff !important;
}

.uk-form-icon:not(a):not(button):not(input) {
    pointer-events: initial !important;
}

.uk-search-default {
    width: 100%;
}

</style>
