<template>
    <span>
    <ImageEnhanced
        :class="'logo' + (isSmall ? ' small': '') + (isMedium ? ' medium': '')+(isWithSlogan ? ' ': '')"
        :src="require('@/assets/'+(isDark ? 'logo_dark' : 'logo_light')+(isWithSlogan ? '' : '')+'.png')">

    </ImageEnhanced>

    <div v-if="isWithSlogan" class="slogan">
        {{ $t('slogan.message') }}
    </div>
</span>
</template>

<script>

import ImageEnhanced from "@/components/ImageEnhanced";

export default {
    name: 'Logo',
    components: {ImageEnhanced},
    props: {
        isDark: Boolean,
        isMedium: Boolean,
        isSmall: Boolean,
        isWithSlogan: Boolean,
    },
}

</script>

<style lang="scss" scoped>

.logo {
    max-height: 70px;
}

.small {
    max-height: 40px;
}

.medium {
    max-height: 60px;
}

.slogan {
    margin-top: 10px;
    font-size: 16px !important;
    font-weight: 400 !important;
}

</style>
