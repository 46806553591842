<template>
    <div>
        <!-- Veränderungen ab hier -->

        <b>made.systems GbR</b><br/>
        Hauptstr. 59<br/>
        D - 79730 Murg<br/>
        Telefon: +49 7761 937 900 2<br/>
        E-Mail: hello@madesystems.de
        <br/><br/>
        <b>Gesellschafter</b><br/>
        Frederic aus den Erlen<br/>
        Frank Mutter
        <br/><br/>
        <b>Gerichtsstand</b>
        <br/>
        Waldshut-Tiengen

        <br/><br/>
        <b>Verantwortlich für den Inhalt</b><br/>
        Frederic aus den Erlen

        <br/>
        <h3>Haftungsausschluss</h3>
        <b>Haftung für Inhalte</b><br/>
        Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und
        Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir für eigene
        Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Wir sind als Diensteanbieter jedoch
        nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
        forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
        Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung
        ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von
        entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.

        <br/><br/>
        <b>Haftung für Links</b><br/>
        Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb
        können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist
        stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum
        Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
        Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
        Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
        derartige Links umgehend entfernen.

        <br/><br/>
        <b>Urheberrecht</b><br/>
        Die durch die Seitenbetreiber erstellten bzw. verwendeten Inhalte und Werke auf diesen Seiten unterliegen dem
        deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
        Grenzen des Urheberrechtes bedürfen der Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien
        dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser
        Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden
        Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam
        werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir
        derartige Inhalte umgehend entfernen.

        <br/><br/>
        <b>Online-Streitbeilegung</b><br/>
        Die Europäische Kommission stellt unter https://ec.europa.eu/consumers/odr/ eine Plattform zur
        Online-Streitbeilegung bereit, die Verbraucher für die Beilegung einer Streitigkeit nutzen können und auf der
        weitere Informationen zum Thema Streitschlichtung zu finden sind.

        <br/><br/>
        <b>Außergerichtliche Streitbeilegung</b><br/>
        Wir sind nicht verpflichtet im Falle einer Streitigkeit mit einem Verbraucher an einem
        Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.

        <!-- Veränderungen bis hier -->
    </div>
</template>

<script>

export default {
    name: 'SiteNoticeText',
}
</script>

<style lang="scss" scoped>

</style>
