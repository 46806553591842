<template>
    <div>

        <div class="satellite-struct uk-text-center uk-margin-medium-bottom">
            <EllipseStatus
                :color="getEllipseColorByStatus()"
                :empty-color="getEllipseColorByStatus()" :no-data="!lastOnline"
                :progress="progress"
                class="uk-margin-bottom"
            >
                <template v-slot:caption>
                    <div v-if="hasSatellite">
                        <div v-if="!hasMonitoring">
                            <span :uk-tooltip="'title:'+$t('no.monitoring.subscription.message')">
                                   {{ $t('no.activity') }}
                            </span>
                        </div>
                        <div v-else-if="lastOnline">
                            <div class="lastactivity">{{ $t('lastActivity') }}</div>
                            <div :uk-tooltip="'title:'+lastOnline" class="status-text active">{{
                                    lastOnlineSeconds
                                }}
                            </div>
                        </div>
                        <div v-else class="status-text">
                            {{ $t('no.activity.yet') }}
                        </div>
                    </div>
                    <div v-else class="status-text">
                        {{ $t('notInstalled') }}
                    </div>
                </template>

                <template v-slot:description>
                    <div class="description satellite">
                        {{ $t('satellite') }}
                    </div>
                </template>

            </EllipseStatus>

            <div class="uk-flex uk-flex-center uk-grid-collapse" uk-grid>
                <div>
                    <hr :class="'uk-width-small divider left '+ getLineClassByStatus()">

                    <Icon :ratio="iconRatio" icon="dashboard"/>
                    <div class="description">
                        {{ $t('portal') }}
                    </div>
                </div>

                <div class="uk-flex uk-flex-middle">
                    <hr class="uk-width-small divider success">
                </div>

                <div>
                    <hr :class="'uk-width-small divider right '+ getLineClassByStatus()">

                    <Icon :ratio="iconRatio" icon="cloud"/>
                    <div class="description">
                        {{ $t('internet') }}
                    </div>
                </div>
            </div>
        </div>

        <InfoBox v-if="hasSatellite" class="uk-margin-top" icon="check" mode="success">
            <span v-html="$t('satellitMessage')"/>
        </InfoBox>
        <InfoBox v-else class="uk-margin-top" icon="info" mode="info">
            <span v-html="$t('satellitNotLinkedMessage')"/><br/><br/>
            <span v-if="isDistributorView" v-html="$t('noSatellitDistributorMessage')"/>
            <span v-else v-html="$t('noSatellitMessage')"/>
        </InfoBox>

    </div>
</template>


<script>

import Icon from "@/components/generic/Icon";
import InfoBox from "@/components/generic/InfoBox";
import EllipseStatus from "@/components/generic/EllipseStatus";
import dayjs from "dayjs";

export default {
    name: 'SatelliteStruct',
    components: {EllipseStatus, InfoBox, Icon},
    props: {
        hasSatellite: Boolean,
        hasMonitoring: Boolean,
        isDistributorView: Boolean,
        monitoring: Object,
    },
    data() {
        return {
            iconRatio: 2.0,
            lastOnline: "",
            lastOnlineSeconds: "",
            progress: 100,
        }
    },
    created() {
        let lastOnline = this.getLastOnline();
        if (lastOnline) {
            this.progress = this.getProgress(lastOnline);
            this.lastOnline = this.getFormattedDateByDateString(lastOnline);
            this.lastOnlineSeconds = this.getAgoDescription(lastOnline)

            let self = this;
            setInterval(function () {
                let lastOnline = self.getLastOnline();
                self.progress = self.getProgress(lastOnline);
                self.lastOnline = self.getFormattedDateByDateString(lastOnline);
                self.lastOnlineSeconds = self.getAgoDescription(lastOnline)
            }, 1000);
        }
    },
    methods: {
        getLineClassByStatus() {
            if (this.hasSatellite) {
                if (this.lastOnline) {
                    return "success";
                }

                return "primary";
            }

            return "default";
        },
        getEllipseColorByStatus() {
            if (this.hasSatellite) {
                if (this.lastOnline) {
                    return "#30C791FF";
                }

                return "#0071e3";
            }

            return "#aaa";
        },
        getLastOnline() {
            if (!this.hasMonitoring) {
                return null;
            }

            let monitoring = this.monitoring;
            let lastDates = [];

            if (monitoring.device.events.length > 0) {
                lastDates.push(monitoring.device.events[monitoring.device.events.length - 1].until);
            }

            if (monitoring.peripherals && monitoring.peripherals.length > 0) {
                for (let i = 0, j = monitoring.peripherals.length; i < j; i++) {
                    if (monitoring.peripherals[i].events.length > 0) {
                        lastDates.push(monitoring.peripherals[i].events[monitoring.peripherals[i].events.length - 1].until);
                    }
                }
            }

            lastDates = lastDates.sort(function (a, b) {
                return a.localeCompare(b)
            });

            return lastDates[lastDates.length - 1];
        },
        getAgoDescription(date) {
            let secondsAgo = this.getSecondsAgoByDateString(date);
            if (secondsAgo < 60) {
                return secondsAgo + " " + this.$t('seconds');
            }

            return dayjs(date).fromNow(true);
        },
        getProgress(date) {
            let secondsAgo = this.getSecondsAgoByDateString(date);
            let progress = 100 - this.getPercentage(180, secondsAgo);

            if (progress < 0) {
                return 0;
            }

            return progress;
        },
    }
}

</script>


<style lang="scss" scoped>

.satellite-struct {
    margin: auto;
    max-width: 500px;
}

.description {
    font-size: 14px;
    margin-top: -2px;
    font-weight: 500;

    &.satellite {
        font-size: 15px;
    }
}

.divider {
    border-width: 3px;

    &.success {
        border-color: $success-color;
    }

    &.danger {
        border-color: $danger-color;
    }

    &.primary {
        border-color: $info-color;
    }
}

.status-text {
    font-weight: 500;
    font-size: 15px;

    &.active {
        font-size: 16px;
    }
}

.lastactivity {
    font-size: 14px;
}

.left {
    transform: rotate(-60deg);
    transform-origin: 80% 80%;
    position: absolute;
    margin-top: -50px;
    margin-left: -0px;
    z-index: 0;
    width: 65px;
}

.right {
    transform: rotate(60deg);
    transform-origin: 80% 80%;
    position: absolute;
    margin-top: -15px;
    margin-left: -40px;
    z-index: 0;
    width: 65px;
}

</style>
