<template>
    <span class="link-item">

        <a :class="(isDark? 'dark' : '') + (isUnderlined? ' underlined' : '')"
           :uk-toggle="toggle">
            <slot/>
        </a>

    </span>
</template>

<script>
export default {
    name: 'ToggleItem',
    props: {
        toggle: String,
        isDark: Boolean,
        isUnderlined: Boolean,
    },
}
</script>

<style lang="scss" scoped>

a {
    transition: color .25s linear;
    color: $site-color;
    text-decoration: none !important;

    &.underlined {
        text-decoration: underline $site-color !important;
    }

    &.active, &:hover, &.router-link-exact-active, &.router-link-active {
        color: $theme-background;

        &.underlined {
            text-decoration: underline $theme-background !important;
        }

    }

    &.dark {
        color: $theme-color;

        &.underlined {
            text-decoration: underline $theme-color !important;
        }

        &.active, &:hover, &.router-link-exact-active, &.router-link-active {
            color: $theme-color-hover;

            &.underlined {
                text-decoration: underline $theme-color-hover !important;
            }

        }
    }

}

</style>
