import Vue from 'vue'
import {i18n} from '@/main'
import VueRouter from 'vue-router'
import Router from '@/router/Router'

import authMiddleware from '@/middlewares/auth';
import adminMiddleware from '@/middlewares/admin';
import distributorMiddleware from '@/middlewares/distributor';

import Start from "@/views/Start";
import Main from "@/views/Main";
import Dashboard from "@/views/Dashboard";
import Devices from "@/views/Devices";
import Device from "@/views/Device";
import Distributors from "@/views/Distributors";
import Distributor from "@/views/Distributor";

import UsersAdministration from "@/views/admin/Users";
import DistributorsAdministration from "@/views/admin/Distributors";
import DevicesAdministration from "@/views/admin/Devices";
import ModulesAdministration from "@/views/admin/Modules";
import SubscriptionsAdministration from "@/views/admin/Subscriptions";
import SatellitesAdministration from "@/views/admin/Satellites";
import StatisticsAdministration from "@/views/admin/Statistics";

import Account from "@/views/Account";
import Logout from "@/views/Logout";
import Wiki from "@/views/Wiki";
import Invite from "@/views/Invite";


Vue.use(VueRouter)

const navigatorLanguages = [
    {
        "key": "de",
        "codes": ["de", "de-CH", "de-AT", "de-LU", "de-LI"],
    },
    {
        "key": "fr",
        "codes": ["fr", "fr-BE", "fr-CA", "fr-CH", "fr-LU"],
    }
];

const allowedLanguages = ['de', 'en', 'fr'];

function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    if (!subsequentMiddleware) return context.next;

    return (...parameters) => {
        context.next(...parameters);
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({...context, next: nextMiddleware});
    };
}

const routes = [
    {
        path: '/:lang',
        component: Router,
        beforeEnter(to, from, next) {
            let lang = to.params.lang
            let target = lang

            lang = Vue.localStorage.get('language');

            if (!allowedLanguages.includes(lang)) {
                for (let i = 0, j = navigatorLanguages.length; i < j; i++) {
                    if (navigatorLanguages[i].codes.includes(navigator.language)) {
                        lang = navigatorLanguages[i].key;
                        break;
                    }
                }
            }

            if (!allowedLanguages.includes(lang)) {
                lang = 'de';
            }

            if (i18n.locale !== lang) {
                i18n.locale = lang;
            }

            if (!allowedLanguages.includes(target)) {
                router.push(+'/' + lang + '/' + target);
            }

            return next();
        },
        children: [
            {
                path: '',
                name: 'main',
                component: Main,
            },
            {
                path: 'login',
                name: 'login',
                component: Start,
            },
            {
                path: 'register',
                name: 'register',
                component: Start,
            },
            {
                path: 'register/confirm/:token',
                name: 'registerconfirm',
                component: Start,
            },
            {
                path: 'resetpassword',
                name: 'resetpassword',
                component: Start,
            },
            {
                path: 'resetpassword/confirm/:token',
                name: 'resetpasswordconfirm',
                component: Start,
            },

            {
                path: 'dashboard',
                name: 'dashboard',
                component: Dashboard,
                meta: {
                    middleware: authMiddleware,
                },
            },

            {
                path: 'devices',
                name: 'devices',
                component: Devices,
                meta: {
                    middleware: authMiddleware,
                },
            },
            {
                path: 'devices/:deviceId',
                name: 'device',
                component: Device,
                meta: {
                    middleware: authMiddleware,
                },
            },
            {
                path: 'invites/:deviceId',
                name: 'invites',
                component: Invite,
            },

            {
                path: 'organisations',
                name: 'organisations',
                component: Distributors,
                meta: {
                    middleware: [authMiddleware, distributorMiddleware],
                },
            },
            {
                path: 'organisations/:distributorId',
                name: 'distributor',
                component: Distributor,
                meta: {
                    middleware: authMiddleware,
                },
            },
            {
                path: 'organisations/:distributorId/devices/:deviceId',
                name: 'distributor/device',
                component: Device,
                meta: {
                    middleware: authMiddleware,
                },
            },

            {
                path: 'administration/users',
                name: 'administration/users',
                component: UsersAdministration,
                meta: {
                    middleware: [authMiddleware, adminMiddleware],
                },
            },
            {
                path: 'administration/organisations',
                name: 'administration/organisations',
                component: DistributorsAdministration,
                meta: {
                    middleware: [authMiddleware, adminMiddleware],
                },
            },
            {
                path: 'administration/devices',
                name: 'administration/devices',
                component: DevicesAdministration,
                meta: {
                    middleware: [authMiddleware, adminMiddleware],
                },
            },
            {
                path: 'administration/modules',
                name: 'administration/modules',
                component: ModulesAdministration,
                meta: {
                    middleware: [authMiddleware, adminMiddleware],
                },
            },
            {
                path: 'administration/subscriptions',
                name: 'administration/subscriptions',
                component: SubscriptionsAdministration,
                meta: {
                    middleware: [authMiddleware, adminMiddleware],
                },
            },
            {
                path: 'administration/logger',
                name: 'administration/logger',
                component: SatellitesAdministration,
                meta: {
                    middleware: [authMiddleware, adminMiddleware],
                },
            },
            {
                path: 'administration/statistics',
                name: 'administration/statistics',
                component: StatisticsAdministration,
                meta: {
                    middleware: [authMiddleware, adminMiddleware],
                },
            },

            {
                path: 'wiki',
                name: 'wiki',
                component: Wiki,
                meta: {
                    middleware: authMiddleware,
                },
            },
            {
                path: 'account',
                name: 'account',
                component: Account,
                meta: {
                    middleware: authMiddleware,
                },
            },
            {
                path: 'logout',
                name: 'logout',
                component: Logout,
                meta: {
                    middleware: authMiddleware,
                },
            },

        ],
    },
    {
        path: '*',
        redirect: '/de/',
    },
    {
        path: '/register/confirm/:token',
        redirect: '/de/register/confirm/:token',
    },
    {
        path: '/resetpassword/confirm/:token',
        redirect: '/de/resetpassword/confirm/:token',
    },
    {
        path: '/invites/:deviceId',
        redirect: '/de/invites/:deviceId',
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return {x: 0, y: 0}
    },
})

router.beforeEach((to, from, next) => {
    if (to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];

        const context = {
            from,
            next,
            router,
            to,
        };
        const nextMiddleware = nextFactory(context, middleware, 1);

        return middleware[0]({...context, next: nextMiddleware});
    }

    return next();
})

export default router
