<template>
    <div class="nested-title">

        <ul class="uk-breadcrumb uk-margin-remove-bottom">
            <li v-for="(item,key) in getRouteParts(properties)" :key="item.name+key"
                :class="item.disabled ? 'uk-disabled' : ' title dark'">
                <router-link v-if="item.route" :class="item.active ? 'router-link-exact-active' : ''"
                             :to="'/' + $i18n.locale + '/' + item.route"
                             :uk-tooltip="'title:'+ (item.tooltip ? item.tooltip : '')"
                >{{ item.name }}
                </router-link>
                <span v-else>{{ item.name }}</span>
            </li>
        </ul>

    </div>
</template>

<script>

export default {
    name: 'NestedTitle',
    props: {
        properties: Array,
    },
}

</script>


<style lang="scss" scoped>

a {
    transition: color .25s linear;
    color: $theme-color !important;
    text-decoration: none !important;

    &:hover {
        color: $theme-color-hover !important;
    }

    font-size: 24px;
    font-weight: 600;
}

.uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before {
    font-size: 24px;
}

.router-link-exact-active {
    color: $theme-color-hover !important;

}

.uk-breadcrumb > :nth-child(n+2):not(.uk-first-column)::before {
    margin: 0 10px 0 calc(10px - 0px) !important;
}

ul {
    // margin: 0!important;
}

</style>