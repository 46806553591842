<template>
    <div>
        <!-- Veränderungen ab hier -->

        Aktuell gibt es keine geplanten Ereignisse.

        <!-- Veränderungen bis hier -->
    </div>
</template>

<script>

export default {
    name: 'NewsText',
}
</script>

<style lang="scss" scoped>

</style>
