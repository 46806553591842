<template>
    <div :class="'action-button uk-text-center'+ (isDisabled ? ' cursor-notallowed' : '')"
         :uk-tooltip="tooltip ? 'title:'+tooltip : ''"
         v-on:click="callAction">

        <a :class="getClasses()">
            <div v-if="!loading">
                <span :uk-icon="'icon: '+icon+'; ratio: 1.3'"/>
            </div>
            <div v-else>
                <div uk-spinner></div>
            </div>

        </a>
        <div v-if="text || $slots" class="text">
            {{ text }}
            <slot/>
        </div>

    </div>
</template>

<script>

export default {
    name: 'ActionButton',
    props: {
        icon: String,
        text: String,
        mode: String,
        tooltip: String,
        isDisabled: Boolean,
        action: Function,
        loading: Boolean,
        noAction: Boolean,
        route: String,
        hasToggle: Boolean,
    },
    methods: {
        getClasses() {
            let classes = 'uk-icon-button round-button uk-button-' + this.mode;

            if (this.isDisabled || this.loading) {
                return classes + ' uk-disabled cursor-notallowed';
            }

            if (this.noAction && !this.route) {
                return classes + ' cursor-initial';
            }

            return classes + ' cursor-pointer';
        },
        callAction() {
            if (this.noAction) {
                return;
            }

            if (this.hasToggle || this.route) {
                return;
            }

            this.action();
        }
    },
}

</script>

<style lang="scss" scoped>

.round-button {
    width: 54px;
    height: 54px;
    transition: border-color 0.25s linear, color 0.25s linear, background-color 0.25s linear !important;
}

.text {
    margin-top: 5px;
    font-size: 15px;
    font-weight: 500;
}

.uk-button-success {
    background-color: #28a778 !important;
    transition: background-color 0.25s linear, color 0.25s linear;
    color: #fff;
    border: 1px solid transparent;

    &.hover,
    &:hover,
    &.active,
    &:active,
    &.focus,
    &:focus {
        color: #fff;
        border: 1px solid transparent;
        background-color: #2bb37f !important;
    }
}

.uk-button-theme {
    color: $theme-color;
    background-color: $theme-background;
    transition: border-color 0.25s linear, color 0.25s linear;

    &:hover, &:focus {
        color: $theme-color-hover;
        background-color: $theme-background-hover;
    }
}

</style>
