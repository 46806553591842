<template>
    <span class="select-field">

        <LabelWithError v-if="label" :class="(isLabelHidden ? ' uk-hidden' : '')"
                        :error="errors.first(name)"
                        :is-label-large="isLabelLarge"
                        :label="$t(label, labelParams) + (isOptional ? ' ('+$t('optional')+')' : '')"/>

        <div class="uk-inline uk-width-1-1">
            <a :uk-icon="'icon:'+icon+'; ratio: 1.0'" class="uk-form-icon" href="#"/>

            <select :autofocus="autofocus" :disabled="disabled" :name="name" :value="value"
                    :class="classGenerator(name)" v-on:change="updateValue($event.target.value)"
                    v-validate="rules" :data-vv-as="$t(label)"
            >
                <option/>
                <option v-for="option in options" :key="option.value" :value="option.value">{{ option.name }}
                </option>
            </select>
        </div>

    </span>
</template>

<script>

import LabelWithError from "@/components/generic/LabelWithError";

export default {
    name: 'SelectField',
    components: {LabelWithError},
    props: {
        label: String,
        icon: String,
        name: String,
        options: Array,
        value: [String, Number],
        rules: String,
        type: String,
        autofocus: {
            type: Boolean,
            default: false
        },
        disabled: Boolean,
        isLabelHidden: Boolean,
        isOptional: Boolean,
        labelParams: Object,
        isLabelLarge: Boolean,
        onInput: {
            type: Function,
            default: function () {
            },
        },
        validate: Number,
    },
    watch: {
        validate() {
            this.validation();
        },
    },
    data() {
        return {
            toggle: false,
        }
    },
    methods: {
        validation() {
            this.$validator.validate().then(valid => {
                if (!valid && this.autofocus) {
                    this.$refs.ref.focus();
                }

                this.$emit('setValid', valid, this.fieldKey)
            });
        },
        classGenerator(name) {
            let classes = "uk-select";

            if (this.errors.first(name)) {
                classes += " danger";
            }

            if (this.simple) {
                classes += " simple";
            }

            if (this.isDark) {
                classes += " dark";
            }

            if (this.actionIcon) {
                classes += " with-icon";
            }

            return classes;
        },
        updateValue(value) {
            if (this.type === 'number') {
                value = parseInt(value);
            }

            this.$emit('input', value, this.fieldKey)
            this.validation();

            let self = this;
            setTimeout(function () {
                self.onInput();
            }, 50);
        },
    },
}
</script>

<style lang="scss" scoped>

select {
    padding-left: 40px;
}

.uk-select {
    border-radius: $border-radius;
    border-color: $border-color !important;
    height: 50px !important;
    color: $site-color-hover !important;
    width: 100%;
    font-weight: 500;
    font-size: 16px;

    &:hover, &:focus {
        border-color: $theme-background !important;
    }

    &.dark {
        &:hover, &:focus {
            border-color: $theme-color-hover !important;
        }
    }

    &.danger {
        background: rgba(240, 80, 80, .15);
        border-color: $theme-background !important;
    }

    &.simple {
        border-radius: 0;
        border-top: 0;
        border-left: 0;
        border-right: 0;
    }
}


.input-error {
    color: $danger-color;
}


.label {
    margin-bottom: 8px;
    font-size: $site-font-size-small;
    color: $border-color-hover;
}


input {
    &:disabled {
        cursor: not-allowed;
        border-top-radius: $border-radius;
    }
}

</style>
