import VueCookie from 'vue-cookie'
import store from '@/store'
import UIkit from "uikit";
import {i18n} from '@/main'

export default function auth({next, router, to}) {
    if (
        (!store.state.token && !VueCookie.get('token')) ||
        (!store.state.userId && !VueCookie.get('userId'))
    ) {

        UIkit.notification({
            message: i18n.messages[i18n.locale]['sessionExpiredMessage'],
            status: 'info',
            pos: 'top-center',
            timeout: this.notificationTimeout,
        });


        let hash = "";
        if (to.hash) {
            hash = to.hash
        }

        store.state.requestedPath = to.path + hash;
        return router.push('/' + i18n.locale + '/login')
    }

    return next();
}