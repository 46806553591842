<template>
    <div>

        <div v-if="isWithHint && distributorStatus !== 'confirmed'" class="hint-struct"/>
        <div :class="isWithHint ? 'hint' : ''">
            <div v-if="isConfirmedHidden && distributorStatus === 'confirmed'"/>
            <div v-else-if="distributorStatus === 'confirmed'" class="uk-animation-fade">
                <InfoBox v-if="isWithInfoBox" :mode="getDistributorMode()">
                    <span v-html="$t('distributorConfirmedMessage')"/>
                </InfoBox>
                <span v-else>
                     <br/>
                    {{ $t('distributorConfirmedMessage') }}
                </span>

                <div class="uk-margin-medium-top"/>
                <LinkObject to="organisations">
                    <Button :text="$t('distributorsOverview')" is-large is-max mode="theme"/>
                </LinkObject>
            </div>
            <div v-else-if="distributorStatus === 'requested'">
                <InfoBox v-if="isWithInfoBox" :mode="getDistributorMode()">
                    <span v-html="$t('distributorAccessAlreadyRequestedMessage')"/>
                </InfoBox>
                <span v-else v-html="$t('distributorAccessAlreadyRequestedMessage')"/>
            </div>
            <div v-else-if="distributorStatus === 'denied'">
                <InfoBox v-if="isWithInfoBox" :mode="getDistributorMode()">
                    <span v-html="$t('distributorDeniedMessage')"/>
                </InfoBox>
                <span v-else v-html="$t('distributorDeniedMessage')"/>
                <div v-if="!showButton">
                    <br/>
                    <LinkItem is-underlined to="" uk-toggle="target: #modal-create-distributor">
                        {{ $t('requestNow') }}
                    </LinkItem>
                </div>
            </div>
            <div v-else>
                <InfoBox v-if="isWithInfoBox" :mode="getDistributorMode()">
                    <span v-html="$t('distributorMessage')"/>
                </InfoBox>
                <span v-else v-html="$t('distributorMessage')"/>
                <div v-if="!showButton">
                    <br/>
                    <LinkItem is-underlined to="" uk-toggle="target: #modal-create-distributor">
                        {{ $t('requestNow') }}
                    </LinkItem>
                </div>
            </div>
        </div>

        <div v-if="distributorStatus !== 'confirmed' && distributorStatus !== 'requested' && showButton">
            <div class="uk-margin-medium-top"/>
            <Button :text="$t('requestDistributorAccess')" is-large is-max mode="theme"
                    uk-toggle="target: #modal-create-distributor"/>
        </div>

        <CreateDistributor v-if="!distributorStatus || distributorStatus === 'denied'" initial is-button-hidden
                           @afterSuccess="getDistributors"/>

    </div>
</template>

<script>

import CreateDistributor from "@/components/creates/CreateDistributor";
import {getUserByIdDistributors} from "@/client/vue-api-client";
import LinkItem from "@/components/generic/LinkItem";
import LinkObject from "@/components/LinkObject";
import Button from "@/components/generic/Button";
import InfoBox from "@/components/generic/InfoBox";

export default {
    name: 'DistributorStatus',
    components: {InfoBox, Button, LinkObject, LinkItem, CreateDistributor},
    props: {
        isWithHint: Boolean,
        isConfirmedHidden: Boolean,
        isWithInfoBox: Boolean,
        showButton: Boolean,
    },
    data() {
        return {
            distributorRequested: false,
            distributorStatus: "",
        }
    },
    created() {
        this.getDistributors();
    },
    methods: {
        getDistributorMode() {
            if (this.distributorStatus === 'confirmed') {
                return 'success';
            }

            if (this.distributorStatus === 'denied') {
                return 'danger';
            }

            return '';
        },
        getDistributors() {
            let self = this;

            getUserByIdDistributors({
                id: self.getUserId,

            }).then(function (response) {
                let distributors = response.data;

                self.distributorStatus = self.getDistributorStatus(distributors);
                if (self.distributorStatus === "confirmed") {
                    self.$store.state.distributor = true;
                    if (self.$cookie.get("token")) {
                        self.$cookie.set("distributor", true);
                    }
                }

                self.$emit('distributorStatus', self.getDistributorMode());

            }).catch(function (e) {
                self.checkGenericError(e);
                self.error = e;
                self.loading = false;

            })
        },
        getDistributorStatus(distributors) {
            if (distributors.length === 0) {
                return "";
            }

            for (let i = 0, j = distributors.length; i < j; i++) {
                if (distributors[i].confirmedAt) {
                    return "confirmed";
                }
            }

            let allDenied = true;
            for (let i = 0, j = distributors.length; i < j; i++) {
                if (!distributors[i].deniedAt) {
                    allDenied = false;
                }
            }

            if (allDenied) {
                return "denied";
            }

            return "requested";
        },
    }
}

</script>

<style lang="scss" scoped>

.hint-struct {
    margin-top: 20px;
}

.hint {
    font-size: 15px;
}

</style>
