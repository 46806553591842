<template>
    <div>

        <div :id="id" :class="'uk-modal' + (isExtended ? ' uk-modal-container' : '') " uk-modal>
            <div
                :class="'uk-modal-dialog uk-modal-body'+ (isCentered? ' uk-margin-auto-vertical' :'') + (isMedium ? ' medium' : '')">

                <button class="uk-modal-close-default" type="button" uk-close/>
                <ModalTitle v-if="title" :title="title" class="uk-text-center"/>

                <slot/>

            </div>
        </div>

    </div>
</template>

<script>

import ModalTitle from "@/components/generic/ModalTitle";

export default {
    name: 'Modal',
    components: {ModalTitle},
    props: {
        id: String,
        isExtended: Boolean,
        isMedium: Boolean,
        title: String,
        isCentered: Boolean,
    },
}

</script>

<style lang="scss" scoped>

.uk-modal-dialog {
    border-radius: $border-radius;

    &.medium {
        width: 800px !important;
    }
}

</style>
