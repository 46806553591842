<template>
    <div>

        <LoadingStruct :is-loading="loading">
            <GenericSiteTitle :title-poperties="[{key: 0, disabled: true}]"/>
            <GenericItemView
                :computed-items="computedItems"
                :error="error"
                :filters="filters"
                :is-loading="loading"
                :is-search="search.length>0"
                :items="items"
                :no-items-message="$t('noDistributorsMessage')"
                :title-poperties="[{key: 0, disabled: true}]"
                is-searchable
                label-plural="distributors"
                @search="setSearch"
            >

                <template v-slot:items>
                    <li v-for="item in computedItems" :key="item.id"
                        :data-role="item.role" :data-status="item.status">
                        <DistributorItem :distributor="item" :users="users" @setConfirmed="setConfirmed"
                                         @setDenied="setDenied"/>
                    </li>
                </template>
            </GenericItemView>
        </LoadingStruct>

    </div>
</template>

<script>

import Vue from "vue";
import DistributorItem from "@/components/items/DistributorItem";
import {getDistributor, getUser} from "@/client/vue-api-client";
import GenericItemView from "@/views/GenericItemView";
import GenericSiteTitle from "@/views/GenericSiteTitle";
import LoadingStruct from "@/views/LoadingStruct";

export default {
    name: 'DistributorsAdministration',
    components: {LoadingStruct, GenericSiteTitle, GenericItemView, DistributorItem},
    data() {
        return {
            loading: true,
            error: null,
            items: [],
            search: "",
            users: [],

            filters: [
                {
                    type: 'status',
                    values: [
                        {name: 'confirmed', total: 0},
                        {name: 'unpermitted', total: 0},
                        {name: 'denied', total: 0},
                    ]
                },
            ],
        }
    },
    created() {
        this.getUsers();
    },
    computed: {
        computedItems() {
            let items = this.items;
            if (this.search && this.search.length) {
                items = this.powerSearcher(items, this.search);
            }

            if (items.length > this.defaultShowLimit) {
                return items.slice(0, this.defaultShowLimit);
            }

            return items;
        },
    },
    methods: {
        setSearch(text) {
            this.search = text;
        },
        setConfirmed(id) {
            for (let i = 0, j = this.items.length; i < j; i++) {
                if (this.items[i].id === id) {
                    Vue.set(this.items[i], 'deniedAt', null);
                    Vue.set(this.items[i], 'confirmedAt', new Date());
                    break;
                }
            }
        },
        setDenied(id) {
            for (let i = 0, j = this.items.length; i < j; i++) {
                if (this.items[i].id === id) {
                    Vue.set(this.items[i], 'deniedAt', new Date());
                    Vue.set(this.items[i], 'confirmedAt', null);
                    break;
                }
            }
        },
        getStatus(distributor) {
            if (distributor.confirmedAt) {
                return 'confirmed';
            }

            if (distributor.deniedAt) {
                return 'denied';
            }

            return 'unpermitted';
        },
        getUsers() {
            let self = this;

            getUser().then(function (response) {
                self.users = response.data;
                self.getItems();

            }).catch(function (e) {
                self.checkGenericError(e);
                self.error = e;
                self.loading = false;

            })
        },
        getUserById(id) {
            for (let i = 0, j = this.users.length; i < j; i++) {
                if (id === this.users[i].id) {
                    return this.users[i];
                }
            }
        },
        getItems() {
            let self = this;

            getDistributor().then(function (response) {
                let items = response.data;
                items.reverse();

                for (let i = 0, j = items.length; i < j; i++) {
                    items[i]['status'] = self.getStatus(items[i]);

                    let user = self.getUserById(items[i].ownerId);
                    if (user) {
                        items[i]['userName'] = user.name;
                        items[i]['userEmail'] = user.mailAddress;
                    }
                }

                self.items = items;
                self.loading = false;

            }).catch(function (e) {
                self.checkGenericError(e);
                self.error = e;
                self.loading = false;

            })

        },
    }
}

</script>

<style lang="scss" scoped>

</style>
