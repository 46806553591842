<template>
    <div>

    </div>
</template>

<script>

import UIkit from "uikit";

export default {
    name: 'Invite',
    data() {
        return {}
    },
    created() {
        let deviceId = this.$route.params.deviceId;
        let email = this.$route.hash;
        if (email) {
            email = email.substring(1);
        }

        if (!deviceId) {
            this.routeTo('dashboard');
        }

        let targetPath = 'devices/' + deviceId;

        if (this.getToken) {
            this.routeTo(targetPath);
        }

        UIkit.notification({
            message: this.$t('invited.notloggedin', {email: email}),
            status: 'info',
            pos: 'top-center',
            timeout: this.notificationTimeout,
        });

        this.$store.state.invitedPath = targetPath;
        if (email) {
            this.$store.state.invitedEmail = email;
        }
        this.routeTo('login');
    },
}

</script>

<style lang="scss" scoped>

</style>
