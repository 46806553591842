<template>
    <div>
        <Card :title="title" mode="default" :is-blank="isBlank" :is-without-body="isWithoutBody">
            <Description v-if="description" :text="description"/>
            <highcharts :options="chartOptions"/>
        </Card>

    </div>
</template>


<script>

import Card from "@/components/generic/Card";
import Description from "@/components/Description";

export default {
    name: 'StatisticPie',
    components: {Description, Card},
    props: {
        title: String,
        data: Array,
        description: String,
        isBlank: Boolean,
        isWithoutBody: Boolean,
    },
    data() {
        return {
            test: "test",
            chartOptions: {
                chart: {
                    type: 'pie'
                },
                title: {
                    text: "",
                },
                plotOptions: {
                    series: {
                        dataLabels: {
                            enabled: true,
                            format: '{point.name}: {point.y:.0f} {test}'
                        }
                    }
                },
                series: [
                    {
                        name: "",
                        colorByPoint: true,
                        data: this.data,
                    }
                ],
            },
        }
    },
}

</script>


<style lang="scss" scoped>

</style>
