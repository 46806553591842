import { render, staticRenderFns } from "./CookieControl.vue?vue&type=template&id=4285cfea&scoped=true&"
import script from "./CookieControl.vue?vue&type=script&lang=js&"
export * from "./CookieControl.vue?vue&type=script&lang=js&"
import style0 from "./CookieControl.vue?vue&type=style&index=0&id=4285cfea&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4285cfea",
  null
  
)

export default component.exports