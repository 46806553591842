import { render, staticRenderFns } from "./UpdateBackupSettings.vue?vue&type=template&id=19cd6582&scoped=true&"
import script from "./UpdateBackupSettings.vue?vue&type=script&lang=js&"
export * from "./UpdateBackupSettings.vue?vue&type=script&lang=js&"
import style0 from "./UpdateBackupSettings.vue?vue&type=style&index=0&id=19cd6582&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19cd6582",
  null
  
)

export default component.exports