<template>
    <GenericCreate
        :action="create" :action-error="error" :action-label="$t('create')"

        :action-loading="loading" :icon-tooltip="$t('createNewSatellite')"

        :modal-id="modalId"

        :success-description="$t('satellite.created.message', {name: itemName})"
        :success-title="$t('satellite.created')" :title="$t('createNewSatellite')" :trigger="trigger"

        icon="plus"
        modal-is-medium
    >

        <span v-for="(inputField, key) in textFields" :key="inputField.name">
            <div v-if="key < textFields.length-1">
                <TextField v-model="inputField.value" :autocomplete="inputField.autocomplete"
                           :autofocus="inputField.autofocus" :field-key="key"
                           :hasPasswordMeter="inputField.hasPasswordMeter"
                           :icon="inputField.icon"
                           :is-optional="inputField.optional"
                           :label="inputField.label"
                           :name="inputField.name"
                           :onEnter="create"
                           :rules="inputField.rules"
                           :type="inputField.type"
                           :validate="trigger"
                           @setValid="setValid"
                />
            </div>
            <div v-if="key !== textFields.length -1" class="uk-margin-bottom"/>
        </span>

        <div class="uk-margin-bottom"/>
        <CardSelect v-model="textFields[2].value" :field-key="0" :label="textFields[2].label"
                    :label-name="textFields[2].name" :no-items-message="$t('noDistributorsMessage')"
                    :optional="textFields[2].optional"
                    :options="getDistributorList()" :rules="textFields[2].rules" :validate="trigger" is-searchable
                    label-plural="distributors"
                    @setValid="setCardValid"/>

        <template v-slot:modal>
            <div class="warning uk-text-center uk-form-danger">{{ $t('configFilesWarning') }}</div>

            <div class="uk-flex-center button-struct" uk-grid>
                <div>
                    <ActionButton
                        :action="downloadConfigFiles"
                        :text="$t('downloadAgain')"
                        icon="cloud-download"
                        mode="primary"
                    />
                </div>
            </div>
        </template>

    </GenericCreate>
</template>

<script>

import {postSatellite} from "@/client/vue-api-client";
import TextField from "../generic/TextField";
import GenericCreate from "@/components/creates/GenericCreate";
import UIkit from "uikit";
import CardSelect from "@/components/CardSelect";
import ActionButton from "@/components/generic/ActionButton";

export default {
    name: 'CreateSatellite',
    components: {
        ActionButton,
        CardSelect,
        GenericCreate,
        TextField,
    },
    props: {
        distributors: Array,
    },
    data() {
        return {
            modalId: "modal-create-satellite",
            trigger: 0,

            textFields: [
                {
                    value: "",
                    valid: false,

                    autofocus: true,
                    name: "serialNumber",
                    label: "serialNumber",
                    rules: "required",
                    icon: "code",
                },
                {
                    value: "",
                    valid: false,

                    autofocus: true,
                    name: "description",
                    label: "description",
                    icon: "tag",
                },
                {
                    value: "",
                    valid: true,

                    autofocus: false,
                    name: "distributor",
                    label: "distributor",
                    icon: "social",
                    rules: "",
                    optional: true,
                },
            ],

            loading: false,
            error: null,
            itemName: "",

            satellite: {
                id: "",
                hardwareGenId: 0,
                distributorId: "",
                deviceId: "",
                apiToken: "",
                emergency: {
                    token: "",
                    expiresAt: ""
                }
            },

            parsedDistributors: [],
        }
    },
    created() {
        let parsedItems = [];
        for (let i = 0, j = this.distributors.length; i < j; i++) {
            if (this.distributors[i].confirmedAt) {
                parsedItems.push(this.distributors[i]);
            }
        }

        this.parsedDistributors = parsedItems;
    },
    methods: {
        downloadConfigFiles() {
            this.downloadSatelliteConfigFiles(this.satellite);
        },
        setCardValid(valid) {
            this.textFields[2].valid = valid;
        },
        setValid(valid, fieldKey) {
            this.textFields[fieldKey].valid = valid
        },
        getDistributorList() {
            let items = [];

            items.push({
                name: this.$t("noDistributor"),
                value: "",
            });

            for (let i = 0, j = this.parsedDistributors.length; i < j; i++) {
                items.push({
                    nameIcon: "social",
                    name: this.parsedDistributors[i].name,
                    value: this.parsedDistributors[i].id,
                });
            }

            return items;
        },
        create() {
            this.trigger++;

            for (let i = 0, j = this.textFields.length; i < j; i++) {
                if (!this.textFields[i].valid) {
                    return;
                }
            }

            let self = this;
            self.loading = true;

            postSatellite({
                satellite: {
                    ean: self.textFields[0].value,
                    description: self.textFields[1].value,
                    distributorId: self.textFields[2].value,
                },

            }).then(function (response) {
                self.itemName = self.textFields[0].value;

                for (let i = 0, j = self.textFields.length; i < j; i++) {
                    self.textFields[i].value = "";
                    self.textFields[i].valid = false;
                }

                self.satellite = response.data;
                self.loading = false;

                UIkit.modal("#" + self.modalId + "-success").show();
                self.$emit('afterSuccess', response.data, false);
                self.downloadConfigFiles();

            }).catch(function (e) {
                self.checkGenericError(e);

                self.error = e;
                self.loading = false;

            })
        },
    }
}

</script>

<style lang="scss" scoped>

.warning {
    margin-top: -20px;
    margin-bottom: 20px;
    font-weight: 500;
}

.button-struct {
    margin-bottom: 40px;
}

</style>
