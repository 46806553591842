<template>
    <GenericCreate
        :action="create" :action-error="error" :action-label="$t('create')"

        :action-loading="loading" :modal-id="modalId"

        :success-description="$t('subscription.created.success', {module: itemModule, distributor: itemDistributor})"
        :success-title="$t('subscription.created')" :title="$t('subscription.create')" :trigger="trigger"

        icon="plus"
        modal-is-medium
    >
        <CardSelect v-model="textFields[0].value" :field-key="0" :label="textFields[0].label"
                    :label-name="textFields[0].name" :no-items-message="$t('modules.notcreated')"
                    :optional="textFields[0].optional"
                    :options="getPlanOptions()" :rules="textFields[0].rules" :validate="trigger" label-plural="modules"
                    @setValid="setModuleValid"/>
        <div class="uk-margin-bottom"/>

        <span v-for="(inputField, key) in textFields" :key="inputField.name">
            <div v-if="key > 0 && key < textFields.length-3">
                <TextField v-model="inputField.value" :autocomplete="inputField.autocomplete"
                           :autofocus="inputField.autofocus" :field-key="key"
                           :hasPasswordMeter="inputField.hasPasswordMeter"
                           :icon="inputField.icon"
                           :is-optional="inputField.optional"
                           :label="inputField.label"
                           :name="inputField.name"
                           :onEnter="create"
                           :rules="inputField.rules"
                           :type="inputField.type"
                           :validate="trigger"
                           @setValid="setValid"
                />
            </div>
            <div v-if="key > 0 && key < textFields.length-1" class="uk-margin-bottom"/>
        </span>

        <div class="uk-margin-bottom"/>
        <LabelWithError :label="$t('validity')"/>
        <v-date-picker v-model="range" :columns="$screens({ default: 1, sm: 2, lg: 3 })" is-expanded is-range/>

        <div class="uk-margin-bottom"/>
        <CardSelect v-model="textFields[4].value" :field-key="3" :label="textFields[4].label"
                    :label-name="textFields[4].name" :no-items-message="$t('distributors.notcreated')"
                    :optional="textFields[4].optional"
                    :options="getDistributorList()" :rules="textFields[4].rules" :validate="trigger" is-searchable
                    label-plural="distributors"
                    @setValid="setDistributorValid"/>

    </GenericCreate>
</template>

<script>

import {postDistributorByIdSubscriptions} from "@/client/vue-api-client";
import TextField from "../generic/TextField";
import GenericCreate from "@/components/creates/GenericCreate";
import UIkit from "uikit";
import CardSelect from "@/components/CardSelect";
import LabelWithError from "@/components/generic/LabelWithError";

export default {
    name: 'CreateSubscription',
    components: {
        LabelWithError,
        CardSelect,
        GenericCreate,
        TextField,
    },
    props: {
        distributors: Array,
        plans: Array,
    },
    data() {
        return {
            modalId: "modal-create-subscription",
            trigger: 0,

            textFields: [
                {
                    value: "",
                    valid: false,

                    autofocus: false,
                    name: "plan",
                    label: "plan",
                    icon: "social",
                    rules: "required",
                },
                {
                    value: 1,
                    valid: true,

                    autofocus: false,
                    name: "quantity",
                    label: "quantity",
                    icon: "cart",
                    rules: "required|min_value:1|decimal:0",
                    type: "number",
                },
                {
                    value: "",
                    valid: true,

                    autofocus: false,
                    name: "validFrom",
                    label: "validFrom",
                    icon: "clock",
                    rules: "required|date_format:yyyy-MM-dd",
                    type: "date"
                },
                {
                    value: "",
                    valid: true,

                    autofocus: false,
                    name: "validUntil",
                    label: "validUntil",
                    icon: "future",
                    rules: "required|date_format:yyyy-MM-dd",
                    type: "date"
                },
                {
                    value: "",
                    valid: false,

                    autofocus: false,
                    name: "distributor",
                    label: "distributor",
                    icon: "social",
                    rules: "required",
                },
            ],

            range: {
                start: new Date(),
                end: new Date()
            },

            loading: false,
            error: null,

            itemModule: "",
            itemDistributor: "",

            parsedDistributors: [],
        }
    },
    created() {
        let parsedItems = [];
        for (let i = 0, j = this.distributors.length; i < j; i++) {
            if (this.distributors[i].confirmedAt) {
                parsedItems.push(this.distributors[i]);
            }
        }

        this.parsedDistributors = parsedItems;
    },
    methods: {
        getDistributorName(id) {
            for (let i = 0, j = this.distributors.length; i < j; i++) {
                if (this.distributors[i].id === id) {
                    return this.distributors[i].name;
                }
            }

            return null;
        },
        setModuleValid(valid) {
            this.textFields[0].valid = valid;
        },
        setDistributorValid(valid) {
            this.textFields[4].valid = valid;
        },
        setValid(valid, fieldKey) {
            this.textFields[fieldKey].valid = valid
        },
        getPlanOptions() {
            let allowedRoles = this.plans;
            let preparedRoles = []

            for (let i = 0, j = allowedRoles.length; i < j; i++) {
                preparedRoles.push(
                    {
                        nameIcon: 'cart',
                        name: this.$t(allowedRoles[i].id),
                        value: allowedRoles[i].id,
                    }
                );
            }

            return preparedRoles;
        },
        getDistributorList() {
            let items = [];

            for (let i = 0, j = this.parsedDistributors.length; i < j; i++) {
                items.push({
                    nameIcon: "social",
                    name: this.parsedDistributors[i].name,
                    value: this.parsedDistributors[i].id,
                });
            }

            return items;
        },
        create() {
            this.trigger++;

            for (let i = 0, j = this.textFields.length; i < j; i++) {
                if (!this.textFields[i].valid) {
                    return;
                }
            }

            let self = this;
            self.loading = true;

            let from = self.range.start;
            from.setHours(0);
            from.setMinutes(0);
            from.setSeconds(0);
            from = self.range.start.toISOString();

            let until = self.range.end;
            until.setHours(23);
            until.setMinutes(59);
            until.setSeconds(59);
            until = self.range.end.toISOString();

            postDistributorByIdSubscriptions({
                id: self.textFields[4].value,
                createSubscriptionPayload: {
                    planId: self.textFields[0].value,
                    quota: self.textFields[1].value,
                    validFrom: from,
                    validUntil: until,
                },

            }).then(function () {
                self.itemModule = self.textFields[0].value;
                self.itemDistributor = self.getDistributorName(self.textFields[4].value);

                for (let i = 0, j = self.textFields.length; i < j; i++) {
                    self.textFields[i].value = "";
                    self.textFields[i].valid = false;
                }

                self.loading = false;

                UIkit.modal("#" + self.modalId + "-success").show();
                self.$emit('afterSuccess')

            }).catch(function (e) {
                self.checkGenericError(e);

                self.error = e;
                self.loading = false;

            })
        },
    }
}

</script>

<style lang="scss" scoped>

</style>
