<template>
    <div>

        <LoadingStruct :is-loading="loading">
            <GenericSiteTitle>
                <template v-slot:action>
                    <RoundButton :action="logout" :text="$t('logout')" icon="sign-out"/>
                </template>
            </GenericSiteTitle>
            <GenericItemView
                :error="error"
                :is-loading="loading">
                <div
                    class="uk-child-width-1-3@xl uk-child-width-1-2@m uk-child-width-1-1 uk-grid-match uk-margin-remove-top uk-grid-medium"
                    uk-grid>
                    <div>
                        <Card :title="$t('personalData')" mode="default">
                            <TextField v-model="user.name" :validate="triggerName"
                                       icon="user" label="foreAndSurname" name="name"
                                       rules="required|alpha_spaces"
                                       @setValid="setNameValid"/>

                            <div class="uk-margin-top"/>
                            <TextField v-model="user.mailAddress" disabled icon="mail" label="emailAddress"
                                       name="email"/>

                            <div v-if="nameUpdated || nameUpdateError" class="uk-animation-fade">
                                <div class="uk-margin-medium-top"/>
                                <InfoBox v-if="nameUpdated">
                                    {{ $t('updateSuccessMessage') }}
                                </InfoBox>
                                <InfoBox v-else mode="danger">
                                    <ErrorMessage
                                        :custom-errors="[{code: 400, message: $t('registerFailedWhitelistMessage')}]"
                                        :error="nameUpdateError"/>
                                </InfoBox>
                            </div>

                            <div class="uk-margin-medium-top"/>
                            <Button :action="updateUsername" :is-loading="nameLoading" :text="$t('saveChanges')"
                                    has-action
                                    is-large is-max mode="theme"/>
                        </Card>
                    </div>


                    <div>
                        <Card :title="$t('password')" mode="default">

                            <TextField v-model="password" :validate="triggerPassword" autocomplete="password"
                                       icon="unlock" label="currentPassword" name="password" rules="required"
                                       type="password"
                                       @setValid="setPasswordValid"/>

                            <div class="uk-margin-top"/>
                            <TextField v-model="newPassword" :validate="triggerPassword"
                                       autocomplete="new-password"
                                       hasPasswordMeter icon="lock" label="newPassword" name="new-password"
                                       rules="required"
                                       type="password" @setValid="setNewPasswordValid"/>


                            <div v-if="passwordUpdated || passwordUpdateError" class="uk-animation-fade">
                                <div class="uk-margin-medium-top"/>
                                <InfoBox v-if="passwordUpdated">
                                    {{ $t('updateSuccessMessage') }}
                                </InfoBox>
                                <InfoBox v-else mode="danger">
                                    <ErrorMessage
                                        :custom-errors="[{code: 400, message: $t('currentPasswordWrongError')}]"
                                        :error="passwordUpdateError"/>
                                </InfoBox>
                            </div>


                            <div class="uk-margin-medium-top"/>
                            <Button :action="updatePassword" :is-loading="passwordLoading" :text="$t('saveNewPassword')"
                                    has-action
                                    is-large is-max mode="theme"/>
                        </Card>
                    </div>


                    <div>
                        <Card :title="$t('twoFactorAuthentication')" mode="default">
                            <span v-html="$t('twoFactorAuthenticationMessage')"/>

                            <InfoBox v-if="user && user.twoFactorOtp && user.twoFactorOtp.Finalized"
                                     class="uk-margin-top" icon="check" mode="success">
                                <span v-html="$t('twoFactorActivated')"/>
                            </InfoBox>
                            <div v-else>
                                <InfoBox class="uk-margin-top" icon="warning" mode="danger">
                                    <span v-html="$t('twoFactorDeactivated')"/>
                                </InfoBox>

                                <div class="uk-margin-medium-top"/>
                                <CreateTwoFactorAuthentication :user-id="this.getUserId" @afterSuccess="getUser"/>
                            </div>

                        </Card>
                    </div>


                    <div v-if="false">
                        <Card :title="$t('notifications')" mode="default">

                            <SwitchButton v-model="accountNotifications" :label="$t('account')"/>
                            <hr/>
                            <SwitchButton v-model="newsletterNotifications" :label="$t('newsletter')"/>

                        </Card>
                    </div>


                    <div>
                        <Card :title="$t('distributorStatus')" mode="default">
                            <DistributorStatus is-with-info-box show-button/>
                        </Card>
                    </div>

                    <div>
                        <Card :title="$t('language')" mode="default">

                            <div v-for="(language, key) in languages" :key="language">
                                <div>
                                    <label class="cursor-pointer">
                                        <div uk-grid>
                                            <div class="uk-width-expand language uk-flex uk-flex-middle">
                                                <CountryFlag :country="language === 'en' ? 'us' : language" size='big'/>
                                                <span class="uk-margin-small-left">
                                                {{ $t(language, language) }}
                                            </span>
                                            </div>
                                            <div class="uk-flex uk-flex-middle">
                                                <input v-model="$i18n.locale" :value="language" class="uk-radio"
                                                       name="language"
                                                       type="radio" v-on:click="switchLanguage(language)">
                                            </div>
                                        </div>
                                    </label>
                                </div>
                                <hr v-if="key !== languages.length-1"/>
                            </div>

                        </Card>
                    </div>

                    <div>
                        <Card :title="$t('logout')" mode="default">
                            <span v-html="$t('logoutMessage')"/>

                            <div class="uk-margin-medium-top"/>
                            <Button :action="logout" :text="$t('logout')" has-action is-large is-max mode="danger"/>
                        </Card>
                    </div>
                </div>

            </GenericItemView>
        </LoadingStruct>

    </div>
</template>

<script>

import Card from "@/components/generic/Card";
import {getUserById, putUserById} from "@/client/vue-api-client";
import TextField from "@/components/generic/TextField";
import Button from "@/components/generic/Button";
import SwitchButton from "@/components/generic/SwitchButton";
import RoundButton from "@/components/RoundButton";
import CountryFlag from 'vue-country-flag'
import InfoBox from "@/components/generic/InfoBox";
import ErrorMessage from "@/components/generic/ErrorMessage";
import CreateTwoFactorAuthentication from "@/components/creates/CreateTwoFactorAuthentication";
import GenericItemView from "@/views/GenericItemView";
import GenericSiteTitle from "@/views/GenericSiteTitle";
import LoadingStruct from "@/views/LoadingStruct";
import DistributorStatus from "@/components/DistributorStatus";

export default {
    name: 'Account',
    components: {
        DistributorStatus,
        LoadingStruct,
        GenericSiteTitle,
        GenericItemView,
        CreateTwoFactorAuthentication,
        ErrorMessage,
        InfoBox,
        RoundButton,
        SwitchButton,
        Button,
        TextField,
        Card,
        CountryFlag
    },
    data() {
        return {
            loading: true,
            error: null,

            triggerName: 0,
            triggerPassword: 0,

            tabs: ['account', 'preferences'],

            user: {
                name: "",
                mailAddress: "",
                createdAt: "",
                id: "",
                permittedAt: "",
                roleId: "",
                twoFactorOtp:
                    {
                        Initialized: false,
                        Finalized: false
                    },
            },

            nameValid: true,
            nameLoading: false,
            nameUpdated: false,
            nameUpdateError: false,


            password: "",
            newPassword: "",

            passwordValid: false,
            newPasswordValid: false,
            passwordLoading: false,
            passwordUpdated: false,
            passwordUpdateError: false,

            accountNotifications: true,
            newsletterNotifications: true,
        }
    },
    created() {
        this.getUser();
    },
    methods: {
        setNameValid(valid) {
            this.nameValid = valid;
        },
        setPasswordValid(valid) {
            this.passwordValid = valid;
        },
        setNewPasswordValid(valid) {
            this.newPasswordValid = valid;
        },
        updateUsername() {

            this.triggerName++;

            if (!this.nameValid) {
                return;
            }

            let self = this;
            self.nameLoading = true;

            putUserById({
                id: self.getUserId,
                user: {
                    name: self.user.name,
                },

            }).then(function () {
                self.nameUpdated = true;
                self.nameLoading = false;

            }).catch(function (e) {
                self.checkGenericError(e);

                self.nameUpdateError = e;
                self.nameLoading = false;

            })
        },
        updatePassword() {
            this.triggerPassword++;

            if (!this.passwordValid || !this.newPasswordValid) {
                return;
            }


            let self = this;
            self.passwordLoading = true;

            putUserById({
                id: self.getUserId,
                user: {
                    previousPassword: self.password,
                    newPassword: self.newPassword,
                },

            }).then(function () {
                self.passwordUpdated = true;
                self.passwordLoading = false;

            }).catch(function (e) {
                self.checkGenericError(e);

                self.passwordUpdateError = e;
                self.passwordLoading = false;

            })
        },
        getUser() {
            let self = this;

            self.loading = true;
            getUserById({
                id: self.getUserId,

            }).then(function (response) {
                self.user = response.data;
                self.loading = false;

            }).catch(function (e) {
                self.checkGenericError(e);
                self.error = e;
                self.loading = false;

            })
        },
    }
}

</script>

<style lang="scss" scoped>

.language {
    font-weight: 400;
    font-size: 16px;
}

.round-button-struct {
    margin-top: 7px;
}

</style>
