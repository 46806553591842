<template>
    <div>

        <LoadingStruct :is-loading="loading">
            <GenericSiteTitle :title-poperties="[{key: 0, disabled: true}]"/>
            <GenericItemView
                :computed-items="computedItems"
                :error="error"
                :is-loading="loading"
                :is-search="search.length>0"
                :items="items"
                :no-items-message="$t('noModulesCreatedMessage')"
                :title-poperties="[{key: 0, disabled: true}]"
                is-searchable
                label-plural="modules"
                @search="setSearch"
            >
                <template v-slot:items>
                    <li v-for="item in computedItems" :key="item.id">
                        <ModuleItem :module="item"/>
                    </li>
                </template>

            </GenericItemView>
        </LoadingStruct>


    </div>
</template>

<script>

import {getPlan} from "@/client/vue-api-client";
import ModuleItem from "@/components/items/ModuleItem";
import GenericItemView from "@/views/GenericItemView";
import GenericSiteTitle from "@/views/GenericSiteTitle";
import LoadingStruct from "@/views/LoadingStruct";

export default {
    name: 'ModulesAdministration',
    components: {
        LoadingStruct,
        GenericSiteTitle,
        GenericItemView, ModuleItem,
    },
    data() {
        return {
            loading: true,
            error: null,
            items: [],
            search: "",
        }
    },
    created() {
        this.getItems();
    },
    computed: {
        computedItems() {
            let items = this.items;
            if (this.search && this.search.length) {
                items = this.powerSearcher(items, this.search);
            }

            if (items.length > this.defaultShowLimit) {
                return items.slice(0, this.defaultShowLimit);
            }

            return items;
        },
    },
    methods: {
        setSearch(text) {
            this.search = text;
        },
        getItems() {
            let self = this;

            getPlan().then(function (response) {
                self.items = response.data;
                self.loading = false;

            }).catch(function (e) {
                self.checkGenericError(e);
                self.error = e;
                self.loading = false;

            })
        },
    }
}

</script>

<style lang="scss" scoped>

</style>
