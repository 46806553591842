<template>
    <div class="navigation">

        <div
            uk-sticky="cls-active: uk-navbar-sticky; cls-inactive: uk-navbar-transparent;">

            <nav class="uk-navbar-container small uk-hidden@s" uk-navbar>
                <div class="uk-navbar-center uk-animation-fade">
                    <ul class="uk-navbar-nav">
                        <li class="app-name-struct">
                            <LinkItem is-dark to="dashboard">
                                <div class="app-link">
                                    <Logo is-small/>
                                </div>
                            </LinkItem>
                        </li>
                    </ul>
                </div>
            </nav>

            <nav class="uk-navbar-container" uk-navbar>
                <div class="uk-navbar-left uk-animation-fade uk-visible@s">
                    <ul class="uk-navbar-nav">
                        <li class="app-name-struct">
                            <LinkItem is-dark to="dashboard">
                                <div class="app-link">
                                    <Logo is-small/>
                                </div>
                            </LinkItem>
                        </li>
                    </ul>
                </div>

                <div class="uk-navbar-center uk-animation-fade uk-hidden@s">
                    <ul class="uk-navbar-nav">
                        <li v-for="(link, key) in computedLinks" :key="link.name"
                            :class="key === computedLinks.length - 1 ? '' : ''" v-on:click="runAction(link)">
                            <LinkItem :to="link.route" is-dark>
                                <div class="uk-grid-collapse" uk-grid>
                                    <div :uk-tooltip="'pos: right; title: '+$t(link.name) " class="uk-hidden@l">
                                        <Icon :icon="link.icon" :ratio="1.0"/>
                                        <div class="uk-flex-center uk-flex">
                                            <div
                                                v-if="$route.name.split('/')[0] === link.name || $route.name.split('/')[0] === link.route || $route.name.split('/')[0]+'s' === link.name"
                                                class="dot"/>
                                        </div>
                                    </div>

                                    <div class="uk-visible@l">
                                        <Icon :icon="link.icon" :ratio="1.0"/>
                                    </div>

                                    <div class="uk-visible@l">
                                        <div class="link-name" v-html="$t(link.name)"/>
                                    </div>
                                </div>

                                <div v-if="link.subLinks" :id="'sub-links-'+link.name"
                                     :uk-dropdown="'mode: '+ link.mode" class="uk-navbar-dropdown">
                                    <ul class="uk-nav uk-navbar-dropdown-nav sub-link-struct">
                                        <li v-for="subLink in link.subLinks" :key="subLink.route"
                                            :uk-toggle="'target: #sub-links-'+link.name">

                                            <LinkObject :to="subLink.route">
                                                <div class="sub-link-item">
                                                    <Icon :icon="subLink.icon"/>
                                                    <span class="link-name" v-html="$t(subLink.name)"/>
                                                </div>
                                            </LinkObject>

                                        </li>
                                    </ul>
                                </div>

                            </LinkItem>
                        </li>

                        <li class="last">
                            <LanguageSelector id="mobile"/>
                        </li>

                    </ul>
                </div>

                <div class="uk-navbar-right uk-animation-fade uk-visible@s">
                    <ul class="uk-navbar-nav">
                        <li v-for="(link, key) in computedLinks" :key="link.name"
                            :class="key === computedLinks.length - 1 ? '' : ''" v-on:click="runAction(link)">
                            <LinkItem :to="link.route" is-dark>
                                <div class="uk-grid-collapse" uk-grid>
                                    <div :uk-tooltip="'pos: right; title: '+$t(link.name) " class="uk-hidden@l">
                                        <Icon :icon="link.icon" :ratio="1.0"/>
                                        <div class="uk-flex-center uk-flex">
                                            <div
                                                v-if="$route.name.split('/')[0] === link.name || $route.name.split('/')[0] === link.route || $route.name.split('/')[0]+'s' === link.name"
                                                class="dot"/>
                                        </div>
                                    </div>

                                    <div class="uk-visible@l">
                                        <Icon :icon="link.icon" :ratio="1.0"/>
                                    </div>

                                    <div class="uk-visible@l">
                                        <div class="link-name" v-html="$t(link.name)"/>
                                    </div>
                                </div>

                                <div v-if="link.subLinks" :id="'sub-links-'+link.name"
                                     :uk-dropdown="'mode: '+ link.mode" class="uk-navbar-dropdown">
                                    <ul class="uk-nav uk-navbar-dropdown-nav sub-link-struct">
                                        <li v-for="subLink in link.subLinks" :key="subLink.route"
                                            :uk-toggle="'target: #sub-links-'+link.name">

                                            <LinkObject :to="subLink.route">
                                                <div class="sub-link-item">
                                                    <Icon :icon="subLink.icon"/>
                                                    <span class="link-name" v-html="$t(subLink.name)"/>
                                                </div>
                                            </LinkObject>

                                        </li>
                                    </ul>
                                </div>

                            </LinkItem>
                        </li>

                        <li class="last">
                            <LanguageSelector id="desktop"/>
                        </li>

                    </ul>
                </div>


            </nav>
        </div>

        <CreateSupportRequest is-button-hidden/>

    </div>
</template>

<script>

import Icon from "@/components/generic/Icon";
import LinkItem from "@/components/generic/LinkItem";
import LinkObject from "@/components/LinkObject";
import LanguageSelector from "@/components/LanguageSelector";
import CreateSupportRequest from "@/components/creates/CreateSupportRequest";
import Logo from "@/components/Logo";

export default {
    name: 'Navigation',
    components: {Logo, CreateSupportRequest, LanguageSelector, LinkObject, LinkItem, Icon},
    props: {
        links: Array,
    },
    computed: {
        computedLinks() {
            let computedLinks = [];

            for (let i = 0, j = this.links.length; i < j; i++) {
                if (this.isPermitted(this.links[i].permission)) {
                    computedLinks.push(this.links[i]);
                }
            }

            return computedLinks;
        }
    },
    data() {
        return {
            distributors: [],
        }
    },
    methods: {
        isPermitted(permission) {
            if (!permission) {
                return true;
            }

            if (permission === 'administrator' && this.isAdmin) {
                return true;
            }

            if (permission === 'distributor' && this.isDistributor) {
                return true;
            }

            return false;
        },
        runAction(link) {
            if (!link.hasAction) {
                return;
            }

            link.action();
        }
    },
}
</script>

<style lang="scss" scoped>

.uk-navbar-container {
    background-color: transparent !important;
    height: $navigation-height;

    &.small {
        margin-top: 10px;
        height: 40px !important;
    }
}

.uk-navbar-sticky {
    background-color: rgba(9, 39, 51, .82) !important;
    backdrop-filter: saturate(180%) blur(20px);
    border-bottom: 1px solid rgba(255, 255, 255, 0.24);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);


    &.mobile {
        //background-color: rgba(9, 39, 51, .92) !important;
        background-color: rgba(255, 255, 255, .82) !important;
        border-top: 1px solid rgba(255, 255, 255, 0.24) !important;
        border-bottom: 0;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.72);
    }
}

.mobile-nav {
    margin-left: -15px !important;
    margin-bottom: 10px;
}

.link-name {
    font-size: 16px;
    margin-left: 10px;

    &.mobile {
        white-space: nowrap !important;
        font-size: 14px;
        margin-left: 0;
        margin-top: 3px;
        color: $site-color;
    }
}

.mobile-icon {
    color: $site-color;
}

.app-link {
    font-size: 16px;
}

.app-name-struct {
    padding: 0 !important;
}

.app-name {
    font-weight: 500;
}

.uk-navbar-container {
    max-width: calc(#{$max-site-width} + 180px);
    margin: auto;
    padding: 0 60px 0 60px;
}

@media (max-width: $breakpoint-medium-max) {
    .uk-navbar-container {
        max-width: calc(#{$max-site-width} + 120px);
        padding: 0 40px 0 40px;
    }
}

@media (max-width: $breakpoint-xsmall-max) {
    .uk-navbar-container {
        max-width: calc(#{$max-site-width} + 120px);
        padding: 0 20px 0 20px;
    }
}

.uk-navbar-nav > li {
    padding: 0 16px 0 16px;

    &.last {
        padding: 0 0 0 16px;
    }
}

.uk-navbar-nav > li > a {
    text-transform: initial !important;
    color: $theme-color !important;

    &.active, &:hover, &.router-link-exact-active, &.router-link-active {
        color: $theme-color-hover !important;
    }
}

.sub-link-struct {
    margin-top: -10px;
    padding: 25px 0 25px 0 !important;
    cursor: initial !important;
}

.sub-link-item {
    padding-top: 5px;
    padding-bottom: 5px;
}

.last {
    margin-top: -5px;
}

.uk-navbar-dropdown {
    padding: 0 25px 0 25px !important;
}

@media (max-width: 445px) {
    .app-name {
        font-size: 13px;
    }
}

.navigation {
    height: $navigation-height;
}

@media (max-width: 445px) {
    .navigation {
        height: 130px;
    }
}


</style>
