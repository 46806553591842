<template>
    <div class="tabs">

        <div v-if="isLoading"/>
        <ul v-else :id="id" :uk-tab="isAnimationDisabled ? '' : 'animation: uk-animation-fade'">
            <li v-for="(tab, key) in tabs" :key="tab" :class="getTabStatus(key, tab)"
                v-on:click="setHashtag(tab)"><a :href="'#'+tab">{{ $t(tab) }}</a>
            </li>
            <slot name="items"/>
        </ul>

        <ul class="uk-switcher tab-content">
            <slot/>
        </ul>

    </div>
</template>

<script>

export default {
    name: 'Tabs',
    props: {
        tabs: Array,
        id: String,
        isLoading: Boolean,
        isAnimationDisabled: Boolean,
    },
    methods: {
        setHashtag(tab) {
            history.pushState({}, null, "#" + tab);
        },
        getTabStatus(key, tab) {
            let hash = this.$route.hash;
            if (hash) {
                if (hash.substring(1) === tab) {
                    return 'uk-active';
                }

                return '';
            }

            if (key === 0) {
                return 'uk-active';
            }

            return '';
        },
    },
}

</script>

<style lang="scss" scoped>

.uk-switcher {
    margin-top: 25px;
}

.uk-tab > * > a {
    text-transform: initial !important;
    font-size: 16px;
    font-weight: 400;
    color: $theme-color;
    border-color: transparent;
    border-width: 3px;

    &:hover {
        color: $theme-color-hover;
        border-bottom: 3px solid $theme-color-hover;
    }

    &:focus {
        color: $theme-color;
    }
}

.uk-tab > .uk-active > a {
    color: $theme-color-hover;
    border-bottom: 3px solid $theme-color-hover;
}

</style>
