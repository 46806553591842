<template>
    <div class="generic-error-message">

        <span v-if="genericErrorMessage">
            <span v-html="genericErrorMessage"/>
        </span>
        <span v-else-if="!error || !error.response">
            {{ $t('connectionLostMessage') }}
        </span>
        <span v-else-if="customErrorExists">
            <span v-for="customError in customErrors" :key="customError.code">
                <span v-if="error.response.status === customError.code">
                    {{ customError.message }}
                </span>
            </span>
        </span>
        <span v-else-if="error.response.status === 429">
            <countdown :end-time="new Date(error.response.headers['x-ratelimit-reset']  * 1000).getTime()">
                <span slot="process" slot-scope="{ timeObj }">
                    <span v-if="timeObj.ceil.m === 1">
                        {{ $t('tooManyRequestsMessageMinute', {minutes: timeObj.ceil.m}) }}
                    </span>
                    <span v-else>
                        {{ $t('tooManyRequestsMessageMinutes', {minutes: timeObj.ceil.m}) }}
                    </span>
                </span>
            </countdown>
        </span>
        <span v-else-if="error.response.status === 500">
            {{ $t('serverErrorMessage') }}
        </span>
        <span v-else>
            {{ $t('unexpectedErrorMessage') }}
        </span>

    </div>
</template>

<script>

export default {
    name: 'ErrorMessage',
    props: {
        error: Error,
        customErrors: {
            code: Number,
            message: String,
        },
        genericErrorMessage: String,
    },
    computed: {
        customErrorExists() {
            if (!this.error || !this.error.response || !this.customErrors) {
                return false;
            }

            for (let i = 0, j = this.customErrors.length; i < j; i++) {
                if (this.customErrors[i].code === this.error.response.status) {
                    return true;
                }
            }

            return false;
        }
    }
}

</script>

<style lang="scss" scoped>

</style>