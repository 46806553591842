import store from '@/store'
import {i18n} from '@/main'
import VueCookie from "vue-cookie";

export default function adminMiddleware({next, router}) {
    if (!store.state.distributor && VueCookie.get('distributor') !== "true") {
        return router.push('/' + i18n.locale + '/account')
    }

    return next();
}