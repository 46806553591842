<template>
    <div class="register-confirm">

        <Seo :title="$t('confirmRegistration')"/>
        <SiteTitle :name="$t('confirmRegistration')" class="uk-text-center"/>

        <div v-if="!result" class="uk-text-center">
            <InfoBox>
                <div uk-spinner/>
            </InfoBox>
        </div>
        <div v-else-if="[200, 208].includes(result)" class="uk-animation-fade">
            <InfoBox v-if="result === 200" mode="success" v-html="$t('registrationConfirmedMessage')"/>
            <InfoBox v-else v-html="$t('confirmAlreadyDoneMessage')"/>

            <div class="uk-margin-medium-top"/>
            <LinkItem to="login">
                <Button :text="$t('gotoLogin')" is-large is-max mode="theme"/>
            </LinkItem>
        </div>
        <div v-else class="uk-animation-fade">
            <InfoBox mode="danger">
                <ErrorMessage :custom-errors="[{code: 422, message: $t('registerTokenInvalid')}]" :error="result"/>
            </InfoBox>

            <div class="uk-margin-medium-top"/>
            <Button :action="confirm" :text="$t('tryAgain')" has-action is-large is-max mode="theme"/>
            <Button v-if="result!==504 || false" :text="$t('resendConfirmation')"
                    :uk-tooltip="'title:'+$t('notAvailableYet')"
                    is-large is-max mode="theme"/>
        </div>


    </div>
</template>

<script>

import Button from "@/components/generic/Button";
import SiteTitle from "@/components/SiteTitle";
import InfoBox from "@/components/generic/InfoBox";
import LinkItem from "@/components/generic/LinkItem";
import {postUserByIdConfirm} from "@/client/vue-api-client";
import ErrorMessage from "@/components/generic/ErrorMessage";
import Seo from "@/components/generic/Seo";

export default {
    name: 'RegisterConfirm',
    components: {Seo, ErrorMessage, LinkItem, InfoBox, SiteTitle, Button},
    data() {
        return {
            result: 0,
        }
    },
    created() {
        this.confirm();
    },
    methods: {
        confirm() {
            this.result = 0;

            let self = this;
            postUserByIdConfirm({
                id: self.$route.params.token,

            }).then(function () {
                self.result = 200;

            }).catch(function (e) {
                self.checkGenericError(e);

                self.result = e;

                if (e && e.response) {
                    if (e.response.data.invalidKeys && e.response.data.invalidKeys[0].failedTag === "uuid4_rfc4122") {
                        self.result = 422;
                    }

                    if (e.response.data.invalidKeys && e.response.data.invalidKeys[0].failedTag === "6451e594-3e05-4c09-a434-5494a75d08bf") {
                        self.result = 208;
                    }
                }

                self.loading = false;
            })
        },
    },
}

</script>


<style lang="scss" scoped>

</style>
